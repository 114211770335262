import _ from 'lodash'

export function getLogicStatus(page, currentPageIndex, storyErrors) {
	const hasInLogic = currentPageIndex !== 0
	const hasOutLogicError = hasOutError(page, storyErrors)
	const isOutLogicSet = !_.isEmpty(page.logics)
	const isInLogicSet = page.on_enter?.enabled

	return {
		hasInLogic,
		hasOutLogicError,
		isOutLogicSet,
		isInLogicSet,
	}

	function hasOutError() {
		const currentPageErrors = _.find(storyErrors, ['_id', page?._id])

		if (
			currentPageErrors &&
			_.some(currentPageErrors.errors, (error) =>
				_.includes(
					[
						'settings-logics-redirect',
						'settings-logics-conditions_field',
						'settings-logics-conditions_value',
					],
					error.error_type
				)
			)
		) {
			return true
		}

		return false
	}
}
