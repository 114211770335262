import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCustomerIdentification } from 'redux/customer/selectors'

export default function useUsetifulIdentification() {
	const customerIdentification = useSelector(getCustomerIdentification)

	useEffect(() => {
		if (customerIdentification?._id) {
			window.usetifulTags = {
				userId: customerIdentification._id,
				username: customerIdentification.username,
				language: customerIdentification.locale,
			}
		}
	}, [customerIdentification])
}
