import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { loginFail, logoutSuccess } from 'redux/customer/slice'
import {
	ISubscription,
	// IChargebeeSubscription,
	// IAppsumoLicense
} from 'redux/subscription/types'
import OrganizationsSvc from 'services/OrganizationsSvc'

export const fetchSubscription = createAsyncThunk(
	'subscription/fetchSubscription',
	async (organization_id, thunkAPI) => {
		const subscription = await OrganizationsSvc.getSubscription(organization_id)
		return subscription
	}
)

// Default Starter Offer values
const initialState: ISubscription = {
	_id: undefined,
	object: 'subscription',
	owner_id: undefined,
	feature_set: {},
}

export const slice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {
		updateSubscription(state, action) {
			const subscription = action.payload
			if (subscription) {
				return subscription
			}
			return state
		},
		partialUpdateSubscription(state, action) {
			const { path, value } = action.payload
			_.set(state, path, value)
			return state
		},
		updateChargeBeeSubscription(state, action) {
			const subscription = action.payload
			_.assign(state, subscription)
		},
		resetSubscription() {
			return initialState
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(logoutSuccess, (state, action) => {
				return initialState
			})
			.addCase(loginFail, (state, action) => {
				return initialState
			})
			.addCase(fetchSubscription.fulfilled, (state, action) => {
				return action.payload
			})
	},
})

export const { updateSubscription, partialUpdateSubscription, updateChargeBeeSubscription, resetSubscription } =
	slice.actions

export default slice.reducer
