export const fr = {
	'languageview.next': 'Suivant',
	'languageview.menu': 'Menu',
	'languageview.restart': 'Recommencer',
	'languageview.return': 'Précédent',
	'languageview.send': 'Envoyer',
	'languageview.start': 'Bouton Démarrer',
	'languageview.invalid': 'Champ invalide',
	'languageview.empty': 'Champ vide',
	'languageview.email': 'Email',
	'languageview.phone': 'Téléphone',
	'languageview.confirm': 'Confirmer',

	'languageview.describe': 'Configurez le texte contenu dans les éléments de votre Story.',
	'languageview.reset_default': 'Rétablir par défaut',
	'languageview.reset_default.warning': 'Ceci réinitialisera les valeurs actuelles par défaut',
}
