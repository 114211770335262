import { createSelector } from '@reduxjs/toolkit'
import { DEFAULT_WORKSPACE_AVATAR } from 'lib/constants'

export const projectSelector = (state, _props) => state.project

export const getCurrentProjectId = createSelector(projectSelector, (state) => state._id)

export const getCurrentProjectName = createSelector(projectSelector, (state) => state.name)

export const getProjectRecentlyUsed = createSelector(projectSelector, (state) => state.recently_used)

export const getDefaultLoaderUrl = createSelector(projectSelector, (state) => state.loader ?? DEFAULT_WORKSPACE_AVATAR)
