import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const LeaderboardsSvc = {
	get,
	getAll,
	create,
	update,
	del,
	exportLeaderboardData,
	resetLeaderboardData,
	isLeaderboardPublished,
	createLeaderboardInStory,
	deleteLeaderboardInStory,
}

export default LeaderboardsSvc

async function getAll({ project }) {
	try {
		const { data } = await axios.get(`${API_URL}/leaderboards/`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		if (!data.success) {
			throw new Error('Could not retrieve leaderboards')
		}
		return data.leaderboards
	} catch (error) {
		return []
	}
}

async function get(leaderboard_id) {
	try {
		const { data } = await axios.get(`${API_URL}/leaderboards/${leaderboard_id}`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		if (!data.success) {
			throw new Error('Could not retrieve leaderboard')
		}
		return data.leaderboard
	} catch (error) {
		return []
	}
}

async function create({ project, leaderboard }) {
	try {
		const { data } = await axios.post(`${API_URL}/leaderboards/`, leaderboard, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data
	} catch (error) {
		return {
			success: false,
		}
	}
}

async function createLeaderboardInStory({ project, name, story_id }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/leaderboards/story/${story_id}`,
			{
				name,
				stories: [story_id],
			},
			{
				params: {
					project,
				},
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return data
	} catch (error) {
		return {
			success: false,
		}
	}
}

async function update({ project, leaderboard }) {
	try {
		const { data } = await axios.put(
			`${API_URL}/leaderboards/${leaderboard._id}`,
			{ leaderboard },
			{
				params: {
					project,
				},
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return {
			success: data.success,
		}
	} catch (err) {
		return {
			success: false,
		}
	}
}

async function del({ project, leaderboard_id }) {
	try {
		const { data } = await axios.delete(`${API_URL}/leaderboards/${leaderboard_id}`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data
	} catch (error) {
		return {
			success: false,
		}
	}
}

async function deleteLeaderboardInStory({ project, leaderboard_id }) {
	try {
		const { data } = await axios.delete(`${API_URL}/leaderboards/story/${leaderboard_id}`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data
	} catch (error) {
		return {
			success: false,
		}
	}
}

async function exportLeaderboardData({ project, leaderboard_id }) {
	if (!project) {
		throw new Error('exportLeaderboardData() - Invalid project')
	}

	return axios.get(`${API_URL}/leaderboards/${leaderboard_id}/export`, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}

async function resetLeaderboardData({ project, leaderboard_id }) {
	if (!project) {
		throw new Error('resetLeaderboardData() - Invalid project')
	}

	return axios.delete(`${API_URL}/leaderboards/${leaderboard_id}/reset`, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}

async function isLeaderboardPublished({ project, leaderboard_id }) {
	if (!project) {
		throw new Error('isLeaderboardPublished() - Invalid project')
	}

	return axios.get(`${API_URL}/leaderboards/${leaderboard_id}/status`, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}
