export const TEXT_EFFECT_LIST = [
	{
		name: 'none',
		img: '/static/text_effect_imgs/none.png',
	},
	{
		name: 'shadow',
		img: '/static/text_effect_imgs/shadow.png',
	},
	{
		name: 'hollow',
		img: '/static/text_effect_imgs/hollow.png',
	},
	{
		name: 'neon',
		img: '/static/text_effect_imgs/neon.png',
	},
	{
		name: 'echo',
		img: '/static/text_effect_imgs/echo.png',
	},
	{
		name: 'otto',
		img: '/static/text_effect_imgs/otto.png',
	},
	{
		name: 'relief',
		img: '/static/text_effect_imgs/relief.png',
	},
	{
		name: 'printer',
		img: '/static/text_effect_imgs/printer.png',
	},
	{
		name: 'glow',
		img: '/static/text_effect_imgs/glow.png',
	},
]

// text-shadow: [x-offset] [y-offset] [blur-radius] [color];

export function initTextEffect(effect, color) {
	switch (effect) {
		case 'shadow':
			return {
				'inner_style.textShadow': `5px 5px 0 ${color.replace(/[^,]+(?=\))/, ' 0.4')}`,
			}
		case 'hollow':
			return {
				'inner_style.WebkitTextFillColor': 'rgba(0, 0, 0, 0)',
				'inner_style.WebkitTextStrokeWidth': '1px',
				'inner_style.WebkitTextStrokeColor': `${color}`,
			}
		case 'neon':
			return {
				'inner_style.filter': `drop-shadow(${color.replace(
					/[^,]+(?=\))/,
					' 0.95'
				)} 0px 0px 2px) drop-shadow(${color.replace(
					/[^,]+(?=\))/,
					' 0.75'
				)} 0px 0px 10px) drop-shadow(${color.replace(/[^,]+(?=\))/, ' 0.44')} 0px 0px 30px)`,
			}
		case 'echo':
			return {
				'inner_style.textShadow': `4px 4px 0px ${color.replace(
					/[^,]+(?=\))/,
					' 0.5'
				)}, 8px 8px 0px ${color.replace(/[^,]+(?=\))/, ' 0.3')}`,
			}
		case 'otto':
			return {
				'inner_style.color': 'rgba(255, 255, 255, 1)',
				'inner_style.textShadow': `0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.3), 0 3px 5px rgba(0,0,0,0.2), 0 5px 10px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.2), 0 20px 20px rgba(0,0,0,0.15)`,
			}
		case 'closed':
			return {
				'inner_style.color': 'rgba(32, 44, 45, 1)',
				'inner_style.textShadow':
					'0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5',
			}
		case 'relief':
			return {
				'inner_style.color': 'rgba(224, 239, 242, 1)',
				'inner_style.textShadow': '-4px 3px 0 #3a50d9, -14px 7px 0 #0a0e27',
			}
		case 'printer':
			return {
				'inner_style.color': 'rgba(188, 46, 30, 1)',
				'inner_style.textShadow':
					'0 1px 0px #378ab4, 1px 0 0px #5dabcd, 1px 2px 1px #378ab4, 2px 1px 1px #5dabcd, 2px 3px 2px #378ab4, 3px 2px 2px #5dabcd, 3px 4px 2px #378ab4, 4px 3px 3px #5dabcd, 4px 5px 3px #378ab4, 5px 4px 2px #5dabcd, 5px 6px 2px #378ab4, 6px 5px 2px #5dabcd, 6px 7px 1px #378ab4, 7px 6px 1px #5dabcd, 7px 8px 0px #378ab4, 8px 7px 0px #5dabcd',
			}
		case 'glow':
			return {
				'inner_style.color': 'rgba(68, 68, 68, 1)',
				'inner_style.textShadow':
					'1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc, 1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc, 5px 6px 1px #eee, 7px 6px 1px #ccc',
			}
	}
}
