// Solution for custom implementation of transient props in emotion
// Adapted from: https://github.com/emotion-js/emotion/issues/2193#issuecomment-1039153870
import type { CreateStyled, StyledOptions } from '@emotion/styled'
import _styled from '@emotion/styled'

const styled = ((element: any, config?: StyledOptions<any>) =>
	_styled(element, {
		shouldForwardProp: (propName: string) => !propName.startsWith('$'),
		...config,
	})) as CreateStyled

const tags =
	'a|abbr|address|area|article|aside|audio|b|base|bdi|bdo|big|blockquote|body|br|button|canvas|caption|cite|code|col|colgroup|data|datalist|dd|del|details|dfn|dialog|div|dl|dt|em|embed|fieldset|figcaption|figure|footer|form|h1|h2|h3|h4|h5|h6|head|header|hgroup|hr|html|i|iframe|img|input|ins|kbd|keygen|label|legend|li|link|main|map|mark|marquee|menu|menuitem|meta|meter|nav|noscript|object|ol|optgroup|option|output|p|param|picture|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|source|span|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|u|ul|var|video|wbr|circle|clipPath|defs|ellipse|foreignObject|g|image|line|linearGradient|mask|path|pattern|polygon|polyline|radialGradient|rect|stop|svg|text|tspan'.split(
		'|'
	)

tags.forEach((tagName) => {
	;(styled as any)[tagName] = styled(tagName as keyof JSX.IntrinsicElements)
})

export default styled
