import axios from 'axios'
import { API_URL } from 'lib/constants'
import dayjs from 'lib/dayjs'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
import * as localStorageSvc from 'services/LocalStorageSvc'

const WidgetsSvc = {
	get,
	getAll,
	create,
	update,
	duplicate,
	del,
	getBannerMetricsByCategory,
	getBannerMetrics,
	getBannerStoryClicks,
	getStoryBubbleMetrics,
}

export default WidgetsSvc

async function get({ _id, fields }) {
	try {
		const { data } = await axios.get(`${API_URL}/settings/widgets/${_id}`, {
			params: {
				fields,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		if (!data.success) {
			throw new Error('Invalid widget')
		}

		return data.widget
	} catch (err) {
		return []
	}
}

async function getBannerMetricsByCategory({ _id, stories_id, platform, since, until, interval, category, project }) {
	const body = {
		stories_id,
		platform,
		interval,
		category,
		project,
	}
	if (since) {
		body.since = dayjs(since).startOf('day').toISOString()
	}
	if (until) {
		body.until = dayjs(until).endOf('day').toISOString()
	}

	try {
		const { data } = await axios.post(
			`${API_URL}/settings/widgets/${_id}/metrics`,
			{
				...body,
			},
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return data.metrics
	} catch (err) {
		return []
	}
}

async function getAll({ project }) {
	try {
		const { data } = await axios.get(`${API_URL}/settings/widgets`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		if (!data.success) {
			throw new Error('Could not retrieve widgets')
		}
		return data.widgets
	} catch (err) {
		return []
	}
}

async function create({ project, isAmp, bannerType }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/settings/widgets`,
			{
				project,
				isAmp,
				banner_type: bannerType,
			},
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return data
	} catch (err) {
		return {
			success: false,
		}
	}
}

async function duplicate({ widget }) {
	try {
		const duplicatedWidget = cloneDeep(widget)
		delete duplicatedWidget._id

		const name = (duplicatedWidget.name || 'Widget').replace('- Copy', '').trim()
		duplicatedWidget.name = `${name} - Copy`
		duplicatedWidget.stories = map(duplicatedWidget.stories, (story) => ({
			story_id: story.story_id,
		}))

		const { data } = await axios.post(`${API_URL}/settings/widgets`, duplicatedWidget, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data
	} catch (err) {
		return {
			success: false,
		}
	}
}

async function update({ widget, project }) {
	try {
		if (!widget._id) {
			throw new Error('Invalid widget _id')
		}

		const { data } = await axios.put(
			`${API_URL}/settings/widgets/${widget._id}`,
			{
				widget,
				project,
			},
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return {
			success: data.success,
		}
	} catch (err) {
		return {
			success: false,
		}
	}
}

async function del({ _id, project }) {
	try {
		if (!_id) {
			throw new Error('deleteWidget() - Invalid _id')
		}

		const { data } = await axios.delete(`${API_URL}/settings/widgets/${_id}`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data
	} catch (err) {
		return {
			sucess: false,
		}
	}
}

async function getBannerMetrics({ _id, platform, since, until, project }) {
	const body = {
		platform,
		project,
	}
	if (since) {
		body.since = dayjs(since).startOf('day').toISOString()
	}
	if (until) {
		body.until = dayjs(until).endOf('day').toISOString()
	}

	try {
		const { data } = await axios.post(
			`${API_URL}/widgetEvents/${_id}/metrics`,
			{
				...body,
			},
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return data.metrics
	} catch (err) {
		return []
	}
}

async function getBannerStoryClicks({ _id, stories_id, platform, since, until, project, isAmp }) {
	const body = {
		stories_id,
		platform,
		project,
		isAmp,
	}
	if (since) {
		body.since = dayjs(since).startOf('day').toISOString()
	}
	if (until) {
		body.until = dayjs(until).endOf('day').toISOString()
	}

	try {
		const { data } = await axios.post(
			`${API_URL}/widgetEvents/${_id}/story-banner-clicks`,
			{
				...body,
			},
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return data.metrics
	} catch (err) {
		return []
	}
}

async function getStoryBubbleMetrics({ story_id, platform, since, until, project }) {
	const params = {
		platform,
		project,
	}
	if (since) {
		params.since = dayjs(since).startOf('day').toISOString()
	}
	if (until) {
		params.until = dayjs(until).endOf('day').toISOString()
	}

	try {
		const { data } = await axios.get(`${API_URL}/widgetEvents/story/${story_id}/metrics`, {
			params,
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data.metrics
	} catch (err) {
		return []
	}
}
