import '@emotion/react';
import createCache from '@emotion/cache';
import type { Theme as MuiTheme } from '@mui/material/styles';
import { alpha, createTheme } from '@mui/material/styles';
import NextLink from 'next/link';
import { forwardRef } from 'react';
import { snackeet_background, snackeet_background_2, snackeet_blue, snackeet_dark_blue, snackeet_dark_yellow, snackeet_light_blue, snackeet_light_yellow, snackeet_text, snackeet_yellow } from './colors';

// prepend: true moves MUI styles to the top of the <head> so they're loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export function createEmotionCache() {
  const cache = createCache({
    key: 'css',
    prepend: true
  });
  cache.compat = true;
  return cache;
}

// Extend MUI theme with custom background colors
declare module '@mui/material/styles' {
  interface TypeBackground {
    primary?: string;
    secondary?: string;
  }
}
declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
}
const LinkBehaviour = forwardRef<HTMLAnchorElement, any>(function LinkBehaviour(props, ref) {
  return <NextLink ref={ref} {...props} />;
});

// Create intermediary theme to use palette & typography
// Inspired from https://stackoverflow.com/a/69264263
const {
  palette,
  typography,
  breakpoints
} = createTheme({
  palette: {
    primary: {
      main: snackeet_blue,
      light: snackeet_light_blue,
      dark: snackeet_dark_blue,
      contrastText: '#fff'
    },
    secondary: {
      main: snackeet_yellow,
      light: snackeet_light_yellow,
      dark: snackeet_dark_yellow,
      contrastText: '#fff'
    },
    background: {
      primary: snackeet_background,
      secondary: snackeet_background_2
    },
    text: {
      primary: snackeet_text
    }
  },
  typography: {
    fontFamily: `'Poppins', sans-serif`,
    h1: {
      fontSize: '18px'
    },
    h2: {
      fontWeight: 600,
      fontSize: '16px'
    },
    h3: {
      fontSize: '14px'
    },
    h4: {
      fontWeight: 600,
      fontSize: '14px'
    },
    h5: {
      fontWeight: 600,
      fontSize: '12px'
    },
    body1: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '0.875rem'
      // lineHeight: '17px',
    },
    body2: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '0.75rem'
      // lineHeight: '16px',
    }
  },
  // Use old breakpoints sizes: https://mui.com/material-ui/migration/v5-component-changes/#breakpoint-sizes
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
});

// The Material UI theme
export const snackeetTheme = {
  palette,
  typography,
  breakpoints,
  components: {
    // Update body size as seen in https://mui.com/material-ui/migration/v5-component-changes/#update-body-font-size
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...typography.body2,
          backgroundColor: palette.background.primary
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          outline: 'none'
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '8px 0'
        },
        vertical: {
          margin: 0
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: '16px'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            marginTop: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 'inherit'
          }
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          display: 'block'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        LinkComponent: LinkBehaviour
      },
      styleOverrides: {
        root: {
          fontWeight: 600
        },
        textPrimary: {
          color: palette.primary.dark,
          '&:hover': {
            backgroundColor: alpha(palette.primary.dark, palette.action.hoverOpacity)
          }
        },
        textSecondary: {
          color: palette.secondary.main,
          '&:hover': {
            backgroundColor: alpha(palette.secondary.main, palette.action.hoverOpacity)
          }
        },
        outlinedPrimary: {
          color: palette.primary.dark,
          border: `1px solid ${alpha(palette.primary.dark, 0.5)}`,
          '&:hover': {
            border: `1px solid ${palette.primary.dark}`,
            backgroundColor: alpha(palette.primary.dark, palette.action.hoverOpacity)
          }
        },
        outlinedSecondary: {
          color: palette.secondary.main,
          border: `1px solid ${alpha(palette.secondary.main, 0.5)}`,
          '&:hover': {
            border: `1px solid ${palette.secondary.main}`,
            backgroundColor: alpha(palette.secondary.main, palette.action.hoverOpacity)
          }
        },
        containedPrimary: {
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.dark,
          '&:hover': {
            backgroundColor: palette.primary.main,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: palette.primary.dark
            }
          }
        },
        containedSecondary: {
          color: palette.primary.dark,
          backgroundColor: palette.secondary.main,
          '&:hover': {
            backgroundColor: palette.secondary.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: palette.secondary.main
            }
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.common.white,
          color: 'rgba(3, 2, 2, 0.87)',
          padding: 10,
          fontSize: 14,
          filter: `drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))`,
          borderRadius: 10
        },
        arrow: {
          color: palette.common.white
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          display: 'flex'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour
      }
    }
  }
};