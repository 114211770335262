import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_APP_LOCALE } from 'lib/constants'
import _ from 'lodash'
import * as localStorageSvc from 'services/LocalStorageSvc'

const getInitialState = () => ({
	isLoggedIn: localStorageSvc.isLoggedIn(),
	locale: DEFAULT_APP_LOCALE,
	_id: undefined,
	role: undefined,
	email: undefined,
	username: undefined,
	chargebee_id: undefined,
	google_id: undefined,
	onboardingFeatures: {
		emptyBackgroundHelper: true,
	},
})

export const slice = createSlice({
	name: 'customer',
	initialState: getInitialState(),
	reducers: {
		logoutSuccess: logoutReducer,
		loginFail: logoutReducer,
		loginSuccess(state, action) {
			localStorageSvc.setLocalToken(action.payload)
			state.isLoggedIn = true
		},
		updateCustomer(state, action) {
			const customer = action.payload
			_.assign(state, customer)
		},
		updateOnboardingFeatures(state, action) {
			for (const path in action.payload) {
				_.set(state, path, action.payload[path])
			}
		},
	},
})

export const { logoutSuccess, loginFail, loginSuccess, updateCustomer, updateOnboardingFeatures } = slice.actions

export default slice.reducer

function logoutReducer(state) {
	localStorageSvc.resetLocalToken()
	localStorageSvc.elementsToCopyStorage.reset()

	state = {
		...getInitialState(),
		isLoggedIn: false,
		locale: state.locale, // Keep previous locale
	}
	return state
}
