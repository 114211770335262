import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit'
import { Context, createWrapper, MakeStore } from 'next-redux-wrapper'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'

import rootReducer, { RootState } from './redux'
import storage from './storage'

const persistConfig = {
	keyPrefix: 'app:',
	key: 'state',
	version: 1,
	storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	devTools: true,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
})

;(store as any).__persistor = persistStore(store) // Nasty hack

// create a makeStore function
const makeStore: MakeStore<RootState> = (context: Context) => store

// export an assembled wrapper
export const wrapper = createWrapper<RootState>(makeStore, { debug: false })

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
