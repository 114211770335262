export const fr = {
	'story_settings.tab.title.language': 'Langue',
	'story_settings.tab.title.users': 'Gestion d’utilisateurs',
	'story_settings.tab.title.notifications': 'Notifications Par Email',
	'story_settings.tab.title.confirmation': 'Réponse Automatique',
	'story_settings.tab.title.versions': 'Versions',
	'story_settings.tab.title.display': 'Affichage',
	'story_settings.tab.title.display_and_navigation': 'Affichage et Navigation',
	'story_settings.tab.title.wallpaper': `Fond d'écran`,
	'story_settings.tab.title.activation': 'Activation',
	'story_settings.tab.title.activation_and_expiration': 'Activation & Expiration',
	'story_settings.tab.title.seo': 'Configuration SEO',
	'story_settings.tab.title.tags': 'Balises de Template',
	'story_settings.tab.title.brand': 'Appliquer Marque Prédéfinie',
	'story_settings.tab.title.api_template': `Utiliser comme modèle d'API`,

	'story_settings.language.title': 'Édition de la langue',

	// Users
	'story_settings.users.title': 'Gestion d’utilisateurs',
	'story_settings.users.refresh_user.title': 'Oublier la session <b>dans le navigateur</b>',
	'story_settings.users.refresh_user.description': `L'activation de cette fonctionnalité garantit que les <i>utilisateurs revenant</i> sont traités comme de nouveaux, sans pré-remplissage des réponses fournies précédemment.
		<br></br>👉 Idéal pour assurer des réponses fraîches à chaque visite.`,
	'story_settings.users.redirect_on_completed.title': 'Redirection à la fin de la story',
	'story_settings.users.redirect_on_completed.description': `L'activation de cette fonctionnalité <i>redirige les utilisateurs revenant vers la page de fin</i> une fois la story terminée.
	<br></br>👉 Adapté pour afficher des résultats comme les scores de quiz ou des pages de résumé.`,
	'story_settings.users.delete_all_users.button.idle': 'Supprimer tous les utilisateurs',
	'story_settings.users.delete_all_users.button.processing': 'Suppression en cours...',

	// Notifications
	'story_settings.notifications.title': 'Configuration des notifications',
	'story_settings.notifications.answers.title': 'Notification des answers',
	'story_settings.notifications.answers.message': `Envoyer des notifications chaque fois qu'un utilisateur termine une Story ou fait une interaction.`,
	'story_settings.notifications.answers.completedOnly_title': `Envoyer uniquement une fois terminé`,
	'story_settings.notifications.answers.completedOnly_description': `N'envoyez des notifications que lorsque les utilisateurs terminent la story.`,
	'story_settings.notifications.answers.hasContactOnly_title': 'Contact Collecté',
	'story_settings.notifications.answers.hasContactOnly_description': `Envoyer une notification lorsque les utilisateurs donnent un contact (e-mail ou téléphone).<br></br>Disponible uniquement lorsque la variable <b>email</b> ou <b>phone</b> est attribuée.`,
	'story_settings.notifications.answers.sendTo_title': `Envoyer des notifications à`,
	'story_settings.notifications.answers.sendTo_description': `Une notification avec les données de réponse sera envoyée à l'e-mail.`,
	'story_settings.notifications.answers.sendTo_emails_helper': `Utilisez une virgule pour séparer plusieurs adresses`,
	'story_settings.notifications.answers.sendTo_placeholder': `Veuillez saisir des e-mails pour les notifications`,
	'story_settings.notifications.answers.sendTo_edit_button': `Modifier`,
	'story_settings.notifications.answers.sendTo_isError': `Veuillez saisir des e-mails valides`,

	// Automatic reply
	'story_settings.reply.header': 'Configuration de la réponse automatique',
	'story_settings.reply.title': 'Réponse automatique',
	'story_settings.reply.description': `Envoyer un e-mail au répondant qui <b>termine</b> la Story.<br></br>La variable <b>"email"</b> doit être attribuée pour activer cette fonctionnalité.`,
	'story_settings.reply.from.title': `E-mail d'expéditeur`,
	'story_settings.reply.from.description': `Pour utiliser une adresse email personnalisée, veuillez contacter notre support client via`,
	'story_settings.reply.replyTo.title': 'E-mail de réponse',
	'story_settings.reply.replyTo.description': `Adresse à laquelle le répondant pourra vous répondre. Si pas spécifiée, ce sera par défaut <b>noreply@snackeet.com</b>`,
	'story_settings.reply.replyTo.placeholder': 'Veuillez entrer un email pour répondre',
	'story_settings.reply.replyTo.error.invalid_email': 'Veuillez entrer un email valide',
	'story_settings.reply.replyTo.button.update': `Modifier`,
	'story_settings.reply.subject.title': 'Sujet',
	'story_settings.reply.subject.description': `Utilisez {{params.variable}} pour inclure les données de la variable de votre Story, par ex. {{params.firstname}}`,
	'story_settings.reply.subject.placeholder': `Exemple: Merci de vous être inscrit à cet événement, {{params.firstname}}`,
	'story_settings.reply.imgURL.title': 'Image',
	'story_settings.reply.imgURL.description': `L'image sera affichée au-dessus de l'e-mail étiré sur toute la largeur du conteneur`,
	'story_settings.reply.message.title': 'Message',
	'story_settings.reply.message.description': `Entrez le texte de votre réponse automatique. Utilisez {{params.variable}} pour inclure les données de la variable de votre Story, par ex. {{params.firstname}}`,
	'story_settings.reply.message.placeholder': `Cher {{params.firstname}},\n\n\nMerci pour votre inscription!\n\n\nCordialement,\nMon équipe.`,

	'story_settings.reply.warning_modal.title': 'Activer la réponse automatique',
	'story_settings.reply.warning_modal.sub_title': `Impossible d'activer la réponse automatique`,
	'story_settings.reply.warning_modal.description': `La variable <b>"email"</b> doit être attribuée pour activer cette fonctionnalité.`,
	'story_settings.reply.waring_banner.title': 'Améliorez votre forfait pour activer cette fonctionnalité !',

	// Versions
	'story_settings.versions.title': 'Édition des versions',
	'story_settings.versions.draft': 'Version Brouillon',
	'story_settings.versions.published': 'Version Publiée',
	'story_settings.buttons.reroll': 'Revenir à la version publiée',
	'story_settings.versions.reroll_description': `Toutes vos modifications sur la version de brouillon seront remplacées par la version publiée.<br></br><br></br>Vous pouvez annuler ces changements avec le bouton Undo en haut à gauche à côté de la zone d'édition.`,

	// Language
	'story_settings.language.sub_title': `Langue de la Story`,

	// Display
	'story_settings.display.title': `Configuration d'affichage`,
	'story_settings.display.progress_bar.title': 'Affichage barre de progression',
	'story_settings.display.progress_bar.message': `Activer/Désactiver la barre de progression de votre story.`,
	'story_settings.display.no_branding.title': 'Affichage de la marque',
	'story_settings.display.no_branding.message': `Afficher/Masquer le logo Snackeet.`,
	'story_settings.display.no_branding.subscribe': `Passer à l'abonnement Croissant ou Donut pour pouvoir modifier cette option.`,
	'story_settings.display.desktop_wallpaper.title': `Fond d'écran`,
	'story_settings.display.desktop_wallpaper.description': `Définissez une image en tant que fond d'écran.<br></br>Par défaut, le fond de la page actuelle sera appliqué avec un effet de flou en tant que fond d'écran.`,

	// Navigation
	'story_settings.navigation.title': 'Configuration de Navigation',
	'story_settings.navigation.navigation_arrows.title': 'Flèches de navigation',
	'story_settings.navigation.navigation_arrows.description':
		'Les utilisateurs pourront naviguer entre les pages en cliquant sur les flèches de navigation.',
	'story_settings.navigation.navigation_method.title': `Comportement de navigation à l'appui`,
	'story_settings.navigation.navigation_method.description':
		'Les utilisateurs pourront naviguer entre les pages soit en tapant, soit en glissant.',
	'story_settings.navigation.navigation_errors.title': 'Erreurs de navigation',
	'story_settings.navigation.navigation_errors.description': `Messages affichés lorsque l'utilisateur essaye de naviguer vers une autre page alors qu'il n'est pas autorisé.`,
	'story_settings.navigation.navigation_errors.error.back': 'Précédent',
	'story_settings.navigation.navigation_errors.error.next': 'Prochain',

	// Expiration
	'story_settings.expiration.title': 'Expiration de la Story',
	'story_settings.expiration.description': `Définissez une date d'expiration pour la story, après laquelle un bandeau apparaîtra affichant un message.`,
	'story_settings.expiration.active': `Date d'expiration`,
	'story_settings.expiration.end_date': 'Date de fin',

	// Status
	'story_settings.status.title': 'Statut de la story:',
	'story_settings.status.change_status.title': 'Activer/Désactiver la story',
	'story_settings.status.change_status.description': `Lorsqu'une story est désactivée, les liens de partage ne fonctionnent plus, quel que soit le moyen de partage.<br></br>Activez la story pour les faire fonctionner.`,
	'story_settings.schedule.title': 'Programmez la story (facultatif)',
	'story_settings.schedule.description': `Vous pouvez définir une période pendant laquelle la story est active et disponible pour vos utilisateurs.`,
	'story_settings.schedule.button': `Définir une période`,
	'story_settings.schedule.startDate.title': `Date de début`,
	'story_settings.schedule.endDate.title': `Date de fin`,
	'story_settings.schedule.timezone.title': `Fuseau horaire`,

	// Publisher
	'story_settings.publisher.setting_header': `Paramètres de la Story`,
	'story_settings.publisher.story_title': `Titre de la Story`,
	'story_settings.publisher.story_language': `Langue de la Story`,

	'story_settings.publisher.publisher_header': `Paramètres de l'éditeur`,
	'story_settings.publisher.publisher_name': `Nom de l'éditeur`,
	'story_settings.publisher.publisher_name_placeholder': `Entrez le nom de l'éditeur`,
	'story_settings.publisher.publisher_logo': 'Logo',
	'story_settings.publisher.publisher_logo_description': `Le logo doit être au format 1:1 et 92x92px ou plus`,
	'story_settings.publisher.publisher_preview': 'Aperçu',
	'story_settings.publisher.publisher_poster_portrait': `Affiche Portrait`,
	'story_settings.publisher.publisher_poster_portrait_description': `L'affiche au format portrait doit avoir un format d'image de 3:4 et 640x853px ou plus`,
	'story_settings.publisher.publisher_poster_square': 'Affiche carrée',
	'story_settings.publisher.publisher_poster_square_description': `L'affiche carrée doit avoir un rapport hauteur/largeur de 1:1 et 640x640px ou plus`,
	'story_settings.publisher.publisher_poster_landscape': 'Affiche Paysage',
	'story_settings.publisher.publisher_poster_landscape_description': `L'affiche paysage doit avoir un format d'image 4:3 et 853x640px ou plus`,

	// SEO Config
	'story_settings.seo_config.allow_indexing.title': 'Indexation des moteurs de recherche',
	'story_settings.seo_config.allow_indexing.description': `Rendez la story visible pour les robots des moteurs de recherche.`,
	'story_settings.seo_config.metas_header': 'Balises de meta',
	'story_settings.seo_config.meta_name': 'Nom de meta',
	'story_settings.seo_config.meta_content': 'Contenu de meta',
	'story_settings.seo_config.meta_add_button': 'Ajouter une nouvelle balise meta',
	'story_settings.seo_config.meta_keywords_title': 'Méta mots-clés',
	'story_settings.seo_config.meta_keywords_label': 'Mots clés',
	'story_settings.seo_config.meta_keywords_placeholder': 'Saisissez vos mots-clés',
	'story_settings.seo_config.meta_keywords_description':
		'Séparez les mots clés par des virgules. Par exemple : webStory, Snackeet, créateur de contenu',

	// Template tags
	'story_settings.template_tags.title': 'Template Tags',
	'story_setting.template_tags.description': `Choisissez des catégories pour la template`,

	// Brand preset
	'story_settings.brand_preset.current_brand': 'Marque Actuelle',
	'story_settings.brand_preset.brand_colors': 'Couleurs de la marque',
	'story_settings.brand_preset.brand_fontFamily': 'Famille de polices de marque',
	'story_settings.brand_preset.choose_new_brand': 'Choisissez une nouvelle marque :',
	'story_settings.brand_preset.change_brand_btn': 'Changer de marque',

	'story_settings.brand_preset.modal_title': 'Appliquer cette configuration à toute la story',
	'story_settings.brand_preset.modal_instruction':
		'Cette action modifiera le style de tous les boutons et champs de formulaire.',

	// API Template
	'story_settings.api_template.title': `Utilisez cette Story comme modèle d'API`,
	'story_settings.api_template.header': 'Activer pour définir les éléments à modifier',
	'story_settings.api_template.description': `Un identifiant unique peut être attribué à chaque élément de la page pour le marquer comme modifiable.<br></br>La structure des données permettant d'utiliser la Story comme modèle peut être obtenue avec le story_id: <b>{story_id}</b>.`,
}
