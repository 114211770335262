export const fr = {
	'modal.trigger.project_creation': 'Ajouter un espace de travail',
	'modal.header.project_creation': `Nouvel espace de travail`,
	'modal.header.project_creating': 'Création en cours...',
	'modal.project_creation.name': `Saisissez un nom...`,
	'modal.project_creation.description': 'Description',
	'modal.project_creation.avatar': `Image de l'espace de travail`,
	'modal.project_creation.error.missing_project_name': `Veuillez saisir le nom de l'espace de travail.`,
	'modal.project_creation.error.project_name_already_exists':
		'Cette espace de travail existe déjà. Veuillez saisir un autre nom.',
	'modal.project_deletion.header': `Supprimer l'espace de travail`,
	'modal.project_deletion.confirmation.header': 'Êtes-vous sûr(e) ?',
	'modal.project_deletion.confirmation.instructions': `<div>Attention ceci est une <b>opération irréversible</b>.</div><br></br><div>Pour confirmer, veuillez saisir le nom de l'espace de travail:</div>`,
	'modal.project_deletion.pending': 'Suppression en cours...',

	'modal.project_update.header': `Mettre à jour l'espace de travail`,
	'modal.project_update.pending': 'Mise à jour...',

	// Organization
	'modal.trigger.organization_creation': 'Créer une nouvelle organisation',
	'modal.header.organization_creation': `Création d'une nouvelle organisation`,
	'modal.header.organization_creation_button': 'Nouvelle organisation',
	'modal.header.license_activation': `Activer license`,
	'modal.header.organization_creating': 'Création en cours...',
	'modal.header.license_activating': 'Activation en cours...',
	'modal.organization_creation.name': `Saisissez un nom...`,
	'modal.organization_creation.slug': `Saisissez un ID unique...`,
	'modal.organization_creation.error.missing_organization_name': `Veuillez indiquer le nom de l'organisation.`,
	'modal.organization_creation.error.missing_organization_slug': `Veuillez indiquer l'ID de l'organisation.`,
	'modal.organization_leave.title': 'Quitter cette organisation ?',
	'modal.organization_kick.title': `Expulser ce membre de l'organisation ?`,
	'modal.organization_change_role.title': 'Changer le rôle du membre ?',
	'modal.organization_deletion.header': `Supprimer l'organisation`,
	'modal.organization_deletion.confirmation.instructions': `<div>Attention ceci est une <b>opération irréversible</b>.</div><br></br><div>Pour confirmer, veuillez saisir le nom de l'organisation:</div>`,
	'modal.organization_deletion.pending': 'Suppression en cours...',

	'modal.organization.creation_description.header': `Une nouvelle organisation sera liée à un nouvel <b>abonnement mensuel.</b>`,
	'modal.organization.creation_description.content': `Il est différent d'un <b>ESPACE DE TRAVAIL</b>.<br></br>Pour créer un <b>ESPACE DE TRAVAIL</b>, sélectionnez d'abord une organisation.`,
	'modal.organization.creation_description.support': `Si vous ne pouvez pas accéder à la page des espaces de travail, mettez à niveau votre abonnement ou contactez le support.`,

	'modal.organization_accept.forbidden.header': `Acceptation de l'invitation`,
	'modal.organization_accept.forbidden.title': `La demande d'adhésion à l'organisation a été refusée`,
	'modal.organization_accept.forbidden.description': `L'organisation a atteint le nombre maximum de membres.<br></br>Un avis de votre demande a été envoyé aux administrateurs de l'organisation.`,

	// Delete Story
	'modal.snackeet_deletion.name': 'Saisissez le nom de la story...',
	'modal.snackeet_deletion.header': 'Supprimer la story',
	'modal.snackeet_deletion.confirmation.header': 'Êtes-vous sûr(e) ?',
	'modal.snackeet_deletion.confirmation.instructions':
		'<div>Attention ceci est une <b>opération irréversible</b>.</div><br></br><div>Pour confirmer, veuillez saisir le nom de la story:</div>',
	'modal.snackeet_deletion.pending': 'Suppression en cours...',

	// Story Folder Selection
	'modal.story_folder_selection.description': 'Attribuer un dossier à la story <b>{ storyName }</b>',
	'modal.story_folder_selection.button.create': 'Créer un dossier',
	'modal.story_folder_selection.input.label': 'Nom du nouveau dossier',
	'modal.story_folder_selection.empty.title': `Vous n'avez pas de dossiers`,
	'modal.story_folder_selection.empty.instruction': `Ils sont utiles pour organiser vos stories dans un espace de travail.`,
	'modal.story_folder_creation.header': `Nouveau dossier`,
	'modal.story_folder_creation.input': `Saisissez le nom du dossier`,
	'modal.story_folder_deletion.title': `Supprimer le dossier`,
	'modal.story_folder_deletion.question': `Voulez-vous supprimer le dossier <b>{ folderName }</b> ?`,
	'modal.story_folder_deletion.warning': `Les stories de ce fichier seront déplacées dans le dossier par défaut.`,
	'modal.story_folder_edition.title': 'Éditer le dossier',
	'modal.story_folder_edition.input.label': 'Nom du dossier',
	'modal.story_folder_edition.button.edit': 'Mettre à jour',

	// Delete Widget Banner
	'modal.widget_banner_deletion.name': 'Saisissez le nom du widget..',
	'modal.widget_banner_deletion.header': 'Supprimer le widget',
	'modal.widget_banner_deletion.confirmation.header': 'Êtes-vous sûr(e) ?',
	'modal.widget_banner_deletion.confirmation.instructions':
		'<div>Attention ceci est une <b>opération irréversible</b>.</div><br></br><div>Pour confirmer, veuillez saisir le nom du widget:</div>',
	'modal.widget_banner_deletion.pending': 'Suppression en cours...',

	// Embed code Widget Banner
	'modal.widget_banner_code.header': 'Code à intégrer',
	'modal.widget_banner_code.instructions':
		'Collez ce code dans votre site Web entre les balises "<body>...</body>", là où vous voulez que le Widget apparaisse dans votre contenu.',
	'modal.widget_banner_code.button.copy': 'Copier ce code',

	// EditWidgetStoryModal
	'modal.widget_banner.story_edit.header': 'Éditer Story',
	'modal.widget_banner.story_edit.display_name': 'Nom',
	'modal.widget_banner.story_edit.caption': 'Légende',
	'modal.widget_banner.story_edit.button.preview': 'Aperçu de Story',
	'modal.widget_banner.story_edit.button.editor': "Ouvrir l'éditeur",
	'modal.widget_banner.story_edit.button.change_image': 'Change image',
	'modal.widget_banner.story_edit.button.change_video': 'Change video',

	// AddWidgetStoryModal
	'modal.widget_banner.story_add.header': 'Gérer les Stories',
	'modal.widget_banner.story_add.description': 'Sélectionnez une story du workspace <b>{projectName}</b>',
	'modal.widget_banner.story_add.no_stories': `Vous n'avez actuellement aucune Story à connecter avec la bannière.`,

	// CreateBannerModal
	'modal.widget_banner.create_widget.title': 'Créer Bannière de Web Stories',
	'modal.widget_banner.create_widget.select_story.title': 'Sélection du type de stories',
	'modal.widget_banner.create_widget.select_story.description': `Veuillez noter qu'une bannière <b>ne peut présenter qu'un seul type</b> de webstory, soit <b>Avancée</b>, soit <b>Simple</b>.`,
	'modal.widget_banner.create_widget.select_design.title': 'Selection de design de bannière',
	'modal.widget_banner.create_widget.select_design.description':
		'Choisissez entre les bulles et les cartes pour créer une bannière visuellement attrayante pour votre site Web.',
	'modal.widget_banner.create_widget.select_design.caption.bubbles': 'Rond ou Carré - ratio 1:1',
	'modal.widget_banner.create_widget.select_design.caption.cards':
		'Carte verticale ou horizontale - Ratio personnalisé',

	// DeleteFile
	'modal.file_deletion.confirmation.header.image': "Supprimer l'image",
	'modal.file_deletion.confirmation.header.video': 'Supprimer la vidéo',
	'modal.file_deletion.confirmation.sub_header': 'Êtes-vous sûr(e) ?',
	'modal.file_deletion.confirmation.instructions':
		'Attention ceci est une <b>opération irréversible</b>.<br></br><br></br>',

	// SettingsUser
	'modal.settings_user.client_deletion.header': `🗑 Suppression d'un client`,
	'modal.settings_user.client_deletion.incorrect_name': `Le nom saisi ne correspond pas au nom du client à supprimer`,
	'modal.settings_user.client_deletion.info': `<div>Êtes-vous sûr(e) de vouloir supprimer<b style='margin: 0 5px'>{name}</b> ?</div><div>⚠️ Cette opération est irréversible !</div><div>👉 Saissisez le nom du client et confirmez.</div>`,
	'modal.settings_user.client_deletion.client_name': `Nom du client`,

	'modal.settings_user.user_creation.client_name.header': `👤 Création d'utilisateur`,
	'modal.settings_user.user_creation.client_name.invalid_mail': `Veuillez spécifier une adresse email valide.`,

	'modal.settings_user.user_deletion.header': `👤 Suppression d'utilisateur`,
	'modal.settings_user.user_creation.content': `Êtes-vous sûr(e) de vouloir supprimer l'utilisateur {first_name} {last_name} ?`,

	'modal.settings_user.client_creation.header': `🎉 Création du client`,
	'modal.settings_user.client_creation.client_name': `Nom du client`,

	// Header confirmation
	'modals.header_confirmation.publish.header': 'Publier les changements',
	'modals.header_confirmation.activate.header': 'Activer la Story',
	'modals.header_confirmation.deactive.header': 'Désactiver la Story',
	'modals.header_confirmation.confirmation.instructions':
		'Les changements que vous avez effectués <b>seront en direct et visibles pour tous vos utilisateurs</b><br></br><br></br>',

	// Snackeet modals
	'modals.update_snackeet.title': 'Modifier nom de la Story',
	'modals.update_snackeet.language': `Changer la langue de la Story`,
	'modals.update_snackeet.language.warning': `Attention! Lors du changement de langue, vos préférences de texte personnalisées pour les boutons et les messages d'erreur <b>seront supprimées et leurs valeurs par défaut seront rétablies</b>`,

	'modals.create_snackeet.steps.story_type': `Sélectionnez le type de votre story`,
	'modals.create_snackeet.steps.story_details': 'Détails de la Story',
	'modals.create_snackeet.steps.from_template': `Utilisez les templates`,
	'modals.create_snackeet.steps.from_scratch': 'Créez à partir de zéro',
	'modals.create_snackeet.steps.choose_one': 'Choisissez un modèle ci-dessous ou',
	'modals.create_snackeet.steps.or': 'ou',
	'modals.create_snackeet.steps.create_with': 'Ou Choisissez un modèle et',
	'modals.create_snackeet.steps.from_instagram': 'Importez depuis Instagram',

	'modals.create_snackeet.snackeet_story.title': 'Web Story <b>Avancée</b>',
	'modals.create_snackeet.snackeet_story.description': 'Propulsé par <b>Snackeet</b> - Web App sans Cookies',
	'modals.create_snackeet.amp_story.title': 'Web Story <b>Simple</b>',
	'modals.create_snackeet.amp_story.description': 'Propulsé par <b>Google AMP</b>',

	'modals.create_snackeet.description_header.classic':
		'<ul><li><b>Logique conditionnelle</b></li><li><b>Hautement personnalisable</b></li><li><b>Sans cookie</b></li></ul>',
	'modals.create_snackeet.description_list.classic':
		'<ul><li>Upload/Download de fichiers</li><li>Formulaires</li><li>Boutons</li><li>Notations</li><li>Quiz et Sondage avancé</li><li>Gamification</li></ul>',
	'modals.create_snackeet.description_header.single_page': 'Tout dans Classique  +',
	'modals.create_snackeet.description_list.single_page':
		'<ul><li>Une seule page et un écran de confirmation</li></ul>',
	'modals.create_snackeet.description_header.amp_story':
		'Le comportement est très similaire à celui des stories Instagram.',
	'modals.create_snackeet.description_list.amp_story':
		'<ul><li>Navigation linéaire uniquement</li><li>Quiz ou Sondage</li><li>Chargement rapide</li><li>Adapté au référencement</li><li>Formulaires simples</li><li>Monétisable (Google Adsense...)</li></ul>',
	'modals.create_snackeet.description.amp_story_learn_more':
		'Quelle est la différence entre Snackeet et Google webstories ( ?)',

	'modals.create_snackeet.story_features.advanced_form': 'Formulaire avancé',
	'modals.create_snackeet.story_features.landing_page': `Landing page`,
	'modals.create_snackeet.story_features.gaming': 'Expérience ludique',
	'modals.create_snackeet.story_features.photo_contest': 'Concours photo',
	'modals.create_snackeet.story_features.fortune_wheel': 'Roue de la fortune',
	'modals.create_snackeet.story_features.microsite': 'Microsite',
	'modals.create_snackeet.story_features.collect_feedback': 'Collecter des retours',

	'modals.create_snackeet.story_features.interactive_video': 'Vidéo interactive',
	'modals.create_snackeet.story_features.bio_link': 'Landing page & Bio link',
	'modals.create_snackeet.story_features.contests': 'Jeux concours',
	'modals.create_snackeet.story_features.quiz_survey': 'Quiz & Sondage',
	'modals.create_snackeet.story_features.acquisition_form': 'Formulaire acquisition',
	'modals.create_snackeet.story_features.review_collection': 'Récolte avis',

	'modals.create_snackeet.story_features.video_display': 'Affichage vidéo',
	'modals.create_snackeet.story_features.product_demo': 'Découverte de produit',
	'modals.create_snackeet.story_features.articles': `Articles d'actualité`,
	'modals.create_snackeet.story_features.display_content': 'Affichage de contenu',
	'modals.create_snackeet.story_features.simple_engagement': 'Engagement simple',
	'modals.create_snackeet.story_features.custom_scenarios': 'Scénarios personnalisés',

	'modals.global_settings.reset_warning': 'Ceci supprimera tous vos changements.',

	// Create Page
	'modals.create_page.title': `Nouvelle page`,
	'modals.create_page.page_type': `Quel type de réponse voulez-vous créer ?`,
	'modals.create_page.page_type.blank': 'Sans Réponse',
	'modals.create_page.page_type.form': 'Formulaire',
	'modals.create_page.page_type.rating': `NPS/Notation`,
	'modals.create_page.describe.blank': 'Pensée pour un contenu purement visuel',
	'modals.create_page.describe.multiple_choice':
		"Ajoutez des boutons classique ou des boutons d'action (lien/fichier)",
	'modals.create_page.describe.form': `Ajoutez des champs de formulaire`,
	'modals.create_page.describe.rating': `Ajoutez une modale de notation`,
	'modals.create_page.page_type.multiple_choice': 'Choix multiple',
	'modals.create_page.button_type': `Quel type de bouton voulez-vous créer ?`,
	'modals.create_page.button_type.answer': 'Classique',
	'modals.create_page.button_type.action': 'Lien/Fichier',
	'modals.create_page.describe.button_type.answer': 'Ajoutez des réponses',
	'modals.create_page.describe.button_type.action': 'Ajoutez des lien/fichier',
	'modals.create_page.rating_type': `Quel type de notation voulez-vous créer ?`,
	'modals.create_page.helper.change_page_type': 'Vous pourrez toujours changer de type de réponse plus tard',
	'modals.create_page.change_type.title': `Changer de type de réponse`,
	'modals.create_page.change_type.page_type': `Choisissez votre nouveau type de réponse`,

	'modals.create_page.step_title.new_page': 'Créer une nouvelle page',
	'modals.create_page.step_title.template': 'Utilisez les modèles',
	'modals.create_page.step_title.interaction': 'Configurer la réponse de la page',

	'modals.change_response.step_title.page_type': 'Quel type de réponse souhaitez-vous ?',
	'modals.change_response.step_title.interaction': 'Configurer la réponse de la page',

	'modals.interaction_settings.multiple_choice': 'Configurer les boutons de page',
	'modals.interaction_settings.form': 'Configurer le formulaire de page',

	// Snackeet Page
	'modals.delete_page.title': `Supprimer la page`,
	'modals.delete_page.header': `Êtes-vous sûr de vouloir supprimer la page <b>{name}</b> ?`,
	'modals.duplicate_page.title': `Dupliquer la page`,
	'modals.duplicate_page.header': `Êtes-vous sûr de vouloir dupliquer la page <b>{name}</b> ?`,
	'modals.switch_button_type.header': `Changer le type de bouton`,
	'modals.switch_page_type.header': `Change le type de réponse`,
	'modals.switch_page_type.sub_header': `<b>Êtes-vous sur de vouloir changer le type de cette page?</b>`,
	'modals.switch_button_type.sub_header': `<b>Êtes-vous sur de vouloir changer le type de bouton ?</b>`,

	// Recorder Options
	'modals.recorder_options.title': `Options d'enregistrement`,

	// Hotspots
	'modals.hotspot.redirection.title': 'Redirection',
	'modals.hotspot.redirection.message': `L'utilisateur sera redirigé vers la page choisie une fois le timer écoulé ou s'il appuie pour passer à la page suivante`,
	'modals.hotspot.redirection_none.message': `L'utilisateur NE SERA PAS automatiquement redirigé vers une autre page.`,
	'modals.hotspot.project.intvite_member.title': `Inviter un membre dans l'espace de travail`,
	'modals.hotspot.project.intvite_member.message': 'Bientôt disponible',
	'modals.hotspot.tag.title': 'Tags produits',
	'modals.hotspot.tag.message':
		'Les tags vous permettent de mettre en avant vos produits et de rediriger vos utilisateurs vers la page de paiement.',
	'modals.hotspot.calendar.title': 'Calendrier',
	'modals.hotspot.calendar.message':
		'Permettez à vos prospects de prendre rendez-vous directement depuis une Story et obtenez davantage de leads.',
	'modals.hotspot.subtitle.title': 'Sous-titres',
	'modals.hotspot.subtitle.message':
		'Vous pouvez créer automatiquement des sous-titres pour les vidéos que vous importez.',
	'modals.hotspot.whitelist.title': 'Domaines autorisés',
	'modals.hotspot.whitelist.message':
		"Ajoutez un nom de domaine pour afficher le widget sur une page de votre site web. Vous pouvez ajouter jusqu'à 10 noms de domaine.",
	'modals.hotspot.whitelist.footer': 'Par exemple : snackeet.com',
	'modals.hotspot.widget_settings.title': 'Édition du widget',
	'modals.hotspot.widget_settings.message':
		"Décidez de l'apparence du widget. Vous pourrez toujours la modifier une fois l'intégration faite.",
	'modals.hotspot.start_page.title': 'Page de début',
	'modals.hotspot.start_page.message':
		"Sur la première page, aucune autre interaction que le bouton Start n'est possible.",
	'modals.hotspot.start_page.information':
		"Sur la première page, aucune autre interaction que le bouton Start n'est possible. Si vous souhaitez ajouter une interaction créez une nouvelle page en cliquant sur {icon}.",
	'modals.hotspot.start_end_page.actions':
		'Ne peut pas être dupliquée ou supprimée, veuillez créer une nouvelle page.',
	'modals.hotspot.end_page.title': 'Page de fin',
	'modals.hotspot.end_page.message':
		"En fonction du type de story choisi, la page de fin vous permettra d'afficher le score des utilisateurs (quiz) ou des résultats comparatifs (sondages).",
	'modals.hotspot.end_page.information':
		"Sur la dernière page, seul le bouton redémarrer et le partage des réseaux sociaux (disponible dans les modèles) sont possible en tant qu'interaction. N'hésitez pas à regarder les modèles pour trouver des idées. Vous pouvez toujours utiliser la barre d'outils pour personnaliser votre page de fin.",
	'modals.hotspot.url_variables.title': `Variables d'URL`,
	'modals.hotspot.url_variables.message': `En passant la valeur dans les variables de l'URL, Snackeet peut remplir ces variables sous forme de texte ou peut préremplir un formulaire.`,
	'modals.hotspot.game_required.description': `L'utilisateur est obliger d'interagir avec le jeu pour pouvoir continuer`,
	'modals.hotspot.game.lose_option.message': `*Si cette option est activée l'utilisateur qui tombera sur ce prix sera redirigé vers la fenêtre perdante`,
	'modals.hotspot.game.replayable.description': `À chaque nouvelle session la roue se réinitialise (exemple: Quand un utilisateur recommence une story)`,
	'modals.hotspot.block_cta.tooltip.description': `Lorsqu'elle est activée, une info-bulle intermédiaire apparaîtra lorsque l'élément sera cliqué, si elle est désactivée, la redirection sera directe.`,
	'modals.hotspot.block_cta.redirect.tooltip.description': `Lorsqu'elle est activée, la redirection sera directe.`,

	// Consent Modal
	'modals.consent.title': 'Politique de confidentialité',
	'modals.consent.redirection': 'Consulter la politique de confidentialité',

	// Variables
	'modals.variables.header': 'Choisissez { count, plural, one { une variable } other { des variables }}',
	'modals.variables.default.title': 'PAR DÉFAUT',
	'modals.variables.default.subtitle': 'Variables par défaut',
	'modals.variables.leaderboard.subtitle': 'Variables du Leaderboard',
	'modals.variables.custom.title': 'Variables personnalisées',
	'modals.variables.custom.subtitle': `Créez et utilisez vos propres variables. Les espaces et les caractères spéciaux ne sont pas pris en charge, à l'exception de "-" et "_"`,
	'modals.variables.custom.create': `Créer une variable`,
	'modals.variables.assigned': 'Attribuée',
	'modals.variables.assigned_here': 'Attribuée ici',
	'modals.variables.tooltip.header': 'La variable est attribuée à',
	'modals.variables.tooltip.page_name': 'Nom de la page: ',
	'modals.variables.tooltip.page_type': 'Type de la page: ',
	'modals.variables.tooltip.field': 'Champ: ',
	'modals.variables.allowType.head': 'Seuls les variables de type',
	'modals.variables.allowType.foot': "sont autorisées pour l'assignation",

	'modals.variables.create_variable.name': 'Nom de variable',
	'modals.variables.create_variable.type': 'Sélectionner le type de variable',
	'modals.variables.create_variable.create_btn': 'Créer',

	'modals.variables.description.text': `Utilisé pour la valeur du texte provenant de la saisie de texte, de la réponse au bouton,...`,
	'modals.variables.description.number': `Utilisé pour la valeur numérique à partir de la saisie numérique, la notation classique et pour la calculatrice en logique avancée.`,
	'modals.variables.description.date': `Utilisé pour la valeur de date du sélecteur de date et de la configuration logique avancée.`,
	'modals.variables.description.boolean': `Utilisé pour fournir des valeurs VRAI et FAUX et peut être attribué à la saisie d'une case à cocher.`,

	// Story Slug
	'modals.story_slug.header': `Configurer le slug`,
	'modals.story_slug.input.label': 'Lien personnalisé',
	'modals.story_slug.input.error': 'Cette URL existe déjà ou est invalide',

	// Story Custom Domain
	'modals.story_custom_domain.header': `Configuration du domaine personnalisé`,
	'modals.story_custom_domain.input.label': `Sélectionnez un domaine personnalisé`,
	'modals.story_custom_domain.button.add': `Gérer les domaines`,
	'modals.story_custom_domain.no_results.instruction': `Vous devez d'abord <b>créer</b> un domaine ou <b>attribuer un espace de travail</b> au domaine souhaité.`,
	'modals.story_custom_domain.no_results.instruction.agent': `<div>Aucun domaine personnalisé n'a été configuré.<br></br>Veuillez contacter votre administrateur pour plus d'informations.</div>`,

	'modals.custom_domain.limit_reached.title': 'Limite de domaines atteinte',
	'modals.custom_domain.limit_reached.description': `Vous avez atteint <b>la limite de {count} { count, plural, one { domaine } other { domaines }}</b> pour votre abonnement.<br></br><br></br>Si vous souhaitez utiliser un nouveau domaine, vous pouvez soit changer de plan, soit désactiver tous les domaines personnalisés des autres stories.`,

	// Create Custom Domain
	'modals.create_custom_domain.header': 'Créer un domaine personnalisé',
	'modals.create_custom_domain.stepper.label.specify_domain': 'Choix du domaine',
	'modals.create_custom_domain.stepper.label.verify_domain': 'Configuration DNS',
	'modals.create_custom_domain.stepper.label.select_projects': `Sélection d'espace de travail`,
	'modals.create_custom_domain.step.1.title': 'Saisissez votre sous-domaine',
	'modals.create_custom_domain.step.1.description': `Par exemple:  `,
	'modals.create_custom_domain.step.2.title': 'Configurez vos enregistrements DNS',
	'modals.create_custom_domain.step.2.description.a':
		'Dans votre fournisseur DNS, créer une entrée <b>CNAME</b> pour votre domaine personnalisé',
	'modals.create_custom_domain.step.2.description.b': `Pointez l'entrée vers`,
	'modals.create_custom_domain.step.2.redirection_status': 'Status de la redirection',
	'modals.create_custom_domain.alert.redirection_status': `<b>Cliquez sur vérifier</b> une fois que vous avez ajouté l'enregistrement CNAME.<br></br>Les modifications peuvent prendre jusqu\'à <i>24 heures</i> pour prendre effet.`,
	'modals.create_custom_domain.access_admin': `Ce domaine sera disponible pour tous les utilisateurs des espaces de travail que vous sélectionnerez ci-dessous :`,
	'modals.create_custom_domain.access_agent': `Ce domaine sera disponible pour tous les utilisateurs de cet espace de travail.`,
	'modals.create_custom_domain.warning_agent':
		'Si vous devez le rendre disponible dans plusieurs espaces de travail, veuillez contacter votre administrateur.',
	'modals.create_custom_domain.article_link': 'En savoir plus sur comment ajouter un nom de domaine personnalisé',
	'modals.create_custom_domain.invalid': `Ce nom de domaine est invalide.<br></br>Assurez-vous bien que c'est de type <b>story.customer.com</b>`,
	'modals.create_custom_domain.unavailable': `Ce nom de domaine n'est pas disponible.`,

	// Import Modal
	'modals.import.header': `Import d'un fichier multimédia`,
	'modals.import.status.uploading': 'Import et compression du fichier...',
	'modals.import.status.verifying': 'En cours de vérification...',
	'modals.import.status.done': 'Le fichier est prêt à être utilisé',
	'modals.import.status.error': `Erreur d'import`,
	'modals.import.status.uploading.description':
		'Une fois le fichier importé, il sera disponible dans la galerie de toutes les Stories de cet espace de travail.',
	'modals.import.status.done.description':
		'Il est disponible dans la galerie de toutes les Stories de cet espace de travail.',
	'modals.import.overtime': 'Le processus peut prendre plus de temps que prévu',

	// List Settings Modal
	'modals.list_settings.header': 'Paramètres de champs de liste',
	'modal.list_settings.instruction': 'Veuillez choisir une colonne pour configurer votre liste',

	// Integration Modal
	'modals.integration.header': 'Erreur Intégration {name}',
	'modals.integration.sub_header': 'Impossible de se connecter à {name} !',
	'modals.integration.description': `La variable <b>email</b> doit être attribuée pour se connecter à {name}`,
	'modals.integration.intruction.addVariable': 'Comment attribuer des variables dans votre Story',
	'modals.integration.intruction.connection': 'Comment se connecter à {name}',

	// Widget Icon
	'modals.widget_icon.header': 'Icône du widget',
	'modals.widget_icon.media_type.title': 'Miniature',
	'modals.widget_icon.caption': 'Texte Intérieur (Facultatif)',
	'modals.widget_icon.caption.placeholder': 'Cliquez moi !',

	// BackgroundModal
	'modals.background_helper.text': "Sélectionnez un type d'arrière-plan",
	'modals.background_helper.close_text': 'Ne plus afficher',

	// Story Errors Popover
	'modals.story_errors.title': "Liste d'erreurs",
	'modals.story_errors.message': 'Résolvez les erreurs avant publication',

	// Condition Page
	'modals.condition.header': 'Paramètres de condition de page',
	'modals.condition.error.field': '*Valeur ne peut pas être vide !',
	'modals.condition.error.group': '*Groupe ne peut pas être vide !',

	'modals.condition.translations.fields.title': 'Champs',
	'modals.condition.translations.operators.title': 'Operateurs',
	'modals.condition.translations.value.title': 'Valeur',
	'modals.condition.translations.removeRule.label': 'x',
	'modals.condition.translations.removeRule.title': 'Supprimez Rule',
	'modals.condition.translations.removeGroup.label': 'x',
	'modals.condition.translations.removeGroup.title': 'Supprimez groupe',
	'modals.condition.translations.addRule.label': 'Ajoutez Condition',
	'modals.condition.translations.addRule.title': 'Ajoutez Règle',
	'modals.condition.translations.addGroup.label': 'Groupe ()',
	'modals.condition.translations.addGroup.title': 'Ajouter Groupe',
	'modals.condition.translations.combinators.title': 'Combinateurs',
	'modals.condition.translations.notToggle.label': 'Non',
	'modals.condition.translations.notToggle.title': 'Inversez ce groupe',
	'modals.condition.translations.cloneRule.label': '⧉',
	'modals.condition.translations.cloneRule.title': 'Dupliquez Règle',
	'modals.condition.translations.cloneRuleGroup.label': '⧉',
	'modals.condition.translations.cloneRuleGroup.title': 'Dupliquer Groupe',

	// Redirect to AppSumo
	'modal.subscription.redirect_appsumo.title': 'Modifier votre forfait',
	'modal.subscription.redirect_appsumo.instruction_1':
		'Tous les changements de forfait doivent être effectués sur AppSumo',
	'modal.subscription.redirect_appsumo.instruction_2':
		'Rediriger vers la page AppSumo pour appliquer les modifications',
	'modal.subscription.redirect_appsumo.instruction_downgrade': `Avant de rétrograder sur plateforme AppSumo, assurez-vous de <b>supprimer</b> d'abord les membres de l'organisation.<br></br><br></br>Vous devez supprimer <b>{members}</b> membres.`,
	'modal.subscription.redirect_appsumo.button_cancel': 'Annuler',
	'modal.subscription.redirect_appsumo.button_confirm': 'Rediriger',

	'modal.settings_user.account.delete_redirection': `Vous avez une organisation activée avec les achats AppSumo.<br></br>Toutes les données seront supprimées et vous perdrez des avantages si votre achat.`,

	// Webhook
	'modals.webhook.title': 'Editer votre Webhook',
	'modals.webhook.eventType.snackeet_interaction': `Webhook pour les réponses de l’utilisateur`,
	'modals.webhook.eventType.snackeet_interaction.description': `Ce webhook est déclenché lorsqu’un utilisateur remplit et envoie un formulaire. Il envoie toutes les données collectées.`,
	'modals.webhook.eventType.file_uploaded': `Webhook pour les fichiers téléchargés`,
	'modals.webhook.eventType.file_uploaded.description': `Ce webhook ne s’active <b>QUE</b> lorsqu’un fichier est téléchargé par un utilisateur. Il vous permet de stocker uniquement des fichiers.`,
	'modals.webhook.url_header': `Wehook URL`,
	'modals.webhook.url_placeholder': 'Entrez URL',
	'modals.webhook.headers_title': `Définissez votre en-tête secret (Facultatif)`,
	'modals.webhook.headers_name': `Nom de l'en-tête`,
	'modals.webhook.headers_value': `Valeur de l'en-tête`,

	'modals.webhook.url_error.empty': 'URL cannot be empty',
	'modals.webhook.url_error.invalid': 'Please enter a valid URL',

	// License Activation
	'modals.license.header': `Choisissez une organisation dans la liste ci-dessous pour remplacer son plan actuel par le plan AppSumo`,
	'modals.license.no_organization':
		'Aucune organisation disponible pour appliquer le niveau de plan AppSumo. Veuillez en créer un pour activer cette licence.',

	// Image Selector
	'modals.images.header': 'Choissisez une image',
	'modals.images.type_import': 'Bibliothèque',
	'modals.images.type_link': 'Lien',
	'modals.images.type_pexels': 'Pexels',
	'modals.images.type_gif': 'GIF',
	'modals.images.type_sticker': 'Sticker',
	'modals.images.type_pixabay': 'Pixabay',
	'modals.images.type_recently_used': 'Récentes',
	'modals.images.type_illustrations': 'Illustrations',
	'modals.images.type_instagram': 'Instagram',
	'modals.images.button_import': 'Importez une nouvelle image',
	'modals.images.link.invalid': 'Veuillez saisir une URL valide et sécurisée https://',
	'modals.images.import.dropzone': 'Déposez vos images ici pour les importer',
	'modals.images.import.instruction': 'ou glissez et déposez',

	// Video Selector
	'modals.videos.header': 'Choissisez une vidéo',
	'modals.videos.type_import': 'Bibliothèque',
	'modals.videos.type_link': 'Lien',
	'modals.videos.type_pexels': 'Pexels',
	'modals.videos.type_pixabay': 'Pixabay',
	'modals.videos.type_recently_used': 'Récentes',
	'modals.videos.type_instagram': 'Instagram',
	'modals.videos.type_story_videos': 'Story Vidéos',
	'modals.videos.button_import': 'Importez une nouvelle vidéo',
	'modals.videos.link.invalid': `URL de vidéo invalide ou incompatible. Veuillez vous assurer d'avoir saisi un lien valide. Si le problème persiste, veuillez contacter notre équipe de support.`,
	'modals.videos.import.dropzone': 'Déposez vos vidéos ici pour les importer',

	// Editor Dropzone
	'modals.editor_import.instructions': 'Déposez vos fichiers pour les importer',
	'modals.editor_import.instructions.images': 'Images',
	'modals.editor_import.instructions.videos': 'Vidéos',

	// Subtitles Editor
	'modals.subtitles.unsaved.title': 'Vos sous-titres ne sont pas enregistrés',
	'modals.subtitles.unsaved.description': 'Souhaitez-vous quitter sans enregistrer vos sous-titres ?',
	'modals.subtitles.unsaved.cancel': 'Non',
	'modals.subtitles.unsaved.confirm': 'Oui',

	'modals.subtitles.saved.title': 'Enregistrez vos sous-titres',
	'modals.subtitles.saved.description': `Souhaitez-vous enregistrer vos sous-titres?<br></br><br></br>Ces changements s'appliqueront à toutes les Stories qui utilisent cette vidéo.`,
	'modals.subtitles.saved.cancel': 'Annuler',
	'modals.subtitles.saved.confirm': 'Confirmer',

	// Story Settings
	'modals.story_settings.refresh_user.instructions': `Activer cette option risque <b>d'augmenter considérablement</b> le nombre d'utilisateurs.`,
	'modals.story_settings.delete_all_users.header': 'Suppression des utilisateurs',
	'modals.story_settings.delete_all_users.instructions': `Attention, ceci est une <b>opération irréversible</b>.<br></br>Voulez-vous supprimer <b>tous les utilisateurs</b> de cette Story ?`,

	// Story Favicon
	'modals.story_favicon.title': '{ count, plural, =0 { Ajoutez } other { Sélectionnez }} un Favicon',
	'modals.story_favicon.remove_button': 'Retirer le Favicon',

	// Widget Loader
	'modals.widget_loader.title': '{ count, plural, =0 {Ajoutez} other {Sélectionnez}} une image de chargement',
	'modals.widget_loader.button.default': `Utiliser par défaut`,
	'modals.widget_loader.label.default': `Par défaut`,
	'modals.widget_loader.tooltip.default': `Le loader par défaut est automatiquement utilisé dans tous les nouveaux widgets`,

	'modals.widget_loader.confirm_default.firstLine': `Souhaitez-vous que cette image devienne le loader par défaut ?`,
	'modals.widget_loader.confirm_default.secondLine': `Tous les widgets qui utilisent le loadeur par défaut seront mis à jour`,

	// WHITELIST Confirmation
	'modals.whitelist_confirmation.title': 'Affichage sur toutes les pages',
	'modals.whitelist_confirmation.text':
		'En affichant sur toutes les pages, la liste actuelle des pages autorisées sera <b>supprimée</b>.<br></br><br></br>Voulez vous procéder ?',
	'modals.blacklist_confirmation.title': 'Supprimer les exceptions',
	'modals.blacklist_confirmation.text':
		'La liste actuelle des exceptions sera <b>supprimée</b>.<br></br><br></br>Voulez vous procéder ?',

	// Single Page Overlay
	'modals.page_overlay.title': 'Écran final',
	'modals.page_overlay.description': `Personnalisez le message affiché lorsque l'utilisateur soumet une réponse.`,
	'modals.page_overlay.section.message': 'Texte',
	'modals.page_overlay.section.link_button': 'Bouton Lien',
	'modals.page_overlay.section.link_button.tooltip': 'Redirigez vos utilisateurs vers un autre site web.',
	'modals.page_overlay.section.restart': 'Bouton Recommencer',
	'modals.page_overlay.section.restart.tooltip':
		'Permettez aux utilisateurs de redémarrer et de soumettre à nouveau.',

	'modals.page_overlay.text.input': 'Texte de confirmation',
	'modals.page_overlay.text.input.placeholder': 'Personnaliser le text',
	'modals.page_overlay.link_button.input': 'Texte du bouton',
	'modals.page_overlay.link_button.input.placeholder': 'Visitez notre site web',
	'modals.page_overlay.link_button.input2': 'Lien',

	// Form Settings
	'modals.form.field_remove.title': 'Supprimer le champ de formulaire',
	'modals.form.field_remove.sub_title': 'Voulez-vous supprimer ce champ ?',

	// GDPR Settings
	'modals.form.gdpr_settings.title': 'Paramètres de consentement RGPD',
	'modals.form.gdpr_deactivate.title': 'Désactiver le consentement GDPR',
	'modals.form.gdpr_deactivate.sub_title': 'Souhaitez-vous désactiver le consentement GDPR ?',

	// Save As Template
	'modals.save_template.title': 'Enregistrer comme modèle',
	'modals.save_template.page.title': 'Enregistrer la <b>page</b> comme template',
	'modals.save_template.story.title': 'Enregistrer la <b>story</b> comme template',
	'modals.save_template.name': 'Nom du modèle :',
	'modals.save_template.header.agent':
		'Ce modèle de page sera disponible pour tous les utilisateurs de cet espace de travail.',
	'modals.save_template.warning.agent':
		'Si vous devez le rendre disponible dans plusieurs espaces de travail, veuillez contacter votre administrateur.',
	'modals.save_template.info.agent':
		'Si vous devez modifier ce modèle de page, vous devez d’abord modifier la page originale et l’enregistrer comme modèle. Vous pouvez ensuite supprimer le modèle obsolète.',
	'modals.save_template.header.admin':
		'Ce modèle de page sera disponible pour tous les utilisateurs des espaces de travail que vous sélectionnerez ci-dessous :',
	'modals.save_template.info.admin':
		'Si vous devez modifier ce modèle de page, vous devez d’abord modifier la page originale et l’enregistrer comme modèle. Vous pouvez ensuite supprimer le modèle obsolète.',

	'modals.save_template.story.header.agent':
		'Ce modèle de story sera disponible pour tous les utilisateurs de cet espace de travail.',
	'modals.save_template.story.warning.agent':
		'Si vous devez le rendre disponible dans plusieurs espaces de travail, veuillez contacter votre administrateur.',
	'modals.save_template.story.info.agent':
		'Si vous devez modifier ce modèle de story, vous devez d’abord modifier la story originale et l’enregistrer comme modèle. Vous pouvez ensuite supprimer le modèle obsolète.',
	'modals.save_template.story.header.admin':
		'Ce modèle de story sera disponible pour tous les utilisateurs des espaces de travail que vous sélectionnerez ci-dessous :',
	'modals.save_template.story.info.admin':
		'Si vous devez modifier ce modèle de page, vous devez d’abord modifier la page originale et l’enregistrer comme modèle. Vous pouvez ensuite supprimer le modèle obsolète.',

	// Templates
	'modals.templates.tabs.default': 'Modèles par défaut',
	'modals.templates.tabs.current_pages': 'Pages actuelles de Story',
	'modals.templates.tabs.current_pages_select': 'Pages actuelles de Story à copier',
	'modals.templates.tabs.custom': 'Vos modèles',
	'modals.templates.delete.title': 'Supprimer le modèle',
	'modals.templates.button.choose': 'Choisir ce template',
	'modals.templates.button.select': 'Choisir',
	'modals.templates.button.manage': 'Gérer',
	'modals.templates.instruction':
		'Ce modèle sera disponible pour tous les utilisateurs des espaces de travail que vous sélectionnerez ci-dessous :',
	'modals.templates.page.empty': `Vous n'avez actuellement aucun modèle pour ce type de page.`,
	'modals.templates.story.empty': `Vous n'avez actuellement aucun modèle pour ce type de story.`,
	'modals.templates.attachment.empty': `Vous n'avez actuellement aucun modèle pour ce type de pop-up.`,

	// Verification email
	'modals.verification_email.title': `Dernière étape : Vérifier votre email`,
	'modals.verification_email.header_1':
		'Dans le cadre de notre politique de sécurité, une adresse mail fonctionnelle est obligatoire.',
	'modals.verification_email.header_2': `Un lien de vérification vous a été envoyé par email à : <b>{email}</b>.`,
	'modals.verification_email.header_3': `Actualisez votre page après avoir cliqué sur le lien de vérification.`,
	'modals.verification_email.delay_message': `Renvoyez l’email de vérification : Nouvelle tentative dans <b>{counter}</b> secondes`,
	'modals.verification_email.resend_button': `Renvoyez l’email de vérification`,
	'modals.verification_email.instruction': `Si vous avez besoin d’aide, veuillez contacter notre équipe de support par chat ou à <b>hello@snackeet.com</b>.`,

	'verification_email.success': 'Envoi du lien de vérification réussi',
	'verification_email.error': `Erreur d'envoi du lien de vérification`,

	// Brands
	'modals.create_brand.title': 'Créer une nouvelle marque',
	'modals.create_brand.name_label': 'Nom de marque',
	'modals.create_brand.name_placeholder': 'Entrez le nom de la marque',
	'modals.create_brand.access_admin': `Cette marque sera disponible pour tous les utilisateurs des espaces de travail que vous sélectionnerez ci-dessous :`,
	'modals.create_brand.access_agent': `Cette marque sera disponible pour tous les utilisateurs de cet espace de travail.`,
	'modals.create_brand.warning_agent':
		'Si vous devez la rendre disponible dans plusieurs espaces de travail, veuillez contacter votre administrateur.',

	'modals.delete_brand.modal_title': 'Supprimer la marque',
	'modals.delete_brand.header': 'Êtes-vous sûr?',
	'modals.delete_brand.instruction': `<div>Attention ceci est une <b>opération irréversible</b>.</div><br></br><div>Pour confirmer, veuillez saisir le nom de la marque:</div>`,
	'modals.delete_brand.placeholder': 'Tapez le nom de la marque...',

	// Leaderboards
	'modals.create_leaderboard.title': 'Créer une nouvelle leaderboard',
	'modals.create_leaderboard.name_label': 'Nom de leaderboard',
	'modals.create_leaderboard.name_placeholder': 'Entrez le nom de la leaderboard',

	'modals.delete_leaderboard.title': 'Supprimer la leaderboard',
	'modals.delete_leaderboard.header': 'Êtes-vous sûr?',
	'modals.delete_leaderboard.instruction': `<div>Attention ceci est une <b>opération irréversible</b>.</div><br></br><div>Pour confirmer, veuillez saisir le nom de la leaderboard:</div>`,
	'modals.delete_leaderboard.placeholder': 'Tapez le nom de la leaderboard...',

	// Fonts
	'modals.delete_fonts.title': 'Suppression { count, plural, one { de la police } other { des polices }}',
	'modals.delete_fonts.header':
		'Êtes-vous sûr de supprimer { count, plural, one { la police } other { les polices }} ?',
	'modals.delete_fonts.instruction': `En supprimant { count, plural, one { cette police } other { ces polices }} les stories qui { count, plural, one { l'utilisent } other { les utilisent }} seront affectées.`,

	// Export Users Data
	'modals.export_users_data.title': 'Export initiée',
	'modals.export_users_data.description': `Vos données sont en cours d’export, vous recevrez une notification par e-mail à <b>{ email }</b> une fois que le processus sera terminé.<br></br><br></br>Cela peut prendre jusqu’à 15 min.<br></br><br></br>Veuillez noter que le lien de téléchargement restera actif pendant 14 jours.<br></br><br></br>Si vous voulez le télécharger maintenant, ne fermez pas cette fenêtre.`,
	'modals.export_users_data.file_downloaded': 'Fichier téléchargé',

	// AMP Form
	'modals.amp_form.modal.create': 'Créer un formulaire',
	'modals.amp_form.modal.template': 'Choisissez un modèle',
	'modals.amp_form.modal.settings': 'Paramètres du formulaire',

	// AMP Attachment
	'modals.amp_attachment.modal.create': `Créer une pop-up`,
	'modals.amp_attachment.modal.template': `Choisissez un modèle`,
	'modals.amp_attachment.modal.settings': `Paramètres de pop-up`,
	'modals.amp_attachment.modal.save_only': 'Enregistrer et fermer',
	'modals.amp_attachment.modal.save_as_template': 'Enregistrer en tant que modèle',

	// Stale Story
	'modals.stale_story.title': 'Version obsolète',
	'modals.stale_story.description':
		'Une version plus récente de la story existe.<br></br><br></br>Veuillez rafraichir.',
	'modals.stale_story.button': 'Rafraichir',

	// Export Pages Modal
	'modals.story_export.title': 'Export de Story',
	'modals.story_export.instructions': `Choisissez les pages à exporter en tant qu'image`,

	// Element Selector Drawer
	'modals.element_selector_drawer.title': 'Ajoutez nouveau élément',
	'modals.element_selector_drawer.change_interaction': "Changer l'interaction",
	'modals.element_selector_drawer.description': `Chaque page prend en charge une seule interaction à la fois. Confirmez-vous le changement de l'interaction actuelle ?`,

	// Create Page
	'modals.create_page.single_to_classic_page':
		"Ajouter une nouvelle page créera une <b>Page de fin</b> obligatoire.<br></br><br></br>La <b>page de fin</b> a des limitations spécifiques pour améliorer l'expérience utilisateur.",
	'modals.create_page.add_page_to_single_page': 'Ajoute une nouvelle page',

	// Delete Page
	'modals.delete_page.ending_page_title': 'Supprimer la page de fin',
	'modals.delete_page.ending_page_instruction': 'Passer de <b>Advanced</b> à <b>Une page</b>.',
}
