export const fr = {
	'stories_list.header_title': 'Stories',
	'stories_list.created': 'Créé le',
	'stories_list.published': 'Modifié le',
	'stories_list.page_count': '{count} {count, plural, one {PAGE} other {PAGES}}',
	'stories_list.started': 'Impressions',
	'stories_list.finished': 'Complétion',
	'stories_list.unique_users': 'Utilisateurs',
	'stories_list.status.name': 'Statut',
	'stories_list.status_label.active': 'Actif',
	'stories_list.status_label.inactive': 'Inactif',
	'stories_list.folder.tooltip': 'Assigner la story à un dossier',
	'stories_list.folder.tooltip.assign': 'Attribuer un dossier à la story',
	'stories_list.folder.tooltip.update': 'Modifier le dossier attribué',
	'stories_list.option.stats': 'Statistiques',
	'stories_list.option.delete': 'Supprimer',
	'stories_list.option.configuration': 'Configuration',
	'stories_list.option.unpublished_change': 'Vous avez des changements non publié dans votre Story',
	'stories_list.empty_list_title': 'Pas encore de Stories',
	'stories_list.delete_confirmation': 'Voulez-vous vraiment supprimer cette Story ?',

	'stories_list.modals.creating.title': 'Création de Story',
	'stories_list.modals.creating.importing': `En train d'importer fichiers d'Instagram et de créer une Story...`,
	'stories_list.modals.creating.warning': `⚠️ Ceci pourrait prendre un certain temps`,

	'stories_list.tags.no_tags': 'Stories sans tags',
	'stories_list.tags.new_tags': 'Nouveaux Tags',
	'stories_list.tags.old_tags': 'Anciens Tags',
	'stories_list.tags.modal_titles': 'Modifier les tags de story',
}
