import axios from 'axios'
import { API_URL } from 'lib/constants'
import dayjs from 'lib/dayjs'
import * as localStorageSvc from 'services/LocalStorageSvc'

const ProjectsSvc = {
	getAll,
	get,
	create,
	del,
	update,
	getStoriesMetricsByCategory,
}

export default ProjectsSvc

const DEFAULT_FIELDS = [
	'name',
	'description',
	'avatar',
	'owner',
	'stories_count',
	'users_count',
	'recently_used',
	'loader',
].join(',')

async function getAll(organization, fields = DEFAULT_FIELDS) {
	try {
		const { data } = await axios.get(`${API_URL}/projects`, {
			params: {
				fields,
				organization_id: organization,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.projects
	} catch (err) {
		console.log('getProjects', err)
		return []
	}
}

async function get(organization, _id) {
	try {
		const { data } = await axios.get(`${API_URL}/projects/${_id}`, {
			params: {
				fields: DEFAULT_FIELDS,
				organization_id: organization,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('getProject', err)
		return []
	}
}

async function create(body, organization) {
	const { data } = await axios.post(`${API_URL}/projects`, body, {
		params: {
			organization_id: organization,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data
}

async function del(project_id, organization) {
	const { data } = await axios.delete(`${API_URL}/projects/${project_id}`, {
		params: {
			organization,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data
}

async function update(project_id, organization, body) {
	const { data } = await axios.put(`${API_URL}/projects/${project_id}`, body, {
		params: {
			organization,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	if (!data.success) {
		throw new Error('Error updating workspace')
	}
	return data
}

async function getStoriesMetricsByCategory({ project_id, storiesList, platform, since, until, interval, category }) {
	const body = {
		storiesList,
		platform,
		interval,
		category,
	}
	if (since) {
		body.since = dayjs(since).startOf('day').toISOString()
	}
	if (until) {
		body.until = dayjs(until).endOf('day').toISOString()
	}

	try {
		const { data } = await axios.post(
			`${API_URL}/projects/${project_id}/stories/metrics`,
			{
				...body,
			},
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return data.metrics
	} catch (err) {
		return []
	}
}
