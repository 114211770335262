export const en = {
	'login_register.mail_placeholder': 'Email',
	'login_register.username_placeholder': 'Username',
	'login_register.pwd_placeholder': 'Password',
	'login_register.confirm_pwd_placeholder': 'Confirm your password',
	'login.main_button': 'LOG IN',
	'login.secondary_button': 'Forgot password?',

	'register.title': 'Create a new account',

	'login.title': 'Log in',
	'password_reset.title': 'Reset your password',
	'password_reset.main_button': 'CREATE NEW PASSWORD',
	'password_reset.secondary_button': 'Back to Log in',

	'login.error_header': 'Invalid credentials',
	'login.error_content': 'Your credentials are invalid.',
	'login.reset_password_header': 'Your new password was sent by email.',
	'login.reset_password_message': 'You might need to check your spam folder.',

	'login.button.sign_in': 'Sign in with Google',
	'login.no_account_yet': 'New to Snackeet?',
	'login.signup': `Sign up for free`,

	'register.use_what_for': 'What do you want to achieve?',
	'register.create_account': 'Create your account',
	'register.creating_account': 'Creating account',
	'register.has_account': 'Already have an account?',
	'register.secondary_button': 'Log in',
	'register.cgu': `I agree to the <a href={{TOS}} rel="noopener noreferrer" target="_blank">Terms of Service</a> and <a href={{PP}} rel="noopener noreferrer">Privacy Policy</a>`,

	'register.create_username.title': 'Username creation',
	'register.create_username.instruction': 'Last step: you need to create a unique username to use our services',
	'register.create_username.button.default': 'Create username',
	'register.create_username.button.submitting': 'Creating username',

	'register.app_sumo': 'You account is almost already',

	'captcha.error.missing-input-secret': '[CAPTCHA] The secret parameter is missing.',
	'captcha.error.invalid-input-secret': '[CAPTCHA] The secret parameter is invalid or malformed.',
	'captcha.error.missing-input-response': '[CAPTCHA] The response parameter is missing.',
	'captcha.error.invalid-input-response': '[CAPTCHA] The response parameter is invalid or malformed.',
	'captcha.error.bad-request': '[CAPTCHA] The request is invalid or malformed.',
	'captcha.error.timeout-or-duplicate':
		'[CAPTCHA] The response is no longer valid: either is too old or has been used previously.',

	'password_reset.error.too-soon': 'Still processing... Please try again in a few minutes',
	'password_reset.error.email-error': 'Email could not be sent... Please try again in a few minutes',
	'password_reset.error.email-unregistered': `This email is not registered`,

	'form_error.email_required': 'Required',
	'form_error.password_required': 'No password provided.',
	'form_error.password_too_short': 'Password is too short - 8 chars minimum.',
	'form_error.password_too_long': 'Password is too long - 48 chars maximum.',
	'form_error.password_no_number': 'Password must contain a number.',
	'form_error.password_confirm_required': 'This field is required',
	'form_error.password_confirm_mismatch': 'Both password need to be the same',
	'form_error.cgv_accepted': 'Accept Terms of Service and Privacy Policy is required to sign up',
}
