export const en = {
	'organization.table_title.organization': 'Organization Name',
	'organization.table_title.members': '{count} / {seats} { seats, plural, one { MEMBER } other { MEMBERS }}',
	'organization.table_title.current_members':
		'{count} { count, plural, one { MEMBER } other { MEMBERS }} / UNLIMITED',
	'organization.table_title.role': 'Role',
	'organization.table_title.owner_email': 'Owner',
	'organization.table_title.created_date': 'Created Date',
	'organization.table_title.last_update': 'Last Update',
	'organization.table_title.actions': 'Actions',
	'organization.table_title.invited_role': 'Invited Role',
	'organization.table_title.invited_date': 'Invited on',
	'organization.table_title.username': 'Username',
	'organization.table_title.email': 'Email',
	'organization.table_title.invited_by': 'Invited By',
	'organization.table_title.status': 'Status',

	'organization.search.placeholder': 'Search an organization...',

	'organization.title.pending.invitations':
		'{count} Pending { count, plural, one { Invitation } other { Invitations }}',
	'organization.title.pending.licences': 'Pending Licence',

	'organization.tab.profile': 'Organization Profile',
	// '': 'Notifications Management',
	'organization.tab.members': 'Members Management',

	'organization.tab_title.profile': 'Your organization',
	'organization.tab_title.settings': 'Settings',

	'organization.members.tab.members_list': 'Members',
	'organization.members.tab.invitations_list': 'Invitations',
	'organization.members.number': 'Current members of ',
	'organization.invitations.number': 'Number invitations of ',

	'organization.profile.name': 'Organization name',
	'organization.profile.slug': 'Organization ID',
	'organization.profile.created_date': 'Created date',
	'organization.profile.delete_organization': 'Delete organization',
	'organization.profile.change_logo': 'Change logo',
	'organization.profile.timezone': 'Select a timezone',
	'organization.profile.locale': 'Select notifications language',
	'organization.profile.owner': `Organization's Owner`,
	'organization.profile.ID_exists': 'ID already exists',
	'organization.profile.ID_empty': 'ID cannot be empty',

	'organization.app_header.title': 'Organizations',
	'organization.app_header.settings': 'Settings',

	'organization.notifications.answers': `Send mail notifications to all organization's members each time user finish a story or fill up a form.`,

	'organization.members.table_header.role': 'Role',
	'organization.members.table_header.workspaces_access': 'Workspaces Access',
	'organization.members.table_header.actions': 'Actions',
	'organization.invitations.table_header.role': 'Role',
	'organization.invitations.table_header.invited_date': 'Invited Date',
	'organization.invitations.table_header.invited_by': 'Invited By',
	'organization.invitations.table_header.status': 'Status',

	'organization.invitation_modal.label_input': 'Enter member email',
	'organization.invitation_modal.label_dropdown': 'Role:',
	'organization.invitation_modal.email_missing': 'Email must be filled',
	'organization.invitation_modal.email_error': 'Please enter a valid email',
	'organization.invitation_modal.projects_empty': 'Assigned workspaces cannot be empty',

	'organization.invitation_button.tooltip.header': 'Invite new member',
	'organization.invitation_button.tooltip.description_available': 'Admin can invite new members via email.',
	'organization.invitation_button.tooltip.description_unavailable':
		'The number of members limit has been reached, your organization cannot invite more members.',

	'organization.role.agent': 'Agent',
	'organization.role.admin': 'Admin',
	'organization.role.owner': 'Owner',

	'organization.limitation.interactions.header': 'Number of active users this month',
	'organization.limitation.members.header': 'Current number of members',
	'organization.limitation.timeline': `Reset after `,
	'organization.limitation.change_plan': 'Change your plan',
	'organization.limitation.modal.title': 'Update you plan',
	'organization.limitation.plan_card.seats': `{count} { count, plural, =1 { seat } other { seats }}`,
	'organization.limitation.plan_card.users_per_month': `{count}k active users per month`,
	'organization.limitation.extra_seats.count': `{count} extra { count, plural, =1 { seat } other { seats }}`,
	'organization.limitation.extra_seats.unit': `per seat`,
	'organization.limitation.extra_users_per_month.count': `{count} { count, plural, zero { No } other { k }} extra users per month`,
	'organization.limitation.extra_users_per_month.unit': `per 1k users`,
	'organization.checkout.quantity': `Quantity`,
	'organization.checkout.unit': `Unit`,
	'organization.checkout.price': `Price`,

	// AppSumo
	'organization.licence.subtitle': '👋 Hey Sumoling, you need to activate your lifetime licence.',

	// Update member
	'organization.members.update.title': 'Manage Member Access',
	'organization.members.warning.admin': `<b>ADMIN</b> has all rights (manage access, subscriptions, remove members, etc...)`,
	'organization.members.warning.agent': `<b>AGENT</b> can only access authorized WORKSPACES and can manage STORIES in those WORKSPACES.`,
	'organization.members.assign.instruction': 'Choose workspaces to assign your Agent in',
	'organization.members.update.header.agent': `You are about to grant <b>{username} ({email})</b> the <b>AGENT</b> rights.`,
	'organization.members.update.header.admin': `You are about to grant <b>{username} ({email})</b> the <b>ADMIN</b> rights.`,
	'organization.members.update.action': `Manage Access`,
	'organization.members.update.all_access': `All Workspaces`,

	// Update workspace access
	'organization.projects.access.title': 'Manage Workspace Access',
	'organization.projects.access.header': 'Choose Agents to assign in <b>{projectName}</b>',

	// Api Keys Settings
	'apiKeys.tab.title': 'Api Keys Management',
	'apiKeys.description.1': `Generate and manage API keys to access web stories and their stats.`,
	'apiKeys.description.2': `You can <b>create</b>, <b>delete</b>, <b>enable</b>, or <b>disable</b> keys as desired.`,
	'apiKeys.description.documentation': `For more information about what you can do with the keys, please refer to our public API`,
	'apiKeys.description.link': `documentation.`,
	'apiKeys.warning': 'Exposing your API key can lead to security risks. Always keep it confidential.',
	'apiKeys.empty.instructions': 'Click the button below to create your first Api Key',
	'apiKeys.button.create': 'Generate new Key',
	'apiKeys.table.header.name': 'Name',
	'apiKeys.table.header.key': 'Key',
	'apiKeys.table.header.created_at': 'Created',
	'apiKeys.table.header.last_used_at': 'Last Used',
	'apiKeys.table.header.active': 'Active',
	'apiKeys.name': 'Key name',
	'apiKeys.editModal.title': 'Edit the key',
	'apiKeys.deleteModal.title': 'Delete the key',
	'apiKeys.deleteModal.content': 'Are you sure you want to delete the api key <b>{name}</b>?',

	// Domains
	'domains.tab.title': 'Domains',
	'domains.description': `Add and manage your custom domains to publish your stories under your own brand.`,
	'domains.empty.instructions': 'Click the button below to add your own domain',
	'domains.tooltip.verified': 'Domain verified',
	'domains.tooltip.not_verified': 'Domain not verified',
	'domains.delete_modal.title': 'Delete Domain',
	'domains.delete_modal.instructions':
		'Deleting this domain will <b>reset the URL</b> of the related stories.<br></br><br></br>To confirm, enter the name of the domain:',
	'domains.edit_modal.article_sitemap_link': 'How to submit your sitemap to Google',
	'domains.edit_modal.workspaces': 'Workspaces',
	'domains.edit_modal.notFoundPage.field.title': 'Title',
	'domains.edit_modal.notFoundPage.tooltip.title': 'This is the meta title in the <head> tag of the page',
	'domains.edit_modal.notFoundPage.field.subHeader': 'Message',
	'domains.edit_modal.notFoundPage.field.ctaText': 'Button text',
	'domains.edit_modal.notFoundPage.field.ctaUrl': 'Button URL',
	'domains.edit_modal.notFoundPage.field.accentColor': 'Primary color',
	'domain.edit_modal.confirmation.title': 'Confirm changes',
	'domain.edit_modal.confirmation.projects_list': `You are removing the access to the following workspaces:`,
	'domain.edit_modal.confirmation.confirm_text': `This will <b>reset the URL</b> of the related stories.<br></br><br></br>Do you want to proceed ?`,
	'domains.table.header.status': 'Status',
	'domains.table.header.domain': 'Domain',
	'domains.table.header.last_verified_at': 'Last verified at',
}
