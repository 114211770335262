export const RatingList = [
	{
		type: 'stars',
		category: 'classic_stars',
	},
	{
		type: 'number',
		category: 'classic_number',
	},
	{
		type: 'heart',
		category: 'classic_heart',
	},
	{
		type: 'lens',
		category: 'classic_lens',
	},
	{
		type: 'goodBad',
		category: 'likeDislike',
		imgs: [
			{
				url: 'url("/static/gif/dislike.gif")',
				name: 'dislike',
			},
			{
				url: 'url("/static/gif/like.gif")',
				name: 'like',
			},
		],
	},
	{
		type: 'emotion',
		category: 'happySad',
		imgs: [
			{
				url: 'url("/static/gif/sad.gif")',
				name: 'sad',
			},
			{
				url: 'url("/static/gif/happy.gif")',
				name: 'happy',
			},
		],
	},
	{
		type: 'goodBad',
		category: 'heart',
		imgs: [
			{
				url: 'url("/static/gif/brokenHeart.gif")',
				name: 'dislike',
			},
			{
				url: 'url("/static/gif/heart.gif")',
				name: 'like',
			},
		],
	},
	// {
	// 	type: 'emotion',
	// 	category: 'hotCold',
	// 	imgs: [
	// 		{
	// 			url: 'url("/static/gif/cold.gif")',
	// 			name: 'cold'
	// 		},
	// 		{
	// 			url: 'url("/static/gif/hot.gif")',
	// 			name: 'hot'
	// 		}
	// 	]
	// },
	// {
	// 	type: 'binary',
	// 	category: 'myBadYourBad',
	// 	imgs: [
	// 		{
	// 			url: 'url("/static/gif/myBad.gif")',
	// 			name: 'myBad'
	// 		},
	// 		{
	// 			url: 'url("/static/gif/yourBad.gif")',
	// 			name: 'yourBad'
	// 		}
	// 	]
	// },
	{
		type: 'yesNo',
		category: 'yesNo',
		imgs: [
			{
				url: 'url("/static/gif/no.gif")',
				name: 'no',
			},
			{
				url: 'url("/static/gif/yes.gif")',
				name: 'yes',
			},
		],
	},
	// {
	// 	type: 'goodBad',
	// 	category: 'realFake',
	// 	imgs: [
	// 		{
	// 			url: 'url("/static/gif/fake.gif")',
	// 			name: 'fake'
	// 		},
	// 		{
	// 			url: 'url("/static/gif/real.gif")',
	// 			name: 'real'
	// 		}
	// 	]
	// },
	{
		type: 'yesNo',
		category: 'yesNoBlack',
		imgs: [
			{
				url: 'url("/static/gif/noBlack.png")',
				name: 'no',
			},
			{
				url: 'url("/static/gif/yesBlack.png")',
				name: 'yes',
			},
		],
	},
	{
		type: 'YesNo',
		category: 'yesNoWhite',
		imgs: [
			{
				url: 'url("/static/gif/noWhite.png")',
				name: 'no',
			},
			{
				url: 'url("/static/gif/yesWhite.png")',
				name: 'yes',
			},
		],
	},
	{
		type: 'yesNo',
		category: 'yesNoGreen',
		imgs: [
			{
				url: 'url("/static/gif/noRed.png")',
				name: 'no',
			},
			{
				url: 'url("/static/gif/yesGreen.png")',
				name: 'yes',
			},
		],
	},
	{
		type: 'yesNo',
		category: 'validWrong',
		imgs: [
			{
				url: 'url("/static/gif/wrong.png")',
				name: 'no',
			},
			{
				url: 'url("/static/gif/valid.png")',
				name: 'yes',
			},
		],
	},
	{
		type: 'ternary',
		category: 'likeSosoDislike',
		size: '60px',
		imgs: [
			{
				url: 'url("/static/gif/dislikeRed.gif")',
				name: 'bad',
			},
			{
				url: 'url("/static/gif/sosoRed.gif")',
				name: 'average',
			},
			{
				url: 'url("/static/gif/likeRed.gif")',
				name: 'good',
			},
		],
	},
	{
		type: 'emotion',
		category: 'emotions',
		size: '50px',
		imgs: [
			{
				url: 'url("/static/gif/angry.gif")',
				name: 'angry',
			},
			{
				url: 'url("/static/gif/sad.gif")',
				name: 'sad',
			},
			{
				url: 'url("/static/gif/surprised.gif")',
				name: 'surprised',
			},
			{
				url: 'url("/static/gif/happy.gif")',
				name: 'happy',
			},
		],
	},
]

const arrRatingList = {
	likeDislike: ['like', 'dislike'],
	happySad: ['happy', 'sad'],
	heart: ['like', 'dislike'],
	yesNo: ['yes', 'no'],
	yesNoBlack: ['yes', 'no'],
	yesNoWhite: ['yes', 'no'],
	yesNoGreen: ['yes', 'no'],
	validWrong: ['yes', 'no'],
	likeSosoDislike: ['good', 'average', 'bad'],
	emotions: ['happy', 'surprised', 'sad', 'angry'],
}

export function getRatingAnswers(rating) {
	if (rating.category.startsWith('classic')) {
		return Array.from({ length: rating.max }, (_, i) => i + 1)
	} else {
		return arrRatingList[rating.category]
	}
}
