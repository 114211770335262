// List of options for register page field
export const USE_WHAT_FOR = [
	{
		label: {
			en: 'Generate more leads',
			fr: `Générer plus de prospects`,
		},
		id: 2,
	},
	{
		label: {
			en: 'Promote your products and services',
			fr: `Promouvoir vos produits et services`,
		},
		id: 1,
	},
	{
		label: {
			en: 'Improve brand awareness',
			fr: `Améliorer la notoriété de la marque`,
		},
		id: 5,
	},
	{
		label: {
			en: 'Increase engagement rates',
			fr: `Augmenter les taux d'engagement`,
		},
		id: 4,
	},
	{
		label: {
			en: 'Create micro learning contents',
			fr: `Créer des contenus de micro-apprentissage`,
		},
		id: 6,
	},
	{
		label: {
			en: 'Support your customers',
			fr: `Soutenir vos clients`,
		},
		id: 3,
	},
	{
		label: {
			en: 'Other',
			fr: `Autre`,
		},
		id: 7,
	},
]
