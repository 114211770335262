export const en = {
	'subscription.alert.message.custom_limits': 'Contact us if you need workspaces or custom limits.',
	'subscription.alert.message.upsell': 'Contact us if you want to change to a monthly or yearly subscription.',
	'subscription.alert.button': 'Talk to Us',
	'subscription.tab.title': 'Plans',
	'subscription.plan.free_tier': 'Free Plan',

	'subscription.buttons.subscribe': 'Subscribe',
	'subscription.buttons.subscription_active': 'Manage',
	'subscription.buttons.license_upgrade': 'Upgrade',
	'subscription.buttons.license_downgrade': 'Downgrade',
	'subscription.buttons.license_activated': 'Manage',
	'subscription.currency_change.title': 'Currency change',
	'subscription.currency_change.content': `If you want to change your subscription's currency, please contact us at `,

	// License Tiers
	'license.tiers.snackeet_tier1': 'Tier 1',
	'license.tiers.snackeet_tier2': 'Tier 2',
	'license.tiers.snackeet_tier3': 'Tier 3',
	'license.tiers.snackeet_tier4': 'Tier 4',

	// Feature values
	'feature.value.unlimited': 'Unlimited',
	'feature.value.storage': '{ count } GB storage',
	'feature.value.per_month': '{ count }/Month',
	'feature.value.stats_mode.basic': 'Basic',
	'feature.value.stats_mode.advanced': 'Advanced',
	'feature.value.support.priority': 'Priority',
	'feature.value.support.email_chat': 'Email & Chat',

	// Features Messages
	'subscription.features.upgrade_plan': 'Upgrade',
	'subscription.features.amp.disabled':
		'Google WebStories are not available for your current plan. If you want to upgrade, please contact support.',
	'subscription.features.disabled': 'This feature is currently disabled, please upgrade your plan to activate it.',
	'subscription.story_banners.disabled': `You've reached the maximum number of widget banners ({ banners_count }/{ limit_banners }). Please upgrade your plan to create more.`,
	'subscription.story_create.disabled': `You've reached the maximum number of stories ({ stories_count }/{ limit_stories }). Please upgrade your plan to create more.`,

	// Feature Categories
	'feature.category.team': 'Workspace & Members',
	'feature.category.stories': `Stories`,
	'feature.category.amp_stories_features': `Google Webstories Special Features`,
	'feature.category.snackeet_stories_features': `Snackeet Webstories Special Features`,
	'feature.category.general': `General`,
	'feature.category.in_website': `Insert on your website`,
	'feature.category.sharing': 'Sharing',
	'feature.category.integrations': 'Integrations',
	'feature.category.data_and_files': 'Data & Files',
	'feature.category.support': 'Support',

	// ---------------------------------------------------------------------------------
	// List of features
	'feature.users_per_month': 'Active users',

	// Team & Workspaces
	'feature.seats': 'Members',
	'feature.multiple_workspaces': 'Multiple workspaces',

	// Stories
	'feature.stories_count': 'Number of Stories',
	'feature.snackeet_stories': 'Snackeet Webstories',
	'feature.amp_stories': 'AMP/Google Webstories',

	// AMP Stories Features
	'feature.amp_stories_interactions': 'Interactions (Polls / Quizzes)',
	'feature.amp_cta': 'CTA button',
	'feature.amp_ads': 'Run your own ads',
	'feature.amp_form': 'Form (Page Attachments)',

	// Snackeet Stories Features
	'feature.snackeet_stories_types': 'Types: Advanced Story, Quiz, Survey, Form',
	'feature.cookie_less': '🔥 Cookieless',
	'feature.media_answers': 'Video/audio responses',
	'feature.conditional_logic': 'Conditional logic',
	'feature.wheel': 'Gamification : Spin the wheel',
	'feature.story_schedule': 'Schedule Story expiration',
	'feature.automatic_reply': 'Auto-reply after form is completed',
	'feature.email_verification': 'Email Verification',
	'feature.leaderboards': 'Leaderboards',

	// General
	'feature.brand_presets': 'Brand Presets',
	'feature.no_branding': 'Removed Snackeet watermark',
	'feature.custom_templates': 'Custom Templates',
	'feature.custom_fonts': 'Custom Fonts',
	'feature.video_transcript': 'Automatic subtitle generation for videos',

	// Insert on your website
	'feature.story_banners': 'Story Banners',
	'feature.iframe': 'Iframe',
	'feature.bubble_widget': 'Floating Widget',

	// Sharing
	'feature.link': 'Link',
	'feature.qr_code': 'QR Code',
	'feature.custom_story_slugs': 'Cutomize your Story slug',
	'feature.custom_fqdn': 'Use your own domain name (CNAME)',

	// Integrations
	'feature.integration_platforms': 'Zapier, Pabbly, Integrately',
	'feature.spreadsheets': 'Google Sheets, Air table (soon)',
	'feature.email_integrations': 'Sendinblue, MailerLite, Active Campaign',
	'feature.analytics_integration': 'Fb Pixel, GA, Google Tag Manager',
	'feature.webhooks': 'Webhooks',
	'feature.instagram': 'Instagram Connect for Business Accounts',

	// Data & Files
	'feature.data_export': 'CSV Data export',
	'feature.stats_mode': 'Statistics',
	'feature.stats_retention': 'Stats Data storage (in months)',
	'feature.storage_quota': 'Files Storage (in Gb)',

	// Support
	'feature.support': 'Support Type',
}
