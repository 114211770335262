import {
	AtSymbolIcon,
	ClipboardIcon,
	CursorArrowRaysIcon,
	LinkIcon,
	StarIcon,
	ViewColumnsIcon,
} from '@heroicons/react/24/outline'
import WheelSVG from 'static/svg/game_icons/wheel.svg'

export const PAGELIST = [
	{
		page_type: 'blank',
		options: [
			{
				sub_type: 'none',
				description: 'blank_none',
				icon: <ClipboardIcon />,
			},
		],
	},
	{
		page_type: 'multiple_choice',
		options: [
			{
				button_group_type: 'answer',
				sub_type: 'answer_buttons',
				description: 'button_answers',
				icon: <CursorArrowRaysIcon />,
			},
			{
				button_group_type: 'action',
				sub_type: 'action_buttons',
				description: 'button_action',
				icon: <LinkIcon />,
			},
		],
	},
	{
		page_type: 'form',
		options: [
			{
				sub_type: 'text',
				description: 'field_text',
				icon: <AtSymbolIcon />,
			},
		],
	},
	{
		page_type: 'rating',
		options: [
			{
				sub_type: 'classic',
				description: 'rating_classic',
				icon: <StarIcon />,
			},
		],
	},
	{
		page_type: 'game',
		options: [
			{
				sub_type: 'wheel',
				description: 'wheel_game',
				icon: <WheelSVG />,
			},
		],
	},
	{
		page_type: 'media_answer',
		options: [
			{
				sub_type: 'none',
				description: 'media_answer',
				icon: <ViewColumnsIcon />,
			},
		],
	},
]
