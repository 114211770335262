import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { loginFail, logoutSuccess } from 'redux/customer/slice'

const initialState = {
	_id: null,
	name: null,
	avatar: null,
	languages: [],
}

export const slice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		selectProject(state, action) {
			const project = action.payload
			return project
		},
		updateProject(state, action) {
			_.assign(state, _.omit(action.payload, ['_id', 'users_count', 'stories_count']))
		},
		resetProject() {
			return initialState
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(logoutSuccess, (state, action) => {
				return initialState
			})
			.addCase(loginFail, (state, action) => {
				return initialState
			})
	},
})

export const { selectProject, resetProject, updateProject } = slice.actions

export default slice.reducer
