import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants'
import _ from 'lodash'
import ReactPlayer from 'react-player'

export function getImageHTMLElement(url) {
	return new Promise((resolve, reject) => {
		const img = new Image()

		img.addEventListener('load', () => {
			resolve(img)
		})

		img.addEventListener('error', (event) => {
			reject(`${event.type}: ${event.message}`)
		})

		img.src = url
	})
}

export function getVideoHTMLElement(url) {
	return new Promise((resolve, reject) => {
		const video = document.createElement('video')

		video.addEventListener('loadedmetadata', () => {
			resolve(video)
		})

		video.addEventListener('error', (event) => {
			reject(`${event.type}: ${event.message}`)
		})

		video.src = url
	})
}

export function isVimeoVideo(str) {
	const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g // Use this regex to match with timetap link

	if (!regex.test(str)) {
		return false
	}

	try {
		const url = new URL(str)
		const host = url.hostname
		return _.includes(host, 'vimeo')
	} catch (err) {
		return false
	}
}

export function isValidVideosUrl(str, is_background) {
	const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g // Use this regex to match with timetap link

	if (!regex.test(str)) {
		return false
	}

	if (isVimeoVideo(str)) return true

	return ReactPlayer.canPlay(str)
}

export const playVideo = _.debounce((evt) => {
	const playPromise = evt?.target?.play()
	if (playPromise !== undefined) {
		playPromise
			.then(() => {
				// Playback started!
			})
			.catch(() => {
				// Play was prevented
			})
	}
}, 200)

export function pauseVideo(evt) {
	playVideo.cancel()
	evt?.target?.pause()
}

// Get dimension of a media block via original ratio and height width of story
export async function getMediaDimensions({ type, url }) {
	try {
		let originalHeight
		let originalWidth

		if (type === 'video') {
			const video = await getVideoHTMLElement(url)
			originalHeight = video.videoHeight
			originalWidth = video.videoWidth
		} else {
			const image = await getImageHTMLElement(url)
			originalHeight = image.height
			originalWidth = image.width
		}

		// Make sure dimensions stay within predefined boundaries
		// to prevent big elements from overflowing.
		const ratio = originalWidth / originalHeight
		if (ratio >= 1) {
			const width = _.clamp(originalWidth, PREVIEW_WIDTH - 20)
			return {
				width,
				height: width / ratio,
			}
		} else {
			const height = _.clamp(originalHeight, PREVIEW_HEIGHT / 2)
			return {
				height,
				width: height * ratio,
			}
		}
	} catch (err) {
		console.error(err)
		return {
			height: undefined,
			width: undefined,
		}
	}
}

// Get the original dimension of the media file
export async function getRealMediaDimension({ type, url }) {
	try {
		let originalHeight
		let originalWidth

		if (type === 'video') {
			const video = await getVideoHTMLElement(url)
			originalHeight = video.videoHeight
			originalWidth = video.videoWidth
		} else {
			const image = await getImageHTMLElement(url)
			originalHeight = image.height
			originalWidth = image.width
		}

		return {
			height: originalHeight,
			width: originalWidth,
		}
	} catch (err) {
		console.error(err)
		return {
			height: undefined,
			width: undefined,
		}
	}
}

// Get the video duration
export async function getVideoDuration(url) {
	try {
		const video = await getVideoHTMLElement(url)
		return video.duration
	} catch (err) {
		console.error(err)
		return 0
	}
}
