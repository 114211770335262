import { snackeet_blue } from 'styles/colors'

export const BLOCK_BORDER_PRESET = [
	{
		name: 'none',
		style: {
			borderWidth: '0px',
			borderStyle: 'solid',
			borderRadius: '0px',
			borderColor: snackeet_blue,
			backgroundColor: 'rgba(170, 170, 170, 0)',
		},
	},
	{
		name: 'outlined_square',
		style: {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderRadius: '0px',
			borderColor: snackeet_blue,
			backgroundColor: 'rgba(170, 170, 170, 0)',
		},
	},
	{
		name: 'outlined_round',
		style: {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderRadius: '10px',
			borderColor: snackeet_blue,
			backgroundColor: 'rgba(170, 170, 170, 0)',
		},
	},
	{
		name: 'outlined_circle',
		style: {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderRadius: '120px',
			borderColor: snackeet_blue,
			backgroundColor: 'rgba(170, 170, 170, 0)',
		},
	},
]
