import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const OrganizationsSvc = {
	create,
	get,
	getBySlug,
	getList,
	getMembers,
	getProjects,
	inviteMember,
	getInvitationsList,
	getLicensesList,
	respondInvitation,
	update,
	del,
	kickMember,
	updateMember,
	removeInvitation,
	leave,
	validateSlug,
	getSubscription,
	activateLicense,
	updateWorkspaceAccess,
}

export default OrganizationsSvc

const DEFAULT_FIELDS = ['name', 'logo', 'slug'].join(',')

async function create(body) {
	const { data } = await axios.post(`${API_URL}/organizations`, body, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}

async function get(_id) {
	const { data } = await axios.get(`${API_URL}/organizations/${_id}`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data.organization
}

async function getBySlug(slug) {
	const { data } = await axios.get(`${API_URL}/organizations/slug/${slug}`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data.organization
}

async function getList({ pageNumber, perPage, searchQuery, fields = DEFAULT_FIELDS }) {
	try {
		const { data } = await axios.get(`${API_URL}/organizations`, {
			params: {
				fields,
				pageNumber,
				perPage,
				searchQuery,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('getOrganizations', err)
		return []
	}
}

async function getInvitationsList() {
	try {
		const { data } = await axios.get(`${API_URL}/organizations/invitations`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('getInvitationsList', err)
		return []
	}
}

async function getLicensesList() {
	try {
		const { data } = await axios.get(`${API_URL}/organizations/licenses`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('getLicensesList', err)
		return []
	}
}

async function activateLicense({ organization_id, license_id }) {
	const { data } = await axios.patch(
		`${API_URL}/organizations/${organization_id}/activate/${license_id}`,
		{},
		{
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)

	return data
}

async function respondInvitation(_id, accepting) {
	try {
		const { data } = await axios.post(
			`${API_URL}/organizations/${_id}/invitations/respond`,
			{ accepting },
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)
		return data
	} catch (err) {
		console.log('respondInvitation', err)
	}
}

async function getMembers(_id) {
	const { data } = await axios.get(`${API_URL}/organizations/${_id}/members`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data.members || []
}

async function getProjects(_id) {
	try {
		const { data } = await axios.get(`${API_URL}/organizations/${_id}/projects`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data.projects
	} catch (err) {
		console.log('GetOrganizationProjects', err)
		return []
	}
}

async function leave(_id) {
	try {
		const { data } = await axios.delete(`${API_URL}/organizations/${_id}/leave`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('leaveOrganization', err)
	}
}

async function inviteMember(_id, body) {
	const { data } = await axios.post(`${API_URL}/organizations/${_id}/invitations`, body, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}

async function kickMember(_id, member_id) {
	try {
		const { data } = await axios.delete(`${API_URL}/organizations/${_id}/members/${member_id}`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('KickMember', err)
	}
}

async function updateMember(_id, member_id, body) {
	try {
		const { data } = await axios.patch(`${API_URL}/organizations/${_id}/members/${member_id}`, body, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('UpdateMember', err)
	}
}

async function updateWorkspaceAccess(_id, project_id, body) {
	try {
		const { data } = await axios.patch(`${API_URL}/organizations/${_id}/projects/${project_id}`, body, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('UpdateWorkspaceAccess', err)
	}
}

async function removeInvitation(_id, invitation_id) {
	try {
		const { data } = await axios.delete(`${API_URL}/organizations/${_id}/invitations/${invitation_id}`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		console.log('removeMember', err)
	}
}

function update(_id, body) {
	return axios.patch(`${API_URL}/organizations/${_id}`, body, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}

async function del(_id) {
	const { data } = await axios.delete(`${API_URL}/organizations/${_id}`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data
}

async function validateSlug(slug) {
	const { data } = await axios.post(
		`${API_URL}/organizations/validate`,
		{ slug },
		{
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)
	return data.success
}

async function getSubscription(organization_id) {
	try {
		const { data } = await axios.get(`${API_URL}/organizations/${organization_id}/subscription`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.subscription
	} catch (err) {}
}
