import Loader from 'components/Loader';
import useAppAuthorization from 'lib/hooks/useAppAuthorization';
export default function withAuth(AuthComponent) {
  return function Authenticated(props) {
    const {
      isLoadingData,
      isAccessGranted,
      isRouterReady
    } = useAppAuthorization();

    // When access not granted, a redirection will always take place
    if (isLoadingData || !isAccessGranted || !isRouterReady) {
      return <Loader />;
    }
    return <AuthComponent {...props} />;
  };
}