import _ from 'lodash'

export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

export function moveBlockUp(blocks, blocksToMoveIds) {
	const selectedElement = groupSelectedElements(blocks, blocksToMoveIds)

	for (const group of selectedElement) {
		const groupIndex = group[0].index
		const newIndex = _.clamp(groupIndex + 1, 0, blocks.length)
		blocks.splice(newIndex, 0, ..._.map(group, (o) => o.value))
	}
}

export function moveBlockDown(blocks, blocksToMoveIds) {
	const selectedElement = groupSelectedElements(blocks, blocksToMoveIds)

	// Take the first index of the group as a reference & move the whole group to the new index
	for (const group of selectedElement) {
		const groupIndex = group[0].index
		const newIndex = _.clamp(groupIndex - 1, 0, blocks.length)
		blocks.splice(newIndex, 0, ..._.map(group, (o) => o.value))
	}
}

function filterAndSortByIndex(blocks, blocksToMoveIds) {
	const arrayWithIndexes = _.map(blocks, (value, index) => ({
		index,
		value,
	}))

	const filtered = _.filter(arrayWithIndexes, (f) => blocksToMoveIds.includes(f.value._id))

	return _.sortBy(filtered, 'index')
}

// Group creation based on adjacent elements
function groupSelectedElements(blocks, blocksToMoveIds) {
	const sorted = filterAndSortByIndex(blocks, blocksToMoveIds)
	const groups = []
	let currentGroupIndex = 0
	let previousIndex = 0

	// Remove selectedElements from blocks
	_.remove(blocks, (block) => blocksToMoveIds.includes(block._id))

	// Separate elements to multiple group
	for (const [sortedIndex, sortedElement] of sorted.entries()) {
		if (sortedIndex === 0) {
			groups.push([sortedElement])
		} else if (sortedElement.index === previousIndex + 1) {
			groups[currentGroupIndex].push(sortedElement)
		} else {
			groups.push([sortedElement])
			currentGroupIndex++
		}

		previousIndex = sortedElement.index
	}

	return groups
}
