import _ from 'lodash'

export class CustomerError extends Error {
	constructor(err, params) {
		super(err)
		_.assign(this, params)
	}
}

export class OrganizationError extends Error {
	constructor(err, params) {
		super(err)
		this.redirectTo = '/'
		_.assign(this, params)
	}
}

export class SubscriptionError extends Error {
	constructor(params) {
		super()
		this.redirectTo = '/'
		_.assign(this, params)
	}
}
