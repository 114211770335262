export const fr = {
	'common.survey': 'Sondage',
	'common.quiz': 'Quiz',
	'common.vote': 'Vote',

	'common.snackeet_type.classic': 'Avancée',
	'common.snackeet_type.single_page': 'Avancée',
	'common.snackeet_type.amp': 'Simple',
	'common.form_tooltip': `Vous pouvez configurer les messages d'erreur depuis l'onglet Langues`,

	'common.copy': 'Copier',
	'common.cut': 'Couper',
	'common.paste': 'Coller',
	'common.copy_style': 'Copier Style',
	'common.paste_style': 'Appliquer Style',
	'common.set_background': `Définir comme arrière-plan`,
	'common.yes': 'Oui',
	'common.no': 'Non',
	'common.nothing': 'Rien',
	'common.gender': 'Genre',
	'common.male': 'homme',
	'common.female': 'femme',
	'common.name': 'Nom',
	'common.type': 'Type',
	'common.story_type': 'Story Type',
	'common.profile': 'Profil',

	'common.duration': 'Durée',
	'common.per_page': 'Par page',

	'common.help': 'Aide',
	'common.help.description': `Vous pouvez accéder à notre centre d\'aide, vous pouvez également utiliser le chat.`,

	'common.other_languages': 'Autres langues',
	'common.language': 'Langue',
	'common.language.english': 'Anglais',
	'common.language.french': 'Français',
	'common.language.spanish': 'Espagnol',
	'common.language.german': 'Allemand',

	'common.all': `Tous`,
	'common.users': 'Utilisateurs',
	'common.story_users': 'Utilisateurs de Story',
	'common.users_count': `{ count, plural, one { utilisateur } other { utilisateurs }}`,
	'common.result_count': `{count} {count, plural, one {résultat} other {résultats}}`,
	'common.members_count': `{ count, plural, one { membre } other { membres }}`,
	'common.responses_count': `{ count, plural, one { réponse } other {réponses}}`,
	'common.agents_count': `{count} { count, plural, one { agent } other { agents }}`,
	'common.brands_count': `{ count, plural, one { marque } other { marques }}`,
	'common.organizations_count': '{count} { count, plural, one { Organization } other { Organizations }}',
	'common.stories_count': `{ count } { count, plural, =1 { story } other { stories }}`,
	'common.widgets_count': `{ count } { count, plural, =1 { bannière } other { bannières }}`,
	'common.workspaces_count': `{count} {count, plural, one {Espace de travail} other {Espaces de travail}}`,

	'common.loading': 'Chargement',
	'common.title': 'Titre',
	'common.subtitle': 'Sous-titre',

	'common.days': 'Jours',
	'common.hours': 'Heures',
	'common.minutes': 'Minutes',
	'common.seconds': 'Secondes',

	'common.buttons': 'Boutons',

	'common.button': `{ count, plural, one { Bouton } other { Boutons }}`,
	'common.text_field': 'Champ texte',
	'common.text': 'Texte',
	'common.field': '{ count, plural, one { Champ } other { Champs }}',
	'common.variable': '{ count, plural, one { Variable } other { Variables }}',

	'common.new': 'Nouveau',
	'common.create': 'Créer',
	'common.update': 'Mettre à jour',
	'common.new_story': 'Créer Nouvelle Story',
	'common.new_widget': 'Nouvelle bannière',
	'common.new_brand': 'Nouvelle marque',
	'common.create_new_brand': 'Créer une nouvelle marque',
	'common.new_leaderboard': 'Nouveau Leaderboard',
	'common.create_new_leaderboard': 'Créer un nouveau leaderboard',
	'common.preview': 'Aperçu',

	'common.edit': 'Modifier',
	'common.change_image': 'Modifier Image',
	'common.change_video': 'Modifier',

	'common.up': 'HAUT',
	'common.down': 'BAS',
	'common.selected': '{count} {count, plural, one { sélectionné } other { sélectionnés }}',
	'common.filter_by_story_type': 'Filtrer par type de Story',
	'common.filter_by_folder': 'Filtrer par dossier',
	'common.sort_by': 'Trier par',

	'common.hide': 'Masquer',
	'common.add': 'Ajouter',
	'common.show': 'Afficher',
	'common.visualize': 'Visualiser',
	'common.download': 'Télécharger',
	'common.duplicate': 'Dupliquer',
	'common.value': 'Valeur',

	'common.error_messages': `Messages d'erreur`,
	'common.content': 'Texte par défaut',

	'common.empty_list': 'La liste est vide',

	'common.click_to_copy': 'Cliquer pour copier',
	'common.clipboard.success.copy': 'Copié dans le presse-papier',
	'common.clipboard.error.copy': 'Erreur lors de la copie',
	'common.clipboard.error.paste': 'Erreur lors du collage',
	'common.clipboard.error.access': `Accès au presse-papiers refusé.\nVeuillez l'activer dans les paramètres de votre navigateur.`,
	'common.clipboard.error.not_supported': `Votre navigateur ne prend pas entièrement en charge les opérations du presse-papiers.`,

	'common.unknown': 'Inconnu',
	'common.answers': 'Réponses',
	'common.answer': 'Réponse',

	'common.period': 'Période',
	'common.week': 'Semaine',
	'common.month': 'Mois',
	'common.year': 'Année',

	'common.currency': 'Devise',
	'common.currency.symbol.EUR': '€',
	'common.currency.symbol.USD': '$',

	'common.status.accepted': 'Acceptée',
	'common.status.pending': 'En cours',
	'common.status.rejected': 'Refusée',

	'common.auto_advance': 'Auto-avancer',
	'common.display_timer': 'Affichage du timer',
	'common.undo': 'Annuler',
	'common.redo': 'Refaire',

	'common.placeholder': 'Espace réservé',
	'common.colors': 'Couleurs',
	'common.field_label': 'Libellé du champ',

	'common.coming_soon': `Bientôt disponible`,
	'common.volume.mute': 'Couper le son',
	'common.volume.unmute': 'Réactiver le son',

	// Theming
	'common.theming': 'Thème',
	'common.background': 'Fond',
	'common.color': 'Couleur',
	'common.video': 'Video',
	'common.image': 'Image',
	'common.none': 'Aucun',
	'common.text_color': 'Couleur texte',
	'common.shape_color': 'Couleur forme',
	'common.background_color': 'Couleur fond',
	'common.size': 'Taille',
	'common.font_size': 'Taille Police',
	'common.title_font_size': 'Taille titre',
	'common.subtitle_font_size': 'Taille sous-titre',
	'common.height': 'Hauteur',
	'common.width': 'Largeur',
	'common.bold': 'Gras',
	'common.underline': 'Souligné',
	'common.italic': 'Italique',
	'common.radius': 'Arrondis',
	'common.border': 'Bordures',
	'common.block': 'Bloc',
	'common.shadow': 'Ombre',
	'common.line_height': 'Interligne',
	'common.result_bar': 'Barre de résultat',
	'common.result_bar.background': 'Fond barre de résultat',

	'common.theming.confirm_button': 'Confirmation',
	'common.welcom_page': `page d'accueil`,
	'common.ending_page': 'page de fin',

	// Operators logic
	'common.ops.equal': '= un nombre',
	'common.ops.=': 'Est égal à',
	'common.ops.different': '!= un nombre',
	'common.ops.!=': 'Est différent de',
	'common.ops.lower_equal': '<= un nombre',
	'common.ops.<=': 'Est inférieur ou égal à',
	'common.ops.greater_equal': '>= un nombre',
	'common.ops.>=': 'Est supérieur ou égal à',
	'common.ops.greater': '> un nombre',
	'common.ops.>': 'Est supérieur à',
	'common.ops.lower': '< un nombre',
	'common.ops.<': 'Est inférieur à',
	'common.ops.var_equal': '= une variable',
	'common.ops.var_=': 'Est égal à',
	'common.ops.var_different': '!= une variable',
	'common.ops.var_!=': 'Est différent de',
	'common.ops.var_lower_equal': '<= une variable',
	'common.ops.var_<=': 'Est inférieur ou égal à',
	'common.ops.var_greater_equal': '>= une variable',
	'common.ops.var_>=': 'Est supérieur ou égal à',
	'common.ops.var_greater': '> une variable',
	'common.ops.var_>': 'Est supérieur à',
	'common.ops.var_lower': '< une variable',
	'common.ops.var_<': 'Est inférieur à',
	'common.ops.includes': 'Inclut',
	'common.ops.contains': 'Inclut',
	'common.ops.not_includes': `N'inclut pas`,
	'common.ops.is': 'Est',
	'common.ops.isNot': `N'est pas`,
	'common.ops.answer_is': 'Est',
	'common.ops.answer_isNot': `N'est pas`,
	'common.ops.isAnswered': 'Est répondu',
	'common.ops.answer_isAnswered': 'Est répondu',
	'common.ops.isNotAnswered': `N'est pas répondu`,
	'common.ops.answer_isNotAnswered': `N'est pas répondu`,
	'common.ops.beginsWith': 'Commence par',
	'common.ops.endsWith': 'Se termine par',
	'common.ops.includes_nonSen': 'Inclut (NON sensible à la casse)',
	'common.ops.contains_nonSen': 'Inclut (NON sensible à la casse)',
	'common.ops.is_nonSen': 'Est (NON sensible à la casse)',
	'common.ops.isNot_nonSen': `N'est pas (NON sensible à la casse)`,
	'common.ops.beginsWith_nonSen': 'Commence par (NON sensible à la casse)',
	'common.ops.endsWith_nonSen': 'Se termine par (NON sensible à la casse)',
	'common.ops.before': 'Avant',
	'common.ops.after': 'Après',
	'common.ops.equals': 'Est égal',
	'common.ops.sameDay': 'Est le',
	'common.ops.null': 'Est vide',
	'common.ops.not_null': `N'est pas vide`,
	'common.ops.notNull': `N'est pas vide`,
	'common.ops.is_true': 'Est VRAI',
	'common.ops.is_false': 'Est FAUX',
	'common.ops.quiz_correct': 'Est correct',
	'common.ops.quiz_incorrect': 'Est incorrect',
	'common.ops.quiz_partial': 'Est partiellement correct',
	'common.ops.var_greatest': 'Est le plus grand dans',
	'common.ops.var_smallest': 'Est le plus petit dans',
	'common.ops.media_is_video': 'La réponse est une vidéo',
	'common.ops.media_is_audio': 'La réponse est un audio',
	'common.ops.media_is_text': 'La réponse est un texte',
	'common.ops.media_is_answered': 'Est répondu',
	'common.ops.media_is_null': 'Est nul',
	'common.ops.wheel_is': 'Est',
	'common.ops.wheel_isNot': `N'est pas`,
	'common.ops.wheel_win': 'A gagné',
	'common.ops.wheel_lose': 'A perdu',
	'common.ops.wheel_played': 'Est joué',
	'common.ops.wheel_notPlayed': `N'est pas joué`,
	'common.ops.is_quiz_true': 'est réussi',
	'common.ops.is_quiz_false': `n'est pas réussi`,

	// Consequence Types
	'common.consequence_type.text': 'Text',
	'common.consequence_type.numerical': 'Numérique',

	// Consequence Operators
	'common.consequence_ops.redirect': 'Aller à',
	'common.consequence_ops.replace': 'Remplacer',
	'common.consequence_ops.add': '+  Additionner',
	'common.consequence_ops.subtract': '-  Soustraire',
	'common.consequence_ops.divide': '÷  Diviser',
	'common.consequence_ops.multiply': 'x  Multipler',
	'common.consequence_ops.power': '^ Puissance',
	'common.consequence_ops.root': '√ Racine',
	'common.consequence_ops.inputNumber': 'un numéro',

	'common.consequence_display.add': '+',
	'common.consequence_display.subtract': '-',
	'common.consequence_display.divide': '÷',
	'common.consequence_display.multiply': 'x',
	'common.consequence_display.power': '^',
	'common.consequence_display.root': '√',

	// Condition Headers
	'common.cond_header.if': 'Si',
	'common.cond_header.always': 'Toujours',

	// Combinations logic
	'common.comb.and': 'ET',
	'common.comb.or': 'OU',

	// condition value placeholder
	'common.cond.placeholder': 'Valeur est requise*',

	// Page Errors
	'common.errors': 'Erreurs',
	'common.recommendations': 'Recommandations',

	// Context Menu
	'common.duplicate': 'Dupliquer',
	'common.delete': 'Supprimer',
	'common.bring_to_front': 'Mettre au premier plan',
	'common.bring_forward': 'Avancer',
	'common.send_backward': `Envoyer vers l'arrière`,
	'common.send_to_back': 'Mettre au dernier plan',

	// State
	'common.active': 'En ligne',
	'common.activated': 'Activé',
	'common.disabled': 'Désactivé',
	'common.enabled': 'Activé',
	'common.no_published': 'Story non publiée',

	'common.optional': 'optionnel',
	'common.valid': 'Valide',
	'common.invalid': 'Invalide',
	'common.unset': 'Indéfini',
	'common.fontFamily': 'Famille de police',

	'common.upgrade_offer': 'Vous devez mettre à niveau votre offre pour accéder à cette fonctionnalité premium.',

	'common.platform.mobile': 'Mobile',
	'common.platform.desktop': 'Desktop',

	// Story tags
	'common.story_tag.all': 'Tout',
	'common.story_tag.engagement': 'Engagement',
	'common.story_tag.lead_generation': 'Qualification',
	'common.story_tag.feedback': 'Feedback',
	'common.story_tag.education': 'Éducation',
	'common.story_tag.event': 'Évènement',
	'common.story_tag.e_commerce': 'E-commerce',
	'common.story_tag.recruiting': 'HR/Job',
	'common.story_tag.faq': 'FAQ',
	'common.story_tag.game': 'Jeu',
	'common.story_tag.promotion': 'Promotion',
	'common.story_tag.product_and_unboxing': 'Produit et déballage',
	'common.story_tag.life_style_fashion': 'Style de vie / Mode',
	'common.story_tag.media_news': 'Médias/Actualités',
	'common.story_tag.giveaways_and_raffles': 'Cadeaux et tombolas',
	'common.story_tag.surveys': 'Sondages',
	'common.story_tag.quizs': 'Quizs',
	'common.story_tag.sport': 'Sport',
	'common.story_tag.news_and_entertainment': 'Actualités & Divertissement',
	'common.story_tag.reviews': 'Avis',
	'common.story_tag.promotion_sales': 'Promotion / Ventes',
	'common.story_tag.other': 'Autres',

	'common.story_tag.bio_link': 'Lien bio',
	'common.story_tag.engagement_review': 'Engagement & Avis',
	'common.story_tag.education_faq': 'Éducatif, Tutoriels & FAQ',
	'common.story_tag.marketing_lead': 'Marketing & Campagnes de Prospection',
	'common.story_tag.product_showcase': 'Présentation de Produits & Services',
	'common.story_tag.corporate_job': 'Entreprise (Com RH / Candidature)',
	'common.story_tag.interactive_game': 'Expériences Interactives & Jeux',
	'common.story_tag.micro_site': 'Micro Sites',

	'common.story_tag.howTo_unboxing': 'Comment faire / Conseils / Déballage',
	'common.story_tag.produit_routines': 'Routines avec liste de produits',
	'common.story_tag.benefits': 'Avantages',
	'common.story_tag.review': 'Avis',
	'common.story_tag.faq': 'FAQ',

	'common.story_tag.ask_for_review': 'Demander un avis',
	'common.story_tag.communication_article': 'Communication / Article',
	'common.story_tag.job_offers': "Offres d'emploi",

	'common.story_tag.games_quiz': 'Jeux & Quiz',
	'common.story_tag.vote_survey': 'Vote / Sondage',

	// Zoom
	'common.fit': 'Contenir',
	'common.fill': 'Remplir',

	'common.custom': 'Personnalisé',
	'common.domain': `{ count, plural, one { domaine } other { domaines }}`,

	// AMP Interaction styles
	'common.theme.light': 'Clair',
	'common.theme.dark': 'Sombre',

	'common.size.small': 'Petit',
	'common.size.medium': 'Moyen',
	'common.size.large': 'Large',

	'common.alignment.left': 'Gauche',
	'common.alignment.center': 'Centre',
	'common.alignment.right': 'Droit',
	'common.alignment.justify': 'Justifier',

	'common.option_style.default': 'Défaut',
	'common.option_style.flat': 'Bordure',
	'common.option_style.shadow': 'Ombre',
	'common.option_style.transparent': 'transparent',

	'common.expired': 'Expiré',
	'common.expires_in': 'Expire dans {timeRemaining}',

	'common.url.unsecure': 'Merci de renseigner une URL https',
	'common.url.not_valid': 'Merci de renseigner une URL https valide',

	'common.warning': `Attention`,

	'common.recently_used': 'Utilisé récemment',
	'common.brand_colors': 'Couleurs de la marque',

	'common.unsaved_changes': 'Vous avez des modifications non sauvegardées. Voulez-vous les rejeter et continuer?',

	'common.interactions': 'Interactions:',
	'common.components': 'Composantes:',

	'common.never': 'Jamais',
	'common.settings': 'Paramètres',

	'common.form_submit': 'Soumissions',
	'common.game_played': 'Plays',
	'common.rating_feedback': 'Retours',
	'common.file_upload': 'Uploads',
	'common.responses': 'Réponses',
	'common.cta_clicks': 'CTA Clics',

	'common.added': 'Ajouté',
	'common.order': 'Ordre',
}
