import { createSelector } from '@reduxjs/toolkit'
import { DEFAULT_APP_LOCALE } from 'lib/constants'
import _ from 'lodash'
import { organizationSelector } from 'redux/organization/selectors'

export const customerSelector = (state, _props) => state.customer

export const getCustomerId = createSelector(customerSelector, (state) => state._id)

export const getCustomerEmail = createSelector(customerSelector, (state) => state.email)

export const getAppLocale = createSelector(customerSelector, (state) => state.locale || DEFAULT_APP_LOCALE)

export const getCustomerAvatar = createSelector(customerSelector, (state) => ({
	url: state.avatar,
	letters: getCustomerInitials(state),
}))

export const getCustomerIdentification = createSelector(customerSelector, (state) => {
	if (!state.isLoggedIn) {
		return undefined
	}
	return {
		_id: state._id,
		email: state.email,
		full_name: getCustomerFullName({
			first_name: state.first_name,
			last_name: state.last_name,
		}),
		username: state.username,
		date_created: state.date_created,
		locale: state.locale,
	}
})

export const getEmailVerifiedStatus = createSelector(customerSelector, (state) => state.email_verified)

export const getCustomerRole = createSelector(customerSelector, (state) => state.role)

export const getCustomerUsername = createSelector(customerSelector, (state) => state.username)

// Returns customer's role & ownership regarding the current organization
export const getCustomerRights = createSelector(customerSelector, organizationSelector, (customer, organization) => {
	const role = customer.role === 'super_admin' ? 'admin' : _.find(organization?.members, { _id: customer._id })?.role

	return {
		isOwner: organization?.owner === customer._id,
		isAdmin: role === 'admin',
		isAgent: role === 'agent',
		role,
	}
})

function getCustomerInitials(customer) {
	let initials = ''
	if (customer.first_name) initials += customer.first_name[0]
	if (customer.last_name) initials += customer.last_name[0]
	return initials || null
}

function getCustomerFullName({ first_name, last_name }) {
	let full_name = ''
	if (first_name) full_name += `${first_name} `
	if (last_name) full_name += `${last_name}`
	return full_name.trim()
}
