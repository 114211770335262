export const fr = {
	'placeholder.search': 'Rechercher...',
	'placeholder.search_by_name': 'Chercher par nom',
	'placeholder.search_image': 'Tapez dans la barre de recherche pour trouver une image',
	'placeholder.image_url': `Entrez l'URL de l'image`,
	'placeholder.video_url': `Entrez l'URL de la vidéo`,
	'placeholder.enter_message': `Entrez votre message`,
	'placeholder.form_field': `Entrez une indication`,

	'placeholder.text_editor': 'Entrez un texte...',
}
