import isEmpty from 'lodash/isEmpty'

const CONSENT_MESSAGE = {
	en: 'By clicking on Send, I agree with the privacy policy.',
	fr: "En cliquant sur Envoyer, j'accepte la politique de confidentialité.",
	es: 'Al hacer clic en Enviar, acepto la política de privacidad.',
	de: 'Durch Klicken auf Senden stimme ich der Datenschutzerklärung zu.',
}

const CONSENT_TEXT = {
	en: 'This service is provided by [client_name]\n\nSnackeet, the service provider, collects and keeps data relating to navigation cookies for one year. The data is transmitted to [client_name] and can be sent to third party services connected by the latter.\n\nIn accordance with the French law “informatique et libertés” of January 6, 1978, as amended, and the European Regulation n°2016/679/EU of April 27, 2016, you have the right to access, rectify, portability, erase your data or limit the processing.\n\nFor any request, you can contact [client_email]\n\n[client_address]\n[client_website]',
	fr: 'Ce service est proposé par [client_name]\n\nSnackeet, prestataire, collecte et conserve via cette interface les données relatives aux cookies de navigation pendant un an. Les données sont transmises à [client_name] et peuvent être envoyées à des services tiers connectés par ce dernier.\n\nConformément à la loi « informatique et libertés » du 6 janvier 1978, modifiée et au Règlement européen n°2016/679/UE du 27 avril 2016, vous bénéficiez d’un droit d’accès, de rectification, de portabilité, d’effacement de vos données ou encore de limitation du traitement.\n\nPour toute demande, vous pouvez contacter [client_email]\n\n[client_address]\n[client_website]',
	es: `Este servicio es proporcionado por [client_name]\n\nSnackeet, el proveedor del servicio, recopila y conserva datos relacionados con las cookies de navegación durante un año. Los datos se transmiten a [nombre_cliente] y pueden enviarse a servicios de terceros conectados por este último.\n\nDe conformidad con la ley francesa “informatique et libertés” del 6 de enero de 1978, modificada, y el Reglamento Europeo n° 2016/679/UE de 27 de abril de 2016, tiene derecho a acceder, rectificar, portabilidad, suprimir sus datos o limitar el tratamiento.\n\nPara cualquier petición, puede dirigirse a [client_email]\n\n[client_address] \n[sitio_web_del_cliente]`,
	de: 'Dieser Dienst wird bereitgestellt von [client_name]\n\nSnakeet, der Dienstanbieter, sammelt und speichert Daten in Bezug auf Navigations-Cookies für ein Jahr. Die Daten werden an [client_name] übermittelt und können an von diesem angeschlossene Drittdienste gesendet werden.\n\nIn Übereinstimmung mit dem französischen Gesetz „informatique et libertés“ vom 6. Januar 1978 in der geänderten Fassung und der europäischen Verordnung Nr 2016/679/EU vom 27. April 2016 haben Sie das Recht auf Auskunft, Berichtigung, Übertragbarkeit, Löschung Ihrer Daten oder Einschränkung der Verarbeitung.\n\nFür jede Anfrage können Sie sich an [client_email]\n\n[client_address] wenden. \n[Kundenwebsite]',
}

const CONSENT_TITLE = {
	en: 'Privacy Policy',
	fr: 'Politique de confidentialité',
	es: 'Política de privacidad',
	de: 'Datenschutz-Bestimmungen',
}

const CONSENT_BUTTON = {
	en: 'Visit privacy policy',
	fr: 'Consulter la politique de confidentialité',
	es: 'Visitar política de privacidad',
	de: 'Datenschutzrichtlinie besuchen',
}

const CONSENT_VALIDATION_BUTTON = {
	en: 'Accept',
	fr: 'Accepter',
	es: 'Aceptar',
	de: 'Annehmen',
}

export function getGdprDefaultMessages(lang) {
	return {
		message: CONSENT_MESSAGE[lang],
		text: CONSENT_TEXT[lang],
		title: CONSENT_TITLE[lang],
		button: CONSENT_BUTTON[lang],
		validation_button: CONSENT_VALIDATION_BUTTON[lang],
	}
}

export function initGdprConsent(gdprConsent, lang) {
	if (!isEmpty(gdprConsent?.message)) {
		return gdprConsent
	}

	return {
		active: false,
		url: '',
		...getGdprDefaultMessages(lang),
	}
}
