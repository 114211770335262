export const fr = {
	'snackeet_metrics.counters.title': 'Graphiques',
	'snackeet_metrics.counters._unique_users_count': 'Utilisateurs uniques',
	'snackeet_metrics.counters._landed_count': 'Ouvert',
	'snackeet_metrics.counters._completed_count': 'Completé',

	'snackeet_metrics.counters.newUsers': 'Utilisateurs',
	'snackeet_metrics.counters.views': 'Impressions',
	'snackeet_metrics.counters.completed': 'Completé',
	'snackeet_metrics.counters.clicks': 'Clics Totaux',
	'snackeet_metrics.counters.leads': 'Prospects',
	'snackeet_metrics.counters.answers': 'Interactions',
	'snackeet_metrics.counters.shared': 'Partages',
	'snackeet_metrics.counters.installed': 'Installé',
	'snackeet_metrics.counters.opened': 'Ouvert',

	'snackeet_metrics.newUsers_count': `{count} { count, plural, =1 { Utilisateur } other { Utilisateurs }}`,
	'snackeet_metrics.completed_count': `{count} { count, plural, =1 { Completé } other { Completés }}`,
	'snackeet_metrics.views_count': `{count} { count, plural, =1 { Impression } other { Impressions }}`,
	'snackeet_metrics.clicks_count': `{count} { count, plural, =1 { Clic } other { Clics }}`,
	'snackeet_metrics.leads_count': `{count} { count, plural, =1 { Contact } other { Contacts }}`,
	'snackeet_metrics.answers_count': `{count} { count, plural, =1 { Réponse } other { Réponses }}`,
	'snackeet_metrics.shared_count': `{count} { count, plural, =1 { Partage } other { Partages }}`,

	'snackeet_metrics.newUsers_description': 'Nouveaux utilisateurs',
	'snackeet_metrics.views_description': `Nombre de fois où la Story a été ouverte`,
	'snackeet_metrics.completed_description': `Nombre d'utilisateurs ayant atteint la dernière page`,
	'snackeet_metrics.clicks_description': `Nombre de clics redirigeant vers un autre site ou ouvrant la fenêtre de Pop-up`,
	'snackeet_metrics.total_clicks_description':
		'<b>Clics Totaux:</b> Nombre de clics sur tous les éléments cliquables.<br></br><b>Lien Clics :</b> Nombre de clics qui ouvrent un lien.<br></br><b>CTR :</b> Taux de clics, ratio entre le nombre de liens cliqués et les impressions.',
	'snackeet_metrics.leads_description': `Nombre de formulaires soumis incluant au moins <b>e-mail</b> ou le <b>numéro de téléphone</b> ou <b>nom</b>, <b>prénom</b>`,
	'snackeet_metrics.snackeet_answers_description':
		'Nombre total de réponses provenant des interactions : <i>Boutons de réponse, Quiz/Enquête/Vote, Formulaire, Téléchargement de fichier, NPS/Évaluation, Roue de la fortune, Réponse par vidéo/photo/audio.</i> <br></br><br></br>Consultez la section <b>Pages - Interactions</b> pour plus de détails',
	'snackeet_metrics.answers_description': `Nombre de réponses issues d'interactions, par exemple, enquête, quiz, jeu, choix, formulaire...`,
	'snackeet_metrics.shared_description': `Nombre de fois où la Story a été partagée`,

	'stories_graph.category.title': 'Choisissez une catégorie',

	'snackeet_metrics.platforms.title': 'Appareils',
	'snackeet_metrics.platforms.all': 'Tous les appareils',
	'snackeet_metrics.platforms.desktop': 'PC',
	'snackeet_metrics.platforms.mobile': 'Mobile',
	'snackeet_metrics.platforms.tablet': 'Tablette',
	'snackeet_metrics.platforms.other': 'Autre',

	'snackeet_metrics.interval.daily': 'Quotidien',
	'snackeet_metrics.interval.weekly': 'Hebdomadaire',

	'snackeet_metrics.label.percentage': 'Pourcentage %',
	'snackeet_metrics.label.sortBy': 'Trier par',
	'snackeet_metrics.label.asc': 'Croissant',
	'snackeet_metrics.label.desc': 'Décroissant',

	'snackeet_metrics.empty.title': `C'est vide ici`,
	'snackeet_metrics.empty.subtitle':
		'Nous afficherons les informations dès que vos utilisateurs interagiront avec la Story.',

	'snackeet_metrics.link_to_interactions': 'Utilisateurs détaillés',
	'snackeet_metrics.overview.title': `Vue d'ensemble de Story`,
	'snackeet_metrics.funnel.title': 'Utilisateurs uniques par page',

	'snackeet_metrics.responsesPercentages.title.multiple_choice': '{page_number}. Page des boutons : {page_name}',
	'snackeet_metrics.responsesPercentages.title.rating': `{page_number}. Page d'évaluation: {page_name}`,
	'snackeet_metrics.responsesPercentages.title.game': `{page_number}. Page de Roue de la chance: {page_name}`,
	'snackeet_metrics.responsesPercentages.title.form': `{page_number}. Liste déroulante: {page_name} - {field_label}`,
	'snackeet_metrics.responsesPercentages.title.ending_page': `{page_number}. Évaluation page de fin: {page_name}`,
	'snackeet_metrics.responsesPercentages.title': `{page_number}.{page_name}`,

	'snackeet_metrics.tooltip.page': 'Nom de la page',
	'snackeet_metrics.tooltip.percentage': 'Pourcentage',
	'snackeet_metrics.tooltip.users': `Nombre d'utilisateurs`,
	'snackeet_metrics.tooltip.answers': `Nombre de réponses`,
	'snackeet_metrics.tooltip.answer': 'Titre de la réponse',

	'snackeet_metrics.total_users': `Utilisateurs:`,
	'snackeet_metrics.average_score': 'Score moyen:',

	'snackeet_metrics.created_at': 'Créé',
	'snackeet_metrics.updated_at': 'Modifié',
	'snackeet_metrics.story_name': 'Nom de Story',

	'snackeet_metrics.lead.email': 'E-mail',
	'snackeet_metrics.lead.tel': 'Tél',
	'snackeet_metrics.lead.mail': 'E-mail',
	'snackeet_metrics.lead.firstname': 'Prénom',
	'snackeet_metrics.lead.lastname': 'Nom',
	'snackeet_metrics.lead.company': 'Entreprise',
	'snackeet_metrics.lead.city': 'Ville',
	'snackeet_metrics.lead.zip': 'Code postal',
	'snackeet_metrics.lead.address': 'Adresse',
	'snackeet_metrics.lead.phone': 'Téléphone',
	'snackeet_metrics.lead.website': 'Site web',

	'snackeet_metrics.lead.table_title': 'Prospects de la Story',
	'snackeet_metrics.lead.no_leads': 'Pas encore de prospects',
	'snackeet_metrics.forms.table_title': 'Soumissions de formulaires',

	'snackeet_metrics.carousel.click_attachment': 'Ouvrir la popup',
	'snackeet_metrics.carousel.download': 'Télécharger le fichier',
	'snackeet_metrics.carousel.copyToClipboard': 'Copie: ',
	'snackeet_metrics.carousel.internal': 'Rediriger vers la page',

	'snackeet_metrics.carousel.mailto': 'Envoyer e-mail :',
	'snackeet_metrics.carousel.messageTo': 'Envoyer message :',
	'snackeet_metrics.carousel.phoneTo': 'Appeler :',

	'snackeet_metrics.clicks.tags_title': 'Tags clics',
	'snackeet_metrics.clicks.share_title': 'Bloc de partage clics',
	'snackeet_metrics.clicks.cta_title': 'CTA Blocs clicks',
	'snackeet_metrics.clicks.network_title': 'Bloc réseau clics',
	'snackeet_metrics.clicks.ending_page': 'Boutons de la page de fin clics',
	'snackeet_metrics.of_views': 'impressions',

	'snackeet_metrics.sections.title': 'Sélectionner Statistiques:',
	'snackeet_metrics.sections.story': 'Aperçu de Story',
	'snackeet_metrics.sections.pages': 'Pages - Interactions',
	'snackeet_metrics.sections.leads': 'Prospects',
	'snackeet_metrics.sections.floating_widget': 'Widget Flottant',

	'snackeet_metrics.redirect.next': 'À page suivante ou par la logique',
	'snackeet_metrics.redirect.back': 'À page précédente',
	'snackeet_metrics.redirect.none': 'Aucune redirection',
	'snackeet_metrics.redirect.to_page': 'Vers page:',
	'snackeet_metrics.redirect.invalid': 'Vers une page invalide',

	'snackeet_metrics.media_answer.table_response': 'Réponse',
	'snackeet_metrics.media_answer.table_transcription': 'Transcription',
	'snackeet_metrics.media_answer.table_preview': 'Preview',
	'snackeet_metrics.media_answer.type_text': 'Texte',
	'snackeet_metrics.media_answer.table_video': 'Vidéo',
	'snackeet_metrics.media_answer.table_image': 'Image',
	'snackeet_metrics.media_answer.table_audio': 'Audio',

	'snackeet_metrics.stories_graph.no_stories': 'Aucune story disponible',

	'snackeet_metrics.countries.title': 'Impressions par pays',
	'snackeet_metrics.countries.country': 'Pays',

	'snackeet_metrics.referrer.title': 'Sources de référence pour les ouvertures de lien de Story',
	'snackeet_metrics.referrer.description':
		'Le tableau suivant présente un aperçu des origines du trafic vers votre Story, mettant en évidence les plateformes de médias sociaux et les sites web responsables de la redirection des utilisateurs vers votre contenu',
	'snackeet_metrics.referrer.ref': 'Référent',
	'snackeet_metrics.referrer.count': 'Compte',

	'snackeet_metrics.sources.title': 'Story Sources',
	'snackeet_metrics.sources.source': 'Source',

	'snackeet_metrics.userCodes.title': "Vérifications d'e-mail",
	'snackeet_metrics.userCodes.codes_sent': 'Code envoyé',
	'snackeet_metrics.userCodes.codes_verified': 'Vérifié',
	'snackeet_metrics.userCodes.codes_pending': 'En attente',

	'snackeet_metrics.userCodes.email': 'E-mail',
	'snackeet_metrics.userCodes.status': 'En attente de validation',

	'snackeet_metrics.table.pagination.rowsPerPage': 'lignes par page',

	'snackeet_metrics.domains.title': 'Domaines',
	'snackeet_metrics.domains.count': 'Compte',
	'snackeet_metrics.domains.domain': 'Domaine',
	'snackeet_metrics.domains.statuts': 'Statut',

	'snackeet_metrics.blocked_emails.title': 'E-mails temporaires bloqués',
	'snackeet_metrics.blocked_emails.count': 'e-mails',

	'snackeet_metrics.diposable_emails.title':
		'Au moins <b>{warningEmails}</b> emails temporaires détectés. Veuillez contacter notre équipe par chat ou à <link>hello@snackeet.com</link> pour demander un nettoyage.',
	'snackeet_metrics.diposable_emails.contact': 'Demander un nettoyage',

	'snackeet_metrics.buttonList.clicks': 'CTA Boutons Clics',

	'snackeet_metrics.ctr.title': 'Liens cliqués',
	'snackeet_metrics.ctr.rate': 'CTR',

	'snackeet_metrics.element_clicks.title': 'Statistiques des clics sur les éléments',
	'snackeet_metrics.element_clicks.click_action': 'Action de clic',

	'snackeet_metrics.top_ctr.title': 'Éléments avec la meilleure performance CTR',
	'snackeet_metrics.top_ctr.element': 'Élément',
	'snackeet_metrics.top_ctr.clicks': 'Clics',
	'snackeet_metrics.top_ctr.link': 'Lien',
	'snackeet_metrics.top_ctr.page': 'Page',
	'snackeet_metrics.top_ctr.cta_btn': 'CTA Bouton',
	'snackeet_metrics.top_ctr.cta_element': 'CTA Élément',
	'snackeet_metrics.top_ctr.share': 'Bloc de partage',
	'snackeet_metrics.top_ctr.network': 'Bloc réseau',
	'snackeet_metrics.top_ctr.carousel': 'Carrousel',
	'snackeet_metrics.top_ctr.tag': 'Tag',
	'snackeet_metrics.top_ctr.redirection_button': 'Bouton de la page de fin',

	'snackeet_metrics.top_ctr.share_via': 'Partager via',

	'snackeet_metrics.page_interaction.title': 'Nombre d’interactions par page',
	'snackeet_metrics.page_interaction.type': 'Type d’interaction',
	'snackeet_metrics.page_interaction.count': 'Nombre de réponses',

	'snackeet_metrics.widget.title': 'Statistiques du widget flottant',
	'snackeet_metrics.widget.installed_tooltip':
		'Fait référence au nombre de fois que Widget a été chargée sur le site web',
	'snackeet_metrics.widget.opened_tooltip':
		'Un utilisateur clique sur Widget, indiquant une interaction et un engagement',

	'snackeet_metrics.feedback.count': 'Feedbacks',
	'snackeet_metrics.feedback.title': 'Feedback',
	'snackeet_metrics.feedback.date': 'Date de création',
	'snackeet_metrics.feedback.note': 'Note',

	'snackeet_metrics.pageData.topInfo_clicks': 'clics',
	'snackeet_metrics.pageData.topInfo_responses': 'réponses',

	'snackeet_metrics.leads.details': 'Détails',
	'snackeet_metrics.leads.more_details': 'Plus de détails',
}
