import { Brush, History, Link as LinkIcon, PhotoLibrary, VideoLibrary } from '@mui/icons-material'

export const IMAGES_SELECTOR_LIST = [
	{
		logo: <PhotoLibrary size='large' />,
		value: 'import',
	},
	{
		logo: <History size='large' />,
		value: 'recently_used',
	},
	{
		logo: '/static/integration_logos/tenor.png',
		value: 'sticker',
	},
	{
		logo: '/static/integration_logos/tenor.png',
		value: 'gif',
	},
	{
		logo: '/static/integration_logos/instagram_gradient.svg',
		value: 'instagram',
	},
	{
		logo: '/static/integration_logos/pexels.jpeg',
		value: 'pexels',
	},
	{
		logo: <Brush size='large' />,
		value: 'illustrations',
	},
	{
		logo: <LinkIcon size='large' />,
		value: 'link',
	},
]

export const VIDEOS_SELECTOR_LIST = [
	{
		logo: <VideoLibrary size='large' />,
		value: 'import',
	},
	{
		logo: <History size='large' />,
		value: 'recently_used',
	},
	{
		logo: '/static/integration_logos/instagram_gradient.svg',
		value: 'instagram',
		isBeta: true,
	},
	{
		logo: '/static/integration_logos/pexels.jpeg',
		value: 'pexels',
	},
	// {
	// 	logo: '/static/integration_logos/pixabay.png',
	// 	value: 'pixabay'
	// },
	{
		logo: <LinkIcon size='large' />,
		value: 'link',
	},
]
