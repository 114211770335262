import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCustomerIdentification } from 'redux/customer/selectors'

export default function useSentryIdentification() {
	const customerIdentification = useSelector(getCustomerIdentification)

	useEffect(() => {
		if (customerIdentification?._id) {
			Sentry.setUser({
				id: customerIdentification._id,
				email: customerIdentification.email,
				username: customerIdentification.full_name,
			})
		}
	}, [customerIdentification])
}
