import Bowser from 'bowser'
import { useEffect, useState } from 'react'

export default function useDeviceDetect() {
	const [isMobile, setMobile] = useState(false)

	useEffect(() => {
		if (!navigator) {
			return
		}

		const metaData = Bowser.parse(navigator.userAgent)
		setMobile(metaData.platform.type === 'mobile')
	}, [])

	return { isMobile }
}
