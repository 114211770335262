import _ from 'lodash'

// Update page's variable when unassign
export function unassignPageVariable(variable, page_id) {
	const variableToUpdate = _.cloneDeep(variable)

	variableToUpdate.trackingDatas = variableToUpdate.trackingDatas?.filter(
		(trackingData) => trackingData.page._id !== page_id
	)

	if (_.isEmpty(variableToUpdate.trackingDatas)) {
		variableToUpdate.isUsed = false
		delete variableToUpdate.trackingDatas
	}

	return variableToUpdate
}

// Update field's variable when unassign
export function unassignFieldVariable(variable, field_id) {
	const variableToUpdate = _.cloneDeep(variable)

	variableToUpdate.trackingDatas = variableToUpdate.trackingDatas?.filter(
		(trackingData) => trackingData.field !== field_id
	)

	if (_.isEmpty(variableToUpdate.trackingDatas)) {
		variableToUpdate.isUsed = false
		delete variableToUpdate.trackingDatas
	}

	return variableToUpdate
}

// Update variable when assigne
export function updateVariableData(variable, trackingData) {
	const variableToUpdate = _.cloneDeep(variable)

	if (_.isEmpty(variableToUpdate.trackingDatas)) {
		variableToUpdate.trackingDatas = []
	}

	// Avoid duplicate tracking data
	if (!_.some(variableToUpdate.trackingDatas, (tracking) => _.isEqual(tracking, trackingData))) {
		variableToUpdate.trackingDatas.push(trackingData)
	}

	variableToUpdate.isUsed = true

	return variableToUpdate
}

// Update story's variables when duplicate / apply page template
export function updatePageVariables(page, variables) {
	const variablesToUpdate = _.cloneDeep(variables)

	if (page.type === 'form') {
		if (page.interactions) {
			const formInteraction = _.find(page.interactions, ['type', 'form'])

			for (const field of formInteraction.fields) {
				if (field.variable) {
					const idx = _.findIndex(variablesToUpdate, ['name', field.variable])
					if (idx !== -1) {
						if (_.isEmpty(variablesToUpdate[idx].trackingDatas)) {
							variablesToUpdate[idx].trackingDatas = []
						}
						variablesToUpdate[idx].isUsed = true
						variablesToUpdate[idx].trackingDatas.push({
							page: {
								_id: page._id,
								type: page.type,
							},
							field: field._id,
						})
					} else {
						// Create new variable
						variablesToUpdate.push({
							name: field.variable,
							type: getValidVariableTypes('field', field),
							isUsed: true,
							trackingDatas: [
								{
									page: {
										_id: page._id,
										type: page.type,
									},
									field: field._id,
								},
							],
						})
					}
				}
			}
		}
	} else {
		if (!_.isEmpty(page.variable)) {
			const idx = _.findIndex(variablesToUpdate, ['name', page.variable])

			if (idx >= 0) {
				if (_.isEmpty(variablesToUpdate[idx].trackingDatas)) {
					variablesToUpdate[idx].trackingDatas = []
				}
				variablesToUpdate[idx].isUsed = true
				variablesToUpdate[idx].trackingDatas.push({
					page: {
						_id: page._id,
						type: page.type,
					},
				})
			} else {
				// Create new variable
				variablesToUpdate.push({
					name: page.variable,
					isUsed: true,
					type: 'text',
					trackingDatas: [
						{
							page: {
								_id: page._id,
								type: page.type,
							},
						},
					],
				})
			}
		}
	}

	return variablesToUpdate
}

// 2 cases of type: 'field' or 'page'
export function getValidVariableTypes(type, field) {
	if (type === 'field') {
		if (field?.type === 'number') {
			return 'number'
		} else if (field?.type === 'date') {
			return 'date'
		} else if (field?.type === 'checkbox') {
			return 'boolean'
		}
	} else if (type === 'page') {
		if (field?.type === 'multiple_choice') {
			return 'text'
		} else if (['rating', 'ending_page'].includes(field?.type)) {
			const rating = _.find(field?.interactions, ['type', 'rating'])

			if (rating.category.startsWith('classic')) {
				return 'number'
			}
		}
	}

	return 'text'
}

// Verify variable's name
const FORBIDDEN_NAMES = [
	'id',
	'preview',
	'page',
	'snapshot', // query
	'score',
	'max_score',
	'answers',
	'correct', // system variables
	'_username', // leaderboard variables
]

export function variableExists({ name, currentVariables }) {
	return _.some(currentVariables, { name }) || _.includes(FORBIDDEN_NAMES, name)
}

// Generate variable name to assign by default
export function generateVariableName(sub_type, variables) {
	if (sub_type === 'email') return 'email'

	if (sub_type === 'username') return '_username'

	let variableName = sub_type

	let isInValid = _.some(variables, { name: variableName, isUsed: true })

	let i = 0
	while (isInValid) {
		i++
		variableName = `${sub_type}_${i}`

		isInValid = _.some(variables, { name: variableName, isUsed: true })
	}

	return variableName
}

export function cleanUpCurrentPageVariables({ currentPage, currentVariables }) {
	const clonedVariables = _.cloneDeep(currentVariables)

	// Clean up current page's variable
	for (const variable of clonedVariables) {
		if (variable.trackingDatas) {
			variable.trackingDatas = variable.trackingDatas.filter(
				(trackingData) => trackingData.page._id !== currentPage._id
			)

			if (_.isEmpty(variable.trackingDatas)) {
				variable.isUsed = false
				delete variable.trackingDatas
			}
		}
	}

	return clonedVariables
}
