import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

export async function login({ email, password, token }) {
	const { data } = await axios.post(`${API_URL}/auth/login`, {
		email,
		password,
		token,
	})
	return data
}

export async function register({ email, password, locale, timezone, token }) {
	const { data } = await axios.post(`${API_URL}/auth/register`, {
		email,
		password,
		locale,
		// use_id,
		timezone,
		token,
	})
	return data
}

export async function signin({ vendor, signin_token, username, locale, timezone }) {
	try {
		const { data } = await axios.post(`${API_URL}/auth/signin`, {
			vendor,
			signin_token,
			username,
			locale,
			timezone,
		})
		return data
	} catch (err) {
		if (!err.response) {
			throw new Error('Connection error')
		}
		// Axios error
		const { data } = err.response
		throw new Error(data.message)
	}
}

export async function resendVerificationEmail({ email, customer_id }) {
	const { data } = await axios.post(`${API_URL}/auth/resend-verification`, {
		email,
		customer_id,
	})
	return data
}

export async function resetPassword({ email, token }) {
	const { data } = await axios.post(`${API_URL}/auth/password_reset`, {
		email,
		token,
	})
	return data
}

export async function getResetPasswordOrganizations(token) {
	const {
		data: { success },
	} = await axios.get(`${API_URL}/auth/password_reset/${token}`)
	return { success }
}

export async function createNewPassword({ password_token, token, password, confirm_password }) {
	const {
		data: { success },
	} = await axios.post(`${API_URL}/auth/password_reset/${password_token}`, {
		token,
		password,
		confirm_password,
	})
	return success
}

export async function signinGoogleSheets({ signin_code, organization_id, project_id }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/auth/signin/googlesheets`,
			{ signin_code, organization_id, project_id },
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)
		return data
	} catch (err) {
		console.log(err)
	}
}

export async function signinGoogleDrive({ signin_code, organization_id, project_id }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/auth/signin/googledrive`,
			{ signin_code, organization_id, project_id },
			{
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)
		return data
	} catch (err) {
		console.log(err)
	}
}

export async function updateLastLogin() {
	try {
		const { data } = await axios.post(`${API_URL}/auth/update_last_login`, null, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (err) {
		return null
	}
}
