export const en = {
	'languageview.next': 'Next',
	'languageview.restart': 'Restart',
	'languageview.menu': 'Menu',
	'languageview.return': 'Go back',
	'languageview.send': 'Send',
	'languageview.start': 'Start Button',
	'languageview.invalid': 'Invalid field',
	'languageview.empty': 'Empty field',
	'languageview.email': 'Email',
	'languageview.phone': 'Phone',
	'languageview.confirm': 'Confirm',

	'languageview.describe': 'Configure the default text of your Story items.',
	'languageview.reset_default': 'Reset default',
	'languageview.reset_default.warning': 'This will reset the current values to default',
}
