export const fr = {
	'leaderboard.empty_list_title': 'Aucun Leaderboard prédéfini pour le moment',

	// Editor
	'leaderboard.title.name': 'Nom du Leaderboard',
	'leaderboard.title.label': 'Nom',
	'leaderboard.name.placeholer': 'Entrer le nom du leaderboard',
	'leaderboard.name.error': 'Le nom du leaderboard ne peut pas être vide',
	'leaderboard.settings.learn_more': 'En savoir plus sur le Leaderboard',

	'leaderboard.stories.header': 'Stories',
	'leaderboard.stories.empty': 'Connecter des stories à votre leaderboard',
	'leaderboard.stories.button': 'Ajouter / Supprimer des stories',
	'leaderboard.stories.warning':
		'Votre leaderboard est publié pour collecter des données, veuillez le réinitialiser pour modifier les stories',
	'leaderboard.stories.export': 'Exporter les données',
	'leaderboard.stories.reset': 'Réinitialiser les données',
	'leaderboard.stories.generate_level_list': 'Générer la liste des niveaux',

	'leaderboard.settings.header': 'Paramètres',
	'leaderboard.settings.display_podium.title': 'Afficher le podium',
	'leaderboard.settings.display_podium.description': 'Afficher les 3 meilleurs scores',
	'leaderboard.settings.display_level.title': 'Afficher le niveau',
	'leaderboard.settings.display_level.description': 'Afficher le niveau des questions',
	'leaderboard.settings.display_username.title': "Afficher le nom d'utilisateur",
	'leaderboard.settings.display_username.description': "Afficher le nom d'utilisateur",
	'leaderboard.settings.items_count.title': "Nombre d'éléments",
	'leaderboard.settings.items_count.description': "Le nombre d'utilisateurs à afficher",
	'leaderboard.settings.level_list.title': 'Liste des niveaux',
	'leaderboard.settings.level_list.description': 'Les niveaux du leaderboard',

	'leaderboard.components.header': 'Personnaliser',
	'leaderboard.components.title.label': 'Titre',
	'leaderboard.components.title.placeholder': 'Entrer le titre du leaderboard',
	'leaderboard.components.description.title': 'Description',
	'leaderboard.components.description.placeholder': 'Entrer la description du leaderboard',
	'leaderboard.components.logo.title': 'Logo',
	'leaderboard.components.title.label': 'Titre',
	'leaderboard.components.no_results.title': "Texte à afficher lorsqu'il n'y a pas de score",
	'leaderboard.components.no_results.placeholder': 'Entrer le texte à afficher',

	'leaderboard.appearance.header': 'Apparence',
	'leaderboard.appearance.primary_color': 'Couleur primaire',
	'leaderboard.appearance.secondary_color': 'Couleur secondaire',

	// Delete Modal
	'leaderboard.delete_modal.title': 'Supprimer le leaderboard',
	'leaderboard.delete_modal.header': 'Êtes-vous sûr ?',
	'leaderboard.delete_modal.instruction': `<div>Soyez prudent, ceci est une <b>opération irréversible</b>.</div><br></br><div>Toutes les <b>données</b> associées à ce leaderboard seront <b>supprimées</b>.</div><br></br><div>Pour confirmer, entrez le nom du leaderboard :</div>`,
	'leaderboard.delete_modal.placeholder': 'Tapez le nom du leaderboard',

	// Reset Modal
	'leaderboard.reset_modal.title': 'Réinitialiser le leaderboard',
	'leaderboard.reset_modal.header': 'Êtes-vous sûr ?',
	'leaderboard.reset_modal.instruction': `<div>Soyez prudent, ceci est une <b>opération irréversible</b>.</div><br></br><div>Toutes les <b>données</b> associées à ce leaderboard seront <b>supprimées</b>.</div><br></br><div>Pour confirmer, entrez le nom du leaderboard :</div>`,
	'leaderboard.reset_modal.placeholder': 'Tapez le nom du leaderboard',
	'leaderboard.reset_modal.subtitle': `<div>Soyez prudent, ceci est une <b>opération irréversible</b>.</div><br></br><div>Toutes les <b>données</b> associées à ce leaderboard seront <b>supprimées</b>.</div>`,

	// Modal de suppression
	'leaderboard.delete_modal.title': 'Supprimer le classement',
	'leaderboard.delete_modal.subtitle':
		"<div>Soyez prudent, il s'agit d'une <b>opération irréversible</b>.</div><br></br><div>Toutes les <b>données</b> liées à ce classement seront <b>supprimées</b>.</div>",

	// Srories Modal
	'leaderboard.stories_modal.select_instruction':
		'Sélectionnez des storys à associer au leaderboard.<br></br>Assurez-vous que chaque story comporte des questions de quiz et attribue les variables <b>email</b> et <b>_username</b> pour les ajouter au leaderboard.',
	'leaderboard.stories_modal.no_stories': `Vous n'avez actuellement aucune story de quiz à connecter au leaderboard.`,
	'leaderboard.stories_modal.story_isUsed': 'Cette story est connectée à un autre leaderboard',
	'leaderboard.stories_modal.story_isInvalid': `La story n'atteint pas les conditions pour se connecter au leaderboard`,
	'storyGraph.stories_modal.select_instruction': `Sélectionnez les Stories à afficher dans le graphique`,

	// Notifications de barre de notification
	'toast.success.reset.leaderboard': 'Les données du leaderboard ont été réinitialisées avec succès',
	'toast.error.reset.leaderboard': 'Impossible de réinitialiser les données du leaderboard',
	'toast.success.delete.leaderboard': 'Le leaderboard a été supprimé avec succès',
	'toast.error.delete.leaderboard': 'Échec de la suppression du leaderboard',

	// Boutons de leaderboard
	'leaderboard.button.view': 'Voir',
	'leaderboard.button.delete': 'Supprimer le leaderboard',
	'leaderboard.button.activate': 'Activer le leaderboard',

	// Exigences de leaderboard
	'leaderboard.requirement.title':
		'la story actuelle <b>doit</b> respecter <b>toutes les exigences</b> ci-dessous pour activer le leaderboard',
	'leaderboard.requirement.email':
		'La variable <b>email</b> est attribuée à un champ de formulaire.<br></br>Cela est nécessaire pour identifier le participant au quiz.',
	'leaderboard.requirement.username':
		"La variable <b>_username</b> est attribuée à un champ de formulaire.<br></br>Cela est nécessaire pour afficher le participant dans le Leaderboard public au lieu de l'email.",
	'leaderboard.requirement.quiz': 'la story doit comporter au moins une page de quiz.',
	'leaderboard.requirement.not_meet':
		"Votre story ne répond pas à toutes les exigences pour activer la fonctionnalité. Veuillez revenir à l'édition, modifier le contenu pour respecter toutes les exigences.",

	// Paramètres de la story pour le leaderboard
	'leaderboard.story_settings.title': 'Configuration du leaderboard',
	'leaderboard.story_settings.share_title': 'Partager le classement',
	'leaderboard.story_settings.share_web_title': 'Page Web autonome',
	'leaderboard.story_settings.share_embed_title': 'Intégrer sur votre site web',

	'leaderboard.collect_data.instruction':
		'Leaderboard collectera des données depuis son activation et fonctionnera en parallèle avec le système de collecte de données de Story.',

	'leaderboard.block_editor.button': 'Modifier le Leaderboard',
}
