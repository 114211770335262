import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCustomerIdentification } from 'redux/customer/selectors'

export default function useHotJarIdentification() {
	const customerIdentification = useSelector(getCustomerIdentification)

	useEffect(() => {
		if (!!window.hj && customerIdentification) {
			window.hj('identify', customerIdentification._id, {
				email: customerIdentification.email,
				name: customerIdentification.full_name,
				date_created: customerIdentification.date_created,
			})
		}
	}, [customerIdentification])
}
