export const en = {
	'layout.sidebar.login': 'Login Page',
	'layout.sidebar.register': 'Sign Up',
	'layout.sidebar.password_reset': 'Password Reset',
	'layout.sidebar.workspaces': 'My Workspaces',
	'layout.sidebar.settings': 'Settings',
	'layout.sidebar.organization_current.title': 'Current Organization',
	'layout.sidebar.organization_current.description': 'Manage your current organization',
	'layout.sidebar.organizations_list.title': 'All Organizations',
	'layout.sidebar.organizations_list.description': 'Go to list of your organizations',
	'layout.sidebar.account.title': 'My Account',
	'layout.sidebar.account.description': 'Edit your account profile',
	'layout.sidebar.teams': 'Teams',
	'layout.sidebar.not_found': '404 - Not Found',
	'layout.sidebar.stories': 'Stories',
	'layout.sidebar.widgets': 'Banner Widgets',
	'layout.sidebar.interactions': 'Users',
	'layout.sidebar.brands': 'Brand Kit',
	'layout.sidebar.leaderboards': 'Leaderboards',
	'layout.sidebar.leaderboards.warning': 'Please upgrade your plan to activate this feature',
	'layout.sidebar.warning': 'Your subscription has expired, please renew it to access the platform.',
	'layout.sidebar.subscribe': 'Subscribe',
	'layout.sidebar.warning.users_per_month': `You've reached your responses limit of this month. You now have limited access to your content.`,
	'layout.sidebar.warning.in_trial': `Your organization is in the Trial period. Upgrade your plan for more features.`,
	'layout.sidebar.upgrade': 'Upgrade',

	'layout.navbar.config': 'Edit',
	'layout.navbar.sharing': 'Share',
	'layout.navbar.settings': 'Set up',
	'layout.navbar.stats': 'Stats',
	'layout.navbar.integrations': 'Connect',
	'layout.navbar.content': 'Default Text',
	'layout.navbar.appearance': 'Default Style',
	'layout.navbar.brands': 'Brands',
	'layout.navbar.fonts': 'Fonts',
	'layout.navbar.responses': 'Responses',
	'layout.navbar.graph': 'Stats Overview',
	'layout.navbar.stories': 'Stories',
}
