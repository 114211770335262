export const black = '#1A1A1A'

// SNACKEET COLORS
export const snackeet_blue = '#5270E8'
export const snackeet_dark_blue = '#2F3161'
export const snackeet_light_blue = '#99B5FE'
export const snackeet_yellow = '#F29938'
export const snackeet_dark_yellow = '#f0830b'
export const snackeet_light_yellow = '#FBD878'
export const snackeet_teal = '#46D5C7'
export const snackeet_red = '#FF700A'
export const snackeet_text = '#484747'

export const snackeet_grey = '#DDDDDD'
export const snackeet_dark_grey = '#AFAFAF'
export const snackeet_background = '#F2F2F2'
export const snackeet_background_2 = '#F9F9FB'
export const snackeet_border = '#E5E5E5'
