import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { logoutSuccess } from 'redux/customer/slice'

export default function useReduxLogin() {
	const dispatch = useDispatch()
	const router = useRouter()

	const onLogoutSuccess = useCallback(
		({ redirectTo } = {}) => {
			dispatch(logoutSuccess())
			if (redirectTo) {
				router.push(redirectTo)
			}
		},
		[dispatch, router]
	)

	return {
		onLogoutSuccess,
	}
}
