import { APP_LOCALES } from 'lib/constants'

import { getAppearance } from './appearanceview'
import { getBrandPreset } from './brand_preset'
import { getButtons } from './buttons'
import { getCommons } from './common'
import getConditions from './conditions'
import getEditionMenu from './edition_menu'
import getEditorTranslations from './editor'
import { getErrorMessages } from './error_messages'
import { getFormFields } from './form_fields'
import { getHomePage } from './home'
import { getIntegrations } from './integrations'
import { getInteractions } from './interactions'
import { getLanguageView } from './languageview'
import { getLayoutMessages } from './layout'
import getLeaderboard from './leaderboard'
import getLoginRegisterTranslations from './login_register'
import { getMetrics } from './metrics'
import { getModals } from './modals'
import { getOrganizations } from './organization'
import { getOrganizationLogs } from './organizationLog'
import { getPageTitles } from './page_titles'
import getPasswordResetTranslations from './password_reset'
import { getPlaceholders } from './placeholders'
import { getSettingsUser } from './settings_user'
import { getSharingView } from './sharingview'
import getStoriesList from './stories_list'
import { getStorySettings } from './story_settings'
import { getSubscription } from './subscription'
import { getToasts } from './toasts'
import getUpload from './upload'
import getWidgetEditor from './widget_editor'
import getWidgetsList from './widgets_list'

export function getTranslations() {
	const messages = {}
	for (const lang of APP_LOCALES) {
		messages[lang] = {
			...getLayoutMessages(lang),
			...getButtons(lang),
			...getHomePage(lang),
			...getModals(lang),
			...getToasts(lang),
			...getFormFields(lang),
			...getPlaceholders(lang),
			...getCommons(lang),
			...getSettingsUser(lang),
			...getEditorTranslations(lang),
			...getAppearance(lang),
			...getUpload(lang),
			...getEditionMenu(lang),
			...getInteractions(lang),
			...getStoriesList(lang),
			...getWidgetsList(lang),
			...getWidgetEditor(lang),
			...getLanguageView(lang),
			...getSharingView(lang),
			...getMetrics(lang),
			...getPasswordResetTranslations(lang),
			...getLoginRegisterTranslations(lang),
			...getOrganizations(lang),
			...getIntegrations(lang),
			...getStorySettings(lang),
			...getErrorMessages(lang),
			...getSubscription(lang),
			...getPageTitles(lang),
			...getConditions(lang),
			...getBrandPreset(lang),
			...getLeaderboard(lang),
			...getOrganizationLogs(lang),
		}
	}

	return messages
}
