export const fr = {
	// Error messages
	'conditions.logic_error.case_1': 'Les conditions ne peuvent pas être vides. Veuillez ajouter une condition.',
	'conditions.logic_error.case_2':
		'Le groupe de conditions ne peut pas être vide. Veuillez ajouter une condition au groupe.',
	'conditions.logic_error.case_3': 'La valeur de la condition ne peut pas être vide.',
	'conditions.logic_error.case_4': `L'action ne peut pas être vide. Veuillez choisir une action.`,
	'conditions.logic_error.case_5': 'La variable en action ne peut pas être vide. Veuillez choisir une variable.',
	'conditions.logic_error.case_6': 'Un incrément manque. Veuillez choisir un incrément.',
	'conditions.logic_error.case_7': `Le numéro d'entrée ne peut pas être vide. Entrer un nombre, SVP.`,
	'conditions.logic_error.case_8': 'La valeur de remplacement ne peut pas être vide.',
	'conditions.logic_error.case_9': 'La valeur de page redirigée ne peut pas être vide.',

	//
	'conditions.add_logic': 'Ajouter une logique',
	'conditions.delete_logic': 'Supprimer cette logique',
	'conditions.clone_logic': 'Dupliquer cette logique',
	'conditions.consequence.then': 'Alors',
	'conditions.consequence.add_consequence': 'Ajouter une nouvelle action',

	// Placeholder
	'conditions.consequence.action_selector': 'Sélectionner une action',
	'conditions.consequence.increment_selector': 'Sélectionner...',
	'conditions.consequence.variable_selector': 'Sélectionner une variable',
	'conditions.consequence.input_number': 'Entrer un nombre',
	'conditions.consequence.input_text': 'Entrer une valeur',

	'conditions.increment.input_number': 'Entrer un nombre',

	// Consequense
	'conditions.consequence.conjunction_default': 'à',
	'conditions.consequence.conjunction_add': 'à',
	'conditions.consequence.conjunction_subtract': 'de',
	'conditions.consequence.conjunction_divide': 'par',
	'conditions.consequence.conjunction_multiply': 'par',
	'conditions.consequence.conjunction_power': 'sur',
	'conditions.consequence.conjunction_root': 'de',
	'conditions.consequence.conjunction_replace': 'par',

	// Field Groups
	'conditions.field_group.system': 'Système',
	'conditions.field_group.variable': 'Variables',
	'conditions.field_group.answers': 'Page à choix multiples',
	'conditions.field_group.rating': `NPS/Page d'évaluation`,
	'conditions.field_group.media_answer': 'Page de réponse aux médias',
	'conditions.field_group.form': 'Formulaire',
	'conditions.field_group.game': 'Page de Roue de la chance',

	// Consequence Groups
	'conditions.consequence_group.logic': 'Logique',
	'conditions.consequence_group.text': 'Texte',
	'conditions.consequence_group.numerical': 'Calcul',
	'conditions.consequence_group.variable': 'Variables',
	'conditions.consequence_group.number': 'Numéro',

	// Redirection
	'conditions.consequence.redirect_otherwise.next': `Dans <b>TOUS les autres cas</b>, rediriger vers <b>page suivant</b> par défaut.`,
	'conditions.consequence.redirect_otherwise.none': `Cette page n'a <b>aucune</b> redirection par défaut.`,
	'conditions.consequence.redirect_otherwise.toPage': `Dans <b>TOUS les autres cas</b> redirigez vers la page: <b>{ pageName }</b> par défaut.`,

	// Priority logics
	'conditions.priority_logic.title': 'Buttons configurées (Prioritaire):',
	'conditions.priority_logic.page_answered': 'Si la page répond',
	'conditions.priority_logic.answer': 'Si la réponse est <b>{answerTitle}</b>',
	'conditions.advance_logic.title': 'Logique configurée:',
	'conditions.cta_redirect.title': 'CTA configuré (Prioritaire):',
	'conditions.cta_redirect.onClick': 'Clique sur',
	'conditions.carousels.title': 'Carrousel (Prioritaire):',

	// System variables
	'condition.field.system.quizz_score': 'Note du quiz',
	'condition.field.system.today': 'Date actuelle',
	'condition.field.system.score': 'Note du quiz',
	'condition.field.system.max_score': 'Maximum note du quiz',
	'condition.field.system.quiz_success': 'Le résultat du quiz',

	// Logic excution order
	'condition.excute.order': `La logique est exécutée <b>séquentiellement de haut en bas</b>. La logique est exécutée lorsque l'utilisateur <b>quitte</b> la page.`,

	// Conditions display
	'condition.field_page.undefined': 'Page invalide',
	'condition.field_field.undefined': 'Champ de formulaire non défini',
	'condition.field_rating.undefined': 'Notation non définie',
	'condition.field_answer.undefined': 'Réponse invalide',

	'condition.answers.page': '<b>Réponse</b> de la page {pageIndex}. {pageName}',
	'condition.wheel.page': '<b>Prix</b> de la page {pageIndex}. {pageName}',
	'condition.media_answer.page': '<b>Réponse</b> de la page {pageIndex}. {pageName}',
	'condition.form.page': '<b>Réponse</b> de <b>{inputName}</b> du formulaire à la page {pageIndex}: {pageName}',
	'condition.rating.page': '<b>Évaluation</b> de la page {pageIndex}. {pageName}',
	'condition.page.deaulft_redirection': 'Par défaut,',
}
