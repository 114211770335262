export const en = {
	'common.survey': 'Survey',
	'common.quiz': 'Quiz',
	'common.vote': 'Vote',

	'common.snackeet_type.classic': 'Advanced',
	'common.snackeet_type.single_page': 'Advanced',
	'common.snackeet_type.amp': 'Light',
	'common.form_tooltip': `You can configure the error messages from the Set up tab.`,

	'common.copy': 'Copy',
	'common.cut': 'Cut',
	'common.paste': 'Paste',
	'common.copy_style': 'Copy Style',
	'common.paste_style': 'Apply Style',
	'common.set_background': 'Set as background',
	'common.yes': 'Yes', // Not used
	'common.no': 'No', // Not used
	'common.nothing': 'Nothing', // Not used
	'common.gender': 'Gender', // Not used
	'common.male': 'male', // Not used
	'common.female': 'femme', // Not used
	'common.name': 'Name',
	'common.type': 'Type',
	'common.story_type': 'Story Type',
	'common.profile': 'Profile',

	'common.duration': 'Duration',
	'common.per_page': 'Per page',

	'common.help': 'Help',
	'common.help.description': 'You can access to our help Center, you can also use the chat',

	'common.other_languages': 'Other languages', // Not used
	'common.language': 'Language',
	'common.language.english': 'English',
	'common.language.french': 'French',
	'common.language.spanish': 'Spanish',
	'common.language.german': 'German',

	'common.all': `All`,
	'common.users': 'Users',
	'common.story_users': 'Story Users',
	'common.users_count': `{ count, plural, one { user } other { users }}`,
	'common.result_count': `{count} {count, plural, one {result} other {results}}`,
	'common.members_count': `{ count, plural, one { member } other { members }}`,
	'common.responses_count': `{ count, plural, one { response } other { responses }}`,
	'common.agents_count': `{count} { count, plural, one { agent } other { agents }}`,
	'common.brands_count': `{ count, plural, one { brand } other { brands }}`,
	'common.organizations_count': '{count} { count, plural, one { Organization } other { Organizations }}',
	'common.stories_count': `{ count } { count, plural, =1 { story } other { stories }}`,
	'common.widgets_count': `{ count } { count, plural, =1 { banner } other { banners }}`,
	'common.workspaces_count': `{count} {count, plural, one {Workspace} other {Workspaces}} available`,

	'common.loading': 'Loading',
	'common.title': 'Title',
	'common.subtitle': 'Subtitle',

	'common.days': 'Days',
	'common.hours': 'Hours',
	'common.minutes': 'Minutes',
	'common.seconds': 'Seconds',

	'common.buttons': 'Buttons',

	'common.button': `{ count, plural, one { Button } other { Buttons }}`,
	'common.text': 'Text',
	'common.text_field': 'Text Field',
	'common.field': '{ count, plural, one { Field } other { Fields }}',
	'common.variable': '{ count, plural, one { Variable } other { Variables }}',

	'common.new': 'New',
	'common.create': 'Create',
	'common.update': 'Update',
	'common.new_story': 'Create New Story',
	'common.new_widget': 'New banner',
	'common.new_brand': 'New brand',
	'common.create_new_brand': 'Create A New brand',
	'common.new_leaderboard': 'New Leaderboard',
	'common.create_new_leaderboard': 'Create A New Leaderboard',
	'common.preview': 'Preview',

	'common.up': 'UP',
	'common.down': 'DOWN',
	'common.selected': '{count} selected ',
	'common.filter_by_story_type': 'Filter by Story type',
	'common.filter_by_folder': 'Filter by folder',
	'common.sort_by': 'Sort by',

	'common.hide': 'Hide',
	'common.add': 'Add',
	'common.show': 'Show',
	'common.visualize': 'Visualize',
	'common.download': 'Download',
	'common.duplicate': 'Duplicate',
	'common.value': 'Value',

	'common.error_messages': 'Error messages',
	'common.content': 'Default Text',

	'common.edit': 'Edit',
	'common.change_image': 'Change Image',
	'common.change_video': 'Change Video',

	'common.empty_list': 'The list is empty',

	'common.click_to_copy': 'Click to copy',
	'common.clipboard.success.copy': 'Copied to the clipboard',
	'common.clipboard.error.copy': 'An error ocurred when copying',
	'common.clipboard.error.paste': 'An error ocurred when pasting clipboard',
	'common.clipboard.error.access': 'Clipboard access denied.\nPlease enable it in your browser settings.',
	'common.clipboard.error.not_supported': `Your browser does not support clipboard operations fully.`,

	'common.unknown': 'Unknown',
	'common.answers': 'Answers',
	'common.answer': 'Answer',

	'common.period': 'Period',
	'common.week': 'Week',
	'common.month': 'Month',
	'common.year': 'Year',

	'common.currency': 'Currency',
	'common.currency.symbol.EUR': '€',
	'common.currency.symbol.USD': '$',

	'common.status.accepted': 'Accepted',
	'common.status.pending': 'Pending',
	'common.status.rejected': 'Rejected',

	'common.auto_advance': 'Auto advance',
	'common.display_timer': 'Timer display',
	'common.undo': 'Undo',
	'common.redo': 'Redo',

	'common.placeholder': 'Placeholder',
	'common.colors': 'Colors',
	'common.field_label': 'Field label',

	'common.coming_soon': 'Coming soon',
	'common.volume.mute': 'Mute',
	'common.volume.unmute': 'Unmute',

	// Theming
	'common.theming': 'Theming',
	'common.background': 'Background',
	'common.color': 'Color',
	'common.video': 'Video',
	'common.image': 'Image',
	'common.none': 'None',
	'common.text_color': 'Text color',
	'common.shape_color': 'Shape color',
	'common.background_color': 'Back. Color',
	'common.size': 'Size',
	'common.font_size': 'Font Size',
	'common.title_font_size': 'Title Size',
	'common.subtitle_font_size': 'Subtitle Size',
	'common.height': 'Height',
	'common.width': 'Width',
	'common.bold': 'Bold',
	'common.underline': 'Underline',
	'common.italic': 'Italic',
	'common.radius': 'Corner',
	'common.border': 'Border',
	'common.block': 'Block',
	'common.shadow': 'Shadow',
	'common.line_height': 'Line spacing',
	'common.result_bar': 'Result bar',
	'common.result_bar.background': 'Background result',

	'common.theming.confirm_button': 'Confirm',
	'common.welcom_page': 'welcome page',
	'common.ending_page': 'end page',

	// Operators logic
	'common.ops.equal': '= a number',
	'common.ops.=': 'Is equal to',
	'common.ops.different': '!= a number',
	'common.ops.!=': 'Is different from',
	'common.ops.lower_equal': '<= a number',
	'common.ops.<=': 'Is lower or equal to',
	'common.ops.greater_equal': '>=a number',
	'common.ops.>=': 'Is greater or equal to',
	'common.ops.greater': '> a number',
	'common.ops.>': 'Is greater than',
	'common.ops.lower': '< a number',
	'common.ops.<': 'Is lower than',
	'common.ops.var_equal': '= a variable',
	'common.ops.var_=': 'Is equal to',
	'common.ops.var_different': '!= a variable',
	'common.ops.var_!=': 'Is different from',
	'common.ops.var_lower_equal': '<= a variable',
	'common.ops.var_<=': 'Is lower or equal to',
	'common.ops.var_greater_equal': '>=a variable',
	'common.ops.var_>=': 'Is greater or equal to',
	'common.ops.var_greater': '> a variable',
	'common.ops.var_>': 'Is greater than',
	'common.ops.var_lower': '< a variable',
	'common.ops.var_<': 'Is lower than',
	'common.ops.includes': 'Includes',
	'common.ops.contains': 'Includes',
	'common.ops.not_includes': 'Does not include',
	'common.ops.is': 'Is',
	'common.ops.isNot': 'Is not',
	'common.ops.answer_is': 'Is',
	'common.ops.answer_isNot': 'Is not',
	'common.ops.isAnswered': 'Is Answered',
	'common.ops.answer_isAnswered': 'Is Answered',
	'common.ops.isNotAnswered': 'Is not Answered',
	'common.ops.answer_isNotAnswered': 'Is not Answered',
	'common.ops.beginsWith': 'Begins with',
	'common.ops.endsWith': 'Ends with',
	'common.ops.includes_nonSen': 'Includes (NON-case-sensitive)',
	'common.ops.contains_nonSen': 'Includes (NON-case-sensitive)',
	'common.ops.is_nonSen': 'Is (NON-case-sensitive)',
	'common.ops.isNot_nonSen': 'Is not (NON-case-sensitive)',
	'common.ops.beginsWith_nonSen': 'Begins with (NON-case-sensitive)',
	'common.ops.endsWith_nonSen': 'Ends with (NON-case-sensitive)',
	'common.ops.before': 'Before',
	'common.ops.after': 'After',
	'common.ops.equals': 'Is equal',
	'common.ops.sameDay': 'Is the same day',
	'common.ops.null': 'Is empty',
	'common.ops.not_null': 'Is not empty',
	'common.ops.notNull': 'Is not empty',
	'common.ops.is_true': 'Is TRUE',
	'common.ops.is_false': 'Is FALSE',
	'common.ops.quiz_correct': 'Is correct',
	'common.ops.quiz_incorrect': 'Is incorrect',
	'common.ops.quiz_partial': 'Is partially correct',
	'common.ops.var_greatest': 'Is the greatest in',
	'common.ops.var_smallest': 'Is the smallest in',
	'common.ops.media_is_video': 'Answer is video',
	'common.ops.media_is_audio': 'Answer is audio',
	'common.ops.media_is_text': 'Answer is text',
	'common.ops.media_is_answered': 'Is Answered',
	'common.ops.media_is_null': 'Is null',
	'common.ops.wheel_is': 'Is',
	'common.ops.wheel_isNot': 'Is not',
	'common.ops.wheel_win': 'Won',
	'common.ops.wheel_lose': 'Lost',
	'common.ops.wheel_played': 'Is played',
	'common.ops.wheel_notPlayed': 'Is not played',
	'common.ops.is_quiz_true': 'is successful',
	'common.ops.is_quiz_false': 'is not successful',

	// Consequence Types
	'common.consequence_type.text': 'Text',
	'common.consequence_type.numerical': 'Numerical',

	// Consequence Operators
	'common.consequence_ops.redirect': 'Go to',
	'common.consequence_ops.replace': 'Replace',
	'common.consequence_ops.add': '+  Add',
	'common.consequence_ops.subtract': '-  Subtract',
	'common.consequence_ops.divide': '÷  Divide',
	'common.consequence_ops.multiply': 'x  Multiply',
	'common.consequence_ops.power': '^ Power',
	'common.consequence_ops.root': '√ root',
	'common.consequence_ops.inputNumber': 'a number',

	'common.consequence_display.add': '+',
	'common.consequence_display.subtract': '-',
	'common.consequence_display.divide': '÷',
	'common.consequence_display.multiply': 'x',
	'common.consequence_display.power': '^',
	'common.consequence_display.root': '√',

	// Condition Headers
	'common.cond_header.if': 'If',
	'common.cond_header.always': 'Always',

	// Combinations logic
	'common.comb.and': 'AND',
	'common.comb.or': 'OR',

	// condition value placeholder
	'common.cond.placeholder': 'Value is required*',

	// Page Errors
	'common.errors': 'Errors',
	'common.recommendations': 'Recommendations',

	// Context Menu
	'common.duplicate': 'Duplicate',
	'common.delete': 'Delete',
	'common.bring_to_front': 'Bring to front',
	'common.bring_forward': 'Bring forward',
	'common.send_backward': `Send backward`,
	'common.send_to_back': 'Send to back',

	// State
	'common.active': 'Live',
	'common.activated': 'Activated',
	'common.disabled': 'Disabled',
	'common.enabled': 'Enabled',
	'common.no_published': 'Unpublished Story',

	'common.optional': 'optional',
	'common.valid': 'Valid',
	'common.invalid': 'Invalid',
	'common.unset': 'Unset',
	'common.fontFamily': 'Font family',

	'common.upgrade_offer': 'You need to upgrade your offer to access this premium feature',

	'common.platform.mobile': 'Mobile',
	'common.platform.desktop': 'Desktop',

	// Story tags
	'common.story_tag.all': 'All',
	'common.story_tag.engagement': 'Engagement',
	'common.story_tag.lead_generation': 'Lead Generation',
	'common.story_tag.feedback': 'Feedback',
	'common.story_tag.event': 'Event',
	'common.story_tag.education': 'Education',
	'common.story_tag.e_commerce': 'E-commerce',
	'common.story_tag.recruiting': 'HR/Job',
	'common.story_tag.faq': 'FAQ',
	'common.story_tag.game': 'Game',
	'common.story_tag.promotion': 'Promotion',
	'common.story_tag.product_and_unboxing': 'Product and unboxing',
	'common.story_tag.life_style_fashion': 'Life Style / Fashion',
	'common.story_tag.media_news': 'Media/News',
	'common.story_tag.giveaways_and_raffles': 'Giveaways and raffles',
	'common.story_tag.surveys': 'Surveys',
	'common.story_tag.quizs': 'Quizs',
	'common.story_tag.sport': 'Sport',
	'common.story_tag.news_and_entertainment': 'News & Entertainment',
	'common.story_tag.reviews': 'Reviews',
	'common.story_tag.promotion_sales': 'Promotion / Sales',
	'common.story_tag.other': 'Other',

	'common.story_tag.bio_link': 'Bio Link',
	'common.story_tag.engagement_review': 'Engagement & Reviews',
	'common.story_tag.education_faq': 'Educational & Tutorials & FAQ',
	'common.story_tag.marketing_lead': 'Marketing & Lead Campaigns',
	'common.story_tag.product_showcase': 'Product & Services Showcase',
	'common.story_tag.corporate_job': 'Corporate ( HR Com /Job Application)',
	'common.story_tag.interactive_game': 'Interactive Experiences & Games',
	'common.story_tag.micro_site': 'Micro Sites',

	'common.story_tag.howTo_unboxing': 'How to / Advice / Unboxing',
	'common.story_tag.produit_routines': 'Routines with product list',
	'common.story_tag.benefits': 'Benefits',
	'common.story_tag.review': 'Review',
	'common.story_tag.faq': 'FAQ',

	'common.story_tag.ask_for_review': 'Ask for review',
	'common.story_tag.communication_article': 'Communication / Article',
	'common.story_tag.job_offers': 'Job offers',

	'common.story_tag.games_quiz': 'Games & Quiz',
	'common.story_tag.vote_survey': 'Vote / Survey',

	// Zoom
	'common.fit': 'Fit',
	'common.fill': 'Fill',

	'common.custom': 'Custom',
	'common.domain': `{ count, plural, one { domain } other { domains }}`,

	// AMP Interaction styles
	'common.theme.light': 'Light',
	'common.theme.dark': 'Dark',

	'common.size.small': 'Small',
	'common.size.medium': 'Medium',
	'common.size.large': 'Large',

	'common.alignment.left': 'Left',
	'common.alignment.center': 'Center',
	'common.alignment.right': 'Right',
	'common.alignment.justify': 'Justify',

	'common.option_style.default': 'Default',
	'common.option_style.flat': 'Flat',
	'common.option_style.shadow': 'Shadow',
	'common.option_style.transparent': 'transparent',

	'common.expired': 'Expired',
	'common.expires_in': 'Expires in {timeRemaining}',

	'common.url.unsecure': 'Please provide https URL',
	'common.url.not_valid': 'Please provide a valid https URL',

	'common.warning': 'Warning',

	'common.recently_used': 'Recently Used',
	'common.brand_colors': 'Brand Colors',

	'common.unsaved_changes': 'You have unsaved changes. Do you want to discard and proceed?',

	'common.interactions': 'Interactions:',
	'common.components': 'Components:',

	'common.never': 'Never',
	'common.settings': 'Settings',

	'common.form_submit': 'Submissions',
	'common.game_played': 'Plays',
	'common.rating_feedback': 'Feedbacks',
	'common.file_upload': 'Uploads',
	'common.responses': 'Responses',
	'common.cta_clicks': 'CTA Clicks',

	'common.added': 'Added',
	'common.order': 'Order',
}
