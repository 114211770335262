export const en = {
	'widgets_list.empty_list_title': 'No Widgets yet',
	'widgets_list.empty_list_subtitle': 'You don’t have any Stories banner yet.',
	'widgets_list.tooltip.get_code': 'Get code to embed',
	'widgets_list.widget_card.empty_list_stories': 'This widget has no stories',

	'widgets_list.widget_card.installed': 'Loaded',
	'widgets_list.widget_card.views': 'Impressions',
	'widgets_list.widget_card.clicks': 'Opened',
	'widgets_list.widget_card.of_views': 'of impressions',
	'widgets_list.widget_card.website': 'Installed Website',

	'widgets_list.widget_card.installed_tooltip': 'Refers to the number of times the banner was loaded on the website',
	'widgets_list.widget_card.views_tooltip':
		'The banner actually appears within the visible area of the user’s screen',
	'widgets_list.widget_card.clicks_tooltip': 'A user clicks on the banner, indicating interaction and engagement',

	'widgets_list.graph_title.source': 'Data From Installed Website',
	'widgets_list.graph_title.stories': 'Stories Data',
	'widgets_list.graph_story.clicks': 'Clicks',
	'widgets_list.graph_story.clicks_tooltip': 'Number of times the story card has been clicked',
}
