export const en = {
	'organizationLog.tab.title': 'Activity Logs',

	'organizationLog.organization.invite': `<b>{username}</b> invited <b>{email}</b> as <b>{role}</b>.`,
	'organizationLog.organization.owner': `<b>{username}</b> passed the ownership to <b>{ new_owner }</b>.`,
	'organizationLog.organization.slug': `<b>{username}</b> changed the organization's ID to <b>{ slug }</b>.`,
	'organizationLog.organization.acive_license': `<b>{username}</b> applied the license with license key <b>{ license_key }</b>.`,
	'organizationLog.organization.leave': `<b>{username}</b> left.</b>.`,
	'organizationLog.organization.join': `<b>{username}</b> joined.`,
	'organizationLog.organization.promote': `<b>{username}</b> promoted <b>{ member }</b> to Admin.`,
	'organizationLog.organization.demote': `<b>{username}</b> demoted <b>{ member }</b> to Member.`,
	'organizationLog.organization.kick': `<b>{username}</b> kicked as <b>{ member }</b>.`,

	'organizationLog.project.create': `<b>{ username }</b> created workspace <b>{ project }</b>.`,
	'organizationLog.project.delete': `<b>{ username }</b> deleted the workspace <b>{ project }</b>.`,

	'organizationLog.story.create': `<b>{ username }</b> created <b>{ story_name }</b>.`,
	'organizationLog.story.delete': `<b>{ username }</b> deleted <b>{ story_name }</b>.`,
	'organizationLog.story.publish': `<b>{ username }</b> published <b>{ story_name }</b>.`,

	'organizationLog.users.session_delete': `<b>{ username }</b> cleaned up <b>{ story_name }</b> user sessions.`,
	'organizationLog.users.export': `<b>{ username }</b> exported data of the story <b>{ story_name }</b>.`,

	'organizationLog.details.ip': 'IP',
	'organizationLog.details.country': 'Conuntry',
	'organizationLog.details.region': 'Region',
	'organizationLog.details.timezone': 'Timezone',
	'organizationLog.details.city': 'City',

	'organizationLog.details.type': 'Device',
	'organizationLog.details.browser': 'Browser',
	'organizationLog.details.os': 'OS',

	'organizationLog.filter.type_all': 'All',
	'organizationLog.filter.type_organization': 'Organization',
	'organizationLog.filter.type_project': 'Workspace',
	'organizationLog.filter.type_story': 'Story',
	'organizationLog.filter.type_users': 'Users',

	'organizationLog.story.deleted_story': 'Deleted Story',
	'organizationLog.project.deleted_project': 'Deleted Workspace',
	'organizationLog.user.deleted_story': 'Deleted User',
}
