export const fr = {
	'interactions.page.title': 'Interactions',
	'interactions.export.button': 'Exporter les réponses utilisateurs',
	'interactions.to_story.button': `Afficher les graphiques de la story`,
	'interactions.export.in_progress': 'Export des réponses...',

	'interactions.snackeet_list.title': 'Stories',

	'interaction.user_count': '{ count } { count, plural, one { utilisateur } other { utilisateurs }}',
	'interaction.user': 'Utilisateur',

	'interactions.tab.users': 'Utilisateurs',
	'interactions.tab.pages': 'Pages',
	'interactions.tab.options.user_answers': 'Utilisateurs avec réponses',
	'interactions.tab.options.user_files': 'Utilisateurs avec fichiers ajoutés',
	'interactions.tab.options.successful_quiz': 'Utilisateurs avec quiz réussi',

	'interaction.user_top_info.snackeet': 'Stories',
	'interaction.user_top_info.source': 'Source',

	'interactions.empty_users': 'Aucun utilisateur trouvé',
	'interactions.empty_pages': 'Aucune page trouvée',

	'interactions.delete_session.header': `Êtes-vous sûr d'effectuer la suppression?`,
	'interactions.delete_session.options':
		'Ceci est une <b>opération irréversible.</b><br></br><br></br>Les options de suppression',
	'interactions.delete_session.remove_session': `<b>Supprimer la session:</b> Supprimer l'interaction de l'utilisateur dans la Story actuelle`,
	'interactions.delete_session.remove_user': `<b>Supprimer l'utilisateur:</b> Supprimer l'utilisateur et ses interactions de toutes les Stories`,
	'interactions.delete_session.button.remove_session': 'Supprimer la session',
	'interactions.delete_session.button.remove_user': `Supprimer l'utilisateur`,

	'interactions.user_information': `Informations d'utilisateur (Variables)`,
	'interaction.page_type.blank': 'Aucune Réponse',
	'interaction.page_type.start_page': `Page d'accueil`,
	'interaction.page_type.ending_page': 'Page de fin',
	'interaction.page_type.multiple_choice': 'Boutons/choix',
	'interaction.page_type.form': 'Formulaire',
	'interaction.page_type.rating': 'NPS/Notation',
	'interaction.page_type.media_answer': 'Média',
	'interaction.page_type.game': 'Jeu',
	'interaction.page_type.amp_page': 'AMP Page',

	'interaction.page_type.game.wheel': 'Roue',

	'interaction.page_top_info.completed': 'Complété',
	'interaction.page_top_info.not_completed': 'Non complété',
	'interaction.page_top_info.average_score': 'Score moyen',

	'interaction.pages_seen': 'Pages vues',
	'interaction.user_platform': 'Plateforme utilisateur',

	'interaction.null_data': 'Donnée inconnue, vide ou nulle',
	'interaction.limit_data': `Vous avez atteint votre limite de réponses ce mois-ci. Changez votre plan actuel pour plus de données.`,
	'interaction.page_info': 'Informations sur la page',

	'interaction.user_landed': `Utilisateurs Unique`,
	'interaction.views': 'Impressions',
	'interaction.set_duration': 'Temps défini',
	'interaction.average_spent_time': 'Durée moyenne',
	'interaction.total_spent_time': 'Temps passé total',
	'interaction.time_landed': `Heure d'ouverture`,
	'interaction.spent_time': `Temps passé`,
	'interaction.answered': `Répondu`,

	'interaction.unique_users': 'Utilisateurs uniques',
	'interaction.answers_count': 'Réponses',
	'interaction.clicks_count': 'Lien cliqué',

	'interaction.no_users': `Aucun utilisateur sur cette page.`,
	'interaction.timer_unset': 'Désactivé',

	'interaction.tooltip.preview_trigger': 'Voir la page',
	'interaction.tooltip.icon.files_uploaded': 'Cet utilisateur a ajouté des fichiers',

	'interaction.sec_stay': 'Sec resté',
	'interaction.min_stay': 'Min resté',
	'interaction.user_events': 'Évènements de page',
	'interaction.user_answers': 'Réponses utilisateurs',
	'interaction.empty_events': `Pas d'évènement de page`,
	'interaction.empty_answers': 'Aucune réponse enregistrée',
	'interaction.empty_history': 'Aucun historique enregistré',

	'interactions.export.user_answers': 'Exporter réponses utilisateurs',

	'interactions.uploaded_files.title': 'Fichiers utilisateurs',
	'interactions.uploaded_files.message': 'Aucun fichier reçu.',
	'interactions.uploaded_files.per_page': 'Fichiers par page',

	'interactions.wheel.prize': 'Prix',
	'interactions.wheel.email': 'Email',
}
