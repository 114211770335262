export const en = {
	'leaderboard.empty_list_title': 'No Leaderboards Preset yet',

	// Editor
	'leaderboard.title.name': 'Leaderboard Name',
	'leaderboard.title.label': 'Name',
	'leaderboard.name.placeholer': 'Enter leaderboard name',
	'leaderboard.name.error': 'Leaderboard name cannot be empty',
	'leaderboard.settings.learn_more': 'Learn more about Leaderboard',

	'leaderboard.stories.header': 'Stories',
	'leaderboard.stories.empty': 'Connect Stories to your leaderboard',
	'leaderboard.stories.button': 'Add / Remove stories',
	'leaderboard.stories.warning': 'Your leaderboard is published to collect data, please reset to modify stories',
	'leaderboard.stories.export': 'Export Data',
	'leaderboard.stories.reset': 'Reset Data',
	'leaderboard.stories.generate_level_list': 'Generate Level List',

	'leaderboard.settings.header': 'Settings',
	'leaderboard.settings.display_podium.title': 'Show Podium',
	'leaderboard.settings.display_podium.description': 'Display top 3 highest score',
	'leaderboard.settings.display_level.title': 'Show Level',
	'leaderboard.settings.display_level.description': 'Display level of questions',
	'leaderboard.settings.display_username.title': 'Show Username',
	'leaderboard.settings.display_username.description': 'Display Username',
	'leaderboard.settings.items_count.title': 'Items Count',
	'leaderboard.settings.items_count.description': 'The number of users can be displayed',
	'leaderboard.settings.level_list.title': 'Level List',
	'leaderboard.settings.level_list.description': 'The levels of leaderboard',

	'leaderboard.components.header': 'Customize',
	'leaderboard.components.title.label': 'Title',
	'leaderboard.components.title.placeholder': 'Enter leaderboard title',
	'leaderboard.components.description.title': 'Description',
	'leaderboard.components.description.placeholder': 'Enter leaderboard title',
	'leaderboard.components.logo.title': 'Logo',
	'leaderboard.components.title.label': 'Title',
	'leaderboard.components.no_results.title': 'Text to display when there is no score',
	'leaderboard.components.no_results.placeholder': 'Enter text to display',

	'leaderboard.appearance.header': 'Appearance',
	'leaderboard.appearance.primary_color': 'Primary Color',
	'leaderboard.appearance.secondary_color': 'Secondary Color',

	// Delete Modal
	'leaderboard.delete_modal.title': 'Delete leaderboard',
	'leaderboard.delete_modal.header': 'Are you sure?',
	'leaderboard.delete_modal.instruction': `<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div><b>All data</b> related to this leaderboard will be <b>deleted</b>.</div><br></br><div>To confirm, enter the name of the leaderboard:</div>`,
	'leaderboard.delete_modal.placeholder': 'Type the name of the leaderboard',

	// Reset Modal
	'leaderboard.reset_modal.title': 'Reset leaderboard',
	'leaderboard.reset_modal.header': 'Are you sure?',
	'leaderboard.reset_modal.instruction': `<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div><b>All data</b> related to this leaderboard will be <b>deleted</b>.</div><br></br><div>To confirm, enter the name of the leaderboard:</div>`,
	'leaderboard.reset_modal.placeholder': 'Type the name of the leaderboard',
	'leaderboard.reset_modal.subtitle': `<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div><b>All data</b> related to this leaderboard will be <b>deleted</b>.</div>`,

	// Delete Modal
	'leaderboard.delete_modal.title': 'Delete the leaderboard',
	'leaderboard.delete_modal.subtitle':
		'<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div><b>All data</b> related to this leaderboard will be <b>deleted</b>.</div>',

	// Srories Modal
	'leaderboard.stories_modal.select_instruction':
		'Select stories to connect with the leaderboard.<br></br> Ensure that each story includes <b>Quiz questions</b> and assigns variables <b>email</b> and <b>_username</b> to be added to the leaderboard',
	'leaderboard.stories_modal.no_stories': 'You currently have no Quiz Stories to connect with the leaderboard.',
	'leaderboard.stories_modal.story_isUsed': 'This story is being connected to another leaderboard',
	'leaderboard.stories_modal.story_isInvalid': 'The story does not reach conditions to connect with the leaderboard',
	'storyGraph.stories_modal.select_instruction': 'Select stories to display in the graph',

	// Snackbar notifications
	'toast.success.reset.leaderboard': 'Leaderboard data reset successfully',
	'toast.error.reset.leaderboard': 'Failed to reset leaderboard data',
	'toast.success.delete.leaderboard': 'Leaderboard deleted successfully',
	'toast.error.delete.leaderboard': 'Failed to delete leaderboard',

	'leaderboard.button.view': 'View',
	'leaderboard.button.delete': 'Delete Leaderboard',
	'leaderboard.button.activate': 'Activate the Leaderboard',

	'leaderboard.requirement.title':
		'The current story <b>must</b> meet <b>all the requirements</b> below to activate the Leaderboard',
	'leaderboard.requirement.email':
		'The variable <b>email</b> is assigned in a form field.<br></br>This is required to identify the Quiz participant.',
	'leaderboard.requirement.username':
		'The variable <b>_username</b> is assigned in a form field.<br></br>This is required to display the participant in leaderboard public instead of email',
	'leaderboard.requirement.quiz': 'Story must have at least a quiz page.',
	'leaderboard.requirement.not_meet':
		'Your Story does not meet all the requirements to activate the feature. Please back to Edit, modify the content to adapt all requirements.',

	'leaderboard.story_settings.title': 'Leaderboard Configuration',
	'leaderboard.story_settings.share_title': 'Share Leaderboard',
	'leaderboard.story_settings.share_web_title': 'Standalone Webpage',
	'leaderboard.story_settings.share_embed_title': 'Embed on your website',

	'leaderboard.collect_data.instruction':
		'Leaderboard will collect data since the activation and working parallel  with the story collecting data system.',

	'leaderboard.block_editor.button': 'Edit the Leaderboard',
}
