import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const FontsSvc = {
	getAll,
	del,
}

export default FontsSvc

async function getAll({ organization_id }) {
	try {
		const { data } = await axios.get(`${API_URL}/fonts`, {
			params: {
				organization_id,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		if (!data.success) {
			throw new Error('Could not retrieve fonts')
		}
		return data.fonts
	} catch (error) {
		return []
	}
}

async function del({ organization_id, fontIds }) {
	try {
		const { data } = await axios.delete(`${API_URL}/fonts`, {
			params: {
				organization_id,
				font_id: fontIds.join(','),
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data
	} catch (error) {
		return {
			success: false,
		}
	}
}
