/* eslint-disable prefer-regex-literals */
export const VIDEO_HOSTINGS_BETA = [
	{
		name: 'Adilo',
		url: 'https://adilo.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/adilo.webp',
		host: 'adilo.bigcommand',
	},
	{
		name: 'Wave Video',
		url: 'https://wave.video/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/wavevideo.png',
		host: 'wave.video',
	},
	{
		name: 'SumPlayer',
		url: 'https://sumplayer.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/sumplayer.svg',
		host: 'sumplayer',
	},
	{
		name: 'Loom',
		url: 'https://www.loom.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/loom.png',
		host: 'loom',
	},
	{
		name: 'VadooTV',
		url: 'https://www.vadoo.tv/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/wadootv.webp',
		host: 'api.vadoo.tv',
	},
	{
		name: 'TikTok',
		url: 'https://tiktok.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/tiktok.png',
		host: 'tiktok',
	},
	{
		name: 'BigVu',
		url: 'https://bigvu.tv/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/bigVu.svg',
		host: 'desk.bigvu.tv',
	},
]

export const VIDEO_HOSTING_SUPPORTED = [
	{
		name: 'Youtube',
		url: 'https://youtube.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/youtube.svg.png',
	},
	{
		name: 'Vimeo',
		url: 'https://vimeo.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/vimeo.png',
	},
	{
		name: 'Facebook',
		url: 'https://facebook.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/facebook.svg.png',
	},
	{
		name: 'DailyMotion',
		url: 'https://dailymotion.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/dailymotion.png',
	},
	{
		name: 'Twitch',
		url: 'https://www.twitch.tv/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/twitch.webp',
	},
	{
		name: 'Wistia',
		url: 'https://wistia.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/wistia.svg',
	},
	{
		name: 'Steamable',
		url: 'https://steamable.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/streamable.webp',
	},
	{
		name: 'SoundCloud',
		url: 'https://soundcloud.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/soundcloud.png',
	},
	{
		name: 'Mixcloud',
		url: 'https://mixcloud.com/',
		logo: 'https://storage.googleapis.com/snackeet/important_logos_video_app/mixclound.jpeg',
	},
]

export const REGEX_VIDEO_LOOM = new RegExp(
	/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:loom\.com\/share|loom\.com\/embed)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/
)
export const REGEX_VIDEO_VADOOTV_landing = new RegExp(
	/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:api\.vadoo\.tv\/landing_page\?vid=)([a-zA-Z0-9]+)(?:_[\w_-]+)?$/
)
export const REGEX_VIDEO_VADOOTV_embed = new RegExp(
	/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:api\.vadoo\.tv\/iframe_test\?id=)([a-zA-Z0-9]+)(?:_[\w_-]+)?$/
)
export const REGEX_VIDEO_WAVE_VIDEO = new RegExp(
	/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:watch\.wave\.video|embed\.wave\.video)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/
)
export const REGEX_VIDEO_ADILO = new RegExp(
	/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:adilo\.bigcommand\.com\/watch)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/
)
export const REGEX_VIDEO_SUMPLAYER = new RegExp(
	/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:sumplayer\.com\/video)\/([a-zA-Z0-9]+)(?:-[\w_-]+)?$/
)
export const REGEX_VIDEO_TIKTOK = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:tiktok\.com\/)/)
export const REGEX_VIDEO_BIGVU = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:desk\.bigvu\.tv\/)/)
