import { snackeet_blue } from 'styles/colors'

export const BUTTON_PRESET_LIST = [
	{
		name: 'outlined_square',
		style: {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderRadius: '0px',
			borderColor: snackeet_blue,
			backgroundColor: 'rgba(170, 170, 170, 0)',
		},
	},
	{
		name: 'outlined_round',
		style: {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderRadius: '8px',
			borderColor: snackeet_blue,
			backgroundColor: 'rgba(170, 170, 170, 0)',
		},
	},
	{
		name: 'outlined_circle',
		style: {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderRadius: '30px',
			borderColor: snackeet_blue,
			backgroundColor: 'rgba(170, 170, 170, 0)',
		},
	},
]
