import _ from 'lodash'

export function getTotalPoints(pages) {
	const pagesWithAnswersCountable = _.filter(
		pages,
		(page) =>
			!_.isEmpty(page.answers) &&
			page.answers_properties.type === 'answer' &&
			page.answers_properties.count_in_results
	)

	return _.reduce(
		pagesWithAnswersCountable,
		(sum, page) => {
			if (page.answers_properties.multiple_selection && page.answers_properties.partial_correct) {
				return sum + _.filter(page.answers, (answer) => answer.payload?.correct).length
			} else {
				return sum + 1
			}
		},
		0
	)
}
