export const en = {
	// ----------------- Error messages ------------------

	// Page config
	'error_messages.page.next': '<b>Page Settings:</b> The redirection page is invalid',
	'error_messages.page.on_enter.redirect':
		'<b>Page Settings:</b> The Otherwise redirection of Display Condition is invalid',

	'error_messages.page.logics.consequence_redirect': `<b>Page Settings:</b> the redirection page of Advanced Logic is invalid`,
	'error_messages.page.logics.conditions_field': `<b>Page Settings:</b> the condition field in Advanced Logic is invalid`,
	'error_messages.page.logics.conditions_value': `<b>Page Settings:</b> the condition value in Advanced Logic is invalid`,

	'error_messages.page.redirection_button': '<b>Redirection Button:</b> No URL has been provided',

	// Background
	'error_messages.background.video': '<b>Background:</b> No video has been chosen',
	'error_messages.background.image': '<b>Background:</b> No image has been chosen',

	// Blocks
	'error_messages.block.video': '<b>Video:</b> No file has been chosen',
	'error_messages.block.image': '<b>Image:</b> No file has been chosen',
	'error_messages.block.sticker': '<b>Sticker:</b> No file has been chosen',
	'error_messages.block.calendar': '<b>Calendar:</b> No URL has been provided',
	'error_messages.block.social_network': '<b>Social Networks:</b> No URL has been provided',
	'error_messages.block.location': '<b>Location:</b> No URL has been provided',
	'error_messages.block.iframe': '<b>Iframe:</b> No html iframe has been provided',
	'error_messages.block.share': '<b>Sharing:</b> No URL has been provided',
	'error_messages.block.text': '<b>Text:</b> No value inside (delete it or fill it in)',
	'error_messages.block.video_size': '<b>Video:</b> Recommend using video as background instead',
	'error_messages.block.image_size': '<b>Image:</b> Recommend using image as background instead',

	'error_messages.block.messenger.number': '<b>Messenger:</b> No number in the field',
	'error_messages.block.messenger.user_id': '<b>Messenger:</b> No username/ID in the field',
	'error_messages.block.messenger.link': '<b>Messenger:</b> No URL has been provided',
	'error_messages.block.messenger.mail': '<b>Messenger:</b> No Mail has been provided',
	'error_messages.block.messenger.valid_number': 'Enter a valid phone number',
	'error_messages.block.messenger.valid_mail': 'Enter a valid email',

	'error_messages.block.networks.number': '<b>Networks:</b> Please provide a valid phone number',
	'error_messages.block.networks.user_id': '<b>Networks:</b> Please provide a valid username/ID',
	'error_messages.block.networks.link': '<b>Networks:</b> Please provide a valid URL',
	'error_messages.block.networks.mail': '<b>Networks:</b> Please provide a valid email',

	'error_messages.block.cta.toolip': `<b>CTA:</b> Add a message in the tooltip`,
	'error_messages.block.cta.url.not_valid': `<b>CTA:</b> Please provide https URL`,
	'error_messages.block.cta.url.unsecure': `<b>CTA:</b> Please provide a valid https URL`,
	'error_messages.block.cta.mailto': `<b>CTA:</b> Please provide a valid email`,
	'error_messages.block.cta.attachment': `<b>CTA:</b> Please add a pop up to CTA block`,
	'error_messages.block.cta.copyToClipboard': `<b>CTA:</b> Please provide text to copy`,
	'error_messages.block.cta.download': `<b>CTA:</b> Please provide file to download`,
	'error_messages.block.cta.internal.nonexistent': `<b>CTA:</b> Please provide an existing page (not deleted)`,

	'error_messages.block.api_template.name': `<b>API Template:</b> Modifiable elements on the same page cannot have the same name`,

	'error_messages.page.first_page_disabled': `<b>Page:</b> The first page cannot be disabled`,

	// Tags
	'error_messages.tag.title': '<b>Tag:</b> No title has been provided',
	'error_messages.tag.url': '<b>Tag:</b> No URL has been provided',

	// Answer buttons
	'error_messages.button.redirection': '<b>Answer:</b> The redirection page is invalid',
	'error_messages.button.link': '<b>Link button:</b> No URL has been provided',
	'error_messages.button.file_downloader': '<b>File downloader button:</b> No file has been provided',

	'error_messages.button.quiz.correct_redirect': `<b>Answer:</b> The redirection page when <b>correct</b> is invalid`,
	'error_messages.button.quiz.incorrect_redirect': `<b>Answer:</b> The redirection page when <b>incorrect</b> is invalid`,
	'error_messages.button.quiz.partial_redirect': `<b>Answer:</b> The redirection page when <b>partially correct</b> is invalid`,

	// Form
	'error_messages.gdpr_consent.text':
		'<b>Form:</b> GDPR consent text is not fully customized. Please remove all variables.',

	'error_messages.form.next_button': '<b>Form:</b> Form next redirection page is invalid',
	'error_messages.form.email_domain': '<b>Form:</b> Invalid default domain in Email field',

	// Game
	'error_messages.game.wheel.url_empty': '<b>Game:</b> No URL has been provided',

	// AMP Outlink buttons
	'error_messages.amp_attachments.outlink.link': `<b>Link:</b> An invalid URL has been provided`,
	'error_messages.amp_attachments.outlink.title': `<b>Link:</b> Link button's title should not be empty`,

	// AMP Interactions
	'error_messages.amp_interactions.title': `<b>Interaction:</b> Option's title cannot be empty`,
	'error_messages.amp_interactions.image': `<b>Interaction:</b> Option's image cannot be empty`,
	'error_messages.amp_interactions.image_title': `<b>Interaction:</b> Option's alternative title cannot be empty`,

	// --------------------- Recommendation messages ----------------------
	'error_messages.page.name': '<b>Page:</b> Change the page name to optimize data collection',
	'error_messages.page.background':
		'<b>Background:</b> Background can be changed by double clicking on story or by clicking on background in widget bar',
	'error_messages.page.answers_count':
		'<b>Buttons:</b> The number of buttons on the page should be limited for optimal user experience',
	'error_messages.page.fields_count':
		'<b>Form:</b> The number of fields in a form should be limited for optimal user experience',
	'error_messages.page.text_count': '<b>Page:</b> Too much text in a page',

	// Widget Editor
	'error_messages.widget_editor.errors_count': `{count} { count, plural, one { error } other { erreors }}`,
	'error_messages.widget_editor.intructions': `Please fix the { count, plural, one { error } other { errors }} below`,
	'error_messages.widget_editor.placement': 'Invalid placement',
	'error_messages.widget_editor.appearance': 'Invalid appearance settings',
	'error_messages.widget_editor.list': 'Whitelist/Blacklist issue. Review allowed/blocked domains.',

	// Page Disabled
	'edition_menu.page.disabled.title': 'Page Visibility',
	'edition_menu.page.disabled.desctiption':
		'Deactivate the page,  preserving its data and history. Click to change status. Cannot disable the first page.',
	'edition_menu.page.disabled.status_visible': 'Status : <b>Visible</b>',
	'edition_menu.page.disabled.status_disabled': 'Status : <b>Disabled</b>',
	'edition_menu.page.disabled.icon': 'Page Disabled',

	// Carousel
	'error_messages.carousel.api_template.name':
		'<b>API Template:</b> Modifiable elements on the same page cannot have the same name',
	'error_messages.carousel.image': '<b>Carousel:</b> No image has been chosen',
	'error_messages.carousel.external_link.url.not_valid': '<b>Carousel:</b> Please provide a valid URL',
	'error_messages.carousel.external_link.url.unsecure': '<b>Carousel:</b> Please provide a HTTPS URL',
	'error_messages.carousel.internal.nonexistent': '<b>Carousel:</b> Please provide an existing page',
	'error_messages.carousel.attachment': '<b>Carousel:</b> Please provide an attachment',
	'error_messages.carousel.download': '<b>Carousel:</b> Please provide a file to download',

	// ButtonList
	'error_messages.buttonList.api_template.name':
		'<b>API Template:</b> Modifiable elements on the same page cannot have the same name',
	'error_messages.buttonList.external_link.url.not_valid': '<b>Button List:</b> Please provide a valid URL',
	'error_messages.buttonList.external_link.url.unsecure': '<b>Button List:</b> Please provide a HTTPS URL',
	'error_messages.buttonList.mailto.not_valid': '<b>Button List:</b> Please provide a valid email',
	'error_messages.buttonList.internal.nonexistent': '<b>Button List:</b> Please provide an existing page',
	'error_messages.buttonList.attachment': '<b>Button List:</b> Please provide an attachment',
	'error_messages.buttonList.download': '<b>Button List:</b> Please provide a file to download',
	'error_messages.buttonList.phoneNumber': '<b>Button List:</b> Please provide a valid phone number',

	// Timer
	'error_messages.timer.background_video':
		'<b>Timer:</b> The page timer does not correspond with the background video duration',
}
