export const en = {
	'toast.success.update': 'Updated',
	'toast.success.update.project': 'Workspace updated',

	'toast.success.creation.project': 'Workspace created',
	'toast.success.creation.snackeet': 'Story created',
	'toast.success.creation.widget': 'Widget created',
	'toast.success.creation.brand': 'Brand created',
	'toast.success.creation.leaderboard': 'Leaderboard created',
	'toast.success.creation.apiKey': 'Api Key created',
	'toast.success.creation.invitation': 'Invitation sent',
	'toast.success.creation.storyFolder': 'Folder created',
	'toast.success.creation.domain': 'Domain created',

	'toast.success.deletion.project': 'Workspace deleted',
	'toast.success.deletion.snackeet': 'Story deleted',
	'toast.success.deletion.widget': 'Widget deleted',
	'toast.success.deletion.brand': 'Brand deleted',
	'toast.success.deletion.leaderboard': 'Leaderboard deleted',
	'toast.success.deletion.fonts': '{ count, plural, one { Font } other { Fonts } } deleted',
	'toast.success.deletion.apiKey': 'Api Key deleted',
	'toast.success.deletion.invitation': 'Invitation deleted',
	'toast.success.deletion.domain': 'Domain deleted',

	'toast.success.duplication.snackeet': 'Story duplicated',
	'toast.success.duplication.widget': 'Widget duplicated',
	'toast.success.change_saving': 'Changes saved',
	'toast.success.upload_file': '{ count, plural, =1 { File imported } other { Files imported } }',
	'toast.success.upload_fonts': 'Fonts imported',
	'toast.success.export': 'File exported',
	'toast.success.delete_all_users': 'Users deleted',
	'toast.success.update.apiKey': 'Api Key updated',
	'toast.success.leave_organization': 'Left organization',
	'toast.success.kick_member': `The member was kicked`,
	'toast.success.update_member': `Changes applied`,
	'toast.success.update.domain': `Domain settings updated`,

	'toast.error': 'An error occurred',
	'toast.error.fetching': 'An error occurred during fetching data',
	'toast.error.change_saving': 'An error occurred during saving',
	'toast.error.creation': 'An error occurred during creation',
	'toast.error.creation.storyFolder': `An error ocurred during folder creation`,
	'toast.error.deletion': 'An error occurred during deletion',
	'toast.error.update.project': 'An error occurred during update',
	'toast.error.duplication.snackeet': 'An error occurred during duplication',
	'toast.error.upload_file': `An error occurred during import.`,
	'toast.error.upload_file.invalid_files': `{ count, plural, =1 { This file } other { These files } } cannot be uploaded`,
	'toast.error.upload_fonts.too_large': `Sorry, you cannot upload more than 2 Mb of font files at once.<br></br>Please try again with a smaller number of fonts.`,
	'toast.error.export': 'An error occurred during the export',
	'toast.error.apiKey': 'An error occurred during the operation',
	'toast.error.leave_organization': 'An error occurred when leaving organization',
	'toast.error.update.domain': 'An error occurred when updating the domain',

	'toast.info.uploading': 'Uploading and compressing file...',
	'toast.info.uploading_fonts': 'Uploading fonts...',
}
