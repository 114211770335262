export const GRADIENT_PRESET_LIST = [
	{
		name: 'preset_1',
		gradient: {
			type: 'linear',
			direction: 45,
			colors: ['rgba(255,214,52,1)', 'rgba(239,18,158,1)'],
		},
		gradient_css: 'linear-gradient(45deg, rgba(255,214,52,1), rgba(239,18,158,1))',
	},
	{
		name: 'preset_2',
		gradient: {
			type: 'linear',
			direction: 90,
			colors: ['rgba(52,148,230,1)', 'rgba(236,110,173,1)'],
		},
		gradient_css: 'linear-gradient(90deg, rgba(52,148,230,1), rgba(236,110,173,1))',
	},
	{
		name: 'preset_3',
		gradient: {
			type: 'linear',
			direction: 135,
			colors: ['rgba(131,58,180,1)', 'rgba(253,29,29,1)', 'rgba(252,176,69,1)'],
		},
		gradient_css: 'linear-gradient(135deg, rgba(131,58,180,1), rgba(253,29,29,1), rgba(252,176,69,1))',
	},
	{
		name: 'preset_4',
		gradient: {
			type: 'linear',
			direction: 0,
			colors: ['rgba(255,210,0,1)', 'rgba(247,151,30,1)'],
		},
		gradient_css: 'linear-gradient(0deg, rgba(255,210,0,1), rgba(247,151,30,1))',
	},
	{
		name: 'preset_5',
		gradient: {
			type: 'linear',
			direction: 180,
			colors: ['rgba(111,177,252,1)', 'rgba(67,100,247,1)', 'rgba(0,82,212,1)'],
		},
		gradient_css: 'linear-gradient(180deg, rgba(111,177,252,1), rgba(67,100,247,1), rgba(0,82,212,1))',
	},
]

export const COLOR_PRESET_LIST = [
	{
		name: 'red',
		color: 'rgba(198, 40, 40, 1)',
	},
	{
		name: 'blue',
		color: 'rgba(33, 150, 243, 1)',
	},
	{
		name: 'cycan',
		color: 'rgba(29, 233, 182, 1)',
	},
	{
		name: 'green',
		color: 'rgba(104, 159, 56, 1)',
	},
	{
		name: 'yellow',
		color: 'rgba(249, 168, 37, 1)',
	},
	{
		name: 'indigo',
		color: 'rgba(83, 109, 254, 1)',
	},
	{
		name: 'pink',
		color: 'rgba(255, 128, 171, 1)',
	},
	{
		name: 'purple',
		color: 'rgba(186, 104, 200, 1)',
	},
	{
		name: 'white',
		color: 'rgba(255, 255, 255, 1)',
	},
]
