import { SubscriptionError } from 'lib/Errors'
import useAppInitialization from 'lib/hooks/useAppInitialization'
import useDeviceDetect from 'lib/hooks/useDeviceDetect'
import useReduxLogout from 'lib/hooks/useReduxLogout'
import { Routes } from 'lib/Routes'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { customerSelector } from 'redux/customer/selectors'

const routes = new Routes()
const DEBUG = process.env.NEXT_PUBLIC_APP_ENV !== 'production'

export default function useAppAuthorization() {
	const router = useRouter()
	const { isMobile } = useDeviceDetect()
	const { onLogoutSuccess } = useReduxLogout()
	const reduxCustomer = useSelector(customerSelector)

	const [accessState, setAccessState] = useState({ hasAccess: true })
	const isAccessGranted = useRef(false) // Use Ref to have real time value in between renders

	const grantAccess = useCallback(() => {
		isAccessGranted.current = true
		setAccessState({ hasAccess: true }) // Force re render
	}, [setAccessState])

	const forbidAccess = useCallback(
		(redirectTo = '/') => {
			isAccessGranted.current = false
			setAccessState({
				hasAccess: false,
				redirectTo,
			})
		},
		[setAccessState]
	)

	const verifySubscription = useCallback(({ url, currentSubscription }) => {
		const privatePage = routes.getPrivateRoute(url)
		if (!privatePage) {
			return
		}
		const { organization_slug } = privatePage.getQuery(url)
		// Handle subscription status
		if (privatePage.subscriptionRequired && !isSubscriptionActive(currentSubscription)) {
			const redirectTo = organization_slug ? `/${organization_slug}/settings?tab=subscription` : '/'
			throw new SubscriptionError({ redirectTo })
		}

		// Handle multiple workspaces feature
		if (privatePage.multipleWorkspacesRequired && !currentSubscription?.feature_set?.multiple_workspaces) {
			const redirectTo = organization_slug ? `/${organization_slug}/list` : '/'
			throw new SubscriptionError({ redirectTo })
		}

		// Handle leaderboard feature
		if (privatePage.leaderboardRequired && !currentSubscription?.feature_set?.leaderboards) {
			const redirectTo = organization_slug ? `/${organization_slug}/list` : '/'
			throw new SubscriptionError({ redirectTo })
		}
	}, [])

	const { isLoadingData, loadDataAfterMount } = useAppInitialization({
		grantAccess,
		forbidAccess,
		verifySubscription,
	})

	const verifyAccess = useCallback(
		async (url) => {
			DEBUG && console.log('🔥 ROUTE CHANGED', url)

			if (routes.isNonRedirectable(url)) {
				DEBUG && console.log('---> 🌀 oauth')
				return grantAccess()
			}

			/* ------- PUBLIC PAGE ------- */
			if (routes.isPublic(url)) {
				if (reduxCustomer.isLoggedIn) {
					DEBUG && console.log('---> 🌀  public - logged IN')
					return forbidAccess()
				}

				DEBUG && console.log('---> 🌀  public - logged OUT')
				return grantAccess()
			}

			/* ------- PRIVATE PAGE ------- */
			const privatePage = routes.getPrivateRoute(url)
			DEBUG && console.log('---> 🌀  privatePage', privatePage)
			if (privatePage) {
				if (isMobile) {
					// Redirect to page asking customer to log with desktop
					onLogoutSuccess()
					return forbidAccess('/mobile')
				}

				if (!reduxCustomer.isLoggedIn) {
					DEBUG && console.log('---> 🌀  private - logged OUT')
					return forbidAccess(`/login?redirect=${encodeURIComponent(url)}`)
				}

				if (privatePage.superAdminRequired && reduxCustomer?.role !== 'super_admin') {
					return forbidAccess(`/404`)
				}

				DEBUG && console.log('---> 🌀  BEFORE LOAD')
				await loadDataAfterMount({
					url,
					shouldLoadOrganization: privatePage.organizationRequired,
					shouldLoadProject: privatePage.projectRequired,
				})
				DEBUG && console.log('---> 🌀  private - logged IN - OK')
				return
			}

			// Route not found
			forbidAccess(`/404`)
		},
		[
			isMobile,
			reduxCustomer.isLoggedIn,
			reduxCustomer.role,
			grantAccess,
			forbidAccess,
			loadDataAfterMount,
			onLogoutSuccess,
		]
	)

	// Check access when route changes
	useEffect(
		() => {
			function onRouteStart(url) {
				DEBUG && console.log('⭐️ ON ROUTE START', url)
				// Prevent withAuth from rendering until route is completely changed and validated
				// This avoids unnecessary renders, hence unnecessary requests
				isAccessGranted.current = false
			}
			router.events.on('routeChangeStart', onRouteStart)
			router.events.on('routeChangeComplete', verifyAccess)
			return () => {
				router.events.off('routeChangeStart', onRouteStart)
				router.events.off('routeChangeComplete', verifyAccess)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[verifyAccess]
	)

	// Check access when customer logs out or logs in
	// Must wait for router to be ready in order to pass the correct asPath url
	useEffect(
		() => {
			if (router.isReady) {
				verifyAccess(router.asPath)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reduxCustomer.isLoggedIn]
	)

	// Redirect when no access
	useEffect(
		() => {
			!accessState.hasAccess && router.push(accessState.redirectTo)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[accessState]
	)

	return {
		isLoadingData,
		isAccessGranted: isAccessGranted.current,
		isRouterReady: router.isReady,
	}
}

function isSubscriptionActive(subscription) {
	return subscription.object === 'license' || ['in_trial', 'active'].includes(subscription.status)
}
