export const fr = {
	'form.name': 'Nom',
	'form.description': 'Description',
	'form.first_name': 'Prénom',
	'form.email': 'Email',
	'form.last_name': 'Nom',
	'form.city': 'Ville',
	'form.country': 'Pays',
	'form.text': 'Texte',
	'form.phone': 'Numéro de téléphone',
	'form.primary_contact': 'Contact principal',
	'form.secondary_contacts': 'Contacts secondaires',
	// Customer
	'form.customer.first_name': 'Prénom',
	'form.customer.last_name': 'Nom',
	'form.customer.username': `Nom d'utilisateur`,
	'form.customer.email': 'Email',
	'form.customer.avatar': 'Avatar',
	'form.customer.locale': 'Langue',
	'form.customer.role': 'Rôle',
	// Organization
	'form.organization.name': 'Nom',
	'form.organization.logo': 'Logo',
	// Story Creation
	'form.story.name': 'Veuillez donner un nom',
	// Banner Widget
	'form.thumbnail': 'Vignette',
}
