import { INTERACTION_TYPES } from 'lib/constants'
import _ from 'lodash'
import ReactPlayer from 'react-player'

import { isVimeoVideo } from '.'

// Editor selection

const isElementSelected = _.curry((type, _id, selectedElement) => {
	const elements = _.castArray(selectedElement)
	return _.some(elements, (element) => element._id === _id && element.type === type)
})

export function isSingleTextBlockSelected(selectedElement) {
	return (
		_.isPlainObject(selectedElement) && selectedElement.type === 'block' && selectedElement.payload?.type === 'text'
	)
}

export function isSingleMediaBlockSelected(selectedElement) {
	return (
		_.isPlainObject(selectedElement) &&
		selectedElement.type === 'block' &&
		_.includes(['image', 'video'], selectedElement.payload?.type)
	)
}

export function isBlockSelected({ _id, selectedElement }) {
	return isElementSelected('block', _id, selectedElement)
}

export function isTagSelected({ _id, selectedElement }) {
	return isElementSelected('tag', _id, selectedElement)
}

export function isInteractionLayerSelected({ selectedElement }) {
	return _.some(_.castArray(selectedElement), (element) => INTERACTION_TYPES.includes(element?.type))
}

export function isInteractionSelected({ selectedElement, interactionId }) {
	return _.some(_.castArray(selectedElement), (element) => {
		const isInteraction = INTERACTION_TYPES.includes(element.type)
		const isSelected = element?._id === interactionId
		return isInteraction && isSelected
	})
}

export function createSelectedElementFromArray(elementsArray) {
	if (_.isEmpty(elementsArray)) {
		return {}
	}
	if (elementsArray.length === 1) {
		return elementsArray[0]
	}
	return elementsArray
}

export function getTextElements(selectedElement) {
	return _.filter(
		_.castArray(selectedElement),
		(element) => element.type === 'block' && element.payload.type === 'text'
	)
}

export function hasTextBlock(selectedElement) {
	return _.some(_.castArray(selectedElement), (element) => element.payload.type === 'text')
}

export function getShapeElements(selectedElement) {
	return _.filter(
		_.castArray(selectedElement),
		(element) => element.type === 'block' && element.payload.type === 'shape'
	)
}

export function checkBlur() {
	const activeElement = document.activeElement
	activeElement?.blur()
	const selection = document.getSelection()
	selection?.removeAllRanges()
}

export function canSetAsBackground(selectedElement) {
	if (!selectedElement) return false

	if (
		selectedElement.type === 'video' &&
		!ReactPlayer.canPlay(selectedElement.value) &&
		!isVimeoVideo(selectedElement.value)
	)
		return false

	return true
}

export function isRectangleShape(value = '') {
	const rectange_shapes = ['rectangle', 'rounded_rectangle', 'rounded_square', 'square', 'vertical_rectangle']

	return _.some(rectange_shapes, (shape) => value.includes(shape))
}
