export const fr = {
	'stories_list.header_title': 'Stories',
	'banners_list.header_title': 'Bannières',
	'brands_list.header_title': 'Marques',
	'leaderboards_list.header_title': 'Leaderboards',

	'brand_preset.title.name': 'Nom de marque',
	'brand_preset.title.accessProjects': 'Accès à la marque - administrateur uniquement',
	'brand_preset.title.logo': 'Logo et Favicon de la marque',
	'brand_preset.title.font_family': 'Famille de polices de marque',
	'brand_preset.title.colors': 'Couleurs de la marque',
	'brand_preset.title.fields_labels': 'Champs et libellés de marque',
	'brand_preset.title.buttons': 'Boutons de marque',
	'brand_preset.title.networks': 'Réseaux de marque',

	'brand_preset.name.label': 'Nom',
	'brand_preset.name.placeholder': 'Entrez le nom de la marque',
	'brand_preser.name.error': 'Le nom de la marque ne peut pas être vide.',

	'brand_preset.accessProject.instruction':
		'Cette marque sera disponible pour tous les utilisateurs des espaces de travail que vous sélectionnerez ci-dessous :',

	'brand_preset.logo.add_logo': 'Ajouter un logo',
	'brand_preset.logo.change_logo': 'Changer de logo',
	'brand_preset.logo.no_logo': `Aucun logo n'a été défini`,

	'brand_preset.font_family.label': 'Famille de polices',
	'brand_preset.font_family.placeholder': 'Choisissez une famille de polices',
	'brand_preset.font_family.preview_title': 'Titre, titre, titre, titre, titre, titre',
	'brand_preset.font_family.preview_subtitle': 'Sous-titre, sous-titre, sous-titre, sous-titre, sous-titre',
	'brand_preset.font_family.preview_paragraph': 'Paragraphe, paragraphe, paragraphe, paragraphe, paragraphe',

	'brand_preset.labels.header': `Styles de libellé`,
	'brand_preset.fields.header': 'Styles de champ',
	'brand_preset.fields_border.header': 'Bordure de champ',
	'brand_preset.buttons_styles.header': 'Styles de bouton',
	'brand_preset.buttons_border.header': 'Bordure de bouton',

	'brand_preset.styles.font_size': 'Taille police',
	'brand_preset.styles.text_color': 'Couleur texte',
	'brand_preset.styles.background_color': `Couleur l'arrière plan`,
	'brand_preset.styles.border_size': 'Taille bordure',
	'brand_preset.styles.border_color': 'Couleur bordure',
	'brand_preset.styles.border_radius': 'Arrondi bordure',

	'brand_preset.buttons.start': 'Démarrer',

	'brand_card.choose_a_brand': 'Choisissez une marque',
	'brand_card.no_brand': 'Sans marque',

	'brands_list.empty_list_title': 'Aucune Marque Prédéfinie pour le moment',

	// Fonts
	'fonts_list.button.add_fonts': 'Ajouter des polices',
	'fonts_list.button.import_fonts': 'Importer des polices',
	'fonts_list.table.title': 'Polices personnalisées',
	'fonts_list.table.header.fontFamily': 'Famille de caractères',
	'fonts_list.table.header.fontWeight': 'Graisse',
	'fonts_list.table.header.fontStyle': 'Style',
	'fonts_list.table.header.size': 'Taille',
	'fonts_list.table.header.uploaded_at': 'Ajouté le',
}
