export const en = {
	'settings_user.account.header': 'My Account',
	'settings_user.account.panel.header': 'Account',
	'settings_user.account.panel.personal_info': 'Personal information',
	'settings_user.account.panel.danger_zone': 'Danger Zone',
	'settings_user.account.delete_modal.header': 'Delete Account',
	'settings_user.account.delete_modal.content':
		'Are you sure ?<br></br>By deleting your will <b>lose all your data</b>.<br></br>This is an irreversible operation.',

	'settings_user.account.username_error.empty': 'Username cannot be empty',
	'settings_user.account.username_error.exist': 'Username already exists',
}
