export const TEXT_TEMPLATES = [
	{
		name: 'thank_you',
		elements: [
			{
				_id: 'gfKn8gTQsq3QCkPZyBr-s',
				type: 'text',
				value: '<h1><strong><em>Thank</em></strong></h1>',
				outer_style: {
					width: '243px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(63px) translateY(177px) rotate(-6.1deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 80,
					fontFamily: 'Supermercado One',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					textShadow: '4px 4px 0px rgba(255, 87, 87, 0.5), 8px 8px 0px rgba(255, 87, 87, 0.3)',
					shadowColor: 'rgba(255, 87, 87, 1)',
				},
				effect: 'echo',
			},
			{
				_id: '6e3rd1OK6i7u86wkfXOgX',
				type: 'text',
				value: '<h1><strong><em>you!</em></strong></h1>',
				outer_style: {
					width: '200px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(94px) translateY(246px) rotate(-6.1deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 80,
					fontFamily: 'Supermercado One',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					textShadow: '4px 4px 0px rgba(255, 87, 87, 0.5), 8px 8px 0px rgba(255, 87, 87, 0.3)',
					shadowColor: 'rgba(255, 87, 87, 1)',
				},
				effect: 'echo',
			},
		],
	},
	{
		name: 'contact_us',
		elements: [
			{
				_id: 'WZq9a4MIFGxdBfl16sLao',
				type: 'text',
				value: '<h1><strong>CONTACT US</strong></h1>',
				outer_style: {
					width: '200px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(80px) translateY(241px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 24,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'W8eDHTVECpT0LO3__yEIl',
				type: 'text',
				value: '<h1>(04) 298 3985 2092</h1><h1>+33 7 67 06 43 45</h1><h1><strong><em>info@example.com</em></strong></h1>',
				outer_style: {
					width: '200px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(80px) translateY(273px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 12,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
		],
	},
	{
		name: 'sale_sale_sale',
		elements: [
			{
				_id: 'sDg0WZg8pmo-TcCMgFnS0',
				type: 'text',
				value: '<h1>SALE</h1>',
				outer_style: {
					width: '256px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(51px) translateY(118px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 222, 89, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 84,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'NMeqIIoqMpSwL9VRlTpjx',
				type: 'text',
				value: '<h1>SALE</h1><h1>SALE</h1>',
				outer_style: {
					width: '256px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(51px) translateY(200px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 222, 89, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 84,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
					WebkitTextStrokeWidth: '3px',
					WebkitTextStrokeColor: 'rgba(255, 222, 89, 1)',
				},
				effect: 'hollow',
			},
		],
	},
	{
		name: 'coming_soon',
		elements: [
			{
				_id: 'WrzWo-5OtxjaEtGwX9lGP',
				type: 'text',
				value: '<h1>COMING</h1><h1>SOON</h1>',
				outer_style: {
					width: '256px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(51px) translateY(193px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 189, 89, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 48,
					fontFamily: 'BioRhyme',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					textShadow: '5px 5px 6px rgba(0, 0, 0, 0.19)',
				},
				effect: 'shadow',
			},
		],
	},
	{
		name: 'neon',
		elements: [
			{
				_id: 'YVVqFmt2aw0G4HgjfFLm5',
				type: 'text',
				value: '<h1>NEON</h1>',
				outer_style: {
					width: '256px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(52px) translateY(193px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 102, 196, 1)',
					backgroundColor: 'rgba(0, 0, 0, 0)',
					fontSize: 72,
					fontFamily: 'Rubik',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					filter: 'drop-shadow(rgba(255, 102, 196, 0.95) 0px 0px 2px) drop-shadow(rgba(255, 102, 196, 0.75) 0px 0px 10px) drop-shadow(rgba(255, 102, 196, 0.44) 0px 0px 30px)',
				},
				effect: 'neon',
			},
		],
	},
	{
		name: 'quotes',
		elements: [
			{
				_id: 'nuABqGgtkCoeQymGJzeTd',
				type: 'text',
				value: '<h3>&quot;The future belongs to those who believe in the beauty of their dreams.&quot;</h3>',
				outer_style: {
					width: '284px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(37px) translateY(223px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 24,
					fontFamily: 'Bree Serif',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'LXbX7QfBS6UyNzTynvkrC',
				type: 'text',
				value: '<h1>-Eleanor Roosevelt</h1>',
				outer_style: {
					width: '200px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(80px) translateY(331px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 16,
					fontFamily: 'Rubik',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
		],
	},
	{
		name: 'huge_sale',
		elements: [
			{
				_id: 'RG66AOTQ8mn-XbdvxWFLM',
				type: 'text',
				value: '<h1>HUGE</h1>',
				outer_style: {
					width: '289px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(35px) translateY(191px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 222, 89, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 84,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: '9PbMdFnrNCxetAjDmPcBM',
				type: 'text',
				value: '<h1>SALE</h1>',
				outer_style: {
					width: '287px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(36px) translateY(263px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 222, 89, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 95,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
					WebkitTextStrokeWidth: '3px',
					WebkitTextStrokeColor: 'rgba(255, 222, 89, 1)',
				},
				effect: 'hollow',
			},
		],
	},
	{
		name: 'be_brave',
		elements: [
			{
				_id: '9PbMdFnrNCxetAjDmPcBM',
				type: 'text',
				value: '<h1>BE</h1><h1>BRAVE</h1>',
				outer_style: {
					width: '287px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(30px) translateY(190px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 102, 196, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 72,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
					WebkitTextStrokeWidth: '4px',
					WebkitTextStrokeColor: 'rgba(255, 102, 196, 1)',
				},
				effect: 'hollow',
			},
		],
	},
	{
		name: 'date_subject',
		elements: [
			{
				_id: '7lRc7M6XPAgVgX-dkqx5l',
				type: 'text',
				value: '<h3><strong>DATE</strong></h3>',
				outer_style: {
					width: '58px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(35px) translateY(245px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 16,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: '_f7XxYV3dsEuV-e2jvygD',
				type: 'text',
				value: '<h3><strong>SUBJECT</strong></h3>',
				outer_style: {
					width: '86px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(35px) translateY(277px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 16,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'GixuBsKAE-ldRKDeDKcb_',
				type: 'text',
				value: '<h3>November 10, 2023</h3>',
				outer_style: {
					width: '203px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(134px) translateY(245px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 16,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'hl3Jzp-sd_bk5DNrTXBHt',
				type: 'text',
				value: '<h3>Team metting</h3>',
				outer_style: {
					width: '203px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(134px) translateY(277px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 16,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
		],
	},
	{
		name: 'order_now',
		elements: [
			{
				_id: 'LzTZXNMQrr3i1QXzOKEfI',
				type: 'text',
				value: '<h2><em><strong>Order</strong></em></h2>',
				outer_style: {
					width: '158px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(35px) translateY(248px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 74, 173, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 48,
					fontFamily: 'Axiforma Bold Italic',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'JBuwV7dnZPgA_5R-xaWtA',
				type: 'text',
				value: '<h2><strong>Now</strong>!</h2>',
				outer_style: {
					width: '158px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(175px) translateY(248px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 49, 49, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 48,
					fontFamily: 'Axiforma Bold Italic',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
		],
	},
	{
		name: 'sale_big',
		elements: [
			{
				_id: 'BV1BHQLKOv2TpWcGJr8tP',
				type: 'text',
				value: '<h1>BIG SALE</h1>',
				outer_style: {
					width: '287px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(36px) translateY(171px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(233, 233, 53, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 49,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(247, 243, 225, 1)',
					WebkitTextStrokeWidth: '3px',
					WebkitTextStrokeColor: 'rgba(255, 87, 87, 1)',
				},
				effect: 'hollow',
			},
			{
				_id: 'yxVBpn3Rp3D5B8JMjbnsf',
				type: 'text',
				value: '<p>Up to 50% OFF</p>',
				outer_style: {
					width: '287px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(36px) translateY(218px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 222, 89, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 31,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(247, 243, 225, 1)',
					WebkitTextStrokeWidth: '3px',
					WebkitTextStrokeColor: 'rgba(56, 182, 255, 1)',
				},
				effect: 'hollow',
			},
		],
	},
	{
		name: 'item_price',
		elements: [
			{
				_id: 'SIYAnZsFiJg_sesBjSmhX',
				type: 'text',
				value: '<p>Flamingo Print Pillow Case</p><p>Gold Foil Pillow Case</p><p>Polka Dots Fitted Sheet</p>',
				outer_style: {
					width: '195px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(38px) translateY(262px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 14,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.5',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'xATkoJvQ5HFnztENtcOx-',
				type: 'text',
				value: '<p>$60.00</p><p>$30.00</p><p>$20.00</p>',
				outer_style: {
					width: '61px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(250px) translateY(262px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 14,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.5',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'Imi2o971bHYcEX_B8R8dg',
				type: 'text',
				value: '<p><strong>ITEM</strong></p>',
				outer_style: {
					width: '95px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(38px) translateY(234px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 14,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.5',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'O0vUD2Kj1t_9thO8yJuJp',
				type: 'text',
				value: '<p><strong>Price</strong></p>',
				outer_style: {
					width: '54px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(250px) translateY(234px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 14,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'left',
					lineHeight: '1.5',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
		],
	},
	{
		name: 'meet_and_greet',
		elements: [
			{
				_id: 'WGpy_l5TtqL0_NMghW-Rl',
				type: 'text',
				value: '<p><strong>Meet &amp; greet</strong></p>',
				outer_style: {
					width: '145px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(111px) translateY(178px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(255, 255, 255, 0)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '2px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 18,
					fontFamily: 'Poppins',
					justifyContent: 'center',
					textAlign: 'center',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: '8hgP8RvbxSFK7sNLToa2f',
				type: 'text',
				value: '<p>Sat August 25, 2022</p>',
				outer_style: {
					width: '181px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(94px) translateY(208px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(255, 255, 255, 0)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '2px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 15,
					fontFamily: 'Poppins',
					justifyContent: 'center',
					textAlign: 'center',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: '5VrLSpVJSLt6opwEh37JK',
				type: 'text',
				value: '<p>(570) 427-9826<br/>219 Maple St<br/>Weatherly, Pennsylvania(PA), 18255</p>',
				outer_style: {
					width: '245px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(57px) translateY(240px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(255, 255, 255, 0)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '2px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 15,
					fontFamily: 'Poppins',
					justifyContent: 'center',
					textAlign: 'center',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
			{
				_id: 'peOZL976BUQqcL5NChLxT',
				type: 'shape',
				value: 'https://static.snackeet.com/snackeet_assets/V2/classic/square.png',
				outer_style: {
					transform: 'translateX(77px) translateY(340px) rotate(0deg)',
					top: '0px',
					left: '0px',
					width: '204px',
					height: '6px',
					opacity: '100%',
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, )',
					borderStyle: 'solid',
					borderWidth: '2px',
					borderColor: 'rgba(0, 0, 0, 0)',
					borderRadius: '0%',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
			},
			{
				_id: 'oKLIVpqUXvNvyaTcMaYGi',
				type: 'text',
				value: '<p>More info: contact@test.com</p>',
				outer_style: {
					width: '233px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(62px) translateY(360px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(255, 255, 255, 0)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '2px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 15,
					fontFamily: 'Poppins',
					justifyContent: 'center',
					textAlign: 'center',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
		],
	},
	{
		name: 'limited_time_only',
		elements: [
			{
				_id: 'TYupabDK-rPEn0n4FaDR0',
				type: 'text',
				value: '<p>LIMITED TIME ONLY</p><p>LIMITED TIME ONLY</p>',
				outer_style: {
					width: '279px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(38px) translateY(201px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 49, 49, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 24,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
					WebkitTextStrokeWidth: '1px',
					WebkitTextStrokeColor: 'rgba(255, 49, 49, 1)',
				},
				effect: 'hollow',
			},
			{
				_id: 'R16jEXPqGQmqAnvyv1qBc',
				type: 'text',
				value: '<p>LIMITED TIME ONLY</p><p>LIMITED TIME ONLY</p>',
				outer_style: {
					width: '279px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(38px) translateY(284px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 24,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
					WebkitTextStrokeWidth: '1px',
					WebkitTextStrokeColor: 'rgba(255, 49, 49, 1)',
				},
				effect: 'hollow',
			},
			{
				_id: 'zk-eHICvfnYqn1sQd2IR9',
				type: 'text',
				value: '<p>LIMITED TIME ONLY</p>',
				outer_style: {
					width: '279px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(38px) translateY(256px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(255, 49, 49, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					fontSize: 24,
					fontFamily: 'Archivo Black',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
				},
				effect: 'none',
			},
		],
	},
	{
		name: 'product_specification',
		elements: [
			{
				_id: 'DtVl1z7aKaTCSc6p511GH',
				type: 'text',
				value: '<p><strong><em>LES SPÉCIFICITÉS</em></strong></p>',
				outer_style: {
					width: '301px',
					height: 'auto',
					opacity: '100%',
					transform: 'translateX(29px) translateY(234px) rotate(0deg)',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 222, 89, 1)',
					fontSize: 33,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
					WebkitTextStrokeWidth: '1px',
					WebkitTextStrokeColor: 'rgba(0, 0, 0, 1)',
				},
				effect: 'hollow',
			},
			{
				_id: 'thtOh7XDzVJVKp7p2aDxb',
				type: 'text',
				value: 'DE NOS PRODUITS',
				outer_style: {
					width: '301px',
					height: 'auto',
					opacity: '100%',
					top: '0px',
					left: '0px',
					borderColor: 'rgba(0, 0, 0, 1)',
					borderStyle: 'solid',
					borderRadius: '0px',
					borderWidth: '0px',
					transform: 'translateX(29px) translateY(282px) rotate(0deg)',
				},
				animation: {
					name: '',
					duration: 1000,
					delay: 0,
					forever: false,
					displayDelay: 0,
				},
				keepRatio: false,
				inner_style: {
					color: 'rgba(0, 0, 0, 1)',
					backgroundColor: 'rgba(255, 255, 255, 0.43)',
					fontSize: 27,
					fontFamily: 'Roboto',
					justifyContent: 'center',
					textAlign: 'center',
					lineHeight: '1.25',
					paddingLeft: '6px',
					paddingTop: '6px',
					paddingRight: '6px',
					paddingBottom: '6px',
					WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
					WebkitTextStrokeWidth: '1px',
					WebkitTextStrokeColor: 'rgba(0, 0, 0, 1)',
				},
				effect: 'hollow',
			},
		],
	},
]
