import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCustomerIdentification } from 'redux/customer/selectors'

// Set window.intercomSettings for logged in/out users of our platform

export default function useIntercomIdentification() {
	const customerIdentification = useSelector(getCustomerIdentification)

	useEffect(() => {
		window.intercomSettings = {
			app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
			custom_launcher_selector: '#intercom-contact-button',
		}
		if (customerIdentification) {
			window.intercomSettings = {
				...window.intercomSettings,
				user_id: customerIdentification._id,
				email: customerIdentification.email,
				name: customerIdentification.full_name,
			}
		}
	}, [customerIdentification])
}
