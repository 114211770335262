export const INTEGRATIONS_LIST = [
	{
		_id: '01',
		name: 'googleAnalytics',
		type: 'code',
		logo: 'https://cdn.freebiesupply.com/logos/large/2x/google-analytics-3-logo-svg-vector.svg',
		tags: ['analytics'],
	},
	{
		_id: '02',
		name: 'googleTagManager',
		type: 'code',
		logo: '/static/integration_logos/googleTagManager.svg',
		tags: ['analytics'],
	},
	{
		_id: '03',
		name: 'facebookPixel',
		type: 'code',
		logo: '/static/integration_logos/facebookPixel.png',
		tags: ['analytics'],
	},
	{
		_id: '06',
		name: 'googleSheets',
		type: 'googleSheets',
		logo: '/static/integration_logos/googleSheets.png',
		tags: ['analytics'],
	},
	{
		_id: '22',
		name: 'googleDrive',
		type: 'googleDrive',
		logo: '/static/integration_logos/googleDrive.png',
		tags: ['files'],
	},
	{
		_id: '18',
		name: 'sendinblue',
		type: 'sendinblue',
		logo: '/static/integration_logos/sendinblue.png',
		tags: ['crm', 'automation'],
	},
	{
		_id: '19',
		name: 'mailerLite',
		type: 'mailerLite',
		logo: '/static/integration_logos/mailerLite.png',
		tags: ['crm', 'automation'],
	},
	{
		_id: '20',
		name: 'activeCampaign',
		type: 'activeCampaign',
		logo: '/static/integration_logos/activeCampaign.png',
		tags: ['crm', 'automation'],
	},
	{
		_id: '21',
		name: 'klaviyo',
		type: 'klaviyo',
		logo: '/static/integration_logos/klaviyo.png',
		tags: ['crm', 'automation'],
	},
	{
		_id: '04',
		name: 'hubSpot',
		type: 'hubSpot',
		logo: '/static/integration_logos/hubSpot.png',
		tags: ['crm', 'automation'],
	},
	{
		_id: '05',
		name: 'intercom',
		type: 'intercom',
		logo: '/static/integration_logos/intercom.png',
		tags: ['crm', 'automation'],
	},
	{
		_id: '07',
		name: 'googleSheets',
		type: 'zapier',
		logo: '/static/integration_logos/googleSheets.png',
		tags: ['analytics', 'zapier'],
		integrationUrl: 'https://zapier.com/app/editor/template/733461',
	},
	{
		_id: '08',
		name: 'googleDrive',
		type: 'zapier',
		logo: '/static/integration_logos/googleDrive.png',
		tags: ['files', 'zapier'],
		integrationUrl:
			'https://zapier.com/apps/google-drive/integrations/snackeet/727667/upload-new-snackeet-stories-to-google-drive',
	},
	{
		_id: '09',
		name: 'dropbox',
		type: 'zapier',
		logo: '/static/integration_logos/dropbox.png',
		tags: ['files', 'zapier'],
		integrationUrl:
			'https://zapier.com/apps/dropbox/integrations/snackeet/733448/upload-new-snackeet-files-to-dropbox',
	},
	{
		_id: '10',
		name: 'gmail',
		type: 'zapier',
		logo: '/static/integration_logos/gmail.png',
		tags: ['email', 'analytics', 'zapier'],
		integrationUrl:
			'https://zapier.com/apps/gmail/integrations/snackeet/733478/send-gmail-emails-for-new-interactions-in-snackeet',
	},
	{
		_id: '11',
		name: 'googleDocs',
		type: 'zapier',
		logo: '/static/integration_logos/googleDocs.png',
		tags: ['analytics', 'zapier'],
		integrationUrl:
			'https://zapier.com/apps/google-docs/integrations/snackeet/733546/add-new-snackeet-interactions-to-google-docs',
	},
	{
		_id: '12',
		name: 'slack',
		type: 'zapier',
		logo: '/static/integration_logos/slack.png',
		tags: ['automation', 'zapier'],
		integrationUrl:
			'https://zapier.com/apps/slack/integrations/snackeet/733534/get-slack-notifications-for-new-snackeet-interactions',
	},
	{
		_id: '13',
		name: 'microsoftTeams',
		type: 'zapier',
		logo: '/static/integration_logos/microsoftTeams.png',
		tags: ['automation', 'zapier'],
		integrationUrl:
			'https://zapier.com/apps/microsoft-teams/integrations/snackeet/733582/get-microsoft-teams-notifications-for-new-snackeet-interactions',
	},
	{
		_id: '14',
		name: 'oneDrive',
		type: 'zapier',
		logo: '/static/integration_logos/oneDrive.png',
		tags: ['files', 'zapier'],
		integrationUrl: 'https://zapier.com/app/editor/template/734536',
	},
	{
		_id: '15',
		name: 'mailchimp',
		type: 'zapier',
		logo: '/static/integration_logos/mailchimp.png',
		tags: ['automation', 'crm', 'marketing', 'zapier'],
		integrationUrl: 'https://zapier.com/app/editor/template/733633',
	},
	{
		_id: '16',
		name: 'twitter',
		type: 'zapier',
		logo: '/static/integration_logos/twitter.png',
		tags: ['automation', 'marketing', 'zapier'],
		integrationUrl: 'https://zapier.com/app/editor/template/734501',
	},
	{
		_id: '17',
		name: 'discord',
		type: 'zapier',
		logo: '/static/integration_logos/discord.png',
		tags: ['automation', 'zapier'],
		integrationUrl: 'https://zapier.com/app/editor/template/734513',
	},
]

export const AMP_INTEGRATIONS_LIST = [
	{
		_id: '01',
		name: 'googleAnalytics',
		type: 'code',
		logo: 'https://cdn.freebiesupply.com/logos/large/2x/google-analytics-3-logo-svg-vector.svg',
		tags: ['analytics'],
	},
	{
		_id: '02',
		name: 'facebookPixel',
		type: 'code',
		logo: '/static/integration_logos/facebookPixel.png',
		tags: ['analytics'],
	},
	{
		_id: '03',
		name: 'ampAds',
		type: 'ads',
		logo: '/static/integration_logos/googleAdSense.svg',
		tags: ['Ads'],
	},
]
