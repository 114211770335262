export const en = {
	'placeholder.search': 'Search...',
	'placeholder.search_by_name': 'Search by name',
	'placeholder.search_image': 'Use the search bar to find an image',
	'placeholder.image_url': 'Enter the image url',
	'placeholder.video_url': 'Enter the video url',
	'placeholder.enter_message': `Enter your message`,
	'placeholder.form_field': `Enter your placeholder`,

	'placeholder.text_editor': 'Enter some text...',
}
