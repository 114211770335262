export const en = {
	'password_reset.pwd_placeholder': 'New password',
	'password_reset.confirm_pwd_placeholder': 'Confirm password',
	'password_reset.organization_placeholder': 'Organization',
	'password_reset.main_button': 'Submit',
	'password_reset.secondary_button': 'Log in',
	'password_reset.error_header': 'Invalid token',
	'password_reset.error_content':
		'This link is not valid. If you requested a password reset, please use the latest link sent by email.',
	'password_reset.organization_error': `You must specify the organization`,
	'password_reset.reset_success': `Your password was successfully reset 🎉 Redirecting to login...`,
	'password_reset.reset_error': `An error has occurred. Please try again later.`,
}
