export const fr = {
	'settings_user.account.header': 'Mon compte',
	'settings_user.account.panel.header': 'Compte',
	'settings_user.account.panel.personal_info': 'Mes informations',
	'settings_user.account.panel.danger_zone': 'Zone de danger',
	'settings_user.account.delete_modal.header': 'Suppression du compte',
	'settings_user.account.delete_modal.content':
		'Êtes-vous sûr(e) ?<br></br>En supprimant vos données, vous <b>perdrez toutes vos données</b>.<br></br> Ceci est une opération <b>irréversible.</b>',

	'settings_user.account.username_error.empty': `Le nom d'utilisateur ne peut pas être vide`,
	'settings_user.account.username_error.exist': `Ce nom d'utilisateur existe déjà`,
}
