export const fr = {
	'organization.table_title.organization': `Nom de l'organisation`,
	'organization.table_title.members': '{count} / {seats} { seats, plural, one { MEMBRE } other { MEMBRES }}',
	'organization.table_title.current_members': '{count} { count, plural, one { MEMBRE } other { MEMBRES }} / ILLIMITÉ',
	'organization.table_title.role': 'Rôle',
	'organization.table_title.owner_email': 'Propriétaire',
	'organization.table_title.created_date': 'Date de création',
	'organization.table_title.last_update': 'Dernière mise à jour',
	'organization.table_title.actions': 'Actions',
	'organization.table_title.invited_role': 'Rôle invité',
	'organization.table_title.invited_date': `Invité le`,
	'organization.table_title.username': `Nom d'utilisateur`,
	'organization.table_title.email': 'Email',
	'organization.table_title.invited_by': 'Invité par',
	'organization.table_title.status': 'Statut',

	'organization.search.placeholder': 'Rechercher une organisation...',

	'organization.title.pending.invitations':
		'{count} { count, plural, one { Invitation } other { Invitations }} en attente',
	'organization.title.pending.licences': 'License en attente',

	'organization.tab.profile': `Profil de l'organisation`,
	'organization.tab.members': 'Gestion des membres',

	'organization.tab_title.profile': 'Votre organisation',
	'organization.tab_title.settings': 'Paramètres',

	'organization.members.tab.members_list': 'Membres',
	'organization.members.tab.invitations_list': 'Invitations',
	'organization.members.number': 'Nombre de membres de ',
	'organization.invitations.number': `Nombre d'invitations de `,

	'organization.profile.name': `Nom de l'organisation`,
	'organization.profile.slug': `ID de l'organisation`,
	'organization.profile.created_date': 'Date de création',
	'organization.profile.delete_organization': "Supprimer l'organisation",
	'organization.profile.change_logo': 'Changer le logo',
	'organization.profile.timezone': 'Sélectionnez le fuseau horaire',
	'organization.profile.locale': 'Sélectionnez la langue des notifications',
	'organization.profile.owner': `Propriétaire de l'organisation`,
	'organization.profile.ID_exists': 'ID existe déjà',
	'organization.profile.ID_empty': 'ID ne peux pas être vide',

	'organization.app_header.title': 'Organisations',
	'organization.app_header.settings': 'Paramètres',

	'organization.notifications.answers': `Envoyer des notifications à tous les membres de l'organisation chaque fois qu'un utilisateur termine une Story ou remplit un formulaire.`,

	'organization.members.table_header.role': 'Rôle',
	'organization.members.table_header.workspaces_access': 'Accès aux espaces de travail',
	'organization.members.table_header.actions': 'Actions',
	'organization.invitations.table_header.role': 'Rôle',
	'organization.invitations.table_header.invited_date': `Date d'invitation`,
	'organization.invitations.table_header.invited_by': `Invité(e) par`,
	'organization.invitations.table_header.status': 'Statut',

	'organization.invitation_modal.label_input': `Entrez l'e-mail du membre`,
	'organization.invitation_modal.label_dropdown': 'Rôle:',
	'organization.invitation_modal.email_missing': `L'email doit être rempli`,
	'organization.invitation_modal.email_error': `Veuillez entrer un email valide`,
	'organization.invitation_modal.projects_empty': 'Les espaces de travail attribués ne peuvent pas être vides',

	'organization.invitation_button.tooltip.header': 'Inviter nouveaux membres',
	'organization.invitation_button.tooltip.description_available': `L'administrateur peut inviter de nouveaux membres par e-mail.`,
	'organization.invitation_button.tooltip.description_unavailable':
		'Le nombre limite de membres a été atteint, votre organisation ne peut pas inviter plus de membres.',

	'organization.role.agent': 'Agent',
	'organization.role.admin': 'Admin',
	'organization.role.owner': 'Propriétaire',

	'organization.limitation.interactions.header': `Nombre d'utilisateurs actifs ce mois-ci`,
	'organization.limitation.members.header': `Taille de l'équipe`,
	'organization.limitation.timeline': `Réinitialisé après `,
	'organization.limitation.change_plan': 'Modifier votre offre',
	'organization.limitation.modal.title': 'Configurez votre offre',
	'organization.limitation.plan_card.seats': `{count} { count, plural, =1 { membre } other { membres } } de l'équipe`,
	'organization.limitation.plan_card.users_per_month': `{count}k utilisateurs actifs par mois`,
	'organization.limitation.extra_seats.count': `{count} { count, plural, =1 { membre } other { membres } } d'équipe en plus`,
	'organization.limitation.extra_seats.unit': `par membre`,
	'organization.limitation.extra_users_per_month.count': `{count} { count, plural, =0 { } other { k  } } utilisateurs par mois en plus`,
	'organization.limitation.extra_users_per_month.unit': `par tranche de 1k`,
	'organization.checkout.quantity': `Quantité`,
	'organization.checkout.unit': `Unité`,
	'organization.checkout.price': `Prix`,

	// AppSumo
	'organization.licence.subtitle': '👋 Hey Sumoling, vous devez activer votre licence à vie.',

	// Update member
	'organization.members.update.title': `Gérer l'accès de membre`,
	'organization.members.warning.admin': `<b>ADMIN</b> a tous les droits (gérer les accès, les abonnements, supprimer des membres, etc...)`,
	'organization.members.warning.agent': `<b>AGENT</b> ne peut accéder qu'aux ESPACES DE TRAVAIL autorisés et peut gérer les STORIES dans ces ESPACES DE TRAVAIL.`,
	'organization.members.assign.instruction': 'Choisissez des espaces de travail pour affecter votre agent',
	'organization.members.update.header.agent': `Vous êtes sur le point d'accorder à <b>{username} ({email})</b> les droits <b>AGENT</b>.`,
	'organization.members.update.header.admin': `Vous êtes sur le point d'accorder à <b>{username} ({email})</b> les droits <b>ADMIN</b>.`,
	'organization.members.update.action': `Gérer l'accès`,
	'organization.members.update.all_access': `Tous les espaces de travail`,

	// Update workspace access
	'organization.projects.access.title': `Gérer l'accès à l'espace de travail`,
	'organization.projects.access.header': `Choisissez les agents à affecter dans <b>{projectName}</b>`,

	// Api Keys Settings
	'apiKeys.tab.title': 'Gestion des Clés API',
	'apiKeys.description.1': `Générez et gérez des clés API pour accéder aux stories en ligne et à leurs statistiques.`,
	'apiKeys.description.2': `Vous pouvez <b>créer</b>, <b>supprimer</b>, <b>activer</b> ou <b>désactiver</b> les clés à votre guise.`,
	'apiKeys.description.documentation': `Pour plus d'informations sur ce que vous pourrez faire avec le clés, veuillez consulter notre`,
	'apiKeys.description.link': `documentation.`,
	'apiKeys.warning':
		'La divulgation de votre clé API peut entraîner des risques pour la sécurité. Gardez-la toujours confidentielle.',
	'apiKeys.empty.instructions': 'Cliquer sur le bouton pour créer votre première clé API',
	'apiKeys.button.create': 'Générer nouvelle clé',
	'apiKeys.table.header.name': 'Nom',
	'apiKeys.table.header.key': 'Clé',
	'apiKeys.table.header.created_at': 'Créé',
	'apiKeys.table.header.last_used_at': 'Utilisée',
	'apiKeys.table.header.active': 'Active',
	'apiKeys.name': 'Nom de la clé',
	'apiKeys.editModal.title': 'Editer la clé',
	'apiKeys.deleteModal.title': 'Supprimer la clé',
	'apiKeys.deleteModal.content': 'Êtes-vous sûr de supprimer la clé <b>{name}</b>?',

	// Domains
	'domains.tab.title': 'Domaines',
	'domains.description': `Ajoutez et vérifiez les domaines pour personnaliser les URL de vos stories.`,
	'domains.empty.instructions': `Cliquer sur le bouton pour ajouter votre domaine`,
	'domains.tooltip.verified': 'Domaine vérifié',
	'domains.tooltip.not_verified': 'Domaine non vérifié',
	'domains.delete_modal.title': 'Supprimer le domaine',
	'domains.delete_modal.instructions': `La suppression de ce domain <b>réinitialisera l'URL</b> des stories concernées.<br></br><br></br>Pour confirmer, veuillez saisir le nom de domaine:`,
	'domains.edit_modal.article_sitemap_link': 'Comment envoyer votre sitemap à Google',
	'domains.edit_modal.workspaces': 'Espaces de travail',
	'domains.edit_modal.notFoundPage.field.title': 'Title',
	'domains.edit_modal.notFoundPage.tooltip.title': `Ce champs correspond au champs meta dans la balise <head> de la page`,
	'domains.edit_modal.notFoundPage.field.subHeader': 'Message',
	'domains.edit_modal.notFoundPage.field.ctaText': 'Texte du bouton',
	'domains.edit_modal.notFoundPage.field.ctaUrl': 'URL du bouton',
	'domains.edit_modal.notFoundPage.field.accentColor': 'Couleur primaire',
	'domain.edit_modal.confirmation.title': 'Confirmer les modifications',
	'domain.edit_modal.confirmation.projects_list': `Vous allez enlever l'accès pour les espaces de travail suivants:`,
	'domain.edit_modal.confirmation.confirm_text': `Ceci <b>réinitialisera lURL</b> des stories concernées.<br></br><br></br>Voulez vous procéder ?`,
	'domains.table.header.status': 'Status',
	'domains.table.header.domain': 'Domaine',
	'domains.table.header.last_verified_at': 'Vérifié le',
}
