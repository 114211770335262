export const en = {
	'story_settings.tab.title.language': 'Language',
	'story_settings.tab.title.users': 'Users Management',
	'story_settings.tab.title.notifications': 'Email Notifications',
	'story_settings.tab.title.confirmation': 'Automatic Reply',
	'story_settings.tab.title.versions': 'Versions',
	'story_settings.tab.title.display': 'Display',
	'story_settings.tab.title.display_and_navigation': 'Display and Navigation',
	'story_settings.tab.title.wallpaper': 'Desktop Wallpaper',
	'story_settings.tab.title.activation': 'Activation',
	'story_settings.tab.title.activation_and_expiration': 'Activation & Expiration',
	'story_settings.tab.title.seo': 'SEO Configuration',
	'story_settings.tab.title.tags': 'Template Tags',
	'story_settings.tab.title.brand': 'Apply Brand Kit',
	'story_settings.tab.title.api_template': 'Use as API Template',

	'story_settings.language.title': 'Language Editor',

	// Users
	'story_settings.users.title': 'Manage Users',
	'story_settings.users.refresh_user.title': 'Forget User Session <b>in browser</b>',
	'story_settings.users.refresh_user.description': `Activating this feature ensures that <i>returning users are treated as new</i>, without any previously provided answers pre-populated.
	<br></br>👉 Ideal for ensuring fresh responses each visit.`,
	'story_settings.users.redirect_on_completed.title': 'Redirect on Complete',
	'story_settings.users.redirect_on_completed.description': `Activating this feature <i>redirects returning users to the end page</i> upon completing the story.
	<br></br>👉 Suitable for displaying outcomes like quiz scores or summary pages.`,
	'story_settings.users.delete_all_users.button.idle': 'Delete all users',
	'story_settings.users.delete_all_users.button.processing': 'Deleting users...',

	// Notifications
	'story_settings.notifications.title': 'Notifications Configuration',
	'story_settings.notifications.answers.title': 'Answers Notification',
	'story_settings.notifications.answers.message': `Send email notifications each time users complete a story or perform an interaction.`,
	'story_settings.notifications.answers.completedOnly_title': `Only Send When Completed`,
	'story_settings.notifications.answers.completedOnly_description': `Only send notifications when users complete the Story.`,
	'story_settings.notifications.answers.hasContactOnly_title': 'Send When Contact Collected',
	'story_settings.notifications.answers.hasContactOnly_description': `Send notification when users give contact (email or telephone number).<br></br>Only available when variable <b>email</b> or <b>phone</b> assigned.`,
	'story_settings.notifications.answers.sendTo_title': `Send notifications to`,
	'story_settings.notifications.answers.sendTo_description': `A notification with response data will be sent to the email.`,
	'story_settings.notifications.answers.sendTo_emails_helper': `Use comma to separate multiple addresses`,
	'story_settings.notifications.answers.sendTo_placeholder': `Please enter emails for notifications`,
	'story_settings.notifications.answers.sendTo_edit_button': `Edit Emails`,
	'story_settings.notifications.answers.sendTo_isError': `Please enter valid emails`,

	// Automatic reply
	'story_settings.reply.header': 'Automatic Reply Configuration',
	'story_settings.reply.title': 'Automatic Reply',
	'story_settings.reply.description': `Send an email to the respondent who <b>completes</b> the Story.<br></br>The variable <b>"email"</b> must be assigned to activate this feature.`,
	'story_settings.reply.from.title': `From email`,
	'story_settings.reply.from.description': `To use a custom email, please contact our client support at`,
	'story_settings.reply.replyTo.title': 'Reply-to email',
	'story_settings.reply.replyTo.description':
		'Address to which the respondent can answer you. If not specified, it will be by default <b>noreply@snackeet.com</b>',
	'story_settings.reply.replyTo.placeholder': 'Please enter an email to reply',
	'story_settings.reply.replyTo.error.invalid_email': 'Please enter a valid email',
	'story_settings.reply.replyTo.button.update': 'Edit Email',
	'story_settings.reply.subject.title': 'Subject',
	'story_settings.reply.subject.description': `Use {{params.variable}} to include data from your Story's variable, e.g. {{params.firstname}}`,
	'story_settings.reply.subject.placeholder': `e.g. Thank you for registering to this event, {{params.firstname}}`,
	'story_settings.reply.imgURL.title': 'Image',
	'story_settings.reply.imgURL.description':
		'The image will be displayed on top of the email stretched to the full width of the container',
	'story_settings.reply.message.title': 'Message',
	'story_settings.reply.message.description': `Enter the text of your automatic reply. Use {{params.variable}} to include data from your Story's variable, e.g. {{params.firstname}}`,
	'story_settings.reply.message.placeholder': `Dear {{params.firstname}},\n\n\nThank your for your registration!\n\n\nBest regards,\nMy Team.`,

	'story_settings.reply.warning_modal.title': 'Activate Automatic Reply',
	'story_settings.reply.warning_modal.sub_title': 'Unable to activate automatic reply',
	'story_settings.reply.warning_modal.description': `The variable <b>"email"</b> must be assigned to activate this feature.`,
	'story_settings.reply.waring_banner.title': 'Upgrade your Plan to activate this feature!',

	// Versions
	'story_settings.versions.title': 'Editing Versions',
	'story_settings.versions.draft': 'Draft Version',
	'story_settings.versions.published': 'Published Version',
	'story_settings.versions.reroll_description':
		'All your editing in your Draft will be replaced by your Published version.<br></br><br></br>You can reverse these changes with button Undo on the top left next to the editing zone.',
	'story_settings.buttons.reroll': 'Revert Draft to Publish',

	// Language
	'story_settings.language.sub_title': "Story's Language",

	// Display
	'story_settings.display.title': 'Display Configuration',
	'story_settings.display.progress_bar.title': 'Display progress bar',
	'story_settings.display.progress_bar.message': `Enable/Disable your story's progress bar.`,
	'story_settings.display.no_branding.title': 'Display brand',
	'story_settings.display.no_branding.message': `Show/Hide Snackeet brand.`,
	'story_settings.display.no_branding.subscribe': `Upgrade to Croissant or Donut plans to be able to change this option.`,
	'story_settings.display.desktop_wallpaper.title': `Desktop Wallpaper`,
	'story_settings.display.desktop_wallpaper.description': `Set an image as an immersive background for the story. By default, the background of the current page will be applied with a blur effect as the story background.`,

	// Navigation
	'story_settings.navigation.title': 'Navigation Settings',
	'story_settings.navigation.navigation_arrows.title': 'Navigation arrows',
	'story_settings.navigation.navigation_arrows.description':
		'Users will be able to navigate between pages by clicking on the navigation arrows.',
	'story_settings.navigation.navigation_method.title': 'Navigation touch behavior',
	'story_settings.navigation.navigation_method.description':
		'Users will be able to navigate between pages by either tapping or swiping.',
	'story_settings.navigation.navigation_errors.title': 'Navigation errors',
	'story_settings.navigation.navigation_errors.description':
		'Messages displayed when user tries to navigate to another page when not allowed.',
	'story_settings.navigation.navigation_errors.error.back': 'Previous',
	'story_settings.navigation.navigation_errors.error.next': 'Next',

	// Expiration
	'story_settings.expiration.title': 'Story Expiration',
	'story_settings.expiration.description':
		'Set an expiration date for this story, after which an overlay will appear displaying a message.',
	'story_settings.expiration.active': `Expiration date`,
	'story_settings.expiration.end_date': 'End Date',

	// Status
	'story_settings.status.title': 'Story Status:',
	'story_settings.status.change_status.title': 'Enable/Disable story',
	'story_settings.status.change_status.description':
		'When a story is disabled, sharing links no longer work, regardless of how you share. Enable the story to make them work.',
	'story_settings.schedule.title': 'Schedule your story (optional)',
	'story_settings.schedule.description': `You can define a time range where the story is active and available to your users.`,
	'story_settings.schedule.button': `Set a schedule`,
	'story_settings.schedule.startDate.title': `Start Date`,
	'story_settings.schedule.endDate.title': `End Date`,
	'story_settings.schedule.timezone.title': `Timezone`,

	// Publisher
	'story_settings.publisher.setting_header': 'Story Settings',
	'story_settings.publisher.story_title': `Story's Title`,
	'story_settings.publisher.story_language': `Story's Language`,

	'story_settings.publisher.publisher_header': 'Publisher Settings',
	'story_settings.publisher.publisher_name': 'Publisher Name',
	'story_settings.publisher.publisher_name_placeholder': `Enter publisher's name`,
	'story_settings.publisher.publisher_logo': 'Logo',
	'story_settings.publisher.publisher_logo_description': 'Logo should be a 1:1 aspect ratio and 92x92px or larger',
	'story_settings.publisher.publisher_preview': 'Preview',
	'story_settings.publisher.publisher_poster_portrait': 'Portrait Poster',
	'story_settings.publisher.publisher_poster_portrait_description':
		'Portrait poster should be a 3:4 aspect ratio and 640x853px or larger',
	'story_settings.publisher.publisher_poster_square': 'Square Poster',
	'story_settings.publisher.publisher_poster_square_description':
		'Square poster should be a 1:1 aspect ratio and 640x640px or larger',
	'story_settings.publisher.publisher_poster_landscape': 'Landscape Poster',
	'story_settings.publisher.publisher_poster_landscape_description': `Landscape poster should be a 4:3 aspect ratio and 853x640px or larger`,

	// SEO Config
	'story_settings.seo_config.allow_indexing.title': 'Search Engine Indexing',
	'story_settings.seo_config.allow_indexing.description': 'Make the story visible to search engine crawlers.',
	'story_settings.seo_config.metas_header': 'Meta Tags',
	'story_settings.seo_config.meta_name': 'Meta name',
	'story_settings.seo_config.meta_content': 'Meta content',
	'story_settings.seo_config.meta_add_button': 'Add a new meta tag',
	'story_settings.seo_config.meta_keywords_title': 'Meta Keywords',
	'story_settings.seo_config.meta_keywords_label': 'Keywords',
	'story_settings.seo_config.meta_keywords_placeholder': 'Enter your keywords',
	'story_settings.seo_config.meta_keywords_description':
		'Separate keywords with commas. For example: webStory, Snackeet, content creator',

	// Template tags
	'story_settings.template_tags.title': 'Template Tags',
	'story_setting.template_tags.description': 'Choose categories for story template',

	// Brand preset
	'story_settings.brand_preset.current_brand': 'Current Brand',
	'story_settings.brand_preset.brand_colors': 'Brand colors',
	'story_settings.brand_preset.brand_fontFamily': 'Brand font-family',
	'story_settings.brand_preset.choose_new_brand': 'Choose a new brand:',
	'story_settings.brand_preset.change_brand_btn': 'Change Brand',

	'story_settings.brand_preset.modal_title': 'Apply this Band Preset to the story',
	'story_settings.brand_preset.modal_instruction':
		'This action will change the style of all buttons and form fields.',

	// API Template
	'story_settings.api_template.title': 'Use this Story as an API Template',
	'story_settings.api_template.header': 'Activate to define elements to modify',
	'story_settings.api_template.description':
		'A unique ID can be assigned to every element on the page to mark as modifiable.<br></br>The structure of data to use the story as a template can be obtained with the story_id: <b>{story_id}</b>.',
}
