export const fr = {
	'button.login': 'Se connecter',
	'button.logout': 'Se déconnecter',
	'button.add': 'Ajouter ',
	'button.add_element': 'Ajouter { count, plural, one { un élément } other { des éléments }}',
	'button.activate': 'Activer',
	'button.cancel': 'Annuler',
	'button.confirm': 'Valider',
	'button.delete': 'Supprimer',
	'button.access': 'Ouvrir',
	'button.search': 'Chercher',
	'button.clear_list': 'Vider la liste',
	'button.play': 'Lancer',
	'button.play_animation': 'Lancer animation',
	'button.hide': 'Cacher',
	'button.show': 'Afficher',
	'button.send': 'Envoyer',
	'button.save': 'Enregistrer',
	'button.authorize': 'Autoriser',
	'button.change_without_save': 'Changer sans sauvegarder',
	'button.test': 'Tester',
	'button.create': 'Créer',
	'button.change': 'Changer',
	'button.continue': 'Continuer',
	'button.duplicate': 'Dupliquer',
	'button.edit': 'Éditer',
	'button.edit_slug': 'Configurer slug',
	'button.update': 'Actualiser',
	'button.refresh': 'Rafraîchir',
	'button.import': 'Importer',
	'button.export': 'Exporter',
	'button.export_as': 'Exporter en { type }',
	'button.export_pdf': 'Exporter des graphiques au format PDF',
	'button.export_csv': 'Exporter les données au format CSV',
	'button.filter': 'Filtrer',
	'button.download': 'Télécharger',
	'button.next': 'Suivant',
	'button.next_step': `L'étape suivante`,
	'button.previous': 'Retour',
	'button.change_template': 'Changer de modèle',
	'button.accept': 'Accepter',
	'button.reject': 'Rejeter',
	'button.manage': 'Gérer',
	'button.leave': 'Quitter',
	'button.kick': 'Expulser',
	'button.change_owner': 'Changer de propriétaire',
	'button.remove': 'Retirer',
	'button.customize': 'Personnaliser',
	'button.delete_account': 'Supprimer mon compte',
	'button.delete_organization': 'Supprimer organisation',
	'button.invite_member': 'Inviter nouveau membre',
	'button.connect': 'Connecter',
	'button.disconnect': 'Déconnecter',
	'button.abort': 'Annuler',
	'button.finish': 'Terminer',
	'button.import_file': 'Importer depuis fichier',
	'button.setup': 'Configurer',
	'button.verify': 'Vérifier',
	'button.select_all': 'Tout Sélectionner',
	'button.unselect_all': 'Tout Déselectionner',
	'button.import_selection': 'Importer Sélection',
	'button.close': 'Fermer',

	// tag buttons
	'button.tag.trigger': 'Gérer les tags',
	'button.tag.clear_list': 'Tout enlever',
	'button.tag.fill_list': 'Tout ajouter',
	'button.tag.create': 'Créer nouveau',

	// subtitle buttons
	'button.subtitle.new': 'Nouveau sous-titre',
	'button.subtitle.autoTranscription': 'Génération automatique',

	'button.new': 'Nouveau',
	'button.tags': 'Catégories',
	'button.settings': 'Paramètres',
	'button.theming': 'Apparence',
	'button.back_to_list': 'Retour à la liste',
	'button.reset': 'Rétablir',
	'button.reset_default': 'Rétablir par défaut',

	'button.add_folder': 'AJOUTER DOSSIER',
}
