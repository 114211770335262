export const fr = {
	'widgets_list.empty_list_title': 'Pas encore de Widgets',
	'widgets_list.empty_list_subtitle': `Vous n'avez pas encore de bannière Stories`,
	'widgets_list.tooltip.get_code': 'Obtenir le code pour intégrer',
	'widgets_list.widget_card.empty_list_stories': `Ce widget n'a pas de story`,

	'widgets_list.widget_card.installed': 'Chargée',
	'widgets_list.widget_card.views': 'Impressions',
	'widgets_list.widget_card.clicks': 'Ouverte',
	'widgets_list.widget_card.of_views': 'de impressions',
	'widgets_list.widget_card.website': 'Site web installé',

	'widgets_list.widget_card.installed_tooltip':
		'Fait référence au nombre de fois que la bannière  a été chargée sur le site web',
	'widgets_list.widget_card.views_tooltip': `La bannière apparaît réellement dans la zone visible de l’écran de l’utilisateur`,
	'widgets_list.widget_card.clicks_tooltip':
		'Un utilisateur clique sur la bannière, indiquant une interaction et un engagement',

	'widgets_list.graph_title.source': 'Données du site web installé',
	'widgets_list.graph_title.stories': 'Données des storys',

	'widgets_list.graph_story.clicks': 'Clics',
	'widgets_list.graph_story.clicks_tooltip': 'Nombre de fois que la carte de la story a été cliquée',
}
