export const en = {
	// Error messages
	'conditions.logic_error.case_1': 'Condtions cannot be empty. Please add a condition.',
	'conditions.logic_error.case_2': 'The condition group cannot be empty. Please add a condition to the group.',
	'conditions.logic_error.case_3': 'Condition value cannot be empty.',
	'conditions.logic_error.case_4': 'The action cannot be empty. Please choose a action.',
	'conditions.logic_error.case_5': 'Variable in action cannot be empty. Please choose a variable.',
	'conditions.logic_error.case_6': 'An increment is missing. Please choose an increment.',
	'conditions.logic_error.case_7': 'The input number cannot be empty. Please enter a value.',
	'conditions.logic_error.case_8': 'Replace value cannot be empty.',
	'conditions.logic_error.case_9': 'Go to page cannot be empty.',

	//
	'conditions.add_logic': 'Add New Logic',
	'conditions.delete_logic': 'Delete this Logic',
	'conditions.clone_logic': 'Duplicate this Logic',
	'conditions.consequence.then': 'Then',
	'conditions.consequence.add_consequence': 'Add new action',

	// Placeholder
	'conditions.consequence.action_selector': 'Select an action',
	'conditions.consequence.increment_selector': 'Select...',
	'conditions.consequence.variable_selector': 'Select a variable',
	'conditions.consequence.input_number': 'Enter a number',
	'conditions.consequence.input_text': 'Enter a value',

	'conditions.increment.input_number': 'Enter a number',

	// Consequense
	'conditions.consequence.conjunction_default': 'to',
	'conditions.consequence.conjunction_add': 'to',
	'conditions.consequence.conjunction_subtract': 'from',
	'conditions.consequence.conjunction_divide': 'by',
	'conditions.consequence.conjunction_multiply': 'by',
	'conditions.consequence.conjunction_power': 'of',
	'conditions.consequence.conjunction_root': 'of',
	'conditions.consequence.conjunction_replace': 'by',

	// Field Groups
	'conditions.field_group.system': 'System',
	'conditions.field_group.variable': 'Variables',
	'conditions.field_group.answers': 'Multiple Choices Page',
	'conditions.field_group.rating': 'NPS/Rating Page',
	'conditions.field_group.media_answer': 'Media Answer Page',
	'conditions.field_group.form': 'Form Page',
	'conditions.field_group.game': 'Wheel Page',

	// Consequence Groups
	'conditions.consequence_group.logic': 'Logic',
	'conditions.consequence_group.text': 'Text',
	'conditions.consequence_group.numerical': 'Calculation',
	'conditions.consequence_group.variable': 'Variables',
	'conditions.consequence_group.number': 'Number',

	// Redirection
	'conditions.consequence.redirect_otherwise.next': `In <b>ALL other cases</b> redirect to <b>the next page</b> by default Settings.`,
	'conditions.consequence.redirect_otherwise.none': `This page has <b>no</b> redirection by default.`,
	'conditions.consequence.redirect_otherwise.toPage': `In <b>ALL other cases</b> redirect to the page: <b>{ pageName }</b> by default Settings.`,

	// Priority logics
	'conditions.priority_logic.title': 'Buttons configured (Priority):',
	'conditions.priority_logic.page_answered': 'If page is answered',
	'conditions.priority_logic.answer': 'If answer is <b>{ answerTitle }</b>',
	'conditions.advance_logic.title': 'Logic configured:',
	'conditions.cta_redirect.title': 'CTA configured (Priority):',
	'conditions.cta_redirect.onClick': 'Click on',
	'conditions.carousels.title': 'Carousel (Priority):',

	// System variables
	'condition.field.system.quizz_score': 'Quiz Score',
	'condition.field.system.today': 'Current Date',
	'condition.field.system.score': 'Quiz Score',
	'condition.field.system.max_score': 'Maximum Quiz Score',
	'condition.field.system.quiz_success': 'The Quiz result',

	// Logic excution order
	'condition.excute.order':
		'Logic is executed <b>sequentially from top to bottom</b>. Logic is executed when users <b>leave</b> the page.',

	// Conditions display
	'condition.field_page.undefined': 'Invalid page',
	'condition.field_field.undefined': 'Undefined form field',
	'condition.field_rating.undefined': 'Undefined rating',
	'condition.field_answer.undefined': 'Invalid answer',

	'condition.answers.page': '<b>Answer</b> of page {pageIndex}. {pageName}',
	'condition.wheel.page': '<b>Prize</b> of page {pageIndex}. {pageName}',
	'condition.media_answer.page': '<b>Answer</b> of page {pageIndex}. {pageName}',
	'condition.form.page': '<b>Answer</b> of {inputName} from form at {pageIndex}: { pageName }',
	'condition.rating.page': '<b>Rating</b> from page {pageIndex}. {pageName}',
	'condition.page.deaulft_redirection': 'By default,',
}
