import DeleteIcon from '@mui/icons-material/Delete'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

// condition header
export const cond_headers = [
	{
		text: <FormattedMessage id='common.cond_header.if' />,
		value: 'if',
	},
	{
		text: <FormattedMessage id='common.cond_header.always' />,
		value: 'always',
	},
]

// Custome combinateur display label
export const combinators = [
	{ name: 'and', label: <FormattedMessage id='common.comb.and' /> },
	{ name: 'or', label: <FormattedMessage id='common.comb.or' /> },
]

export const consequences_options = [
	{
		value: 'logic',
		type: 'group',
		options: [
			{
				value: 'redirect',
				type: 'logic',
			},
		],
	},
	{
		value: 'text',
		type: 'group',
		options: [
			{
				value: 'replace',
				type: 'text',
			},
		],
	},
	{
		value: 'numerical',
		type: 'group',
		options: [
			{
				value: 'add',
				type: 'numerical',
			},
			{
				value: 'subtract',
				type: 'numerical',
			},
			{
				value: 'divide',
				type: 'numerical',
			},
			{
				value: 'multiply',
				type: 'numerical',
			},
			{
				value: 'power',
				type: 'numerical',
			},
			{
				value: 'root',
				type: 'numerical',
			},
		],
	},
]

// Custom operators that we need & their display label
export const operators = [
	{ name: '=', label: <FormattedMessage id='common.ops.equal' /> },
	{ name: '!=', label: <FormattedMessage id='common.ops.different' /> },
	{ name: '<', label: <FormattedMessage id='common.ops.lower' /> },
	{ name: '>', label: <FormattedMessage id='common.ops.greater' /> },
	{ name: '<=', label: <FormattedMessage id='common.ops.lower_equal' /> },
	{ name: '>=', label: <FormattedMessage id='common.ops.greater_equal' /> },
	{ name: 'var_=', label: <FormattedMessage id='common.ops.var_equal' /> },
	{
		name: 'var_!=',
		label: <FormattedMessage id='common.ops.var_different' />,
	},
	{ name: 'var_<', label: <FormattedMessage id='common.ops.var_lower' /> },
	{ name: 'var_>', label: <FormattedMessage id='common.ops.var_greater' /> },
	{
		name: 'var_<=',
		label: <FormattedMessage id='common.ops.var_lower_equal' />,
	},
	{
		name: 'var_>=',
		label: <FormattedMessage id='common.ops.var_greater_equal' />,
	},
	{ name: 'is', label: <FormattedMessage id='common.ops.is' /> },
	{
		name: 'is_nonSen',
		label: <FormattedMessage id='common.ops.is_nonSen' />,
	},
	{ name: 'isNot', label: <FormattedMessage id='common.ops.isNot' /> },
	{
		name: 'isNot_nonSen',
		label: <FormattedMessage id='common.ops.isNot_nonSen' />,
	},
	{ name: 'answer_is', label: <FormattedMessage id='common.ops.answer_is' /> },
	{ name: 'answer_isNot', label: <FormattedMessage id='common.ops.answer_isNot' /> },
	{ name: 'answer_isAnswered', label: <FormattedMessage id='common.ops.answer_isAnswered' /> },
	{ name: 'answer_isNotAnswered', label: <FormattedMessage id='common.ops.answer_isNotAnswered' /> },
	{ name: 'contains', label: <FormattedMessage id='common.ops.contains' /> },
	{
		name: 'contains_nonSen',
		label: <FormattedMessage id='common.ops.contains_nonSen' />,
	},
	{
		name: 'beginsWith',
		label: <FormattedMessage id='common.ops.beginsWith' />,
	},
	{
		name: 'beginsWith_nonSen',
		label: <FormattedMessage id='common.ops.beginsWith_nonSen' />,
	},
	{ name: 'endsWith', label: <FormattedMessage id='common.ops.endsWith' /> },
	{
		name: 'endsWith_nonSen',
		label: <FormattedMessage id='common.ops.endsWith_nonSen' />,
	},
	{ name: 'before', label: <FormattedMessage id='common.ops.before' /> },
	{ name: 'after', label: <FormattedMessage id='common.ops.after' /> },
	{ name: 'sameDay', label: <FormattedMessage id='common.ops.equals' /> },
	{ name: 'is_true', label: <FormattedMessage id='common.ops.is_true' /> },
	{ name: 'is_false', label: <FormattedMessage id='common.ops.is_false' /> },
	{ name: 'null', label: <FormattedMessage id='common.ops.null' /> },
	{ name: 'notNull', label: <FormattedMessage id='common.ops.not_null' /> },
	{
		name: 'quiz_correct',
		label: <FormattedMessage id='common.ops.quiz_correct' />,
	},
	{
		name: 'quiz_incorrect',
		label: <FormattedMessage id='common.ops.quiz_incorrect' />,
	},
	{
		name: 'quiz_partial',
		label: <FormattedMessage id='common.ops.quiz_partial' />,
	},
	{
		name: 'var_greatest',
		label: <FormattedMessage id='common.ops.var_greatest' />,
	},
	{
		name: 'var_smallest',
		label: <FormattedMessage id='common.ops.var_smallest' />,
	},
	{
		name: 'media_is_video',
		label: <FormattedMessage id='common.ops.media_is_video' />,
	},
	{
		name: 'media_is_audio',
		label: <FormattedMessage id='common.ops.media_is_audio' />,
	},
	{
		name: 'media_is_text',
		label: <FormattedMessage id='common.ops.media_is_text' />,
	},
	{
		name: 'media_is_answered',
		label: <FormattedMessage id='common.ops.media_is_answered' />,
	},
	{
		name: 'media_is_null',
		label: <FormattedMessage id='common.ops.media_is_null' />,
	},
	{
		name: 'wheel_is',
		label: <FormattedMessage id='common.ops.wheel_is' />,
	},
	{
		name: 'wheel_isNot',
		label: <FormattedMessage id='common.ops.wheel_isNot' />,
	},
	{
		name: 'wheel_win',
		label: <FormattedMessage id='common.ops.wheel_win' />,
	},
	{
		name: 'wheel_lose',
		label: <FormattedMessage id='common.ops.wheel_lose' />,
	},
	{
		name: 'wheel_played',
		label: <FormattedMessage id='common.ops.wheel_played' />,
	},
	{
		name: 'wheel_notPlayed',
		label: <FormattedMessage id='common.ops.wheel_notPlayed' />,
	},
	{
		name: 'is_quiz_true',
		label: <FormattedMessage id='common.ops.is_quiz_true' />,
	},
	{
		name: 'is_quiz_false',
		label: <FormattedMessage id='common.ops.is_quiz_false' />,
	},
]

export function getValidOptions(conditionalFields, field, draftStory) {
	const fieldData = _.find(conditionalFields, ['name', field])

	switch (fieldData?.inputType) {
		case 'system':
			if (fieldData.valueEditorType === 'number') {
				return _.filter(operators, (option) =>
					_.includes(
						['=', '!=', '<', '>', '<=', '>=', 'var_=', 'var_!=', 'var_<', 'var_>', 'var_<=', 'var_>='],
						option.name
					)
				)
			} else if (fieldData.valueEditorType === 'date') {
				return _.filter(operators, (option) => _.includes(['sameDay', 'before', 'after'], option.name))
			} else if (fieldData.valueEditorType === 'select') {
				return _.filter(operators, (option) => _.includes(['is', 'isNot', 'null', 'notNull'], option.name))
			} else if (fieldData.valueEditorType === 'boolean') {
				return _.filter(operators, (option) => _.includes(['is_quiz_true', 'is_quiz_false'], option.name))
			}
			return _.filter(operators, (option) =>
				_.includes(
					[
						'is',
						'isNot',
						'is_nonSen',
						'isNot_nonSen',
						'contains',
						'beginsWith',
						'endsWith',
						'contains_nonSen',
						'beginsWith_nonSen',
						'endsWith_nonSen',
						'null',
						'notNull',
					],
					option.name
				)
			)
		case 'variable':
			if (fieldData.valueEditorType === 'number') {
				if (!_.isEmpty(fieldData.values)) {
					return _.filter(operators, (option) =>
						_.includes(
							[
								'=',
								'!=',
								'<',
								'>',
								'<=',
								'>=',
								'var_=',
								'var_!=',
								'var_<',
								'var_>',
								'var_<=',
								'var_>=',
								'var_greatest',
								'var_smallest',
							],
							option.name
						)
					)
				}
				return _.filter(operators, (option) => _.includes(['=', '!=', '<', '>', '<=', '>='], option.name))
			} else if (fieldData.valueEditorType === 'date') {
				return _.filter(operators, (option) => _.includes(['sameDay', 'before', 'after'], option.name))
			} else if (fieldData.valueEditorType === 'boolean') {
				return _.filter(operators, (option) => _.includes(['is_true', 'is_false'], option.name))
			}
			return _.filter(operators, (option) =>
				_.includes(
					[
						'is',
						'isNot',
						'is_nonSen',
						'isNot_nonSen',
						'contains',
						'beginsWith',
						'endsWith',
						'contains_nonSen',
						'beginsWith_nonSen',
						'endsWith_nonSen',
						'null',
						'notNull',
					],
					option.name
				)
			)
		case 'answers': {
			if (draftStory.type === 'classic') {
				const page_id = fieldData.name.split('.')[1]
				const currentPage = _.find(draftStory.pages, ['_id', page_id])

				if (currentPage?.answers_properties?.count_in_results) {
					if (currentPage?.answers_properties?.partial_correct) {
						return _.filter(operators, (option) =>
							_.includes(
								[
									'answer_is',
									'answer_isNot',
									'quiz_correct',
									'quiz_incorrect',
									'quiz_partial',
									'answer_isAnswered',
									'answer_isNotAnswered',
								],
								option.name
							)
						)
					} else {
						return _.filter(operators, (option) =>
							_.includes(
								[
									'answer_is',
									'answer_isNot',
									'quiz_correct',
									'quiz_incorrect',
									'answer_isAnswered',
									'answer_isNotAnswered',
								],
								option.name
							)
						)
					}
				}
			}
			return _.filter(operators, (option) =>
				_.includes(['answer_is', 'answer_isNot', 'answer_isAnswered', 'answer_isNotAnswered'], option.name)
			)
		}
		case 'rating':
			if (fieldData.valueEditorType === 'number') {
				return _.filter(operators, (option) => _.includes(['=', '!=', '<', '>', '<=', '>='], option.name))
			}
			return _.filter(operators, (option) => _.includes(['is', 'isNot'], option.name))
		case 'media_answer':
			return _.filter(operators, (option) =>
				_.includes(
					['media_is_video', 'media_is_audio', 'media_is_text', 'media_is_answered', 'media_is_null'],
					option.name
				)
			)
		case 'game':
			return _.filter(operators, (option) =>
				_.includes(
					['wheel_is', 'wheel_isNot', 'wheel_win', 'wheel_lose', 'wheel_played', 'wheel_notPlayed'],
					option.name
				)
			)
		case 'form':
			if (fieldData.valueEditorType === 'number') {
				return _.filter(operators, (option) => _.includes(['=', '!=', '<', '>', '<=', '>='], option.name))
			} else if (fieldData.valueEditorType === 'date') {
				return _.filter(operators, (option) => _.includes(['sameDay', 'before', 'after'], option.name))
			} else if (fieldData.valueEditorType === 'select') {
				return _.filter(operators, (option) => _.includes(['is', 'isNot', 'null', 'notNull'], option.name))
			} else if (fieldData.valueEditorType === 'boolean') {
				return _.filter(operators, (option) => _.includes(['is_true', 'is_false'], option.name))
			}
			return _.filter(operators, (option) =>
				_.includes(
					[
						'is',
						'isNot',
						'is_nonSen',
						'isNot_nonSen',
						'contains',
						'beginsWith',
						'endsWith',
						'contains_nonSen',
						'beginsWith_nonSen',
						'endsWith_nonSen',
						'null',
						'notNull',
					],
					option.name
				)
			)
		default:
			return operators
	}
}

export const translations = {
	fields: {
		title: <FormattedMessage id='modals.condition.translations.fields.title' />,
	},
	operators: {
		title: <FormattedMessage id='modals.condition.translations.operators.title' />,
	},
	value: {
		title: <FormattedMessage id='modals.condition.translations.value.title' />,
	},
	removeRule: {
		label: <DeleteIcon />,
		title: <FormattedMessage id='modals.condition.translations.removeRule.title' />,
	},
	removeGroup: {
		label: <DeleteIcon />,
		title: <FormattedMessage id='modals.condition.translations.removeGroup.title' />,
	},
	addRule: {
		label: <FormattedMessage id='modals.condition.translations.addRule.label' />,
		title: <FormattedMessage id='modals.condition.translations.addRule.title' />,
	},
	addGroup: {
		label: <FormattedMessage id='modals.condition.translations.addGroup.label' />,
		title: <FormattedMessage id='modals.condition.translations.addGroup.title' />,
	},
	combinators: {
		title: <FormattedMessage id='modals.condition.translations.combinators.title' />,
	},
	notToggle: {
		label: <FormattedMessage id='modals.condition.translations.notToggle.label' />,
		title: <FormattedMessage id='modals.condition.translations.notToggle.title' />,
	},
	cloneRule: {
		label: <FormattedMessage id='modals.condition.translations.cloneRule.label' />,
		title: <FormattedMessage id='modals.condition.translations.cloneRule.title' />,
	},
	cloneRuleGroup: {
		label: <FormattedMessage id='modals.condition.translations.cloneRuleGroup.label' />,
		title: <FormattedMessage id='modals.condition.translations.cloneRuleGroup.title' />,
	},
}

// Recursive function to validate query
// -> return 0 if OK
// -> return 2 if rules group is empty
// -> return 3 if rule value is empty (unless operator null or notNull)
export function validateQuery(rules) {
	let result = 0

	for (const rule of rules) {
		if (rule.rules && !_.isEmpty(rule.rules)) {
			result = validateQuery(rule.rules)
		} else if (rule.rules && _.isEmpty(rule.rules)) {
			result = 2
			break
		} else if (
			rule.operator &&
			!_.includes(
				[
					'null',
					'notNull',
					'is_true',
					'is_false',
					'quiz_correct',
					'quiz_incorrect',
					'quiz_partial',
					'media_is_video',
					'media_is_audio',
					'media_is_text',
					'media_is_answered',
					'media_is_null',
					'answer_isAnswered',
					'answer_isNotAnswered',
					'wheel_win',
					'wheel_lose',
					'wheel_played',
					'wheel_notPlayed',
					'is_quiz_true',
					'is_quiz_false',
				],
				rule.operator
			) &&
			_.isEmpty(rule.value)
		) {
			result = 3
			break
		}
	}

	return result
}

// Recursive function to validate consequense
// -> return 0 if OK
// -> return 4 if consequence is empty
// -> return 5 if consequence missing variable
// -> return 6 if consequence missing increment
// -> return 7 if consequence numerical type inputNumber missing incrementValue
// -> return 8 if consequence replace missing incrementValue

export function validateConsequence(consequence) {
	if (_.isEmpty(consequence?.condition)) {
		return 4
	} else {
		if (_.includes(['numerical', 'text'], consequence?.condition?.type) && _.isEmpty(consequence.variable)) {
			return 5
		}

		if (consequence?.condition?.type === 'numerical') {
			if (_.isEmpty(consequence?.increment)) {
				return 6
			} else if (consequence.increment.type === 'number') {
				if (_.isEmpty(consequence.incrementValue)) {
					return 7
				}
			}
		} else if (consequence?.condition?.type === 'text') {
			if (_.isEmpty(consequence.incrementValue)) {
				return 8
			}
		}
	}

	return 0
}

// Function to validate logic statement
//	--> return 1 if condition 'If' but conditions is empty
export function validateLogic(logic) {
	if (logic.cond_header === 'if') {
		if (_.isEmpty(logic?.conditions?.rules)) {
			return 1
		}

		const queryStatus = validateQuery(logic?.conditions?.rules)
		if (queryStatus > 0) {
			return queryStatus
		} else {
			for (const consequence of logic.consequences) {
				const consequenceStatus = validateConsequence(consequence)
				if (consequenceStatus > 0) {
					return consequenceStatus
				}
			}
		}
	} else if (logic.cond_header === 'always') {
		for (const consequence of logic.consequences) {
			const consequenceStatus = validateConsequence(consequence)
			if (consequenceStatus > 0) {
				return consequenceStatus
			}
		}
	}

	return 0
}
