import { createSlice } from '@reduxjs/toolkit'
import { loginFail, logoutSuccess } from 'redux/customer/slice'

const initialState = {
	_id: null,
	name: null,
	logo: null,
	members: [],
	invitations: [],
}

export const slice = createSlice({
	name: 'organization',
	initialState,
	reducers: {
		updateOrganization(state, action) {
			// Completely override organization
			const organization = action.payload
			if (organization) {
				return organization
			}
			return state
		},
		resetOrganization() {
			return initialState
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(logoutSuccess, (state, action) => {
				return initialState
			})
			.addCase(loginFail, (state, action) => {
				return initialState
			})
	},
})

export const { updateOrganization, resetOrganization } = slice.actions

export default slice.reducer
