export const fr = {
	'page_title.login': 'Connexion | Snackeet',
	'page_title.password_reset': 'Réinitaliser mot de passe | Snackeet',
	'page_title.activation': 'Activation | Snackeet',
	'page_title.register': 'Inscription | Snackeet',
	'page_title.mobile': 'Snackeet',

	'page_title.account': 'Account | Snackeet',
	'page_title.organization_settings': 'Organization | Snackeet',
	'page_title.analytics': 'Analyses | Snackeet',

	'page_title.editor': 'Editeur | Snackeet',
	'page_title.widget_editor': 'Editeur Widget | Snackeet',
	'page_title.brand_editor': 'Editeur Marque | Snackeet',
	'page_title.leaderboard_editor': 'Editeur Classement | Snackeet',

	'page_title.brands_list': 'Brands | Snackeet',
	'page_title.widgets_list': 'Widgets | Snackeet',
	'page_title.stories_list': 'Stories | Snackeet',
	'page_title.workspaces_list': 'Espaces | Snackeet',
	'page_title.leaderboards_list': 'Classements | Snackeet',
	'page_title.domains_list': 'Domaines | Snackeet',

	'page_title.404': 'Page Non Trouvée | Snackeet',
	'404.main': 'Page Non Trouvée',
	'404.button': `Aller à l'accueil`,
}
