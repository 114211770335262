import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import some from 'lodash/some'

export const getContextMenuSlice = (state) => ({
	contextMenu: state.contextMenu,
	elementsToCopy: state.elementsToCopy,
	selectedElement: state.selectedElement,
	copiedTextStyles: state.copiedTextStyles,
})

export const getPublisherSlice = (state) => ({
	language: state.draftStory?.story_settings?.language,
	publisher: state.draftStory?.story_settings?.publisher,
	name: state.draftStory?.name,
	title: state.draftStory?.metadata?.title,
})

export const getEditZoneSlice = (state) => ({
	storyId: state.draftStory.story_id,
	storyType: state.draftStory.type,
	page: state.page,
	pageIndex: findIndex(state.draftStory.pages, { _id: state.page._id }),
	isBackgroundActive: state.isBackgroundActive,
	isDisplayShareDrawer: state.isDisplayShareDrawer,
	isTextEditing: state.textEditionState?.isTextEditing,
	selectedElement: state.selectedElement,
	isDisplayElementsDrawer: state.isDisplayElementsDrawer,
})

export const getLayersSlice = (state) => ({
	draftStory: state.draftStory,
	page: state.page,
	selectedElement: state.selectedElement,
	isLayersSectionActive: state.isLayersSectionActive,
})

export const getExpirationSettingsSlice = (state) => ({
	language: state.draftStory?.story_settings?.language,
	expiration: state.draftStory?.story_settings?.expiration,
	variables: state.draftStory?.variables,
	recently_used: state.draftStory?.recently_used,
	brand_colors: state.draftStory?.brand_colors,
	brand_fontFamily: state.draftStory?.brand_fontFamily,
})

export const getNavigationSettingsSlice = (state) => ({
	messages: state.draftStory?.messages,
	display_navigation_buttons: state.draftStory?.story_settings?.display_navigation_buttons,
	navigation_method: state.draftStory?.story_settings?.navigation_method,
})

export const getCurrentPageErrors = (state) => find(state.storyFeedback.storyErrors, { _id: state.page._id })?.errors

export const getBlockCtaErrorStatus = (blockId) => (state) => {
	const pageErrors = find(state.storyFeedback.storyErrors, {
		_id: state.page._id,
	})?.errors

	return some(pageErrors, (error) => error.element_id === blockId && error.message_id.includes('block.cta'))
}

export const getBlockApiTemplateErrorStatus = (blockId) => (state) => {
	const pageErrors = find(state.storyFeedback.storyErrors, {
		_id: state.page._id,
	})?.errors

	return some(pageErrors, (error) => error.element_id === blockId && error.message_id.includes('block.api_template'))
}

export const getCurrentPageRecommendations = (pageId) => (state) =>
	find(state.storyFeedback.storyErrors, { _id: pageId })?.errors

export const getBackgroundMenuSlice = (state) => ({
	recently_used: state.draftStory.recently_used,
	storyType: state.draftStory.type,
	brand_colors: state.draftStory.brand_colors,
	storyType: state.draftStory.type,
})

export const getPageIndex = (_id) => (state) => findIndex(state.draftStory.pages, { _id })

export function hasQuizPage(state) {
	return (
		state.draftStory?.type === 'classic' &&
		some(
			state.draftStory?.pages,
			(page) =>
				page.type === 'multiple_choice' &&
				page.answers_properties.type === 'answer' &&
				page.answers_properties.count_in_results
		)
	)
}

export function hasSurveyPage(state) {
	return (
		state.draftStory?.type === 'classic' &&
		some(
			state.draftStory?.pages,
			(page) =>
				page.type === 'multiple_choice' &&
				page.answers_properties.type === 'answer' &&
				page.answers_properties.count_response_percentage
		)
	)
}

export function hasPageWithResults(state) {
	return (
		state.draftStory?.type === 'classic' &&
		some(
			state.draftStory?.pages,
			(page) =>
				page.type === 'multiple_choice' &&
				page.answers_properties.type === 'answer' &&
				(page.answers_properties.count_in_results || page.answers_properties.count_response_percentage)
		)
	)
}
