import _ from 'lodash'
import * as store from 'store2'

export const JWT_TOKEN = 'JWT_TOKEN'
export const CURRENT_COLORS = 'CURRENT_COLORS'
export const ELEMENTS_TO_COPY = 'ELEMENTS_TO_COPY'
export const LAST_FONT = 'LAST_FONT'

store.remove('currentOrganization')

export function getLocalToken() {
	const token = store.get(JWT_TOKEN)
	return token
}

export function resetLocalToken() {
	store.remove(JWT_TOKEN)
}

export function setLocalToken(token) {
	if (token) {
		store.set(JWT_TOKEN, token)
	}
}

export function isLoggedIn() {
	return !_.isNil(getLocalToken())
}

export const elementsToCopyStorage = {
	set(elementsToCopy) {
		if (!elementsToCopy) return
		store.set(ELEMENTS_TO_COPY, elementsToCopy)
	},

	get() {
		return store.get(ELEMENTS_TO_COPY)
	},

	reset() {
		store.remove(ELEMENTS_TO_COPY)
	},
}
