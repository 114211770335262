export const fr = {
	'layout.sidebar.login': 'Connexion',
	'layout.sidebar.register': 'Création de compte',
	'layout.sidebar.password_reset': 'Réinitialiser le mot de passe',
	'layout.sidebar.workspaces': 'Mes Espaces',
	'layout.sidebar.settings': 'Paramètres',
	'layout.sidebar.organization_current.title': 'Organisation Actuelle',
	'layout.sidebar.organization_current.description': 'Gérer votre organisation actuelle',
	'layout.sidebar.organizations_list.title': 'Toutes les organisations',
	'layout.sidebar.organizations_list.description': `Accédez à la liste de vos organisations`,
	'layout.sidebar.account.title': 'Mon compte',
	'layout.sidebar.account.description': 'Modifier le profil de votre compte',
	'layout.sidebar.teams': 'Équipes',
	'layout.sidebar.not_found': '404 - Non trouvé',
	'layout.sidebar.stories': 'Stories',
	'layout.sidebar.widgets': 'Widgets Bannière',
	'layout.sidebar.interactions': 'Utilisateurs',
	'layout.sidebar.brands': 'Votre Marque',
	'layout.sidebar.leaderboards': 'Classements',
	'layout.sidebar.leaderboards.warning': 'Veuillez mettre à niveau votre plan pour activer cette fonctionnalité',
	'layout.sidebar.warning': 'Votre abonnement a pris fin, veuillez le renouveler pour accéder à la plateforme.',
	'layout.sidebar.subscribe': `S'abonner`,
	'layout.sidebar.warning.users_per_month': `Vous avez atteint votre limite de réponses de ce mois. Vous avez maintenant un accès limité à votre contenu.`,
	'layout.sidebar.warning.in_trial': `Votre organisation est en période d'essai. Améliorez votre plan pour plus de fonctionnalités`,
	'layout.sidebar.upgrade': 'Changer de plan',

	'layout.navbar.config': 'Éditer',
	'layout.navbar.sharing': 'Partager',
	'layout.navbar.settings': 'Paramétrer',
	'layout.navbar.stats': 'Stats',
	'layout.navbar.integrations': 'Connecter',
	'layout.navbar.content': 'Texte par défaut',
	'layout.navbar.appearance': 'Style par défaut',
	'layout.navbar.brands': 'Marques',
	'layout.navbar.fonts': 'Polices',
	'layout.navbar.responses': 'Réponses',
	'layout.navbar.graph': `Stats : Vue d'ensemble`,
	'layout.navbar.stories': 'Stories',
}
