export const fr = {
	'toast.success.update': 'Mis à jour',
	'toast.success.update.project': 'Workspace mis à jour',

	'toast.success.creation.project': 'Workspace créé',
	'toast.success.creation.snackeet': 'Story créée',
	'toast.success.creation.widget': 'Widget créé',
	'toast.success.creation.brand': 'Widget créé',
	'toast.success.creation.leaderboard': 'Leaderboard créé',
	'toast.success.creation.apiKey': 'Clé Api créée',
	'toast.success.creation.invitation': 'Invitation envoyée',
	'toast.success.creation.storyFolder': 'Dossier créé',
	'toast.success.creation.domain': 'Domaine créé',

	'toast.success.deletion.project': 'Workspace supprimé',
	'toast.success.deletion.snackeet': 'Story supprimée',
	'toast.success.deletion.widget': 'Widget supprimé',
	'toast.success.deletion.brand': 'Marque supprimée',
	'toast.success.deletion.leaderboard': 'Leaderboard supprimée',
	'toast.success.deletion.fonts': '{ count, plural, one { Police supprimée } other { Polices supprimées } }',
	'toast.success.deletion.apiKey': 'Api Key supprimée',
	'toast.success.deletion.invitation': 'Invitation supprimée',
	'toast.success.deletion.domain': 'Domaine supprimé',

	'toast.success.duplication.snackeet': 'Story dupliquée',
	'toast.success.duplication.widget': 'Widget dupliqué',
	'toast.success.change_saving': 'Changements sauvegardés',
	'toast.success.upload_file': '{ count, plural, =1 { Fichier importé } other { Fichiers importés } }',
	'toast.success.upload_fonts': 'Polices importées',
	'toast.success.export': 'Fichier exporté',
	'toast.success.delete_all_users': 'Utilisateurs supprimés',
	'toast.success.update.apiKey': 'Api Key sauvegardée',
	'toast.success.leave_organization': `Quitté l'organization`,
	'toast.success.kick_member': `Le membre a été expulsé`,
	'toast.success.update_member': `Changements appliqués`,
	'toast.success.update.domain': `Paramètre du domaine mis à jour`,

	'toast.error': 'Une erreur est survenue',
	'toast.error.fetching': 'Une erreur est survenue lors de la récupération des données',
	'toast.error.change_saving': `Une erreur est survenue lors de la sauvegarde`,
	'toast.error.creation': 'Une erreur est survenue lors de la création',
	'toast.error.creation.storyFolder': `Une erreur est survenue lors de la création du dossier`,
	'toast.error.deletion': 'Une erreur est survenue lors de la suppression',
	'toast.error.update.project': 'Une erreur est survenue lors de la mise à jour',
	'toast.error.duplication.snackeet': 'Une erreur est survenue lors de la reproduction',
	'toast.error.upload_file': `Une erreur est survenue lors de l'import`,
	'toast.error.upload_file.invalid_files': `{ count, plural, =1 { Ce fichier ne peut pas être importé } other { Ces fichiers ne peuvent pas être importés } }`,
	'toast.error.upload_fonts.too_large': `Désolé, vous ne pouvez pas charger plus de 2 Mo de fichiers polices en une seule fois.<br></br>Veuillez réessayer avec un plus petit nombre de polices.`,
	'toast.error.export': `Une erreur est survenue lors de l'export`,
	'toast.error.apiKey': `Une erreur est survenue lors de l'opération`,
	'toast.error.leave_organization': `Une erreur est survenue lors du départ de l'organization`,
	'toast.error.update.domain': 'Une erreur est survenue lors de la mise à jour du domaine',

	'toast.info.uploading': 'Téléchargement et compression de fichiers en cours...',
	'toast.info.uploading_fonts': 'Téléchargement des polices en cours...',
}
