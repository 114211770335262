export const fr = {
	'widget_editor.header.button.go_back': 'Revenir à la liste',

	'widget_editor.settings.title': 'Paramètres du Widget',
	'widget_editor.tabs.placement': 'Où doit-il être placé ?',

	'widget_editor.name.placeholder': 'Nom du Widget',
	'widget_editor.status.title': 'État',
	'widget_editor.status.active': 'Widget actif',
	'widget_editor.status.disabled': 'Widget désactivé',
	'widget_editor.dynamic_alias.placeholder': 'Alias dynamique',
	'widget_editor.dynamic_alias.tooltip': `Attribuez un nom unique et mémorable à votre web story pour faciliter son intégration et son partage, sans utiliser l'identifiant.<br></br>Une façon plus simple de référencer vos bannières !`,

	'widget_editor.appearance.title.story_text': 'Texte des bulles',
	'widget_editor.appearance.title.story_border': 'Bordure des bulles',
	'widget_editor.appearance.title.story_bubble_size': 'Taille des bulles',
	'widget_editor.appearance.title.story_card_size': 'Taille des cartes',
	'widget_editor.appearance.title.story_card_border_radius': 'Arrondi des cartes',
	'widget_editor.appearance.title.story_caption_fontSize': 'Taille de légende',
	'widget_editor.appearance.title.banner_margins': 'Espacements',

	'widget_editor.appearance.banner.color': 'Couleur du fond',
	'widget_editor.appearance.banner.styles': 'Style de la bannière',
	'widget_editor.appearance.banner.has_play_icon': 'Afficher bouton play',
	'widget_editor.appearance.banner.has_play_icon.tooltip':
		'Affichez une icône "play" dans chaque cercle pour augmenter le taux de clics',
	'widget_editor.appearance.banner.has_attribution': `Afficher le nom et le logo en haut à gauche`,
	'widget_editor.appearance.banner.has_attribution.tooltip': `Pour plus de clarté, affichez le nom et le logo d'une story quand elle est ouverte.`,
	'widget_editor.appearance.banner.has_flex_wrap': 'Multiligne',
	'widget_editor.appearance.banner.has_flex_wrap.tooltip':
		'Au lieu de devoir faire défiler les articles, vous pouvez les afficher en une seule fois sur plusieurs lignes.',
	'widget_editor.appearance.story_text.color': 'Couleur',
	'widget_editor.appearance.story_text.fontFamily': 'Police',
	'widget_editor.appearance.story_border.start_color': 'Couleur initiale du gradient',
	'widget_editor.appearance.story_border.end_color': 'Couleur finale du gradient',
	'widget_editor.appearance.story_border.radius': 'Arrondis',
	'widget_editor.appearance.card_shadow': 'Ombre des cartes',
	'widget_editor.appearance.card_shadow.tooltip': `Afficher un effet d'ombre au niveau des cartes`,

	'widget_editor.appearance.margin.top': 'Espace en haut',
	'widget_editor.appearance.margin.right': 'Espace à droite',
	'widget_editor.appearance.margin.bottom': 'Espace en bas',
	'widget_editor.appearance.margin.left': 'Espace à gauche',

	'widget_editor.animation.label.border': `Animation de la bordure`,
	'widget_editor.animation.name.none': `Aucune`,
	'widget_editor.animation.name.chain_pulse': `Pulsation à la chaine`,
	'widget_editor.animation.name.continuous_pulse': `Pulsation continue`,

	'widget_editor.placement.options.inline': 'Inline',
	'widget_editor.placement.options.custom': 'Ciblé',
	'widget_editor.placement.custom_target.label': `L'ID de votre div`,

	'widget_editor.placement.position.before': 'Avant cet emplacement',
	'widget_editor.placement.position.inside': 'En haut de cet emplacement',
	'widget_editor.placement.position.bottom': 'En bas de cet emplacement',
	'widget_editor.placement.position.after': 'Après cet emplacement',
	'widget_editor.placement.position.replace': `Dans l'emplacement (remplacez-le)`,

	'widget_editor.stories.button.change_design': 'Changer le design',
	'widget_editor.stories.button.add': 'Ajouter des stories',
	'widget_editor.stories.alert.story_disabled': 'La story est désactivée et ne sera pas affichée dans la bannière',

	'widget_editor.whitelist.title': `Où doit-il apparaître ?`,
	'widget_editor.whitelist.radio.all_pages': 'Toutes les pages',
	'widget_editor.whitelist.radio.some_pages': 'Certaines pages',
	'widget_editor.blacklist.button.add': 'Ajouter des exceptions',
	'widget_editor.blacklist.button.remove': 'Supprimer toutes les exceptions',

	'widget_editor.loader_image.button': 'Image de chargement',
	'widget_editor.loader_image.description': `Lors de l'ouverture du widget, cette image est affichée pendant que la story se charge.`,

	'widget_editor.floating_widget.active_button': 'Activer le widget flottant',
	'widget_editor.floating_widget.active_header': 'Intégrez la story sur votre site web avec le widget flottant',
}
