import useHotJarIdentification from './useHotJarIdentification'
import useIntercomIdentification from './useIntercomIdentification'
import useSentryIdentification from './useSentryIdentification'
import useUsetifulIdentification from './useUsetifulIdentification'

export default function useThirdPartyIdentification() {
	useIntercomIdentification()
	useHotJarIdentification()
	useSentryIdentification()
	useUsetifulIdentification()
}
