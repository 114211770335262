import isEqual from 'fast-deep-equal'
import { DEFAULT_APP_DELAY, RatingList, SHORT_SNACKEET_URL } from 'lib/constants'
import dayjs from 'lib/dayjs'
import { hasQuizPage } from 'lib/editorStore/selectors'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import isURL from 'validator/lib/isURL'

export * from './arrayUtils'
export * from './fontUtils'
export * from './LogicUtils'
export * from './mediaUtils'
export * from './quizUtils'
export * from './selectionUtils'
export * from './variablesUtils'
export * from './videoUtils'

export const sleep = (delay = DEFAULT_APP_DELAY) => new Promise((resolve) => setTimeout(resolve, delay))

export function updateUrl({ parameters, parametersToDelete }) {
	const url = new URL(location.href)
	_.each(parameters, (value, name) => {
		url.searchParams.set(name, value)
	})
	_.each(parametersToDelete, (name) => {
		url.searchParams.delete(name)
	})
	window.history.replaceState(window.history.state, null, url.href)
}

export function getUrlParameters(parameters = []) {
	parameters = _.castArray(parameters)
	const url = new URL(location.href)
	return _.reduce(
		parameters,
		(results, name) => {
			const value = url.searchParams.get(name)
			return value ? { ...results, [name]: value } : results
		},
		{}
	)
}

export function displayTime(timeInSeconds) {
	if (timeInSeconds < 60) {
		return `${timeInSeconds.toFixed(1)}`
	}

	const hours = _.floor(timeInSeconds / 3600)
	const minutes = _.floor((timeInSeconds % 3600) / 60)
	const seconds = (timeInSeconds % 3600) % 60

	if (hours === 0) {
		return `${minutes} : ${seconds.toFixed(1)}`
	}

	return `${hours} : ${minutes} : ${seconds.toFixed(1)}`
}

export function extractBlockAppearanceStyle(outer_style) {
	return _.omit(
		outer_style,
		['transform', 'width', 'height', 'top', 'left', 'display']
		// ['transform', 'width', 'top', 'left']
	)
}

export function convert2csv(items) {
	if (_.isEmpty(items)) return ''

	const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here

	const header = Object.keys(items[0])

	const csv = [
		header.join(','), // header row first
		...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')),
	].join('\r\n')

	return csv
}

export function extractFileName(contentDispositionValue) {
	let filename = ''
	if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		const matches = filenameRegex.exec(contentDispositionValue)
		if (matches && matches[1]) {
			filename = matches[1].replace(/['"]/g, '')
		}
	}
	return filename
}

export function getInputSize(text) {
	const length = text.length
	if (length < 7) return length || 1
	return length + 1
}

export function isUrl(url = '', prefix = 'common') {
	const result = {
		success: false,
		messageId: null,
	}

	if (!isURL(url)) {
		result.messageId = `${prefix}.url.not_valid`
	} else if (isURL(url, { protocols: ['http'] })) {
		result.messageId = `${prefix}.url.unsecure`
	} else {
		result.success = true
	}
	return result
}

export function isValidLocationEmbed(str) {
	const regex1 = /<iframe\s*src="https:\/\/www\.google\.com\/maps\/embed\?[^"]+"*\s*[^>]+>*<\/iframe>/gm
	const regex2 = /https:\/\/www\.google\.com\/maps\/embed\?[^"]+/gm

	if (str.match(regex1)) {
		return 'regex 1'
	} else if (str.match(regex2)) {
		return 'regex 2'
	}

	return false
}

export function extractMapSrc(str) {
	return str.split('src=')[1].split('><')[0].split('"')[1]
}

export function isValidCalendarUrl(str) {
	// const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
	// '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
	// '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
	// '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
	// '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
	// '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

	const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g // Use this regex to match with timetap link

	if (!regex.test(str)) {
		return false
	}

	// Temporary allow all hosts
	try {
		const url = new URL(str)
		const host = url.hostname
		// return _.some(CALENDAR_BRANDS, brand => _.includes(host, brand.host))

		return true
	} catch (err) {
		return false
	}
}

export function getTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone
}

// Get suitable fields & datas for conditions settings
export function extractConditionalFields(draftStory, currentPage, countCurrentPage = true) {
	const currentPageIndex = _.findIndex(draftStory.pages, { _id: currentPage._id })

	if (currentPageIndex >= 0) {
		const results = [
			{
				name: 'system.today',
				label: 'Current Date',
				valueEditorType: 'date',
				inputType: 'system',
			},
		]
		const pagesToExtract = countCurrentPage
			? _.cloneDeep(draftStory.pages).slice(0, currentPageIndex + 1)
			: _.cloneDeep(draftStory.pages).slice(0, currentPageIndex)

		if (hasQuizPage({ draftStory })) {
			results.push(
				{
					name: 'system.score',
					label: 'Quiz Score',
					valueEditorType: 'number',
					inputType: 'system',
					values: _.map(draftStory.variables, (v) => ({
						name: v.name,
						label: v.name,
					})),
				},
				{
					name: 'system.max_score',
					label: 'Quiz Max Score',
					valueEditorType: 'number',
					inputType: 'system',
					values: _.map(draftStory.variables, (v) => ({
						name: v.name,
						label: v.name,
					})),
				},
				{
					name: 'system.quiz_success',
					label: 'The quiz successed',
					valueEditorType: 'boolean',
					inputType: 'system',
				}
			)
		}

		// Extract from pages
		for (const page of pagesToExtract) {
			if (page.type === 'multiple_choice' && page.answers_properties.type === 'answer') {
				results.push({
					name: `answers.${page._id}`,
					label: page.name,
					valueEditorType: 'select',
					inputType: 'answers',
					values: _.map(page.answers, (answer) => ({
						name: answer._id,
						label: answer.title,
					})),
				})
			} else if (page.type === 'game') {
				const wheelInteraction = page.interactions[0]

				results.push({
					name: `wheel.${page._id}`,
					label: page.name,
					valueEditorType: 'select',
					inputType: 'game',
					values: _.map(wheelInteraction.components.wheel.properties.prizes, (prize) => ({
						name: prize._id,
						label: prize.option,
					})),
				})
			} else if (page.type === 'form') {
				const formInteraction = page.interactions[0]

				_.forEach(formInteraction.fields, (field) => {
					results.push({
						name: `form.${page._id}.${field._id}`,
						label: `${field.title || field.placeholder} - page: ${page.name}`,
						inputType: 'form',
						...getConditionFromField(field),
					})
				})
			} else if (page.type === 'rating') {
				const ratingInteraction = page.interactions[0]

				if (ratingInteraction.category.startsWith('classic')) {
					results.push({
						name: `rating.${page._id}`,
						label: page.name,
						valueEditorType: 'number',
						inputType: 'rating',
					})
				} else {
					results.push({
						name: `rating.${page._id}`,
						label: page.name,
						valueEditorType: 'select',
						inputType: 'rating',
						values: _.map(
							_.find(RatingList, (rating) => rating.category === ratingInteraction.category)?.imgs,
							(img) => ({
								name: img.name,
								label: img.name,
							})
						),
					})
				}
			} else if (page.type === 'media_answer') {
				results.push({
					name: `media_answer.${page._id}`,
					label: page.name,
					valueEditorType: 'text',
					inputType: 'media_answer',
				})
			}
		}

		// Extract from variables
		_.forEach(draftStory.variables, (variable) => {
			if (variable.name === '_username') {
				results.push({
					name: `variable.${variable.name}`,
					label: 'leaderboard _username',
					valueEditorType: variable.type,
					inputType: 'system',
					values: _.map(
						_.filter(draftStory.variables, (vr) => vr.name !== variable.name && vr.type === variable.type),
						(v) => ({
							name: v.name,
							label: v.name,
						})
					),
				})
			} else if (variable.name === '_level') {
				results.push({
					name: `variable.${variable.name}`,
					label: 'leaderboard _level',
					inputType: 'system',
					...getLeaderBoardLevelOptions({
						trackingDatas: variable?.trackingDatas,
						pages: draftStory.pages,
					}),
				})
			} else if (variable.type === 'number') {
				results.push({
					name: `variable.${variable.name}`,
					label: variable.name,
					valueEditorType: variable.type,
					inputType: 'variable',
					values: _.map(
						_.filter(draftStory.variables, (vr) => vr.name !== variable.name && vr.type === variable.type),
						(v) => ({
							name: v.name,
							label: v.name,
						})
					),
				})
			} else {
				results.push({
					name: `variable.${variable.name}`,
					label: variable.name,
					valueEditorType: variable.type,
					inputType: 'variable',
				})
			}
		})

		return _.sortBy(results, ['inputType'])
	} else {
		return []
	}
}

function getConditionFromField(field) {
	switch (field.type) {
		case 'number':
			return {
				valueEditorType: 'number',
			}
		case 'select':
			return {
				valueEditorType: 'select',
				values: _.map(field?.settings?.options, (option) => ({
					name: option,
					label: option,
				})),
			}
		case 'date':
			return {
				valueEditorType: 'date',
			}
		case 'checkbox':
			return {
				valueEditorType: 'boolean',
			}
		default:
			return {
				valueEditorType: 'text',
			}
	}
}

function getLeaderBoardLevelOptions({ trackingDatas, pages }) {
	// init values
	const values = []

	if (!_.isEmpty(trackingDatas)) {
		_.forEach(trackingDatas, (tracking) => {
			const page = _.find(pages, ['_id', tracking?.page?._id])

			if (page && page?.type === 'multiple_choice') {
				_.forEach(page.answers, (answer) => {
					values.push({
						name: answer.title,
						label: answer.title,
					})
				})
			} else if (page && page?.type === 'form') {
				const formInteraction = _.first(page.interactions)
				const currentField = _.find(formInteraction.fields, ['_id', tracking?.field])

				if (currentField && currentField?.type === 'select') {
					_.forEach(currentField?.settings?.options, (option) => {
						values.push({
							name: option,
							label: option,
						})
					})
				}
			}
		})
	}

	if (_.isEmpty(values)) {
		return {
			valueEditorType: 'text',
		}
	} else {
		return {
			valueEditorType: 'select',
			values: _.uniqBy(values, 'name'),
		}
	}
}

export function gradientToCSS({ type, direction, colors } = {}) {
	if (type === 'linear') {
		return `${type}-gradient(${direction}deg, ${colors.join(', ')})`
	} else if (type === 'radial') {
		return `${type}-gradient(${direction}, ${colors.join(', ')})`
	}

	return ''
}

export function extractQuestionFromBlocks(blocks) {
	return _.reduce(
		blocks,
		(str, block) => (block.type === 'text' && /\?/.test(block.value) ? str + block.value : str),
		''
	)
}

// export function getFilteredDefaultPageTemplates ({ pageTemplates, type, buttonsType }) {
// 	if (type === 'multiple_choice' && !_.isEmpty(buttonsType)) {
// 		return _.filter(pageTemplates, template => (
// 			template.type === type &&
// 			template?.answers_properties?.type === buttonsType
// 		))
// 	}

// 	return _.filter(pageTemplates, template => (
// 		template.type === type
// 	))
// }
export function getFilteredDefaultPageTemplates({ pageTemplatesByTag, type, buttonsType, currentType }) {
	// Initialize the result object
	const result = {}
	const filterFunction =
		type === 'multiple_choice' && !_.isEmpty(buttonsType)
			? (template) => template.type === type && template?.answers_properties?.type === buttonsType
			: type === 'all'
			? (template) => template.type === currentType
			: { type }

	// Iterate over each key in the pageTemplates object
	Object.keys(pageTemplatesByTag).forEach((tagId) => {
		result[tagId] = _.filter(pageTemplatesByTag[tagId], filterFunction)
	})

	return result
}

export function getFilteredCustomPageTemplates({ pageTemplates, type, buttonsType, isLayoutOnly, currentType }) {
	if (isLayoutOnly) {
		if (type === 'amp_page') {
			return _.filter(pageTemplates, (template) => template?.page.type === type)
		}

		return _.filter(pageTemplates, (template) => template?.page.type !== 'amp_page')
	} else {
		if (type === 'amp_page') {
			return _.filter(pageTemplates, (template) => template?.page.type === type)
		}

		if (type === 'all') {
			_.filter(pageTemplates, (template) => template?.page?.type === currentType)
		}

		if (type === 'multiple_choice' && !_.isEmpty(buttonsType)) {
			return _.filter(
				pageTemplates,
				(template) => template?.page.type === type && template?.page?.answers_properties?.type === buttonsType
			)
		}

		return _.filter(pageTemplates, (template) => template?.page.type === currentType)
	}
}

export function isStoryPublishable(draftStory, published) {
	return (
		!isEqual(draftStory.pages, published.pages) ||
		!isEqual(draftStory.name, published.name) ||
		!isEqual(draftStory.tags, published.tags) ||
		!isEqual(draftStory.theming, published.theming) ||
		!isEqual(draftStory.messages, published.messages) ||
		!isEqual(draftStory.integrations, published.integrations) ||
		!isEqual(draftStory.story_settings, published.story_settings) ||
		!isEqual(draftStory.gdprConsent, published.gdprConsent) ||
		!isEqual(draftStory.share_settings, published.share_settings) ||
		!isEqual(draftStory.metadata, published.metadata) ||
		!isEqual(draftStory.story_favicon, published.story_favicon) ||
		draftStory.story_slug !== published.story_slug ||
		draftStory.story_fqdn !== published.story_fqdn
	)
}

export function getCurrentUsersPerMonth(interactions_count) {
	if (!interactions_count) return 0

	const currentMonth = dayjs().utc().format('M')
	const currentYear = dayjs().utc().format('YYYY')

	if (!interactions_count[currentYear]) return 0

	if (!interactions_count[currentYear][currentMonth]) return 0

	return interactions_count[currentYear][currentMonth]
}

export function generateShareLinks({ storySlug, storyId, storyCustomFQDN, storyVariables = [] }) {
	const searchParams = new URLSearchParams()

	for (const variableParam of storyVariables) {
		if (_.isEmpty(variableParam.param)) continue
		searchParams.append(variableParam.name, variableParam.param)
	}

	const basePreviewUrl = `${SHORT_SNACKEET_URL}/${storyId}`
	const baseLiveUrl = getLiveUrl({ storyCustomFQDN, storySlug, storyId })

	// Append params to URLs
	return {
		liveStoryUrl: `${baseLiveUrl}?${decodeURIComponent(searchParams.toString())}`.replace(/[&?]$/, ''),
		previewStoryUrl: `${basePreviewUrl}?preview&${decodeURIComponent(searchParams.toString())}`.replace(
			/[&?]$/,
			''
		),
	}
}

function getLiveUrl({ storyCustomFQDN, storySlug, storyId }) {
	let baseUrl = storyCustomFQDN ? `https://${storyCustomFQDN}` : SHORT_SNACKEET_URL

	if (!_.isNil(storySlug)) {
		baseUrl = `${baseUrl}/${storySlug}`
	} else {
		baseUrl = `${baseUrl}/${storyId}`
	}

	return baseUrl
}

export function copyElement(elementToCopy) {
	if (!elementToCopy) return []
	const element = _.cloneDeep(elementToCopy)
	element._id = nanoid()

	return [element]
}

const DEFAULT_REDIRECTION_CTA_OPTIONS = {
	enabled: false,
	tooltipEnabled: true,
	redirectionType: 'none',
	link: '',
	icon: '',
	tooltip: 'Click Here',
	attachment: {},
}

export function filterPasteElementsByStoyType(elements, storyType) {
	// Block's types only available in Snackeet story
	const only_snackeet = [
		'countdown',
		'location',
		'calendar',
		'iframe',
		'social-network',
		'networks',
		'share',
		'messenger',
		'tag',
		'carousel',
		'buttonList',
		'leaderboard',
	]

	if (storyType === 'amp') {
		const ampElememts = elements.filter((element) => !only_snackeet.includes(element?.type))

		// Refactor cta options
		_.forEach(ampElememts, (element) => {
			if (element?.cta_options) {
				if (['internal', 'attachment'].includes(element.cta_options?.redirectionType)) {
					element.cta_options = DEFAULT_REDIRECTION_CTA_OPTIONS
				}
			}
			return element
		})

		return ampElememts
	}

	return elements
}

export function getFirstStoryPage(story) {
	return _.find(story.pages, ['type', 'start_page']) ?? story.pages[0]
}

export function isTimerDisabled(page) {
	return Boolean(
		// Disable timer if the page type is 'form', 'media_answer', 'ending_page', or 'game'.
		['form', 'media_answer', 'ending_page', 'game'].includes(page.type) ||
			// Disable timer if there's a calendar block present in the page.
			_.findIndex(page.blocks, { type: 'calendar' }) !== -1 ||
			// Disable timer if the page type is 'multiple_choice', the answer is required,
			(page.type === 'multiple_choice' &&
				page.answers_properties.required_answer === true &&
				!page.answers_properties.count_in_results) ||
			// Disable timer if the multiple_choice page type action with a upload button
			(page.type === 'multiple_choice' &&
				page.answers_properties.type === 'action' &&
				_.some(page.answers, { type: 'file_uploader' })) ||
			// Disable timer if there's a required 'rating' interaction on the page.
			_.find(page.interactions, { type: 'rating' })?.required ||
			// Disable timer if the 'next' property of the page is set to 'none'.
			page.next === 'none'
	)
}

export function getObjectPosition({ zoom = 1, croppedArea = {} }) {
	const x = croppedArea?.x || 0
	const y = croppedArea?.y || 0
	const areaWidth = croppedArea?.width || 100
	const areaHeight = croppedArea?.height || 100

	if (zoom > 1) {
		const offsetX = (x / (100 - areaWidth)) * 100
		const offsetY = (y / (100 - areaHeight)) * 100

		return `${offsetX}% ${offsetY}%`
	} else {
		const offsetX = areaWidth === 100 ? 50 : (x / (100 - areaWidth)) * 100
		const offsetY = areaHeight === 100 ? 50 : (y / (100 - areaHeight)) * 100

		return `${offsetX}% ${offsetY}%`
	}
}

export function transformToObjPostion(transform, zoom = 1) {
	if (transform) {
		const properties = transform.split(' ')
		const translateX = parseFloat(properties[0].replace('translateX(', ''))
		const translateY = parseFloat(properties[1].replace('translateY(', ''))

		return `calc(50% + ${translateX / zoom}px) calc(50% + ${translateY / zoom}px)`
	}

	return '50% 50%'
}

export function extractPositionProperties(outer_style, block = '') {
	if (!outer_style) {
		return {}
	}

	const animationFillMode = 'none'
	let { top, left, transform, height, width, opacity, ...visualProperties } = outer_style

	visualProperties = { ...visualProperties, width, height, animationFillMode }
	const positionProperties = {
		top,
		left,
		transform,
		opacity,
		width,
		height,
		position: 'absolute',
	}

	if (block === 'video') {
		visualProperties.placeSelf = 'center center'
	}

	return { positionProperties, visualProperties }
}

export function isStoryNotScheduled(storySchedule) {
	if (!storySchedule) return false
	try {
		const { startDate, endDate, timezone } = storySchedule
		const startDateTz = dayjs.tz(startDate, timezone)
		const endDateTz = dayjs.tz(endDate, timezone)
		const isOnSchedule = dayjs().isBetween(startDateTz, endDateTz, 'minute', '[]')
		return !isOnSchedule
	} catch (err) {
		console.log(err)
		return false
	}
}

// Remove timezone from date
export function dateWithoutTimezone(date) {
	const tzoffset = date.getTimezoneOffset() * 60000 // offset in milliseconds
	const withoutTimezone = new Date(date.valueOf() - tzoffset).toISOString().slice(0, -1)
	return withoutTimezone
}
