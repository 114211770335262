export const CALENDAR_BRANDS = [
	{
		name: 'Calendly',
		url: 'https://calendly.com/',
		logo: '/static/svg/calendar_brands/calendly_logo.png',
		host: 'calendly',
	},
	{
		name: 'Hubspot',
		url: 'https://www.hubspot.com/products/sales/schedule-meeting/',
		logo: '/static/svg/calendar_brands/hubspot_logo.png',
		host: 'hubspot',
	},
	{
		name: 'Acuity Scheduling',
		url: 'https://acuityscheduling.com/',
		logo: '/static/svg/calendar_brands/acuity_scheduling_logo.png',
		host: 'acuity',
	},
	{
		name: 'You can book',
		url: 'https://youcanbook.me/',
		logo: '/static/svg/calendar_brands/ycbm_logo.png',
		host: 'youcanbook',
	},
	{
		name: 'TimeTap',
		url: 'https://www.timetap.com/',
		logo: '/static/svg/calendar_brands/timetap_logo.png',
		host: 'timetap',
	},
	{
		name: 'Tucalendi',
		url: 'https://www.tucalendi.com/',
		logo: '/static/svg/calendar_brands/tucalendi.png',
		host: 'tucalendi',
	},
	{
		name: 'Book Like a Boss',
		url: 'https://www.booklikeaboss.com/',
		logo: '/static/svg/calendar_brands/book_like_a_boss.svg',
		host: 'bookme',
	},
	{
		name: 'Monday',
		url: 'https://www.monday.com/',
		logo: '/static/svg/calendar_brands/monday.png',
		host: 'view',
	},
	{
		name: 'Trafft',
		url: 'https://trafft.com/',
		logo: '/static/svg/calendar_brands/trafft.svg',
		host: 'trafft',
	},
	{
		name: 'Pipedrive',
		url: 'https://www.pipedrive.com/',
		logo: '/static/svg/calendar_brands/pipedrive_logo.png',
		host: 'pipedrive',
	},
	{
		name: 'Agile CRM',
		url: 'https://www.agilecrm.com/',
		logo: '/static/svg/calendar_brands/agile_crm_logo.png',
		host: 'agilecrm',
	},
	{
		name: 'TidyCal',
		url: 'https://tidycal.com/',
		logo: '/static/svg/calendar_brands/tidycal.svg',
		host: 'tidycal',
	},
	{
		name: 'Agiled',
		url: 'https://agiled.app/',
		logo: '/static/svg/calendar_brands/agiled_logo.png',
		host: 'agiled',
	},
	// {
	// 	name: 'Livestorm',
	// 	url: 'https://livestorm.co/online-meeting-software/',
	// 	logo: '/static/svg/calendar_brands/livestorm_logo.png',
	// 	host: 'livestorm'
	// },
	// {
	// 	name: 'Meetup',
	// 	url: 'https://www.meetup.com/',
	// 	logo: '/static/svg/calendar_brands/meetup_logo.svg',
	// 	host: 'meetup'
	// }
]
