import { createSelector } from '@reduxjs/toolkit'
import split from 'lodash/split'
import toLower from 'lodash/toLower'
import { RootState } from 'redux/index'

import { IFeatureSet } from './types'

export const subscriptionSelector = (state: RootState) => state.subscription

export const getSubscriptionFeatures = createSelector(subscriptionSelector, (state) => state.feature_set)

export const getFeature = (featureName: keyof IFeatureSet) =>
	createSelector(subscriptionSelector, (state) => state.feature_set[featureName])

export const getSubscriptionPlanDetails = createSelector(subscriptionSelector, (state) => {
	const [planId, currency, period] = split(state.item_price_id, '-')
	return {
		planId,
		currency,
		period: toLower(period).replace(/ly$/, ''),
		itemPriceId: state.item_price_id,
		price: state.price,
	}
})
