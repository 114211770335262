import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardActions, IconButton, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material';
import { FileValidationError } from 'lib/upload';
import map from 'lodash/map';
import mime from 'mime';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';
import type { DefaultExtensionType } from 'react-file-icon';
import { defaultStyles, FileIcon } from 'react-file-icon';
interface UploadErrorReportProps extends CustomContentProps {
  invalidFiles?: FileValidationError[];
}
const UploadErrorReport = forwardRef<HTMLDivElement, UploadErrorReportProps>(function UploadErrorReport({
  id,
  message,
  invalidFiles,
  ...props
}, ref) {
  const {
    closeSnackbar
  } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);
  return <SnackbarContent ref={ref}>
			<Card sx={{
      width: '100%'
    }}>
				<CardActions sx={{
        bgcolor: 'error.main',
        color: 'error.contrastText',
        p: 1,
        pl: 2,
        justifyContent: 'space-between'
      }}>
					<Typography variant='body1'>{message}</Typography>
					<Box sx={{
          ml: 'auto'
        }}>
						<IconButton size='small' sx={{
            color: 'inherit',
            p: 1
          }} onClick={handleDismiss}>
							<CloseIcon fontSize='small' />
						</IconButton>
					</Box>
				</CardActions>
				<List>
					{map(invalidFiles, (fileError, index) => {
          const ext = mime.getExtension(fileError.file.type) || undefined;
          return <ListItem key={index}>
								<ListItemIcon>
									<SvgIcon sx={{
                fontSize: 35
              }}>
										<FileIcon extension={ext} {...defaultStyles[(ext as DefaultExtensionType)]} />
									</SvgIcon>
								</ListItemIcon>
								<ListItemText primary={fileError.file.name} />
							</ListItem>;
        })}
				</List>
			</Card>
		</SnackbarContent>;
});
export default UploadErrorReport;