export const en = {
	'stories_list.header_title': 'Stories',
	'stories_list.created': 'Created at ',
	'stories_list.published': 'Last published at ',
	'stories_list.page_count': '{count} {count, plural, one {PAGE} other {PAGES}}',
	'stories_list.started': 'Impressions',
	'stories_list.finished': 'Finished',
	'stories_list.unique_users': 'Users',
	'stories_list.status.name': 'Status',
	'stories_list.status_label.active': 'Active',
	'stories_list.status_label.inactive': 'Inactive',
	'stories_list.folder.tooltip.assign': 'Assign a folder to the story',
	'stories_list.folder.tooltip.update': 'Change the assigned folder',
	'stories_list.option.stats': 'Stats',
	'stories_list.option.delete': 'Delete',
	'stories_list.option.configuration': 'Setup',
	'stories_list.option.unpublished_change': 'You have unpublished changes in your story',
	'stories_list.empty_list_title': 'No Stories yet',
	'stories_list.delete_confirmation': 'Are you sure you want to delete this Story?',

	'stories_list.modals.creating.title': 'Creating Story',
	'stories_list.modals.creating.importing': 'Importing assets from Instagram and creating story...',
	'stories_list.modals.creating.warning': '⚠️ This might take a while.',

	'stories_list.tags.no_tags': 'Stories without tags',
	'stories_list.tags.new_tags': 'New Tags',
	'stories_list.tags.old_tags': 'Old Tags',
	'stories_list.tags.modal_titles': 'Modify story tags',
}
