import { CustomerError, OrganizationError, SubscriptionError } from 'lib/Errors'
import useReduxLogout from 'lib/hooks/useReduxLogout'
import { PrivateRoute, Routes } from 'lib/Routes'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customerSelector } from 'redux/customer/selectors'
import { updateCustomer } from 'redux/customer/slice'
import { fetchFonts } from 'redux/fonts/slice'
import { organizationSelector } from 'redux/organization/selectors'
import { resetOrganization, updateOrganization } from 'redux/organization/slice'
import { projectSelector } from 'redux/project/selectors'
import { resetProject, selectProject } from 'redux/project/slice'
import { subscriptionSelector } from 'redux/subscription/selectors'
import { fetchSubscription, resetSubscription } from 'redux/subscription/slice'
import * as AuthSvc from 'services/AuthSvc'
import CustomersSvc from 'services/CustomersSvc'
import LeaderboardsSvc from 'services/LeaderboardsSvc'
import * as localStorageSvc from 'services/LocalStorageSvc'
import OrganizationsSvc from 'services/OrganizationsSvc'
import ProjectsSvc from 'services/ProjectsSvc'
import SnackeetSvc from 'services/SnackeetSvc'
import WidgetsSvc from 'services/WidgetsSvc'
import useAsyncEffect from 'use-async-effect'
import { useImmer } from 'use-immer'

const DEBUG = process.env.NEXT_PUBLIC_APP_ENV !== 'production'
const routes = new Routes()

export default function useAppInitialization({ grantAccess, forbidAccess, verifySubscription }) {
	const [state, setState] = useImmer({
		hasMounted: false,
		isLoadingData: true,
	})
	const { onLogoutSuccess } = useReduxLogout()
	const router = useRouter()
	const dispatch = useDispatch()
	const reduxCustomer = useSelector(customerSelector)
	const reduxOrganization = useSelector(organizationSelector)
	const reduxProject = useSelector(projectSelector)
	const reduxSubscription = useSelector(subscriptionSelector)

	const onResetOrganization = useCallback(() => {
		dispatch(resetSubscription())
		dispatch(resetOrganization())
		dispatch(resetProject())
	}, [dispatch])

	const loadCustomer = useCallback(async () => {
		// Handle logged out scenarios
		// 1) JWT not present OR customer not set
		if (!localStorageSvc.isLoggedIn() || !reduxCustomer?._id) {
			throw Error('User logged out')
		}

		// 2) JWT expired or invalid
		const { success, customer } = await CustomersSvc.get(reduxCustomer?._id)
		if (!success) {
			throw Error('Expired or invalid JWT')
		}

		dispatch(updateCustomer(customer))
		AuthSvc.updateLastLogin()
		return customer
	}, [dispatch, reduxCustomer?._id])

	const loadProject = useCallback(
		async (organization_id, project_id) => {
			DEBUG && console.log('loadProject', { organization_id, project_id })
			const project = await ProjectsSvc.get(organization_id, project_id)
			dispatch(selectProject(project))
		},
		[dispatch]
	)

	const loadSubscription = useCallback(
		async (organization_id) => {
			DEBUG && console.log('loadSubscription')
			if (!organization_id) return
			if (!state.hasMounted || organization_id !== reduxSubscription.owner_id) {
				const subscription = await dispatch(fetchSubscription(organization_id)).unwrap()
				return subscription
			}
			// By default return current subscription
			return reduxSubscription
		},
		[dispatch, reduxSubscription, state.hasMounted]
	)

	const loadFonts = useCallback(
		(organization_id) => {
			DEBUG &&
				console.log('loadFonts', {
					hasMounted: state.hasMounted,
					organization_id,
					reduxSubowerId: reduxSubscription.owner_id,
					isDifferent: organization_id !== reduxSubscription.owner_id,
				})
			if (!organization_id) return
			if (!state.hasMounted || organization_id !== reduxSubscription.owner_id) {
				dispatch(fetchFonts(organization_id))
			}
		},
		[dispatch, reduxSubscription, state.hasMounted]
	)

	const loadOrganization = useCallback(
		async ({ shouldLoadProject, url }) => {
			const { urlOrganizationSlug, urlStoryId, urlWidgetId, urlLeaderboardId } = extractFromUrl({
				url,
				routerQuery: router.query,
			})
			if (!reduxOrganization?._id && !urlOrganizationSlug) {
				return
			}

			/* ---- Loading Organization ---- */
			let organization = reduxOrganization
			// Load based of URL
			if (urlOrganizationSlug) {
				// Load if on mount or different org than current one
				if (!state.hasMounted || urlOrganizationSlug !== reduxOrganization?.slug) {
					organization = await OrganizationsSvc.getBySlug(urlOrganizationSlug)
				}
			} else {
				// Load if mount to get latest org data
				if (!state.hasMounted) {
					organization = await OrganizationsSvc.get(reduxOrganization?._id)
				}
			}

			const isDifferentOrg = !_.isEqual(organization, reduxOrganization)
			if (isDifferentOrg) {
				dispatch(updateOrganization(organization))
				dispatch(resetProject())
			}

			const [subscription] = await Promise.all([
				loadSubscription(organization?._id),
				loadFonts(organization?._id),
			])

			/* ---- Loading Project ---- */
			if (shouldLoadProject) {
				if (urlStoryId) {
					// Load workspace linked to specified story
					const story = await SnackeetSvc.getProjectId({
						story_id: urlStoryId,
						organization_id: organization._id,
					})
					story && (await loadProject(organization._id, story.project))
				} else if (urlWidgetId) {
					// Load workspace linked to specified widget
					const widget = await WidgetsSvc.get({
						_id: urlWidgetId,
						fields: 'project',
					})
					widget && (await loadProject(organization._id, widget.project))
				} else if (urlLeaderboardId) {
					const leaderboard = await LeaderboardsSvc.get(urlLeaderboardId)

					leaderboard && (await loadProject(organization._id, leaderboard.project))
				} else if (isDifferentOrg || !reduxProject._id || reduxProject.owner?._id !== organization._id) {
					/* If story not specified, load default workspace when:
					- loading a different organization
					- no workspace was already loaded
					- the current workspace does not belong to the current organization
					*/

					// Allow super admin to access organization with no multiple workspaces
					if (reduxCustomer.role === 'super_admin') {
						await loadProject(organization._id, organization.default_project)
					} else {
						// check role of customer in organization
						const currentMemberRights = _.find(organization.members, ['_id', reduxCustomer._id])

						if (currentMemberRights.role === 'admin') {
							// If admin then load default project
							await loadProject(organization._id, organization.default_project)
						} else if (currentMemberRights.role === 'agent' || !_.isEmpty(currentMemberRights.projects)) {
							// If agent then load 1st project in list of accessible projects
							const projectToLoad = _.first(currentMemberRights.projects)
							await loadProject(organization._id, projectToLoad)
						}
					}
				}
			}

			return {
				subscription,
				organization,
			}
		},
		[
			router.query,
			state.hasMounted,
			reduxOrganization,
			reduxProject,
			reduxCustomer,
			loadSubscription,
			loadFonts,
			loadProject,
			dispatch,
		]
	)

	const loadData = useCallback(
		async ({ url, shouldLoadCustomer, shouldLoadOrganization, shouldLoadProject } = {}) => {
			DEBUG &&
				console.log('LOAD', {
					url,
					shouldLoadCustomer,
					shouldLoadOrganization,
					shouldLoadProject,
				})

			let currentCustomer
			let currentOrganization
			let currentSubscription

			if (shouldLoadCustomer) {
				try {
					currentCustomer = await loadCustomer()
				} catch (err) {
					onLogoutSuccess()
					throw new CustomerError(err)
				}
			}

			if (shouldLoadOrganization) {
				try {
					const data = await loadOrganization({
						shouldLoadProject,
						url,
					})
					currentSubscription = data?.subscription
					currentOrganization = data?.organization
				} catch (err) {
					onResetOrganization()
					throw new OrganizationError(err)
				}
			}

			return {
				currentCustomer,
				currentOrganization,
				currentSubscription,
			}
		},
		[loadCustomer, loadOrganization, onLogoutSuccess, onResetOrganization]
	)

	// 🔐 Grant or forbid access each time data is loaded

	const loadDataOnMount = useCallback(
		async (isMounted) => {
			if (!router.isReady) return

			try {
				const { currentSubscription } = await loadData({
					shouldLoadCustomer: true,
					shouldLoadOrganization: true,
					shouldLoadProject: true,
				})
				verifySubscription({ url: router.asPath, currentSubscription })
				grantAccess()
			} catch (err) {
				// When an error occurs, only redirect if not a private page
				if (!routes.isPrivate(router.asPath)) {
					return grantAccess()
				}
				if (err instanceof CustomerError) {
					forbidAccess(`/login?redirect=${encodeURIComponent(router.asPath)}`)
				} else if (err instanceof OrganizationError) {
					forbidAccess(`/login?redirect=${encodeURIComponent(err.redirectTo)}`)
				} else if (err instanceof SubscriptionError) {
					forbidAccess(err.redirectTo)
				}
			} finally {
				if (isMounted()) {
					setState((draft) => {
						draft.hasMounted = true
						draft.isLoadingData = false
					})
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loadData, grantAccess, forbidAccess]
	)

	const loadDataAfterMount = useCallback(
		async ({ url, ...opts }) => {
			setState((draft) => {
				draft.isLoadingData = true
			})
			try {
				const { currentSubscription } = await loadData({ url, ...opts })
				verifySubscription({ url, currentSubscription })
				grantAccess()
			} catch (err) {
				if (err instanceof SubscriptionError || err instanceof OrganizationError) {
					forbidAccess(err.redirectTo)
				} else {
					forbidAccess()
				}
			} finally {
				setState((draft) => {
					draft.isLoadingData = false
				})
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loadData, grantAccess, forbidAccess]
	)

	useAsyncEffect(loadDataOnMount, [router.isReady])

	return {
		...state,
		loadDataAfterMount,
		loadDataOnMount,
	}
}

function extractFromUrl({ url, routerQuery }) {
	if (!url) {
		return extractFromRouterQuery(routerQuery)
	}

	const route = routes.getPrivateRoute(url)
	if (route instanceof PrivateRoute) {
		return extractFromRouterQuery(route.getQuery(url))
	}

	return {}
}

function extractFromRouterQuery(routerQuery = {}) {
	const urlOrganizationSlug = routerQuery.organization_slug
	const urlStoryId =
		routerQuery.snackeet || // used in interactions page
		routerQuery.story_id // used in story editor page
	const urlWidgetId = routerQuery.widget_id
	const urlLeaderboardId = routerQuery.leaderboard_id

	return {
		urlOrganizationSlug,
		urlStoryId,
		urlWidgetId,
		urlLeaderboardId,
	}
}
