import { SUBTITLES_DISPLAY_DELAY_MS } from 'lib/constants'

export async function positionSubtitles(subtitles, snapToLines = false, line) {
	const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
	await sleep(SUBTITLES_DISPLAY_DELAY_MS)

	for (const subtitle of subtitles) {
		const textTrack = document.getElementById(subtitle._id)?.track
		positionCues(textTrack?.activeCues, snapToLines, line)
		positionCues(textTrack?.cues, snapToLines, line)
	}
}

function positionCues(cues, snapToLines, line) {
	if (cues) {
		// Modify one by one, can't use map because read only
		for (const cue of cues) {
			cue.snapToLines = snapToLines // Set cues position in percentage
			cue.line = line
		}
	}
}
