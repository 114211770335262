export const en = {
	'button.login': 'Login',
	'button.logout': 'Logout',
	'button.add': 'Add',
	'button.add_element': 'Add { count, plural, one { element } other { elements }}',
	'button.activate': 'Activate',
	'button.cancel': 'Cancel',
	'button.confirm': 'Confirm',
	'button.delete': 'Delete',
	'button.access': 'Open',
	'button.search': 'Search',
	'button.clear_list': 'Clear the list',
	'button.play': 'Play',
	'button.play_animation': 'Play animation',
	'button.hide': 'Hide',
	'button.show': 'Show',
	'button.send': 'Send',
	'button.save': 'Save',
	'button.authorize': 'Authorize',
	'button.change_without_save': 'Change without save',
	'button.test': 'Test',
	'button.change': 'Change',
	'button.create': 'Create',
	'button.continue': 'Continue',
	'button.duplicate': 'Duplicate',
	'button.edit': 'Edit',
	'button.edit_slug': 'Edit slug',
	'button.update': 'Update',
	'button.refresh': 'Refresh',
	'button.import': 'Import',
	'button.export': 'Export',
	'button.export_as': 'Export as { type }',
	'button.export_pdf': 'Export Graphs as PDF',
	'button.export_csv': 'Export Data as CSV',
	'button.filter': 'Filter',
	'button.download': 'Download',
	'button.next': 'Next',
	'button.next_step': 'Next Step',
	'button.previous': 'Back',
	'button.change_template': 'Change Template',
	'button.accept': 'Accept',
	'button.reject': 'Reject',
	'button.manage': 'Manage',
	'button.leave': 'Leave',
	'button.kick': 'Kick',
	'button.change_owner': 'Change Owner',
	'button.remove': 'Remove',
	'button.customize': 'Customize',
	'button.delete_account': 'Delete my account',
	'button.delete_organization': 'Delete organization',
	'button.invite_member': 'Invite New Member',
	'button.connect': 'Connect',
	'button.disconnect': 'Disconnect',
	'button.abort': 'Abort',
	'button.finish': 'Finish',
	'button.import_file': 'Import from file',
	'button.setup': 'Setup',
	'button.verify': 'Verify',
	'button.select_all': 'Select All',
	'button.unselect_all': 'Unselect All',
	'button.import_selection': 'Import Selection',
	'button.close': 'Close',

	// tag buttons
	'button.tag.trigger': 'Manage tags', // Not used
	'button.tag.clear_list': 'Remove all', // Not used
	'button.tag.fill_list': 'Add all', // Not used
	'button.tag.create': 'Create new', // Not used

	// subtitle buttons
	'button.subtitle.new': 'New Subtitle',
	'button.subtitle.autoTranscription': 'Auto Transcript',

	'button.new': 'New', // Not used
	'button.tags': 'Categories', // Not used
	'button.settings': 'Settings', // Not used
	'button.theming': 'Theming', // Not used
	'button.back_to_list': 'Back to list', // Not used
	'button.reset': 'Reset',
	'button.reset_default': 'Reset defaults', // Not used

	'button.add_folder': 'ADD FOLDER',
}
