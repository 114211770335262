import styled from '@styled';
import PropTypes from 'prop-types';
import React from 'react';
import * as StyledSpinkit from 'styled-spinkit';
export default React.memo(Loader);
function Loader({
  $inline,
  $heightPx,
  size,
  name
}) {
  const LoaderComponent = StyledSpinkit[name];
  return <LoaderContainer $inline={$inline} $heightPx={$heightPx}>
			<LoaderComponent size={size} />
		</LoaderContainer>;
}
Loader.propTypes = {
  $inline: PropTypes.bool,
  $heightPx: PropTypes.number,
  size: PropTypes.number,
  name: PropTypes.oneOf(Object.keys(StyledSpinkit))
};
Loader.defaultProps = {
  $inline: false,
  size: 200,
  name: 'Pulse'
};
function computeHeight({
  $inline,
  $heightPx
}) {
  if ($heightPx) {
    return `${$heightPx}px`;
  }
  return `100${$inline ? '%' : 'vh'}`;
}
export const LoaderContainer = styled.div`
	width: 100%;
	height: ${computeHeight};
	display: grid;
	place-items: center;
`;