export const fr = {
	'organizationLog.tab.title': `Journaux d'activité`,

	'organizationLog.organization.invite': `<b>{username}</b> a invité <b>{email}</b> en tant que <b>{role}</b>.`,
	'organizationLog.organization.owner': `<b>{ username }</b> a transféré la propriété à <b>{ new_owner }</b>.`,
	'organizationLog.organization.slug': `<b>{ username }</b> a modifié l'identifiant de l'organisation en <b>{ slug }</b>.`,
	'organizationLog.organization.acive_license': `<b>{ username }</b> a appliqué la licence avec la clé de licence <b>{ license_key }</b>.`,
	'organizationLog.organization.leave': `<b>{ username }</b> a quitté.</b>.`,
	'organizationLog.organization.join': `<b>{ username }</b> a rejoint.`,
	'organizationLog.organization.promote': `<b>{ username }</b> a promu <b>{ member }</b> en tant qu'administrateur.`,
	'organizationLog.organization.demote': `<b>{ username }</b> a rétrogradé <b>{ member }</b> en tant que membre.`,
	'organizationLog.organization.kick': `<b>{ username }</b> a expulsé <b>{ member }</b>.`,

	'organizationLog.project.create': `<b>{ username }</b> a créé l'espace de travail <b>{ project }</b>.`,
	'organizationLog.project.delete': `<b>{ username }</b> a supprimé l'espace de travail <b>{ project }</b>.`,

	'organizationLog.story.create': `<b>{ username }</b> a créé <b>{ story_name }</b>.`,
	'organizationLog.story.delete': `<b>{ username }</b> a supprimé <b>{ story_name }</b>.`,
	'organizationLog.story.publish': `<b>{ username }</b> a publié <b>{ story_name }</b>.`,

	'organizationLog.users.session_delete': `<b>{ username }</b> a nettoyé les sessions utilisateur de <b>{ story_name }</b>.`,
	'organizationLog.users.export': `<b>{ username }</b> a exporté les données de la Story <b>{ story_name }</b>.`,

	'organizationLog.details.ip': 'IP',
	'organizationLog.details.country': 'Pays',
	'organizationLog.details.region': 'Région',
	'organizationLog.details.timezone': 'Fuseau horaire',
	'organizationLog.details.city': 'Ville',

	'organizationLog.details.type': 'Appareil',
	'organizationLog.details.browser': 'Navigateur',
	'organizationLog.details.os': `Système d'exploitation`,

	'organizationLog.filter.type_all': 'Tout',
	'organizationLog.filter.type_organization': 'Organisation',
	'organizationLog.filter.type_project': 'Espace de travail',
	'organizationLog.filter.type_story': 'Story',
	'organizationLog.filter.type_users': 'Utilisateurs',

	'organizationLog.story.deleted_story': 'Deleted Story',
	'organizationLog.project.deleted_project': 'Deleted Workspace',
	'organizationLog.user.deleted_story': 'Deleted User',
}
