import axios from 'axios'
import { API_URL } from 'lib/constants'
import dayjs from 'lib/dayjs'
import { CustomerError } from 'lib/Errors'
import { dateWithoutTimezone } from 'lib/utils'
import * as localStorageSvc from 'services/LocalStorageSvc'

const SnackeetSvc = {
	createFromScratch,
	createFromStory,
	createFromDefaultTemplate,
	createFromMyTemplate,
	createFromInstagram,

	exportStoryStats,

	del,
	get,
	getProjectId,
	update,
	updateTags,
	getAll,
	getSnackeetMetrics,
	getPageStats,
	getSnackeetFunnel,
	getPageMetrics,
	getAnswersPerPage,
	GetPageDropdownAnswers,
	getUsersPerPlatform,
	getStoryUsersCount,
	getStoryCounters,
	getUsersFromPage,

	getCarouselProductData,
	getButtonListFavicon,
}

export default SnackeetSvc

async function createFromScratch({ story, project, organization_id, brandId }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/snackeets`,
			{
				creationMethod: 'scratch',
				brandId,
				creationData: {
					story,
				},
			},
			{
				params: { project, organization_id },
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		await createPublishedStory({ data, project, organization_id })

		return {
			success: true,
			draftStory: data.snackeet,
		}
	} catch (err) {
		console.log('createFromScratch Story', err)
		return {
			success: false,
		}
	}
}

async function createFromStory({ story_id, project, organization_id }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/snackeets`,
			{
				creationMethod: 'story',
				creationData: {
					story_id,
				},
			},
			{
				params: { project, organization_id },
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		await createPublishedStory({ data, project, organization_id })

		return {
			success: true,
			draftStory: data.snackeet,
		}
	} catch (err) {
		console.log('createFromStory Story', err)
		return {
			success: false,
		}
	}
}

async function createFromDefaultTemplate({ templateId, project, name, language, organization_id, brandId }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/snackeets`,
			{
				creationMethod: 'default_template',
				brandId,
				creationData: {
					templateId,
					name,
					language,
				},
			},
			{
				params: { project, organization_id },
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		await createPublishedStory({ data, project, organization_id })

		return {
			success: true,
			draftStory: data.snackeet,
		}
	} catch (err) {
		console.log('createFromDefaultTemplate story', err)
		return {
			success: false,
		}
	}
}

async function createFromMyTemplate({ templateId, organization_id, project, name, language, brandId }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/snackeets`,
			{
				creationMethod: 'my_template',
				brandId,
				creationData: {
					templateId,
					name,
					language,
				},
			},
			{
				params: { project, organization_id },
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		await createPublishedStory({ data, project, organization_id })

		return {
			success: true,
			draftStory: data.snackeet,
		}
	} catch (err) {
		console.log('createFromDefaultTemplate story', err)
		return {
			success: false,
		}
	}
}

async function createFromInstagram({ project, story, brandId, organization_id }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/snackeets`,
			{
				creationMethod: 'instagram',
				brandId,
				creationData: {
					story,
				},
			},
			{
				params: { project, organization_id },
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		await createPublishedStory({ data, project, organization_id })

		return {
			success: true,
			draftStory: data.snackeet,
		}
	} catch (err) {
		console.log('createFromTemplate story', err)
		return {
			success: false,
		}
	}
}

async function del({ story_id, project, organization_id }) {
	if (!story_id) {
		throw new Error('deleteSnackeet() - Invalid _id')
	}

	const { data } = await axios.delete(`${API_URL}/snackeets/${story_id}`, {
		params: {
			project,
			organization_id,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}

async function updateTags({ story_id, project, tags, organization_id }) {
	if (!story_id) {
		throw new Error('UpdateSnackeet() - Invalid _id')
	}

	const { data } = await axios.put(
		`${API_URL}/snackeets/${story_id}/template_tags`,
		{ tags },
		{
			params: {
				project,
				organization_id,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)

	return data
}

async function get({ story_id, project }) {
	const { data } = await axios.get(`${API_URL}/snackeets/${story_id}`, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data?.snackeet
}

async function getProjectId({ story_id, organization_id }) {
	const { data } = await axios.get(`${API_URL}/snackeets/project/${story_id}`, {
		params: {
			organization_id,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data.snackeet
}

// Returns updatedAt timestamp
async function update(story) {
	try {
		const { data } = await axios.put(`${API_URL}/snackeets/${story.story_id}?project=${story.project}`, story, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data?.updatedAt
	} catch (err) {
		const {
			response: { data },
		} = err
		if (!data.success && data.logout) {
			throw new CustomerError(data)
		}
		throw err
	}
}

async function getUsersFromPage({ story_id, page_id, project, sort = 'asc', platform, since, until }) {
	if (!story_id || !page_id || !project) {
		throw new Error('Missing parameter')
	}

	const params = {
		project,
		platform,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	try {
		const { data } = await axios.get(`${API_URL}/published/${story_id}/pages/${page_id}/users?sort=${sort}`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
			params,
		})
		return data.users
	} catch (err) {
		console.log('Error getUsersFromPage', err)
		return []
	}
}

async function getPageMetrics({ story_id, page_id, project, platform, since, until }) {
	if (!story_id || !page_id || !project) {
		throw new Error('Missing parameter')
	}

	const params = {
		project,
		platform,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	try {
		if (story_id && page_id) {
			const { data } = await axios.get(`${API_URL}/published/${story_id}/pages/${page_id}/metrics`, {
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
				params,
			})
			return data?.metrics
		}
	} catch (e) {
		console.log('Error getPageMetrics', e)
		return []
	}
}

async function getAll({ project }) {
	try {
		if (!project) {
			throw new Error('SnackeetSvc.getAll() - Invalid project')
		}

		const { data } = await axios.get(`${API_URL}/snackeets?project=${project}`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.results
	} catch (err) {
		console.log('Snackeet.getAll', err)
		return []
	}
}

async function getSnackeetMetrics({ project, story_id, platform, since, until, interval }) {
	const params = {
		project,
		platform,
		interval,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	const { data } = await axios.get(`${API_URL}/published/${story_id}/metrics`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.metrics
}

async function getStoryCounters({ story_id, project }) {
	const { data } = await axios.get(`${API_URL}/published/${story_id}/counters`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params: {
			project,
		},
	})
	return data.counters
}

async function getSnackeetFunnel({ project, story_id, since, until, platform }) {
	const params = {
		project,
		platform,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	const { data } = await axios.get(`${API_URL}/published/${story_id}/funnel`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.funnel
}

async function getPageStats({ project, story_id, since, until, platform }) {
	const params = {
		project,
		platform,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	const { data } = await axios.get(`${API_URL}/published/${story_id}/pages_metrics`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.pages_metrics
}

async function exportStoryStats({ project, story_id, data }) {
	const params = {
		project,
	}

	return axios.post(
		`${API_URL}/published/${story_id}/export_graphs`,
		{ data },
		{
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
			params,
			responseType: 'blob',
		}
	)
}

async function getAnswersPerPage({ project, story_id, since, until, platform, page_id, page_type }) {
	const params = {
		project,
		platform,
		page_id,
		page_type,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	const { data } = await axios.get(`${API_URL}/published/${story_id}/answersPerPage`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.answerPercentages
}

async function GetPageDropdownAnswers({ project, story_id, since, until, platform, page_id, field_id }) {
	const params = {
		project,
		platform,
		page_id,
		field_id,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	const { data } = await axios.get(`${API_URL}/published/${story_id}/PageDropdownAnswers`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.answerPercentages
}

async function getUsersPerPlatform({ project, story_id, since, until }) {
	const params = {
		project,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	const { data } = await axios.get(`${API_URL}/published/${story_id}/usersPerPlatform`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.usersPerPlatform
}

async function getStoryUsersCount({ project, story_id, since, until, platform }) {
	const params = {
		project,
		platform,
	}

	if (since) {
		const convertedDate = dateWithoutTimezone(since)

		params.since = dayjs.utc(convertedDate).startOf('day').toISOString()
	}
	if (until) {
		const convertedDate = dateWithoutTimezone(until)

		params.until = dayjs.utc(convertedDate).endOf('day').toISOString()
	}

	const { data } = await axios.get(`${API_URL}/published/${story_id}/storyUsersCount`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.storyUsersCount
}

async function createPublishedStory({ data, project, organization_id }) {
	await axios.post(
		`${API_URL}/snackeets/${data.snackeet.story_id}/publish`,
		{},
		{
			params: {
				project,
				organization_id,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)

	return data.snackeet
}

async function getCarouselProductData({ project, story_id, url }) {
	const params = {
		project,
		url,
	}

	const { data } = await axios.get(`${API_URL}/snackeets/${story_id}/carousel/product`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.metaData
}

async function getButtonListFavicon({ project, story_id, url }) {
	const params = {
		project,
		url,
	}

	const { data } = await axios.get(`${API_URL}/snackeets/${story_id}/buttonList/favicon`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		params,
	})
	return data.favicon
}
