export const DEFAULT_FONT_FAMILIES = [
	'Abril Fatface',
	'Alegreya',
	'Alegreya SC',
	'Aleo',
	'Archivo Black',
	'Archivo Narrow',
	'Axiforma Bold Italic',
	'Axiforma Thin',
	'Axiforma Light',
	'Barlow Condensed',
	'B612',
	'Belleza',
	'BioRhyme',
	'Bree Serif',
	'Caladea',
	'Cardo',
	'Caveat',
	'Caveat Brush',
	'Charm',
	'Chivo',
	'Cinzel',
	'Cormorant Infant',
	'Courgette',
	'Crimson Pro',
	'Cutive Mono',
	'Eczar',
	'Fira Sans',
	'Fredericka the Great',
	'Great Vibes',
	'Hachi Maru Pop',
	'IBM Plex Sans',
	'Imbue',
	'Inknut Antiqua',
	'Inter',
	'Karla',
	'La Belle Aurore',
	'Lato',
	'Libre Baskerville',
	'Libre Franklin',
	'Lora',
	'Montserrat',
	'Neuton',
	'Noto Sans JP',
	'Noto Sans TC',
	'Oleo Script',
	'Oleo Script Swash Caps',
	'Open Sans',
	'Oswald',
	'PT Sans',
	'Pacifico',
	'Playfair Display',
	'Poppins',
	'Proza Libre',
	'Questrial',
	'Raleway',
	'Region Bretagne',
	'Roboto',
	'Roboto Condensed',
	'Roboto Slab',
	'Rokkitt',
	'Rubik',
	'Slabo 27px',
	'Source Sans Pro',
	'Source Serif Pro',
	'Space Grotesk',
	'Space Mono',
	'Spectral',
	'Squada One',
	'Sue Ellen Francisco',
	'Supermercado One',
	'Syne',
	'Titillium Web',
	'Ubuntu',
	'Unna',
	'VT323',
	'Vollkorn',
	'Vollkorn SC',
	'Yusei Magic',
]

const customFonts = ['Axiforma Bold Italic', 'Axiforma Thin', 'Axiforma Light', 'Region Bretagne']

export function getGoogleFontsUrl() {
	const query = DEFAULT_FONT_FAMILIES
		// Ignore all custom fonts for which we have the font files
		.filter((fontFamily) => !customFonts.includes(fontFamily))
		.reduce((queryString, family) => `${queryString}&family=${family.replace(/\s/g, '+')}:wght@400;700`, '')
		.replace(/^\&/, '')
	return `https://fonts.googleapis.com/css2?${query}&display=swap`
}
