export const en = {
	'form.name': 'Name',
	'form.description': 'Description',
	'form.first_name': 'First name',
	'form.email': 'Email',
	'form.last_name': 'Last name',
	'form.city': 'City',
	'form.country': 'Country',
	'form.text': 'Text',
	'form.phone': 'Phone number',
	'form.primary_contact': 'Primary contact',
	'form.secondary_contacts': 'Secondary contacts',
	// Customer
	'form.customer.first_name': 'First name',
	'form.customer.last_name': 'Last name',
	'form.customer.username': `User name`,
	'form.customer.email': 'Email',
	'form.customer.avatar': 'Picture',
	'form.customer.locale': 'Language',
	'form.customer.role': 'Role',
	// Organization
	'form.organization.name': 'Name',
	'form.organization.logo': 'Logo',
	// Story
	'form.story.name': 'Give it a name',
	// Banner Widget
	'form.thumbnail': 'Thumbnail',
}
