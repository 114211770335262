export const fr = {
	'password_reset.pwd_placeholder': 'Nouveau mot de passe',
	'password_reset.confirm_pwd_placeholder': 'Confirmer le mot de passe',
	'password_reset.organization_placeholder': 'Organisation',
	'password_reset.main_button': 'Soumettre',
	'password_reset.secondary_button': 'Se connecter',
	'password_reset.error_header': 'Jeton invalide',
	'password_reset.error_content': `Ce lien n'est pas valide. Si vous avez sollicité la réinitialisation de votre mot de passe, veuillez utiliser le lien reçu par email le plus récent.`,
	'password_reset.organization_error': `Vous devez spécifier l'organisation`,
	'password_reset.reset_success': `Le mot de passe a bien été réinitialisé 🎉 Redirection à la page de connection...`,
	'password_reset.reset_error': `Une erreure est survenue. Veuillez réessayer plus tard.`,
}
