export const en = {
	'stories_list.header_title': 'Stories',
	'banners_list.header_title': 'Banners',
	'brands_list.header_title': 'Brands',
	'leaderboards_list.header_title': 'Leaderboards',

	'brand_preset.title.name': 'Brand Name',
	'brand_preset.title.accessProjects': 'Brand Access - Admin Only',
	'brand_preset.title.logo': 'Brand Logo and Favicon',
	'brand_preset.title.font_family': 'Brand Font-family',
	'brand_preset.title.colors': 'Brand Colors',
	'brand_preset.title.fields_labels': 'Brand Fields and Labels',
	'brand_preset.title.buttons': 'Brand Buttons',
	'brand_preset.title.networks': 'Brand Networks',

	'brand_preset.name.label': 'Name',
	'brand_preset.name.placeholder': 'Enter brand name',
	'brand_preser.name.error': 'Brand name cannot be empty.',

	'brand_preset.accessProject.instruction':
		'This brand will be available for all users of the workspaces your will select below:',

	'brand_preset.logo.add_logo': 'Add a logo',
	'brand_preset.logo.change_logo': 'Change logo',
	'brand_preset.logo.no_logo': 'No logo has been set',

	'brand_preset.font_family.label': 'Font-family',
	'brand_preset.font_family.placeholder': 'Choose a font-family',
	'brand_preset.font_family.preview_title': 'Title, title, title, title, title, title',
	'brand_preset.font_family.preview_subtitle': 'Subtitle, subtitle, subtitle, subtitle, subtitle',
	'brand_preset.font_family.preview_paragraph': 'Paragraph, paragraph, paragraph, paragraph, paragraph',

	'brand_preset.labels.header': 'Label Styles',
	'brand_preset.fields.header': 'Field Styles',
	'brand_preset.fields_border.header': 'Field Border',
	'brand_preset.buttons_styles.header': 'Button Styles',
	'brand_preset.buttons_border.header': 'Button Border',

	'brand_preset.styles.font_size': 'Font size',
	'brand_preset.styles.text_color': 'Text color',
	'brand_preset.styles.background_color': 'Background color',
	'brand_preset.styles.border_size': 'Border size',
	'brand_preset.styles.border_color': 'Border color',
	'brand_preset.styles.border_radius': 'Border corner',

	'brand_preset.buttons.start': 'Start',

	'brand_card.choose_a_brand': 'Choose a Brand',
	'brand_card.no_brand': 'No Brand',

	'brands_list.empty_list_title': 'No Brands Preset yet',

	// Fonts
	'fonts_list.button.add_fonts': 'Add new fonts',
	'fonts_list.button.import_fonts': 'Import fonts',
	'fonts_list.table.title': 'Custom fonts',
	'fonts_list.table.header.fontFamily': 'Font Family',
	'fonts_list.table.header.fontWeight': 'Weight',
	'fonts_list.table.header.fontStyle': 'Style',
	'fonts_list.table.header.size': 'Size',
	'fonts_list.table.header.uploaded_at': 'Uploaded At',
}
