export const fr = {
	'subscription.alert.message.custom_limits': `Contactez nous si vous avez besoin d'espaces de travail ou de limites personnalisées`,
	'subscription.alert.message.upsell': `Contactez nous si vous voulez passer à un abonnement mensuel ou annuel`,
	'subscription.alert.button': 'Contact',
	'subscription.tab.title': 'Plans',
	'subscription.plan.free_tier': 'Plan Gratuit',

	'subscription.buttons.subscribe': "S'abonner",
	'subscription.buttons.subscription_active': 'Gérer',
	'subscription.buttons.license_upgrade': 'Améliorer',
	'subscription.buttons.license_downgrade': 'Rétrograder',
	'subscription.buttons.license_activated': 'Gérer',
	'subscription.currency_change.title': 'Changement de devise',
	'subscription.currency_change.content': `Si vous souhaitez changer la devise de votre abonnement, veuillez nous contacter à l'adresse suivante `,

	// License Tiers
	'license.tiers.snackeet_tier1': 'Tier 1',
	'license.tiers.snackeet_tier2': 'Tier 2',
	'license.tiers.snackeet_tier3': 'Tier 3',
	'license.tiers.snackeet_tier4': 'Tier 4',

	// Feature values
	'feature.value.unlimited': 'Ilimité',
	'feature.value.storage': '{ count } Go de stockage',
	'feature.value.per_month': '{ count }/Mois',
	'feature.value.stats_mode.basic': 'Basique',
	'feature.value.stats_mode.advanced': 'Avancé',
	'feature.value.support.priority': 'Priorité',
	'feature.value.support.email_chat': 'Email & Chat',

	// Subscription Features
	'subscription.features.upgrade_plan': 'Mettre à niveau',
	'subscription.features.amp.disabled': `Les Google WebStories ne sont pas disponibles dans votre plan. Nous vous invitons à passer à un plan supérieur.`,
	'subscription.features.disabled': `Cette fonctionnalité est actuellement désactivée, veuillez mettre à niveau votre plan pour l'activer.`,
	'subscription.story_banners.disabled': `Vous avez atteint le nombre maximum de bannières de widgets <b>({ banners_count }/{ limit_banners })</b>. Veuillez mettre à jour votre plan pour en créer d'autres.`,
	'subscription.story_create.disabled': `Vous avez atteint le nombre maximum de Stories <b>({ stories_count }/{ limit_stories })</b>. Veuillez mettre à jour votre plan pour en créer d'autres.`,

	// Feature Categories
	'feature.category.team': `Gestion d'équipe`,
	'feature.category.stories': `Stories`,
	'feature.category.amp_stories_features': `Fonctionnalités Spéciales Google Webstories`,
	'feature.category.snackeet_stories_features': `Fonctionnalités Spéciales Snackeet Webstories`,
	'feature.category.general': `Général`,
	'feature.category.in_website': `Dans votre site`,
	'feature.category.sharing': 'Partage',
	'feature.category.integrations': 'Intégrations',
	'feature.category.data_and_files': 'Données & Fichiers',
	'feature.category.support': 'Support',

	// ---------------------------------------------------------------------------------
	// List of features
	'feature.users_per_month': 'Utilisateurs actifs',

	// Team & Workspaces
	'feature.seats': `Taille de l'équipe`,
	'feature.multiple_workspaces': `Multiples espaces de travail`,

	// Stories
	'feature.stories_count': 'Nombre de stories',
	'feature.snackeet_stories': 'Snackeet Webstories',
	'feature.amp_stories': 'AMP/Google Webstories',

	// AMP Stories Features
	'feature.amp_stories_interactions': 'Intéractions (Sondages / Quiz)',
	'feature.amp_cta': 'Bouton CTA',
	'feature.amp_ads': 'Passez vos propres annonces',
	'feature.amp_form': 'Formulaire (Pages Jointes)',

	// Snackeet Stories Features
	'feature.snackeet_stories_types': 'Types: Advanced, Quiz, Sondage, Formulaire',
	'feature.cookie_less': '🔥 Sans Cookies',
	'feature.media_answers': 'Réponses Vidéo/Audio',
	'feature.conditional_logic': 'Logique conditionnelle',
	'feature.wheel': 'Gamification : Roue de la fortune',
	'feature.story_schedule': `Planification d'expiration de Story expiration`,
	'feature.automatic_reply': 'Réponse automatique une fois le formulaire complété',
	'feature.email_verification': `Vérification d'email`,
	'feature.leaderboards': 'Classements',

	// General
	'feature.brand_presets': 'Marque Prédéfinie',
	'feature.no_branding': 'Suppression du filigrane de Snackeet',
	'feature.custom_templates': 'Templates personnalisés',
	'feature.custom_fonts': 'Polices personnalisées',
	'feature.video_transcript': 'Génération de sous-titres vidéos automatiques',

	// Insert on your website
	'feature.story_banners': 'Bannières de Stories',
	'feature.iframe': 'Iframe',
	'feature.bubble_widget': 'Widget Flottant',

	// Sharing
	'feature.link': 'Lien',
	'feature.qr_code': 'QR Code',
	'feature.custom_story_slugs': 'Personnalisez le slug de votre Story',
	'feature.custom_fqdn': 'Utilisez votre nom de domaine (CNAME)',

	// Integrations
	'feature.integration_platforms': 'Zapier, Pabbly, Integrately',
	'feature.spreadsheets': 'Google Sheets, Air table (bientôt)',
	'feature.email_integrations': 'Sendinblue, MailerLite, Active Campaign',
	'feature.analytics_integration': 'Fb Pixel, GA, Google Tag Manager',
	'feature.webhooks': 'Webhooks',
	'feature.instagram': 'Instagram Connect pour comptes Business',

	// Data & Files
	'feature.data_export': 'Export de données CSV',
	'feature.stats_mode': 'Statistiques',
	'feature.stats_retention': 'Stockage des données de Stats (nombre de mois)',
	'feature.storage_quota': 'Stockage de fichiers (en Go)',

	// Support
	'feature.support': 'Type de Support',
}
