import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const CustomersSvc = {
	get,
	update,
	del,
	hasLicense,
	validateUsername,
}

export default CustomersSvc

async function get(_id) {
	try {
		if (!_id) {
			throw new Error('getCustomer() - Invalid _id')
		}

		const { data } = await axios.get(`${API_URL}/customers/${_id}`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return {
			success: true,
			customer: data.customer,
		}
	} catch (err) {
		return {
			success: false,
		}
	}
}

async function validateUsername(username) {
	const { data } = await axios.post(
		`${API_URL}/customers/validate`,
		{ username },
		{
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)
	return data.success
}

function update(_id, partial_data) {
	return axios.patch(`${API_URL}/customers/${_id}`, partial_data, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}

async function hasLicense(_id) {
	try {
		if (!_id) {
			throw new Error('hasLicense() - Invalid _id')
		}

		const { data } = await axios.get(`${API_URL}/customers/${_id}/hasLicense`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.success
	} catch (error) {
		return false
	}
}

function del(_id) {
	return axios.delete(`${API_URL}/customers/${_id}`, {
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}
