export const en = {
	'sharing_view.display_mode.full_screen': `Open in fullscreen`,
	'sharing_view.display_mode.embedded': 'Embedded',

	'sharing_view.display_mode.instruction_display': `Choose display mode`,
	'sharing_view.display_mode.instruction_settings': `How should it look ?`,
	'sharing_view.display_mode.instruction_embed_widget': `Install this code`,
	'sharing_view.display_mode.instruction_copypaste': `Copy and paste this code anywhere in the '<body>' section of your website`,
	'sharing_view.display_mode.instruction_variables': `(Advanced setup) Pass on <b>dynamic information</b> or <b>identify logged-in users</b> using variables through this script.`,
	'sharing_view.display_mode.variables_article_link': `Read this article for more information.`,

	'sharing_view.widget.text_bubble': `Text Bubble (Optional)`,
	'sharing_view.widget.text_collapse': 'Widget collapse text',
	'sharing_view.widget.avatar': `Icon`,
	'sharing_view.widget.avatar_displayed': `Hide miniature by default`,
	'sharing_view.widget.avatar_displayed.tooltip.message': `if enabled, the widget can be open using <b>"Open automatically"</b> or <b>"Widget Commands"</b>`,
	'sharing_view.widget.avatar_square': `Square`,
	'sharing_view.widget.avatar_horizontal': `Horizontal`,
	'sharing_view.widget.avatar_vertical': `Vertical`,

	'sharing_view.widget.open_settings.title': 'How should it open ?',
	'sharing_view.widget.open_settings.description': `How should the widget open?`,
	'sharing_view.widget.automatic_open.title': 'Open automatically',
	'sharing_view.widget.automatic_open.option.on_load': `When the user lands on the page`,
	'sharing_view.widget.automatic_open.option.on_timeout': 'After a certain period of time',
	'sharing_view.widget.automatic_open.option.on_timeout.parameter': 'Delay (in seconds)',
	'sharing_view.widget.automatic_open.option.on_scroll': `When the user scrolls down the page`,
	'sharing_view.widget.automatic_open.option.on_scroll.parameter': 'Scroll (in percentage)',
	'sharing_view.widget.automatic_open.option.on_exit': `When the user leaves the page`,

	// Sharing View tabs
	'sharing_view.title_1': 'SEO',
	'sharing_view.title_2': 'Channels',
	'sharing_view.title_3': 'Embed on your website',
	'sharing_view.tab.title.publisher': 'Publisher',
	'sharing_view.tab.title.metadata': 'Metadata',
	'sharing_view.tab.title.link': `Link`,
	'sharing_view.tab.title.email': `Email`,
	'sharing_view.tab.title.widget': `Floating Widget`,
	'sharing_view.tab.title.iframe': `Iframe`,
	'sharing_view.tab.title.qrcode': 'QR code',
	'sharing_view.tab.title.banner': 'Banner',

	'sharing_view.share_story.title': `Share your Story URL`,
	'sharing_view.share_story.description':
		'You can customize the link to use your own domain, improving SEO, enhancing brand recognition, and providing a more professional appearance.',
	'sharing_view.share_story.helper': `Learn more about variables in URL`,
	'sharing_view.share_story.subtitle': '🔗  Share the link below',
	'sharing_view.share_story.button.setup_custom_domain': 'Setup custom domain',
	'sharing_view.share_story.button.setup_favicon': 'Setup Favicon',
	'sharing_view.preview_mode': `Preview mode:`,
	'sharing_view.share_iframe_description': `You can change the height and width of the iframe to suit your site.`,
	'sharing_view.share_iframe_width': `Width:`,
	'sharing_view.share_iframe_height': `Height:`,

	'sharing_view.metadata': `Metadata`,
	'sharing_view.story_title': 'Add story title',
	'sharing_view.story_title.subtitle': 'Change story title when sharing',
	'sharing_view.story_title.placeholder': 'Enter a title',
	'sharing_view.add_description': `Add a description`,
	'sharing_view.add_description.subtitle': `When sharing, this text will be displayed in the thumbnails.`,
	'sharing_view.add_image': `Add an image`,
	'sharing_view.choose_image': `Change image`,
	'sharing_view.add_image.subtitle': `When sharing, this image will be displayed in the thumbnails.`,
	'sharing_view.add_image.error': `An error occurred with this image. Delete it or change it.`,
	'sharing_view.add_variables': `Add tracking variables`,
	'sharing_view.add_variables.subtitle': `Variables allow  known information to be passed in a URL. You can then use them in your story`,
	'sharing_view.add_variables.helper': `Know more about variables...`,
	'sharing_view.add_variables.reminder': `Don’t forget to replace XXXX by a value or another variable linked to your CRM. For example '{{variable}}'`,
	'sharing_view.share_email': `Share with an email`,
	'sharing_view.share_email.instruction': `Share your Story by email`,
	'sharing_view.share_email.instruction.snapshot': `Use the image or GIF below and insert the link to it`,
	'sharing_view.share_email.instruction.code.title': `Ready to be used `,
	'sharing_view.share_email.instruction.code.description': `Paste the code below directly in the mail.`,
	'sharing_view.share_email.download.gif': 'Download Story GIF',
	'sharing_view.share_email.download.png': 'Download Story PNG',
	'sharing_view.share_qrcode.instruction': 'Download and share your QR code',
	'sharing_view.share_qrcode.instruction.subtitle': 'Download your own QR code with your story link and share it',
	'sharing_view.export.instruction': 'Export and Download the story as images',

	'sharing_view.switch.enabled': 'On',
	'sharing_view.switch.disabled': 'Off',
	'sharing_view.delay_open': 'After',

	'sharing_view.tooltip.custom_slug.enabled': 'Customize the share link',
	'sharing_view.tooltip.custom_slug.disabled': 'You need a paying subscription in order to customize the share link',

	'sharing_view.publish_message.description': `Don't forget to publish the story to update the description !`,
	'sharing_view.publish_message.story_slug': `Don't forget to publish the story to update the share link !`,

	'sharing_view.widget_commands.title': 'Widget Commands',
	'sharing_view.widget_commands.description':
		'Whenever a Snackeet Widget is embedded on your website, you have access to commands to automatically trigger actions.',
	'sharing_view.widget_commands.bind_instructions': 'You can bind these commands on a button element, for example:',
	'sharing_view.widget_commands.stateCommands': 'Open or close',
	'sharing_view.widget_commands.displayCommands': 'Enable or disable full screen mode',
	'sharing_view.widget_commands.bubbleCommands': 'Display or hide the message bubble',
	'sharing_view.widget_commands.avatarCommands': 'Display or hide the icon',
	'sharing_view.widget_commands.minimizeCommands': 'Minimize or restore the widget',
}
