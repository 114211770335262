import { combineReducers } from '@reduxjs/toolkit'

import customerReducer from './customer/slice'
import fontsReducer from './fonts/slice'
import organizationReducer from './organization/slice'
import projectReducer from './project/slice'
import subscriptionReducer from './subscription/slice'

const rootReducer = combineReducers({
	customer: customerReducer,
	organization: organizationReducer,
	subscription: subscriptionReducer,
	project: projectReducer,
	fonts: fontsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
