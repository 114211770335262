export const AmpInteractionList = [
	{
		key: '0',
		type: 'amp_poll',
		image: '/static/amp_interaction_images/amp_poll.png',
	},
	{
		key: '2',
		type: 'amp_image_poll',
		image: '/static/amp_interaction_images/amp_image_poll.png',
	},
	{
		key: '3',
		type: 'amp_quiz',
		image: '/static/amp_interaction_images/amp_quiz.png',
	},
	{
		key: '4',
		type: 'amp_image_quiz',
		image: '/static/amp_interaction_images/amp_image_quiz.png',
	},
	{
		key: '1',
		type: 'amp_binary_poll',
		image: '/static/amp_interaction_images/amp_binary_poll.png',
	},
	{
		key: '5',
		type: 'amp_slider',
		image: '/static/amp_interaction_images/amp_slider.png',
	},
]

export const AmpInteractionTypes = [
	'amp_poll',
	'amp_binary_poll',
	'amp_image_poll',
	'amp_quiz',
	'amp_image_quiz',
	'amp_slider',
]
