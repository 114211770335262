import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _remove from 'lodash/remove'
import { loginFail, logoutSuccess } from 'redux/customer/slice'
import FontsSvc from 'services/FontsSvc'

export const fetchFonts = createAsyncThunk('fonts/fetchFonts', async (organization_id, thunkAPI) => {
	const fonts = await FontsSvc.getAll({ organization_id })
	return { organization_id, fonts }
})
const initialState = {
	entities: [],
	isLoading: false,
	organizationId: undefined,
}

const fontSlice = createSlice({
	name: 'fonts',
	initialState,
	reducers: {
		addFonts: (state, action) => {
			state.entities.push(...action.payload)
		},
		deleteFonts: (state, action) => {
			const fontIds = action.payload ?? []
			_remove(state.entities, (font) => fontIds.includes(font.font_id))
		},
	},
	extraReducers: (builder) => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder
			.addCase(fetchFonts.pending, (state, action) => {
				state.isLoading = true
			})
			.addCase(fetchFonts.fulfilled, (state, action) => {
				state.isLoading = false
				state.entities = action.payload.fonts
			})
			.addCase(logoutSuccess, () => initialState)
			.addCase(loginFail, () => initialState)
	},
})

export const { addFonts, deleteFonts } = fontSlice.actions

export default fontSlice.reducer
