import { createSelector } from '@reduxjs/toolkit'

export const organizationSelector = (state, _props) => state.organization

export const getCurrentOrganizationId = createSelector(organizationSelector, (state) => state?._id)

export const getCurrentOrganizationChargebeeId = createSelector(organizationSelector, (state) => state?.chargebee_id)

export const getCurrentSlug = createSelector(organizationSelector, (state) => state?.slug)

export const getOrganizationLogo = createSelector(organizationSelector, (state) => state?.logo)

export const getOrganizationName = createSelector(organizationSelector, (state) => state?.name)

export const getOrganizationMembersCount = createSelector(organizationSelector, (state) => state?.members?.length)

export const getInstagramAccounts = createSelector(organizationSelector, (state) => state?.integrations?.instagram)

export const getTimezone = createSelector(organizationSelector, (state) => state?.settings?.timezone)
