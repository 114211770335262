export const en = {
	'page_title.login': 'Log in | Snackeet',
	'page_title.password_reset': 'Password Reset | Snackeet',
	'page_title.activation': 'Activation | Snackeet',
	'page_title.register': 'Register | Snackeet',
	'page_title.mobile': 'Snackeet',

	'page_title.account': 'Account | Snackeet',
	'page_title.organization_settings': 'Organization | Snackeet',
	'page_title.analytics': 'Analytics | Snackeet',

	'page_title.editor': 'Editor | Snackeet',
	'page_title.widget_editor': 'Widget Editor | Snackeet',
	'page_title.brand_editor': 'Brand Editor | Snackeet',
	'page_title.leaderboard_editor': 'Leaderboard Editor | Snackeet',

	'page_title.brands_list': 'Brands | Snackeet',
	'page_title.widgets_list': 'Widgets | Snackeet',
	'page_title.stories_list': 'Stories | Snackeet',
	'page_title.workspaces_list': 'Workspaces | Snackeet',
	'page_title.leaderboards_list': 'Leaderboards | Snackeet',
	'page_title.domains_list': 'Domains | Snackeet',

	'page_title.404': 'Page Not Found | Snackeet',
	'404.main': 'Page Not Found',
	'404.button': 'Go to Homepage',
}
