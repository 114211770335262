export function getFontName(font) {
	return font.metadata?.fontSubfamily !== 'Regular'
		? `${font.metadata.fontFamily} ${font.metadata.fontSubfamily}`
		: font.metadata.fontFamily
}

export function getFontFamily(brandFontFamily, currentFontFamily) {
	if (brandFontFamily) return brandFontFamily

	if (currentFontFamily) return currentFontFamily

	return 'Roboto'
}
