export const en = {
	'interactions.page.title': 'Interactions',
	'interactions.export.button': 'Export users answers',
	'interactions.to_story.button': 'View story graphs',
	'interactions.export.in_progress': 'Exporting answers...',
	'interactions.snackeet_list.title': 'Stories',

	'interaction.user_count': '{ count } { count, plural, one { user } other { users }}',
	'interaction.user': 'User',

	'interactions.tab.users': 'Users',
	'interactions.tab.pages': 'Pages',
	'interactions.tab.options.user_answers': 'Users with answers',
	'interactions.tab.options.user_files': 'Users with files uploaded',
	'interactions.tab.options.successful_quiz': 'Users with successful quiz',

	'interaction.user_top_info.snackeet': 'Stories',
	'interaction.user_top_info.source': 'Source',

	'interactions.empty_users': 'No users found',
	'interactions.empty_pages': 'No pages found',

	'interactions.delete_session.header': 'Are you sure to perform the delete?',
	'interactions.delete_session.options':
		'This is an <b>irreversible operation.</b><br></br><br></br>Deletion options',
	'interactions.delete_session.remove_session': `<b>Remove Session:</b> Remove interaction of the user in the current story`,
	'interactions.delete_session.remove_user': `<b>Remove User:</b> Remove the user and interactions from all stories`,
	'interactions.delete_session.button.remove_session': 'Remove Session',
	'interactions.delete_session.button.remove_user': 'Remove User',

	'interactions.user_information': 'User information (Variables)',
	'interaction.page_type.blank': 'No Answer',
	'interaction.page_type.start_page': 'Welcome page',
	'interaction.page_type.ending_page': 'End page',
	'interaction.page_type.multiple_choice': 'Button/Choice',
	'interaction.page_type.form': 'Form',
	'interaction.page_type.rating': 'NPS/Rating ',
	'interaction.page_type.media_answer': 'Media',
	'interaction.page_type.game': 'Game',
	'interaction.page_type.amp_page': 'AMP Page',

	'interaction.views': 'Impressions',
	'interaction.unique_users': 'Unique users',
	'interaction.answers_count': 'Answers',
	'interaction.clicks_count': 'Outlink Clicked',

	'interaction.page_type.game.wheel': 'Wheel',

	'interaction.page_top_info.completed': 'Completed',
	'interaction.page_top_info.not_completed': 'Not completed',
	'interaction.page_top_info.average_score': 'Average score',

	'interaction.pages_seen': 'Pages seen',
	'interaction.user_platform': 'User Platform',

	'interaction.null_data': 'Unknown, empty or null data',
	'interaction.limit_data': `You've reached your responses limit this month. Upgrade your current Plan for more data.`,
	'interaction.page_info': 'Page info',

	'interaction.user_landed': 'Unique Users',
	'interaction.set_duration': 'Set duration',
	'interaction.average_spent_time': 'Avg. time spent',
	'interaction.total_spent_time': 'Total time spent',
	'interaction.time_landed': `Opening time`,
	'interaction.spent_time': `Time spent`,
	'interaction.answered': `Answered`,

	'interaction.no_users': `No users on this page.`,
	'interaction.timer_unset': 'Unset',

	'interaction.tooltip.preview_trigger': 'View page',
	'interaction.tooltip.icon.files_uploaded': 'This user has uploaded files',

	'interaction.sec_stay': 'Sec stay',
	'interaction.min_stay': 'Min stay',
	'interaction.user_events': 'Page Events',
	'interaction.user_answers': 'User answers',
	'interaction.empty_events': 'No page events',
	'interaction.empty_answers': 'No user answers',
	'interaction.empty_history': 'No recorded events',

	'interactions.export.user_answers': 'Export User Answers',

	'interactions.uploaded_files.title': 'User files',
	'interactions.uploaded_files.message': 'No user files uploaded yet.',
	'interactions.uploaded_files.per_page': 'Files per page',

	'interactions.wheel.prize': 'Prize',
	'interactions.wheel.email': 'Email',
}
