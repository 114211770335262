export const fr = {
	'sharing_view.display_mode.full_screen': `Ouverture en plein écran`,
	'sharing_view.display_mode.embedded': 'Intégré',

	'sharing_view.display_mode.instruction_display': `Choisissez le mode d'affichage.`,
	'sharing_view.display_mode.instruction_settings': `Comment doit-il être ?`,
	'sharing_view.display_mode.instruction_embed_widget': `Insérez ce code`,
	'sharing_view.display_mode.instruction_copypaste': `Copiez et collez ce code n'importe où dans la section '<body>' de votre site web`,
	'sharing_view.display_mode.instruction_variables': `(Configuration avancée) Transmettez des <b>informations dynamiques</b> ou <b>identifiez les utilisateurs connectés</b> à l'aide de variables via ce script.`,
	'sharing_view.display_mode.variables_article_link': `Lisez cet article pour en savoir plus.`,

	'sharing_view.widget.text_bubble': `Bulle de Texte (Facultatif)`,
	'sharing_view.widget.text_collapse': 'Texte de réduction du widget',
	'sharing_view.widget.avatar': `Icône`,
	'sharing_view.widget.avatar_displayed': `Cacher la miniature par défaut`,
	'sharing_view.widget.avatar_displayed.tooltip.message': `Si activée, le widget peut être ouvert en utilisant <b>"Ouvrir automatiquement"</b> ou <b>"Commandes du Widget"</b>`,
	'sharing_view.widget.avatar_square': `Carré`,
	'sharing_view.widget.avatar_horizontal': `horizontal`,
	'sharing_view.widget.avatar_vertical': `Vertical`,

	'sharing_view.widget.open_settings.title': `Comment doit-il s'ouvrir ?`,
	'sharing_view.widget.open_settings.description': `Comment le widget devrait s'ouvrir?`,
	'sharing_view.widget.automatic_open.title': 'Ouvrir automatiquement',
	'sharing_view.widget.automatic_open.option.on_load': `Lorsque l'utilisateur arrive sur la page`,
	'sharing_view.widget.automatic_open.option.on_timeout': 'Après un certain délai',
	'sharing_view.widget.automatic_open.option.on_timeout.parameter': 'Délai (en secondes)',
	'sharing_view.widget.automatic_open.option.on_scroll': `Lorsque l'utilisateur fait défiler la page`,
	'sharing_view.widget.automatic_open.option.on_scroll.parameter': 'Défilement (en pourcentage)',
	'sharing_view.widget.automatic_open.option.on_exit': `Lorsque l'utilisateur quitte la page`,

	// Sharing View tabs
	'sharing_view.title_1': 'SEO',
	'sharing_view.title_2': 'Canaux',
	'sharing_view.title_3': 'Intégrez sur votre site Web',
	'sharing_view.tab.title.publisher': 'Publisher',
	'sharing_view.tab.title.metadata': 'Metadonnées',
	'sharing_view.tab.title.link': `Lien`,
	'sharing_view.tab.title.email': `Email`,
	'sharing_view.tab.title.widget': `Widget Flottant`,
	'sharing_view.tab.title.iframe': `Iframe`,
	'sharing_view.tab.title.qrcode': 'QR Code',
	'sharing_view.tab.title.banner': 'Bannière',

	'sharing_view.share_story.title': `Partagez votre Story à partir de ce lien`,
	'sharing_view.share_story.description': `Vous pouvez personnaliser le lien pour utiliser votre propre domaine, améliorant ainsi le SEO, renforçant la reconnaissance de la marque et offrant une apparence plus professionnelle.`,
	'sharing_view.share_story.helper': `En savoir plus de variables dans URL`,
	'sharing_view.share_story.subtitle': '🔗  Partagez le lien ci-dessous',
	'sharing_view.share_story.button.setup_custom_domain': 'Configurer domaine personnalisé',
	'sharing_view.share_story.button.setup_favicon': 'Configurer Favicon',
	'sharing_view.preview_mode': `Mode Aperçu`,
	'sharing_view.share_iframe_description': `Vous pouvez modifier la hauteur et la largeur de l'iframe pour l'adapter à votre site.`,
	'sharing_view.share_iframe_width': `Largeur:`,
	'sharing_view.share_iframe_height': `Hauteur:`,

	'sharing_view.metadata': `Métadonnées`,
	'sharing_view.story_title': `Ajouter le titre de la story`,
	'sharing_view.story_title.subtitle': 'Changer le titre de la story lors du partage',
	'sharing_view.story_title.placeholder': 'Entrez un titre',
	'sharing_view.add_description': `Ajouter une description`,
	'sharing_view.add_description.subtitle': `Lors du partage, ce texte sera affiché dans les vignettes.`,
	'sharing_view.add_image': `Ajouter une image`,
	'sharing_view.choose_image': `Changer image`,
	'sharing_view.add_image.subtitle': `Lors du partage, cette image sera affiché dans les vignettes.`,
	'sharing_view.add_image.error': `Un probème est survenu avec cette image. Supprimez-la ou changer la.`,
	'sharing_view.add_variables': `Attribuer des variables de suivi`,
	'sharing_view.add_variables.subtitle': `Les variables permettent de transmettre des informations connues dans une URL. Vous pouvez ensuite les utiliser dans vos story.`,
	'sharing_view.add_variables.helper': `En savoir plus sur les variables...`,
	'sharing_view.add_variables.reminder': `N'oubliez pas de remplacer XXXX par une valeur ou une autre variable liée à votre CRM. Par exemple '{{variable}}'`,
	'sharing_view.share_email': `Partager dans un email`,
	'sharing_view.share_email.instruction': `Copier et collez dans votre email`,
	'sharing_view.share_email.instruction.snapshot': `Utilisez l'image ou le GIF ci-dessous et insérez le lien vers celui-ci`,
	'sharing_view.share_email.instruction.code.title': `Prêt à être utilisé`,
	'sharing_view.share_email.instruction.code.description': `Collez le code ci-dessous directement dans l'e-mail.`,
	'sharing_view.share_email.download.gif': 'Télécharger Story GIF',
	'sharing_view.share_email.download.png': 'Télécharger Story PNG',
	'sharing_view.share_qrcode.instruction': 'Télécharger et partager votre QR code',
	'sharing_view.share_qrcode.instruction.subtitle':
		'Télèchargez votre propre QR code avec le lien de votre story et partagez-le',
	'sharing_view.export.instruction': `Exportez et téléchargez la story en tant qu'images`,

	'sharing_view.switch.enabled': 'Activé',
	'sharing_view.switch.disabled': 'Desactivé',
	'sharing_view.delay_open': 'Après',

	'sharing_view.tooltip.custom_slug.enabled': 'Personnaliser le lien de partage',
	'sharing_view.tooltip.custom_slug.disabled': 'Il faut un abonnement payant pour modifier le lien de partage',

	'sharing_view.publish_message.description': `N'oubliez pas de publier la story pour mettre à jour la description !`,
	'sharing_view.publish_message.story_slug': `N'oubliez pas de publier la story pour mettre à jour le lien de partage !`,

	'sharing_view.widget_commands.title': 'Commandes du Widget',
	'sharing_view.widget_commands.description': `Lorsqu'un widget Snackeet est intégré à votre site Web, vous avez accès à des commandes permettant de déclencher automatiquement des actions.`,
	'sharing_view.widget_commands.bind_instructions':
		'Vous pouvez lier ces commandes à un élément de type bouton, par exemple :',
	'sharing_view.widget_commands.stateCommands': 'Ouvrir ou fermer',
	'sharing_view.widget_commands.displayCommands': 'Activer ou désactiver le mode plein écran',
	'sharing_view.widget_commands.bubbleCommands': 'Afficher ou cacher la bulle de message',
	'sharing_view.widget_commands.avatarCommands': `Afficher ou cacher l'icône`,
	'sharing_view.widget_commands.minimizeCommands': 'Minimiser ou restaurer le widget',
}
