import { useEffect, useState } from 'react'

// ⚠️ Because we consider each custom font as a separate font-family
// (even if from same font family but different style/weight),
// we DO NOT set the font-style & font-weight in FontFace

export default function useCustomFonts(fonts = []) {
	const [fontFaces, setFontFaces] = useState(new Set())
	const [fontFaceSets, setFontFaceSets] = useState(new Set())

	useEffect(
		() => {
			const loadFonts = () => {
				fonts.forEach(async (font) => {
					const fontFace = new FontFace(font.font_id, `url(${font.url})`)
					document.fonts.add(fontFace)
					setFontFaces((prevFontFaces) => prevFontFaces.add(fontFace))

					try {
						const loadedFont = await fontFace.load()
						setFontFaceSets((prevFontFaceSets) => prevFontFaceSets.add(loadedFont))
					} catch (err) {
						console.error(`❌ Font load: ${font.font_id}`, err)
					}
				})
			}

			loadFonts()

			return () => {
				fontFaces.forEach((fontFace) => {
					document.fonts.delete(fontFace)
				})
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fonts]
	)

	useEffect(() => {
		Promise.all([...fontFaceSets]).then(() => {
			console.log('✅ All fonts have loaded')
		})
	}, [fontFaceSets])
}
